<template>
  <div style="padding: 12px" class="column is-4">
    <div class="columns">
      <DeliveryLog 
        v-if="selectedShipNotice && selectedShipNotice.DeliveryLogs &&  selectedShipNotice.DeliveryLogs.length > 0"
        :deliveryLogs="selectedShipNotice.DeliveryLogs"  />
    </div>

    <div class="columns">
      <MaterialReceipt 
        v-if="selectedShipNotice && selectedShipNotice.Receipts &&  selectedShipNotice.Receipts.length > 0"
        :receipts="selectedShipNotice.Receipts" />
    </div>

  </div>
  
</template>

<script>
  import DeliveryLog from './DeliveryLog.vue';
  import MaterialReceipt from './MaterialReceipt.vue';
  export default {
    name: 'ShipNoticeExtra',
    components: { DeliveryLog, MaterialReceipt },
    props: ['selectedShipNotice']
  }
</script>

<style lang="less" scoped>
  .custom-label-style{
    margin: 0px !important
  }
</style>