<template>
  <div style="box-sizing: border-box">
    <Alert ref="alert" />
    <InProcess />
    <aside
      class="sidebar"
    >
      <Sidebar 
        @onSignOut="signOut"
      />
    </aside>
      <div class="main-content">
        <div class="fulfillify-header">
          <img
            class="fulfillify-logo"
            alt="Fulfillify Logo"
            src="../assets/favicon.png"
          />
          <div class="mobile-menu-btn">
            <i @click="onSwitchShowMenuFlag" class="fa fa-bars menu-icon"></i>
            <div v-if="showMenu" class="mobile-menu-dropdown">
              <Menu  
                @onSignOut="signOut"
                @onShowHideMenu="onSwitchShowMenuFlag" />
            </div>
          </div>
        </div>
        <FailedLoading class="failed-loading" v-if="failedLoading" />
        <router-view v-else-if="requiredResourcesLoaded" v-slot="{ Component }">
          <keep-alive :include="cachedComponent">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _cookie from "js-cookie"
import api from './../lib/api-gateway';
import Sidebar from "../components/Common/Sidebar.vue";
import Menu from "../components/Common/Menu.vue";
import Alert from "../components/Common/Alert.vue";
import InProcess from './../components/Common/InProcess';
import FailedLoading from '../components/Common/FailedLoading.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "Home",
  components: { Sidebar, InProcess, Menu, Alert, FailedLoading },
  data(){
    return{ 
      showMenu: false,
      cachedComponent: null,
      failedLoading: false,
      initialResourcesLoaded: false,
      allResourcesLoaded: false
    };
  },
  async created() {
    try {
      if(!await this.loadInitialResources())
        return;
    
      this.initialResourcesLoaded = true;

      const apiCalls = [
          [ api.getWarehouses, (res) => this.onLoadSetWarehouses(res.result.warehouses.filter(w => !w.IsThirdParty)) ],
          [ api.getCountriesAndStates, (res) => {
              this.onLoadSetCountries(res.result.countries);
              this.onLoadSetStates(res.result.states);
            } ],
          [ api.getCarriers, (res) => this.onLoadSetCarriers(res.result.carriers) ],
          [ api.getServiceLevels, (res) => this.onLoadSetServiceLevels(res.result.serviceLevels) ],
          [ api.getItemGroups, (res) => this.onSetItemGroups(res.result.itemGroups) ]
        ];
      
      await this.makeApiCallsAndHandleResult(apiCalls);

      this.allResourcesLoaded = true;
    } catch(err) {
      console.log(err);
      this.failedLoading = true;
    }
  },
  provide() {
    return {
      alert: async(...params) => {
        await this.$refs.alert.alert(...params);
      },
      confirm: async(...params) => {
        return await this.$refs.alert.confirm(...params);
      },
      prompt: async(...params) => {
        return await this.$refs.alert.prompt(...params);
      }
    }
  },
  watch: {
    $route(val) {
      switch(val.name) {
        case 'Orders':
        case 'OrderDetail':
        case 'OrderEdit':
        case 'DeductOrderDetail':
        case 'DeductOrderEdit':
          this.cachedComponent = 'OrdersContainer';
          break;
        case 'ArchivedOrders':
        case 'ArchivedOrderDetail':
          this.cachedComponent = 'ArchivedOrdersContainer';
          break;
        case 'OrderImports':
        case 'OrderImportDetail':
          this.cachedComponent = 'OrderImports';
          break;
        case 'Inventory':
        case 'ProductDetail':
          this.cachedComponent = 'Inventory';
          break;
        case 'ItemImports':
        case 'ItemImportDetail':
          this.cachedComponent = 'ItemImports';
          break;
        case 'Shipments':
        case 'ShipNoticeDetail':
          this.cachedComponent = 'Shipments';
          break;
        case 'ManageClients':
        case 'ClientDetailManage':
          this.cachedComponent = 'ManageClients';
          break;
        case 'ManageUsers':
        case 'UserDetailManage':
          this.cachedComponent = 'ManageUsers';
          break;
        case 'UsersSettings':
        case 'UserDetailSettings':
          this.cachedComponent = 'UsersSettings';
          break;
        case 'Recipients':
        case 'RecipientDetail':
          this.cachedComponent = 'Recipients';
          break;
        case 'Webhooks':
        case 'WebhookDetail':
          this.cachedComponent = 'Webhooks';
          break;
        default:
          // I think keep-alive's logic checks the 'include' property on component deactivation,
          // so this can't be empty, otherwise all components not in the cases above get cached.
          this.cachedComponent = 'invalid-component';
      }
    }
  },
  computed:{
    ...mapGetters(['loggedInUser', 'isUserInternal']),
    requiredResourcesLoaded() {
      return this.initialResourcesLoaded &&
        (!this.$route.meta.allResourcesRequired || this.allResourcesLoaded);
    }
  },
  methods: { 
    ...mapActions([
      'onMakingAPICalls',
      'onSetCurrentClient',
      'onLoadSetLoggedInUser',
      'onLoadSetClients',
      'onLoadSetWarehouses',
      'onLoadSetCountries',
      'onLoadSetStates',
      'onLoadSetCarriers',
      'onLoadSetServiceLevels'
    ]),
    ...mapActions('product', ['onSetItemGroups']),
    async loadInitialResources() {
      await this.makeApiCallAndHandleResult(
        api.getLoggedInUser,
        res => this.onLoadSetLoggedInUser(res.result.loggedInUser));
      
      if(!this.isUserInternal && _cookie.get('fulfillify-client-id')) {
        await this.signOut();
        return false;
      }

      const cookieClientId = parseInt(_cookie.get('fulfillify-client-id'));
      if(cookieClientId)
        api.setClientId(cookieClientId);

      await this.makeApiCallAndHandleResult(
        api.getCurrentClient,
        res => this.onSetCurrentClient(res.result.client));

      if(this.isUserInternal)
        await this.makeApiCallAndHandleResult(
          api.getClientsList,
          res => this.onLoadSetClients(res.result));
      
      return true;
    },
    signOut: async function() {
      this.onMakingAPICalls('Logging out...');
      
      try {
        await api.logout();
      } catch {/**/}
      
      _cookie.remove("fulfillify-token");
      _cookie.remove("fulfillify-client-id");

      window.location.href = this.$router.resolve({ name: 'Login' }).href;
    },
    onSwitchShowMenuFlag(){ 
      this.showMenu = !this.showMenu;
    },
    async makeApiCallAndHandleResult(apiCall, successHandler) {
      const res = await apiCall();
      if(res.error)
        throw new Error(res.message);
      else
        await successHandler(res);
    },
    async makeApiCallsAndHandleResult(apiCalls) {
      await Promise.all(
        apiCalls.map(async ([apiCall, successHandler]) =>
          await this.makeApiCallAndHandleResult(apiCall, successHandler)));
    }
  }
};
</script>
<style lang="less" scoped>
.fulfillify-header {
  display: none;
  height: 48px;
  width: 100%;
}
.sidebar{
  z-index: 2;
  position: fixed;
  height: 100vh;
}
.fulfillify-logo {
  float: left;
  height: 40px;
  widows: 40px;
  position: relative;
}
.mobile-menu-btn {
  float: right;
  margin-left: 20px;
}
.menu-icon{
  font-size: 2em;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media screen and (min-width: 561px)
  and (max-width: 880px){
   .fulfillify-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-right: 1.5em;
    border-bottom: 0.5px solid rgba(162,166,176, .6);
  }
  .sidebar{
    display: none;
  }
}
@media screen and (min-width: 270px)
  and (max-width: 560px){
    .fulfillify-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 0.5px solid rgba(162,166,176, .6);
  }
  .sidebar{
    display: none;
  }
}
@media screen and (min-width: 880px){
  .failed-loading {
    padding-left: 2.8em;
  }
  .mobile-menu-btn {
    display: none;
  }
  .mobile-menu-dropdown{
    display: none;
  }
}

@media screen and (min-width: 880px) and (max-width: 1024px) {
  .main-content{
    padding-left: 66px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1460px) {
  .main-content{
    padding-left: 68px;
  }
}

@media screen and (min-width: 1461px) {
  .main-content{
    padding-left: 70px;
  }
}
</style>

<style lang="less">
.blue-message {
  background: var(--theme-gray-bg) !important;
  .message-header {
    background: var(--theme-blue) !important;
    font-weight: normal !important;
    color: white !important;
    * {
      color: white !important;
    }
  }
}
.blue-button-outlined {
  box-sizing: border-box;
  border-color: var(--theme-blue) !important;
  * {
    color: var(--theme-blue) !important;
  }
}
.red-button-outlined {
  border-color: var(--theme-red) !important;
  * {
    color: var(--theme-red) !important;
  }
}
.blue-button {
  background: var(--theme-blue) !important;
  border-color: var(--theme-blue) !important;
  * {
  color: white !important;
}
}
.red-button {
  background: var(--theme-red) !important;
  border-color: var(--theme-red) !important;
  * {
  color: white !important;
}
}
.blue-button, .blue-button-outlined {
  &:active:enabled, &:focus {
    border-color: var(--theme-blue) !important;
  }
}
.red-button, .red-button-outlined {
  &:active:enabled, &:focus {
    border-color: var(--theme-red) !important;
  }
}
.blue-button, .red-button, .blue-button-outlined, .red-button-outlined {
  &:hover {
    box-shadow: 0 0 2px #b5b5b5 !important;
  }
}
.bigger-button {
  font-size: 18px !important;
  padding: .75em 1.25em !important;
}

@media screen and (max-width: 879px) {
  .bigger-button {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 880px) and (max-width: 1024px) {
  .bigger-button {
    font-size: 17px !important;
  }
}
</style>
