<template>
  <Container :title="`${selectedUser.FirstName} ${selectedUser.LastName}`">
    <template #actions>
      <UserEditActions :isUserValid="isUserValid"
        :showUpdateUser="enableEditingRole || enableActivatingDeactivating"
        :showDeleteUser="enableDeleting" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <UserHeaderInfo
          :disableValidation="true"
          :validateRole="enableEditingRole"
          @isUserValid="isUserValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <UserAccount
            :enableEditingRole="enableEditingRole"
            :enableActivatingDeactivating="enableActivatingDeactivating"
            class="end-form-section" />
          <div class="form-section-heading">
            USER INFORMATION
          </div>
          <UserBasic :disableEditing="true" />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import UserEditActions from './Header/UserEditActions.vue';
import UserHeaderInfo from '../Manage/UserHeaderInfo.vue';
import UserAccount from '../Manage/UserAccount.vue';
import UserBasic from '../Manage/UserBasic.vue';
export default {
  name: 'UserDetail',
  components: { Container, EntityData, UserEditActions, UserHeaderInfo, UserAccount, UserBasic },
  data() {
    return {
      isUserValid: false
    }
  },
  created() {
    this.onSetEditSelectedUser();
  },
  computed: {
    ...mapGetters(['isUserAdmin', 'isUserInternal', 'isUserOwner']),
    ...mapGetters('manage', ['selectedUser']),
    enableDeleting() {
      return this.isUserAdmin;
    },
    enableEditingRole() {
      return this.isUserInternal && this.selectedUser.Role != 'API';
    },
    enableActivatingDeactivating() {
      return (this.isUserInternal && this.selectedUser.Role != 'API') ||
        (this.isUserOwner && this.selectedUser.Role == 'User');
    }
  },
  methods: {
    ...mapActions('manage', ['onSetEditSelectedUser'])
  }
}
</script>

<style lang="less" scoped>
.form-section-heading {
  font-size: 16px;
  font-weight: 350;
  letter-spacing: .5;
  margin-bottom: 20px;

  :not(:first-child) {
    margin-top: 20px;
  }
}
.end-form-section {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
</style>