<template>
  <div class="sidebar-container">
    <img :src="companyLogo" class="company-logo" />
    <p class="website-title">fulfillify 2.0</p>
    <ul class="sidebar-list">
      <SidebarItem
        v-for="(item, index) in filteredSideBarItems"
        :key="index"
        :sidebarItem="item"
        @itemClicked="itemClicked"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from "./SidebarItem";
export default {
  name: "Sidebar",
  emits: ['onSignOut'],
  components: { SidebarItem },
  data() {
    return {
      sideBarItems: [
        {
          name: "Dashboard",
          linkName: "Dashboard",
          iconType: "fas fa-home"
        },
        {
          name: "Orders",
          linkName: "Orders",
          iconType: "fas fa-box-open",
          urlSection: "orders"
        },
        {
          name: "Inventory",
          linkName: "Inventory",
          iconType: "fas fa-pallet",
          urlSection: "inventory"
        },
        {
          name: "Shipments",
          linkName: "Shipments",
          iconType: "fas fa-truck",
          urlSection: "shipments"
        },
        {
          name: "Analytics",
          linkName: "Analytics",
          iconType: "fa fa-chart-pie",
        },
        {
          name: "Manage",
          linkName: "Manage",
          iconType: "fas fa-user-lock",
          urlSection: "manage"
        },
        {
          name: "Settings",
          linkName: "Settings",
          iconType: "fas fa-cogs",
          urlSection: "settings"
        },
        {
          name: "Support",
          linkName: "Support",
          iconType: "fas fa-headset",
        },
        {
          name: "Logout",
          iconType: "fas fa-sign-out-alt",
          style: "margin-top: .3em",
          bgColor: '#414b52'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['isUserInternal', 'currentClient']),
    companyLogo(){
      return require('@/assets/favicon.png')
    },
    filteredSideBarItems() {
      return this.sideBarItems.filter(i =>
        i.name == 'Manage' ? this.isUserInternal :
        i.name == 'Analytics' ? !!this.currentClient.PowerBiReportId :
        true
      );
    }
  },
  methods: {
    itemClicked(name) {
      if(name == 'Logout')
        this.$emit('onSignOut');
    }
  }
};
</script>

<style lang="less" scoped>
.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.company-logo {
  height: 44px;
  margin-top: 12px;
}
.website-title {
  font-size: 11px;
  color: #555;
}
.sidebar-list {
  width: 70px;
  list-style: none;
  margin-top: calc(6vh - 10px);
  margin-bottom: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    flex-grow: 1;
    height: 1px;
    max-height: 62px;
  }
}

@media screen and (min-width: 561px)
  and (max-width: 880px){
    .sidebar-container{
      display: none;
    }
  }
@media screen and (min-width: 270px)
  and (max-width: 560px){
     .sidebar-container{
      display: none;
    }
  }

@media screen and (max-width: 1024px) {
  .company-logo {
    height: 40px;
    margin-top: 8px;
  }
  .sidebar-list {
    width: 66px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1460px) {
  .company-logo {
    height: 42px;
    margin-top: 10px;
  }
  .sidebar-list {
    width: 68px;
  }
}
</style>