<template>
  <EntityData gapClass="is-5">
    <template #EntityBasic>
      <div class="column is-8 entity-column">
        <OrderBasic :order="order" />
        <OrderRecipient :recipient="order.Recipient" />
        <OrderItems :items="items" :shipmentNote="order.ShipmentNote" />
      </div>
    </template>
    <template #EntityExtra>
      <div class="column is-4 entity-column">
        <OrderHistory :order="order" />
        <OrderTracking v-if="order.Fulfillment" :order="order" />
      </div>
    </template>
  </EntityData>
</template>

<script setup>
import EntityData from '../Common/EntityData.vue';
import OrderBasic from './OrderBasic.vue';
import OrderRecipient from './OrderRecipient.vue';
import OrderItems from './OrderItems.vue';
import OrderHistory from './OrderHistory.vue';
import OrderTracking from './OrderTracking.vue';
import { getOrderItems } from '../../composables/order';

const { order } = defineProps(['order']);
const items = getOrderItems(order);
</script>

<style lang="less" scoped>
.entity-column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
</style>
