// Keep the Bulma import first, so that on dev (local) builds, where CSS is not extracted
// (to support HMR), Bulma's <style> tag is first in the DOM, and app <style> tags are later,
// allowing app styles to override Bulma styles without !important or other tricks. Production
// builds already have this because CSS is extracted.
import "./../node_modules/bulma/css/bulma.css";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
