<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderWithMoreActions
        btnText="Search"
        iconName="fas fa-search"
        @expanded="$refs.searchBox.select()">
        <template #options>
          <SearchBox
            ref="searchBox"
            @onSearch="$emit('onSearch', $event)"
            @onSearchReset="$emit('onSearchReset')" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderWithMoreActions
        btnText="Filter"
        iconName="fas fa-filter">
        <template #options>
          <RoleFilter @change="$emit('filterChanged', { field: 'role', value: $event.target.value })" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderButton
        v-if="isUserAdmin"
        btnText="Add User"
        :useButtonStyle="true"
        iconName="fa fa-plus"
        @handler="$router.push({ name: 'UserCreateManage' })" />
      <NavigationMenuComponent />
    </template>
  </ComponentHeaderActions>
</template>

<script setup>
import { useStore } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import ComponentHeaderWithMoreActions from '../../Common/ComponentHeaderWithMoreActions.vue';
import SearchBox from '../../Common/Search/SearchBox.vue';
import RoleFilter from './RoleFilter.vue';
import NavigationMenuComponent from './NavigationMenuComponent.vue';

defineEmits(['onSearch', 'onSearchReset', 'filterChanged']);

const isUserAdmin = useStore().getters['isUserAdmin'];
</script>
