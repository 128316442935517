<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Inventory' })" />
      <EntityActions
        :showUpdateProduct="true"
        :isProductValid="isProductValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../../Common/EntityActions.vue';
export default {
  name: 'InventoryEditActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, EntityActions },
  props: [ 'isProductValid' ]
}
</script>

<style lang="less" scoped>

</style>