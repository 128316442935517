<template>
  <div class="columns" style="display: block">
    <UserHeaderInfo v-show="false"
      :ref="getUserMessagesAndChecks"
      :isNewUser="true"
      :forNewClient="true" />
    <ValidationMessages v-if="isUserInternalOrOwner"
      class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="validationMessages"
      :checks="validationChecks">
      <p v-if="isNewClient" style="margin-top: 10px">
        <b>Client must be setup in Logiwa first!!</b>
      </p>
    </ValidationMessages>
    <div v-if="!isNewClient && isAdminSection" class="column existing-client-header">
      <div class="field">
        <label class="label custom-label-style">Client ID</label>
        <p class="">{{ selectedClient._id }}</p>
      </div>
      <div class="field">
        <label class="label custom-label-style">Active</label>
        <p class="">{{ selectedClient.IsActive ? 'YES' : 'NO' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import util from './../../lib/util.js';
import ValidationMessages from '../Common/ValidationMessages.vue';
import UserHeaderInfo from './UserHeaderInfo.vue';

export default {
  name: 'ClientHeaderInfo',
  props: [ 'isNewClient' ],
  emits: ['isClientValid'],
  components: { ValidationMessages, UserHeaderInfo },
  data() {
    return {
      userValidationMessages: [],
      userValidationChecks: [],
      isUserEmailValid: false
    }
  },
  watch: {
    isClientValid: {
      immediate: true,
      handler: function(val) {
        this.$emit("isClientValid", val)
      }
    }
  },
  computed: {
    ...mapGetters(['isUserInternal', 'isUserInternalOrOwner']),
    ...mapGetters('manage', ['selectedClient', 'newClient', 'newClientConfig']),
    isAdminSection() {
      return this.$route.params.isAdminSection;
    },
    notificationSettingsObject() {
      return this.isNewClient ? this.newClient : this.newClient.NotificationSettings;
    },
    validationMessages() {
      if(this.isNewClient) {
        return [
            [ 'Client information:',
              'All fields are required except Additional Notification Emails and Power BI Report ID',
              'Logiwa Client ID must be valid',
              'Power BI Report ID must be a valid GUID if provided' ],
            [ 'User/Account information:', ...this.userValidationMessages ],
            'All email addresses must be valid'
          ];
      } else {
        const messages = [
            `All fields are required except Additional Notification Emails${this.isUserInternal ? ' and Power BI Report ID' : ''}`,
            'All email addresses must be valid'
          ];
        if(this.isUserInternal)
          messages.splice(1, 0, 'Power BI Report ID must be a valid GUID if provided');
        return messages;
      }
    },
    validationChecks() {
      if(this.isNewClient) {
        return [
            [ this.haveRequiredFields, this.clientIdIsValid, this.isPowerBiReportIdValid ],
            this.userValidationChecks,
            this.emailAddressesAreValid 
          ];
      } else {
        const checks = [
            this.haveRequiredFields,
            this.emailAddressesAreValid
          ];
        if(this.isUserInternal)
          checks.splice(1, 0, this.isPowerBiReportIdValid);
        return checks;
      }
    },
    haveRequiredFields() {
      return (!this.isNewClient || util.trimString(this.newClient.Id)) &&
        util.trimString(this.newClientConfig.Name) &&
        util.trimString(this.newClient.Description) &&
        this.newClientConfig.DefaultReleaseWarehouse &&
        this.newClientConfig.DefaultCarrier &&
        this.newClientConfig.DefaultServiceLevel &&
        util.trimString(this.notificationSettingsObject.DefaultNotificationEmail);
    },
    clientIdIsValid() {
      return /^\d+$/.test(this.newClient.Id);
    },
    emailAddressesAreValid() {
      return util.testEmail(this.notificationSettingsObject.DefaultNotificationEmail) &&
        !this.notificationSettingsObject.SecondaryNotificationEmails.filter(
          e => util.trimString(e) && !util.testEmail(e)).length &&
        (!this.isNewClient || this.isUserEmailValid);
    },
    isClientValid() {
      return this.haveRequiredFields &&
        this.clientIdIsValid &&
        this.emailAddressesAreValid &&
        this.isPowerBiReportIdValid &&
        (!this.isNewClient || !this.userValidationChecks.filter(c => !c).length);
    },
    isPowerBiReportIdValid() {
      return !this.newClientConfig.PowerBiReportId || util.testGuid(this.newClientConfig.PowerBiReportId);
    }
  },
  methods: {
    getUserMessagesAndChecks(userHeaderInfo) {
      if(userHeaderInfo) {
        this.userValidationMessages = userHeaderInfo.validationMessages;
        this.userValidationChecks = userHeaderInfo.validationChecks;
        this.isUserEmailValid = userHeaderInfo.isEmailValid;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.existing-client-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.custom-label-style {
  margin: 0px !important
}
</style>
