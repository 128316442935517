<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Preferred Clients"
        iconName="fas fa-bookmark"
        :useButtonStyle="true"
        :isOutlinedButton="true"
        @handler="emit('editPreferredClients')" />
      <EntityActions
        :showUpdateUser="true"
        :isProfileView="true"
        :isUserValid="isUserValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script setup>
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../Common/EntityActions.vue';

defineProps(['isUserValid']);
const emit = defineEmits(['editPreferredClients']);
</script>
