<template>
  <div>
    <div class="field">
      <label class="label">Country {{ showAsterisk ? '*' : '' }}</label>
      <input
        v-if="!disableEditing"
        class="input is-normal"
        type="text"
        placeholder="Search"
        onfocus="this.select()"
        :value="countryFilter"
        @input="countryFilterChanged" />
    </div>
    <div class="field">
      <div class="control">
        <div class="select is-normal">
          <select @change="changeCountry($event.target.value)" :disabled="disableEditing">
            <option v-if="!filteredCountries.length && countryFilter" selected>No results</option>
            <template v-else>
              <option disabled :selected="country == ''">-- Select a Country --</option>
              <template v-if="filteredCountries.length">
                <option
                  v-for="(c, index) in filteredCountries"
                  :value="c.countryCode"
                  :key="index"
                  :selected="c.countryCode == country">
                  {{ c.country }}
                </option>
              </template>
            </template>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">State {{ countryHasStates ? (showAsterisk ? '*' : '') : '/ Province' }}</label>
      <input
        v-if="!disableEditing && countryHasStates"
        class="input is-normal"
        type="text"
        placeholder="Search"
        onfocus="this.select()"
        :value="stateFilter"
        @input="stateFilterChanged" />
    </div>
    <div class="field">
      <div class="control">
        <div v-if="countryHasStates" class="select is-normal">
          <select @change="changeState($event.target.value)" :disabled="disableEditing">
            <option v-if="!filteredStates.length && stateFilter" selected>No results</option>
            <template v-else>
              <option disabled :selected="state == ''">-- Select a State --</option>
              <template v-if="filteredStates.length">
                <option
                  v-for="(s, index) in filteredStates" 
                  :key="index"
                  :value="s.stateAbbr"
                  :selected="s.stateAbbr == state">
                  {{ s.stateName }}
                </option>
              </template>
            </template>
          </select>
        </div>
        <input v-else
          class="input"
          maxlength="40"
          :value="state"
          @input="changeState($event.target.value)"
          :disabled="disableEditing" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'CountryAndState',
  props: ['showAsterisk', 'selectedCountry', 'selectedState', 'disableEditing'],
  emits: ['update'],
  data() {
    return {
      countryFilter: '',
      stateFilter: '',
      country: this.selectedCountry,
      state: this.selectedState
    }
  },
  mounted() {
    // This will do validation.
    this.changeCountry(this.selectedCountry || 'US');
    this.changeState(this.selectedState);
  },
  computed: {
    ...mapGetters(['countries', 'states']),
    countryHasStates() {
      return this.country == 'US' || this.country == 'CA';
    },
    filteredCountries() {
      return this.search(this.countries, 'country', this.countryFilter);
    },
    filteredStates() {
      return this.search(this.states[this.country], 'stateName', this.stateFilter);
    }
  },
  watch: {
    selectedCountry(value) {
      this.changeCountry(value);
    },
    selectedState(value) {
      this.changeState(value);
    },
    country() {
      this.stateFilter = '';
    }
  },
  methods: {
    countryFilterChanged(event) {
      this.countryFilter = event.target.value;
      if(this.countryFilter)
        this.restrictFieldToList(this.filteredCountries, 'countryCode', this.country, this.changeCountry);
      else
        this.changeCountry('');
    },
    stateFilterChanged(event) {
      this.stateFilter = event.target.value;
      if(this.stateFilter)
        this.restrictFieldToList(this.filteredStates, 'stateAbbr', this.state, this.changeState);
      else
        this.changeState('');
    },
    restrictFieldToList(list, valueField, value, changeFunc) {
      if(!list.find(c => c[valueField] == value))
        changeFunc(list.length ? list[0][valueField] : '');
    },
    changeCountry(country) {
      if(this.validateAndChangeField(this.countries, 'countryCode', 'country', country)) {
        this.$emit('update', { field: 'country', value: this.country });
        this.changeState('');
      }
    },
    changeState(state) {
      if(this.validateAndChangeField(this.states[this.country], 'stateAbbr', 'state', state))
        this.$emit('update', { field: 'state', value: this.state });
    },
    validateAndChangeField(map, validateProp, field, value) {
      const oldValue = this[field];
      this[field] = !map || [...map.values()].filter(e => e[validateProp] == value).length ? value : '';
      return this[field] != oldValue;
    },
    search(map, property, search) {
      const mapValues = map ? [...map.values()] : [];
      if(!map || !search)
        return mapValues;

      // Search by 'starts with' and then by 'contains'
      const searchLower = search.toLowerCase();
      const startsWithResults = mapValues.filter(x => x[property].toLowerCase().startsWith(searchLower));
      const includesResults = mapValues.filter(x => x[property].toLowerCase().includes(searchLower));
      return Array.from(new Set(startsWithResults.concat(includesResults)));
    }
  }
}
</script>

<style lang="less" scoped>
</style>
