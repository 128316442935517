<template>
  <FfyColumnsView>
    <template #form>
      <div style="width: 430px; max-width: 100%">
        <div v-if="success">
          <p class="custom-label-style" style="margin-bottom: 20px">Your password was reset successfully.</p>
          <ButtonWithSpinner label="Go to Login" class="is-fullwidth submit-btn is-large" @click="goToLogin" />
        </div>
        <form v-else v-on:submit.prevent="submitRequest" >
          <div>
            <p class="custom-label-style" style="margin-bottom: 20px">Enter the code you received, and choose a new password:</p>
            <p class="custom-label-style">Code</p>
            <div class="control has-icons-left has-icons-right">
              <input v-model.trim="code" class="input is-large" placeholder="Code" />
              <span class="icon is-left is-large">
                <i class="fa fa-hashtag" aria-hidden="true"></i>
              </span>
              <span class="icon is-right is-large">
                <i class="fa fa-check"></i>
              </span>
            </div>
          </div>
          <div style="margin-top: 20px">
            <p class="custom-label-style">Password</p>
            <div
              class="control has-icons-left has-icons-right"
              style="color:#999"
            >
              <input v-model.trim="password" class="input is-large" type="password" placeholder="Password" />
              <span class="icon is-left is-large">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <span class="icon is-right is-large">
                <i class="fa fa-check"></i>
              </span>
            </div>
          </div>
          <div style="margin-top: 15px">
            <div
              class="control has-icons-left has-icons-right"
              style="color:#999"
            >
              <input v-model.trim="verifyPassword" class="input is-large" type="password" placeholder="Verify Password" />
              <span class="icon is-left is-large">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <span class="icon is-right is-large">
                <i class="fa fa-check"></i>
              </span>
            </div>
          </div>
          <div style="margin-top: 40px">
            <ButtonWithSpinner
              label="Reset Password"
              class="is-fullwidth submit-btn is-large"
              :isSubmit="true"
              :isInProcess="submitting" />
            <div 
              v-if="haveError || errMessage" 
              style="color:orange; font-weight: 600; text-align: center"
            >
              <template v-if="errMessage">
                {{ errMessage }}
              </template>
              <template v-else>
                Our system can't fulfill this request at this time. Please try again later. If the error persists, contact support.
              </template>
            </div>
            <hr />
            <div style="">
              <p class="support-text">
                NEED HELP? EMAIL
                <a href="mailto:support@fulfillify.com" style="color: #ffa500 !important">SUPPORT@FULFILLIFY.COM</a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </template>
  </FfyColumnsView>
</template>

<script>
// @ is an alias to /src
import api from './../lib/api-gateway';
//import util from './../lib/util';
//import _cookie from "js-cookie";
//import {mapGetters} from 'vuex';
import FfyColumnsView from "../components/Common/FfyColumnsView.vue";
import ButtonWithSpinner from "../components/Common/ButtonWithSpinner.vue";
export default {
  name: "PasswordReset",
  components: { FfyColumnsView, ButtonWithSpinner },
  data: function() {
    return {
      code: '',
      password: '',
      verifyPassword: '',
      submitting: false,
      success: false,
      haveError: false,
      errMessage: ''
    }
  },
  methods: {
    async submitRequest() {
      this.haveError = false;
      this.errMessage = '';

      if(!this.code) {
        this.errMessage = 'Code is required';
        return;
      }
      if(!/\d+/.test(this.code)) {
        this.errMessage = 'Code is invalid';
        return;
      }
      if(!this.password) {
        this.errMessage = 'Password is required';
        return;
      }
      if(!this.verifyPassword) {
        this.errMessage = 'Verify your password';
        return;
      }
      if(this.password != this.verifyPassword) {
        this.errMessage = "Passwords don't match";
        return;
      }

      this.submitting = true;
      const res = await api.resetPassword(this.$route.params.id, this.code, this.password);
      this.submitting = false;

      if(!res.error)
        this.success = true;
      else
        this.haveError = true;
    },
    goToLogin() {
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>

<style lang="less" scoped>
.custom-label-style { 
  color:#fff !important;
}
.support-text{
  background: #fff; 
  border-radius: 4px;
  font-weight: bold;
  padding: 8px; 
  text-align: center; 
}
@media screen and (min-width: 561px)
  and (max-width: 880px){
    .support-text{
      border-radius: 2px;
      font-size: 16px;
      padding: 4px;
    }
  }
@media screen and (min-width: 270px)
  and (max-width: 560px){
    .support-text{
      border-radius: 2px;
      font-size: 12px;
      padding: 4px;
    }
  }
</style>
