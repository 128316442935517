<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'ItemImports' })" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../../Common/ComponentHeaderButton.vue';
export default {
  name: 'ItemImportDetailActions',
  components: { ComponentHeaderActions, ComponentHeaderButton }
}
</script>
