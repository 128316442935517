<template>
  <Modal
    title="Assign Service Levels"
    width="1000px"
    :defaultOkCancelBtnData="['Save Changes', 'Cancel']"
    @close="doAction">
    <AssignList height="40vh"
      :options="selectedCarrierServiceLevels"
      v-model="assignedServiceLevels">
      <template #left-heading>
        <div class="asl-column-heading">
          <CarrierList @change="carrier = $event.target.value" />
        </div>
      </template>
      <template #right-heading>
        <div class="asl-column-heading">
          Assigned service levels:
        </div>
      </template>
    </AssignList>
  </Modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Modal from '../Common/Modal.vue';
import CarrierList from '../Common/CarrierList.vue';
import AssignList from '../Common/AssignList.vue';
import api from '../../lib/api-gateway';
import { makeApiCall } from '../../composables/api';

const store = useStore();
const client = store.getters['currentClient'];
const serviceLevels = store.getters['serviceLevels'];
const deliveryOptions = store.getters['order/deliveryOptions'];

const carrier = ref(null);
const assignedServiceLevels = ref(
  serviceLevels.filter(x => deliveryOptions.includes(x.name)).map(x => x.name));
const selectedCarrierServiceLevels = computed(() =>
  serviceLevels.filter(x => x.carrierId == carrier.value).map(x => x.name).sort());

function doAction(value) {
  if(!value)
    store.dispatch('manage/onToggleShowAssignServiceLevelsModal');
  else
    makeApiCall('Updating service levels...',
      'Service levels updated successfully.',
      makeServiceLevelCalls);
}

async function makeServiceLevelCalls() {
  const res = await api.assignAllServiceLevels(assignedServiceLevels.value);

  if(!res.error
    && assignedServiceLevels.value.length
    && !client.OnboardingFlags.ServiceLevelsSetup)
    await api.updateClientOnboardingFlags(client._id, { ServiceLevelsSetup: true });

  return res;
}
</script>

<style lang="less" scoped>
.asl-column-heading {
  height: 2em;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-end;
}
</style>