export default {
  deductOrderColor: '#B4EEF2',
  getOrderStatusColor: (status) =>
    status === 'Closed' ? '#40826D'
      : status === 'Submitted' ? '#ebfffc'
      : status === 'Approved' ? '#FFFDD0'
      : status === 'Confirmed' ? '#FFBF00'
      : status === 'Released' ? '#ECFFDC'
      : status === 'Cancelled' ? '#DC143C'
      : status.startsWith('OnHold') ? '#FF3800'
      : null,
  getSubOrdersStatusColor: (allClosed, allCancelled) =>
    allCancelled ? '#FF8A78'
      : allClosed ? '#CAF7CE'
      : '#FAFF61',
  getWarehouseStatusColor: (status) =>
    ['Shipped', 'Delivered'].indexOf(status) >= 0 ? '#40826D'
      : status === 'Initial' ? '#ebfffc'
      : status === 'Cancelled' ? '#DC143C'
      : status === 'Entered' ? '#ECFFDC'
      : ['Started', 'Ready', 'Assigned'].indexOf(status) >= 0 ? '#FFFDD0'
      : status === 'Picked' ? '#90EE90'
      : status === 'Packed' ? '#7CFC00'
      : status === 'Check Inventory' ? '#FF3800'
      : null,
  getAsnStatusColor: (status) =>
    status == 'Created' ? '#FFFDD0'
      : status == 'In Transit' ? '#FFBF00'
      : status == 'Delivered' ? '#ECFFDC'
      : status == 'Received' ? '#90EE90'
      : status == 'In Stock' ? '#40826D'
      : status == 'Cancelled' ? '#DC143C'
      : null,
  getWarehouseColor: (warehouse) =>
    warehouse == 'ROC' ? '#5F6DFF'
      : warehouse == 'SPA' ? '#E65050'
      : warehouse == 'MID' ? '#FF7D46'
      : warehouse == 'OKL' ? '#A6B359'
      : null
};
