
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

const order = { 
  namespaced: true,
  state: {
    selectedOrder: {},
    loadedFiles: {},
    deliveryOptions: new Array(),
    customServicelevels: new Array(),
    returnServiceLevels: new Array(),
    showReturnModal: false,
    showReturnsDetailModal: false,
    recipient: newRecipient(),
    newOrder: newOrder(),
    newDeductOrder: newDeductOrder(),
    newWorkOrder: newWorkOrder(),
    isOrderValid: false,
    newReturn: newReturn()
  },
  mutations: { ...mutations },
  actions: { ...actions }, 
  getters: { ...getters }
}

function newOrder() {
  return {
    Name: '', 
    DeliveryOption: '',
    Notes: '',
    ShipByDate: '',
    Recipient: {},
    WaitForClientApproval: false,
    RequestUCCLabels: false
  }
}

function newDeductOrder() {
  return {
    Name: '',
    WarehouseCode: '',
    Notes: '',
    CreateWorkOrder: false
  }
}

function newWorkOrder() {
  return {
    ShipmentNoticeId: '',
    CompletedByDate: ''
  };
}

function newRecipient() {
  return {
    FirstName: '', 
    LastName: '',
    Company: '',
    OriginalCustomerNumber: '',
    Email:'',
    Address1: '',
    Address2:'',
    City: '',
    State: '',
    ZipCode: '',
    Phone: '',
    Country: '',
    Notes: ''
  }
}

function newReturn() {
  return {
    WarehouseCode: "",
    ReturnItems: new Array(),
    ShipFromName:"",
    ShipFromAddress1:"",
    ShipFromAddress2:"",
    ShipFromCity:"",
    ShipFromState:"",
    ShipFromZip:"",
    ShipFromCountry:""
  }
}

export { order, newOrder, newDeductOrder, newWorkOrder, newRecipient, newReturn }
