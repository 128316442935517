document.addEventListener('mousedown', handleOutsideClick);
window.addEventListener('blur', handleOutsideClick);

export function registerHideOnOutsideClickHandlers(onMounted, toggleElRef, elRef, hideFunc) {
  onMounted(() => {
      toggleElRef.value.classList.add('hide-on-external-click');
      toggleElRef.value.elementToHide = elRef.value;
      if(hideFunc)
        toggleElRef.value.hideElementFunc = hideFunc;
    });
}

export function constrainWithinViewportVertically(onMounted, onBeforeUnmount, observeElRef, elRef) {
  const htmlEl = document.documentElement;
  const observer = new MutationObserver(constrain);

  onMounted(() => observer.observe(observeElRef.value, { attributes: true, childList: true, subtree: true }));
  onBeforeUnmount(() => observer.disconnect());

  function constrain() {
    // Need to avoid infinite loop because changes here can trigger the MutationObserver.
    if(getComputedStyle(elRef.value).display != 'none') {
      const elRect = elRef.value.getBoundingClientRect();
      const overflow =
        Math.max(0, (0 - elRect.top)) +
        Math.max(0, (elRect.bottom - htmlEl.clientHeight));
      
      if(overflow > 0) {
        elRef.value.style.maxHeight = `${elRef.value.offsetHeight - overflow - 2}px`;
        elRef.value.style.overflowY = 'auto';
      }
    } else if(elRef.value.style.overflowY != null) {
      elRef.value.style.maxHeight = null;
      elRef.value.style.overflowY = null;
    }
  }
}

function handleOutsideClick(event) {
  for(const el of document.getElementsByClassName('hide-on-external-click'))
    if(getComputedStyle(el.elementToHide).display != 'none'
      && (!event || !(event.target instanceof Node)
        || (!el.contains(event.target) && !el.elementToHide.contains(event.target))))
      if(el.hideElementFunc)
        el.hideElementFunc();
      else
        el.elementToHide.style.display = 'none';
};
