<template>
  <button :disabled="isInProcess" :type="isSubmit ? 'submit' : 'button'" class="button">
    <div style="margin: 0px 1.5em; position: relative">
      <div style="position: absolute; left: -1.8em; padding-top: 1px; height: 100%">
        <i v-show="isInProcess" class="fa fa-spin fa-spinner" style="font-size: 1.3em" />
      </div>
      {{ label }}
    </div>
  </button>
</template>

<script>
export default {
  name: 'ButtonWithSpinner',
  props: [ 'isSubmit', 'label', 'isInProcess' ]
}
</script>

<style lang="less" scoped>
/* Use styles like this in the parent to change colors on this button.
   This example sets the border/foreground/bg colors, and switches them on hover.
.button-class {
  border: 1px solid #fff;
  background: #ffa500;
}
.button-class * {
  color: #fff !important;
}
.button-class:hover {
  border: 1px solid #ffa500;
  background: #fff;
}
.button-class:hover * {
  color: #ffa500 !important;
}*/
</style>
