<template>
  <div style="padding: 10px">
    <div class="warehouse-list">
      <WarehouseList label="Warehouse" :allowUnselect="true" :includeResetButton="true" @selectWarehouse="filterChanged('warehouse', $event)" />
    </div>
    <div class="field">
      <label class="label">Date</label>
      <div class="control" style="margin-bottom: 10px">
        <label class="label date-input-label">From:</label>
        <input ref="dateFrom" type="date" class="input date-field" @change="dateChanged('dateFrom', $event.target.value)" />
        <i v-if="dateFrom" class="fa fa-undo reset-button" @click="resetDate('dateFrom')" />
      </div>
      <div class="control">
        <label class="label date-input-label">To:</label>
        <input ref="dateTo" type="date" class="input date-field" @change="dateChanged('dateTo', $event.target.value)" />
        <i v-if="dateTo" class="fa fa-undo reset-button" @click="resetDate('dateTo')" />
      </div>
    </div>
  </div>
</template>

<script>
import WarehouseList from '../WarehouseList.vue';
export default {
  name: "WarehouseAndDateFilter",
  components: { WarehouseList },
  emits: ['filterChanged'],
  data() {
    return {
      dateFrom: '',
      dateTo: ''
    }
  },
  methods: {
    dateChanged(field, value) {
      if(!value || parseInt(value.split('-')[0]) >= 1000) {
        this[field] = value;
        this.filterChanged(field, value);
      }
    },
    resetDate(field) {
      this.$refs[field].value = '';
      if(this[field]) {
        this[field] = '';
        this.filterChanged(field, '');
      }
    },
    filterChanged(field, value) {
      this.$emit('filterChanged', { field, value });
    }
  }
}
</script>

<style lang="less" scoped>
.warehouse-list {
  margin-bottom: 20px;
  position: relative;
}
.date-input-label {
  display: inline-block;
  width: 60px;
  font-weight: normal;
}
.date-field {
  width: unset;
  vertical-align: baseline;
}
.reset-button {
  font-size: 1.25rem;
  padding: .4rem;
  margin-left: 5px;
  cursor: pointer;
}
</style>
