<template>
  <div v-if="queryIsActive">
    <div class="filter-item" v-show="!queriedRecipients.length">
      No results found.
    </div>
    <div
      v-for="(res, index) in queriedRecipients"
      :key="index"
      @click="selectRecipient(res)"
      class="filter-item filter-item-valid">
      {{ getRecipientText( res ) }}
    </div>
  </div>
</template>

<script>
import api from "../../lib/api-gateway";
import util from "../../lib/util";
export default {
  name: 'RecipientSearch',
  props: ['search'],
  emits: ['activeChanged', 'recipientSelected'],
  data() {
    return {
      queryIsActive: false,
      queryTimer: null,
      queriedRecipients: [],
      getRecipientText: util.getRecipientText.bind(util)
    };
  },
  watch: {
    search() {
      this.searchAndUpdateRecipientsTimer();
    },
    queryIsActive(val) {
      this.$emit('activeChanged', val);
    }
  },
  methods: {
    searchAndUpdateRecipientsTimer(){
      if(this.queryTimer) {
        clearTimeout(this.queryTimer)
        this.queryTimer = null
      }

      if(this.search)
        this.queryTimer = setTimeout(this.searchAndUpdateRecipients, 750, this.search)
      else
        this.queryIsActive = false;
    },
    async searchAndUpdateRecipients(query){
      const res = await api.queryRecipients(query);
      
      if(res.error)
        console.log(res.message)
      else
        this.queriedRecipients = res.result.recipients;

      this.queryIsActive = true;
    },
    selectRecipient(res) {
      this.$emit('recipientSelected', res);
      this.queriedRecipients = [],
      this.queryIsActive = false;
    }
  }
}
</script>

<style lang="less" scoped>
.filter-item {
  padding: 4px;
  background-color: #f6f6f6;
}
.filter-item-valid:hover {
  background: #ddd;
  cursor: pointer;
}

@media screen and (max-width: 1024px){
  .filter-item {
    font-size: 12px !important;
    padding: 4px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1460px){
  .filter-item {
    font-size: 14px !important;
    padding: 4px;
  }
}

</style>
