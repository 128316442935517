<template>
  <ClientsList class="client-switcher-list is-up is-right"
    style="min-width: 280px"
    :useLoggedInUser="true"
    :modelValue="currentClient._id"
    @change="switchClient($event)" />
</template>

<script setup>
import { useStore } from 'vuex';
import _cookie from 'js-cookie';
import ClientsList from '../Manage/ClientsList.vue';

const store = useStore();

const loggedInUser = store.getters['loggedInUser'];
const currentClient = store.getters['currentClient'];

async function switchClient(event) {
  const client = JSON.parse(event.target.value);

  if(client._id != currentClient._id) {
    if(client._id != loggedInUser.ClientId)
      _cookie.set('fulfillify-client-id', client._id);
    else
      _cookie.remove('fulfillify-client-id');
    
    store.dispatch('onMakingAPICalls', `Switching to client ${client.Name}...`);
    window.location.reload();
  }
}
</script>

<style lang="less">
.client-switcher-list .expand-button {
  border-color: var(--theme-blue) !important;
}
</style>
