<template>
  <div class="field">
    <label class="label">{{ label }}</label>
    <div class="control">
      <div class="select" style="vertical-align: baseline">
        <select v-model="warehouse" @change="warehouseChanged" :disabled="disableEditing">
          <option selected value="" :disabled="!allowUnselect">
            -- Select a Warehouse --
          </option>
          <option 
            v-for="[ Code, option ] in warehouses" 
            :key="Code"
            :value="JSON.stringify(option)"
          >
            {{ option.Name }}
          </option>
        </select>
        
      </div>
      <i v-if="warehouse && includeResetButton" class="fa fa-undo reset-button" @click="resetWarehouse" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
  export default {
    name: 'WarehouseList',
    props: ['label', 'disableEditing', 'allowUnselect', 'includeResetButton'],
    emits: ['selectWarehouse'],
    data() {
      return {
        warehouse: ''
      }
    },
    computed:{
      ...mapGetters(['warehouses'])
    },
    methods: {
      warehouseChanged(){
        this.$emit('selectWarehouse', this.warehouse ? JSON.parse(this.warehouse) : null);
      },
      resetWarehouse() {
        this.warehouse = '';
        this.warehouseChanged();
      }
    }
  }
</script>

<style lang="less" scoped>
.warehouse-select {
  display: inline-block;
  vertical-align: baseline;
}
.reset-button {
  font-size: 1.25rem;
  padding: .4rem;
  margin-left: 5px;
  cursor: pointer;
}
</style>