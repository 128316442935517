<template>
  <Container title="Create Webhook">
    <template #actions>
      <WebhookCreateActions :isWebhookValid="isWebhookValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <WebhookHeaderInfo :isNewWebhook="true" @isWebhookValid="isWebhookValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <WebhookBasic :isNewWebhook="true" />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script>
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import WebhookHeaderInfo from './Header/WebhookHeaderInfo.vue';
import WebhookCreateActions from './Header/WebhookCreateActions.vue';
import WebhookBasic from './WebhookBasic.vue';
export default {
  name: 'WebhookCreate',
  components: { Container, EntityData, WebhookHeaderInfo, WebhookCreateActions, WebhookBasic },
  data() {
    return {
      isWebhookValid: false,
    }
  }
}
</script>
