<template>
  <label>
    <input type="checkbox" style="display: none" :checked="checked" :disabled="disabled">
    <span class="slider">&nbsp;</span>
  </label>
</template>

<script>
export default {
  name: 'CheckboxSwitch',
  props: ['checked', 'disabled']
}
</script>

<style lang="less" scoped>
// Adapted from W3Schools
.slider {
  display: inline-block;
  cursor: pointer;
  position: relative;
  height: 16px;
  line-height: 1;
  width: 30px;
  border-radius: 8px;
  background-color: #aaa;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background-color: white;
  transition: .4s;
}
input:checked + .slider {
  background: var(--theme-blue);
  &:before {
    margin-left: 14px;
  }
}
input:disabled + .slider {
  cursor: not-allowed !important;
  background-color: #aaa !important;
  &:before {
    background-color: #ccc !important;
  }
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--theme-blue);
}
</style>
