<template>
  <ImportCreate class="text-size" :uploadFileFunc="uploadFileFunc" @close="$emit('close')">
    Upload your file with the following rules:
    <div class="columns">
      <div class="column">
        <ul>
          <li>Should be a .csv or .xls/.xlsx.</li>
          <li>Should have one header row.</li>
          <li>Optional columns can be left empty, but they can't be removed from the spreadsheet.</li>
        </ul>
      </div>
      <div class="column">
        <ul>
          <li>
            To add more items to an order, repeat the Order Name with a new Product SKU,
            Quantity, and optional Lot Number on the following lines.
            Other fields are not required.
          </li>
        </ul>
      </div>
    </div>
    The file should have the following columns:
    <div class="columns">
      <div class="column">
        <ol>
          <li>Order Name</li>
          <li>Product SKU</li>
          <li>Product Quantity</li>
          <li>Shipping Service Level</li>
          <li>Recipient Company (optional)</li>
          <li>Recipient First Name (required if no Company)</li>
          <li>Recipient Last Name (required it no Company)</li>
          <li>Recipient Address</li>
          <li>Recipient Address 2 (optional)</li>
          <li>Recipient City</li>
          <li>Recipient State</li>
          <li>Recipient Zip Code</li>
        </ol>
      </div>
      <div class="column">
        <ol start="13">
          <li>Sender Company (optional)</li>
          <li>Sender First Name</li>
          <li>Sender Last Name</li>
          <li>Sender Address</li>
          <li>Sender City</li>
          <li>Sender State</li>
          <li>Sender Zip Code</li>
          <li>Ship Reference 1 (optional)</li>
          <li>Recipient Country</li>
          <li>Warehouse Code (optional)</li>
          <li>Product Lot Number (optional)</li>
        </ol>
      </div>
    </div>
  </ImportCreate>
</template>

<script>
  import api from './../../lib/api-gateway';
  import ImportCreate from '../Common/ImportCreate.vue';
  export default {
    name: 'OrderImportCreate',
    components: { ImportCreate },
    emits: ['close'],
    computed: {
      uploadFileFunc() {
        return api.importOrders;
      }
    }
  }
</script>
