<template>
  <div class="columns" style="display: block">
    <ValidationMessages
      class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="[
        'First Name and Last Name or Company',
        'Complete address: Address, City, Zip Code (5 digits)*, Phone (10 digits)* & Country (* = format for US)',
        'If country is US or Canada: State is required',
        'Email must be valid if provided'
      ]"
      :checks="validationChecks" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from './../../../lib/util.js'
import ValidationMessages from '../../Common/ValidationMessages.vue';
export default {
  name: 'RecipientHeaderInfo',
  components: { ValidationMessages },
  emits: ['isRecipientValid'],
  watch: {
    isRecipientValid: {
      immediate: true,
      handler: function(val) {
        this.$emit("isRecipientValid", val)
      }
    }
  },
  computed: {
    ...mapGetters('manage', ['newRecipient']),
    isRecipientValid() {
      return !this.validationChecks.filter(x => !x).length;
    },
    validationChecks() {
      return [ this.haveFirstAndLastNameOrCompany, this.addressIsValid, this.stateIsValid, this.emailIsValid ];
    },
    haveFirstAndLastNameOrCompany() {
      return util.trimString(this.newRecipient.Company) ||
        (util.trimString(this.newRecipient.FirstName) && util.trimString(this.newRecipient.LastName));
    },
    addressIsValid() {
      const phoneAndZipAreValid =
        /^\d{5}$/.test(util.trimString(this.newRecipient.ZipCode)) &&
        /^\d{10}$/.test(util.trimString(this.newRecipient.Phone));
      
      return util.trimString(this.newRecipient.Address1) &&
        util.trimString(this.newRecipient.City) &&
        util.trimString(this.newRecipient.ZipCode) &&
        util.trimString(this.newRecipient.Country) &&
        (this.newRecipient.Country != 'US' || phoneAndZipAreValid);
    },
    stateIsValid() {
      return util.trimString(this.newRecipient.State) ||
        (this.newRecipient.Country != 'US' && this.newRecipient.Country != 'CA');
    },
    emailIsValid() {
      return !util.trimString(this.newRecipient.Email) || util.testEmail(this.newRecipient.Email);
    }
  }
}
</script>

<style lang="less" scoped>
</style>