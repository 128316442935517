<template>
  <div class="component-header">
    <div class="component-header-left">
      <h1 class="component-header-title">
        <slot name="title"></slot>
      </h1>
      <slot name="subtitle"></slot>
    </div>
    <slot name="actions"></slot>
  </div>
</template>

<style lang="less" scoped>
.component-header {
  background: white;
  padding: 5px 9px 5px 12px;
  min-height: 68px;
  display: flex; 
  align-items: center;
}
.component-header-title {
  margin-left: -1px;
  font-size: 1.5em;
  font-weight: bold;
  white-space: nowrap;
}
.component-header-left {
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .component-header {
    min-height: 60px;
  }
  .component-header-left {
    font-size: .9em;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1460px) {
  .component-header {
    min-height: 64px;
  }
}
</style>