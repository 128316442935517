<template>
  <Container>
    <MaxWidthContainer class="card content-container">
      <div class="onboarding-container">
        <span class="entity-text">To-do list for onboarding a client:</span>
        <div v-for="(item, i) in onboardingItems" :key="i"
          class="onboarding-item"
          :class="{ 'onboarding-item-done': item.status }">
          <div class="checkmark">
            <i v-if="item.status" class="fas fa-check"></i>
          </div>
          <div>
            <p class="entity-heading">{{ item.label }}</p>
            <p v-if="item.description" class="theme-blue">{{ item.description }}</p>
            <p v-if="item.error">
              <span class="theme-error">{{ item.error }}</span>
              (<a @click="clearErrorFlag(item.errorFlag)">dismiss</a>)
            </p>
          </div>
          <button v-if="item.button"
            class="button blue-button bigger-button"
            style="margin-left: auto"
            :disabled="item.inProgressMsg"
            @click="item.handler">
            <span>
              {{ item.inProgressMsg || item.button }}
            </span>
          </button>
        </div>
      </div>
    </MaxWidthContainer>
  </Container>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Container from './../Common/Container.vue';
import MaxWidthContainer from './../Common/MaxWidthContainer.vue';
import api from '../../lib/api-gateway';
import { makeApiCall } from '../../composables/api';

const router = useRouter();
const client = useStore().getters['currentClient'];

const onboardingItems = [
  {
    label: 'Client was setup in Logiwa',
    status: true
  },
  {
    label: 'Setup Service Levels',
    description: 'Assign service levels to the client',
    status: client.OnboardingFlags.ServiceLevelsSetup,
    button: 'Go to Service Levels',
    handler: goToServiceLevels
  },
  {
    label: 'Create an API User',
    description: 'Create an API user for the client to use',
    status: client.OnboardingFlags.APIUserCreated,
    button: client.OnboardingFlags.APIUserCreated ? null : 'Auto-Create API User',
    handler: createApiUser
  },
  {
    label: 'Import All Items',
    description: 'Import all items and inventory from Logiwa',
    status: client.OnboardingFlags.ItemDataImported,
    button: 'Import All Item Data',
    inProgressMsg: client.OnboardingFlags.ItemDataImportInProgress ? 'Import in Progress...' : null,
    handler: importItemsData,
    ...client.OnboardingFlags.ErrorsOccurredDuringItemDataImport
      ? { error: 'Errors occurred during the import.', errorFlag: 'ErrorsOccurredDuringItemDataImport' }
      : {}
  }
];

function goToServiceLevels() {
  router.push({ name: 'SettingsServiceLevels' });
}
function createApiUser() {
  makeApiCall('Creating API user. Please wait...',
    res => res,
    makeCreateApiUserCalls);
}
function importItemsData() {
  makeApiCall('Importing item data. Please wait...',
    res => res,
    () => api.importAllItemsData(client._id));
}
async function clearErrorFlag(flag) {
  makeApiCall('Clearing error. Please wait...',
    'The error was cleared successfully.',
    () => api.updateClientOnboardingFlags(client._id, { [flag]: false }));
}

async function makeCreateApiUserCalls() {
  const res = await api.createApiUser(client._id);
  if(!res.error)
    await api.updateClientOnboardingFlags(client._id, { APIUserCreated: true });
  return res;
}
</script>

<style lang="less" scoped>
.onboarding-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.onboarding-item {
  padding: 1em;
  border: 3px solid #FF9999;
  border-radius: 1em;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.onboarding-item-done {
  border-color: #67AB9F;
}
.checkmark {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  flex-shrink: 0;

  i {
    color: var(--theme-green);
  }
}
</style>
