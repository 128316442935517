<template>
  <div :class="{ columns: isHorizontal }">
    <div :class="{ 'column is-6': isHorizontal }">
      <div v-if="isAdminSection && isNewClient" class="field">
        <label class="label">Logiwa Client ID</label>
        <div class="control">
          <input @input="clientDataChanged('Id', $event)" type="number" class="input" :value="newClient.Id" style="max-width: 200px" />
        </div>
      </div>
      <div v-if="isAdminSection" class="field">
        <label class="label">Logiwa Name</label>
        <div class="control">
          <input @input="clientConfigDataChanged('Name', $event)" class="input" :value="newClientConfig.Name" />
        </div>
      </div>
      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <input @input="clientDataChanged('Description', $event)" class="input" :disabled="!isUserInternalOrOwner" :value="newClient.Description" />
        </div>
      </div>
      <NotificationEmails v-if="isHorizontal" :isNewClient="isNewClient" class="is-hidden-mobile" />
      <div v-else style="height: 0"></div>
    </div>
    <div :class="{ 'column is-6': isHorizontal }">
      <div class="field">
        <label class="label">Default Release Warehouse</label>
        <div class="control">
          <SelectList
            @change="clientConfigDataChanged('DefaultReleaseWarehouse', $event)"
            :options="warehouses"
            valueField="Code"
            labelField="Name"
            instructionLabel="-- Select a Warehouse --"
            :selectedValue="newClientConfig.DefaultReleaseWarehouse"
            :disabled="!isUserInternal" />
        </div>
      </div>
      <div class="field">
        <label class="label">Default Carrier</label>
        <div class="control">
          <SelectList
            @change="clientConfigDataChanged('DefaultCarrier', $event)"
            :options="carriers"
            valueField="name"
            labelField="name"
            instructionLabel="-- Select a Carrier --"
            :selectedValue="newClientConfig.DefaultCarrier"
            :disabled="!isUserInternal" />
        </div>
      </div>
      <div class="field">
        <label class="label">Default Service Level</label>
        <div class="control">
          <SelectList
            @change="clientConfigDataChanged('DefaultServiceLevel', $event)"
            :options="serviceLevels"
            valueField="name" 
            labelField="name"
            instructionLabel="-- Select a Service Level --"
            :selectedValue="newClientConfig.DefaultServiceLevel"
            :disabled="!isUserInternal" />
        </div>
      </div>
      <div v-if="isUserInternal" class="field">
        <label class="label">Power BI Report ID</label>
        <div class="control">
          <input @input="clientConfigDataChanged('PowerBiReportId', $event)" class="input" :value="newClientConfig.PowerBiReportId" />
        </div>
      </div>
      <NotificationEmails :isNewClient="isNewClient" :class="{ 'is-hidden-tablet': isHorizontal }" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SelectList from './../Common/SelectList.vue';
import NotificationEmails from './NotificationEmails.vue';
export default {
  name: 'ClientBasic',
  components: { SelectList, NotificationEmails },
  props: ['isNewClient', 'isHorizontal'],
  computed: {
    ...mapGetters(['isUserInternal', 'isUserInternalOrOwner', 'carriers', 'serviceLevels', 'warehouses']),
    ...mapGetters('manage', ['newClient', 'newClientConfig']),
    isAdminSection() {
      return this.$route.params.isAdminSection;
    }
  },
  methods: {
    ...mapActions('manage', ['onSetNewClientData', 'onSetNewClientConfigData']),
    clientDataChanged(field, event) {
      this.onSetNewClientData({ field, value: event.target.value });
    },
    clientConfigDataChanged(field, event) {
      this.onSetNewClientConfigData({ field, value: event.target.value });
    }
  }
}
</script>

<style lang="less" scoped>
.addl-notification-email-line {
  display: flex;
  margin-bottom: 10px;
}
.addl-notification-email-input {
  width: unset;
  flex-grow: 1;
}
.addl-notification-email-btn {
  border: none;
  background: none;
  width: 3em;
  cursor: pointer;
}
.addl-notification-email-btn-icon {
  font-size: 1.5em;
}
</style>