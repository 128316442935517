<template>
  <Container :title="`Notice - ${selectedShipNotice.Id}`">
    <template #actions>
      <ShipmentEditActions :isShipNoticeValid="isShipNoticeValid" />
    </template>
    
    <EntityData class="card content-container" gapClass="is-4">
      <template #EntityHeaderInfo>
        <ShipNoticeHeaderInfo
          :selectedShipNotice="selectedShipNotice"
          :isEditable="isNoticeInTransitOrCreated"
          @isShipNoticeValid="isShipNoticeValid = $event" />
      </template>
      <template #EntityBasic>
        <ShipNoticeBasic containerClass="column is-3" :disableEditing="!isNoticeInTransitOrCreated" />
      </template>
      
      <template #EntityItems>
        <ShipNoticeItems :disableEditing="!isNoticeInTransitOrCreated" />
      </template>

      <template #EntityExtra>
      <ShipNoticeExtra containerClass="column is-4"
      :selectedShipNotice="selectedShipNotice"/>
      </template>
    
    </EntityData>
    
  </Container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Container from './../../Common/Container.vue';
  import ShipmentEditActions from './Header/ShipmentEditActions.vue';
  import EntityData from './../../Common/EntityData.vue';
  import ShipNoticeHeaderInfo from './ShipNoticeHeaderInfo.vue';
  import ShipNoticeBasic from './ShipNoticeBasic.vue'; 
  import ShipNoticeItems from './ShipNoticeItems';
  import ShipNoticeExtra from './ShipNoticeExtra';
  export default {
    name: 'ShipNoticeDetail',
    components: {
      Container,
      ShipmentEditActions,
      EntityData,
      ShipNoticeHeaderInfo,
      ShipNoticeBasic,
      ShipNoticeItems,
      ShipNoticeExtra },
    mounted(){
      const n = this.selectedShipNotice;

      const sN = {
        WarehouseCode: n.WarehouseCode,
        ExpectedDate: n.ExpectedDate,
        Carrier: n.Carrier,
        TrackingNumber : n.TrackingNumber,
        PONumber: n.PONumber,
        PackageCount: n.PackageCount,
        PalletCount: n.PalletCount,
        Vendor: n.Vendor
      };

      if(n.Products && n.Products.length > 0)
      {  
        const products = new Map();

        for(let p of n.Products)
          products.set(p.Link.Name, { StockNumber: p.Link.Name, Quantity: p.Quantity, QuantityReceived: p. QuantityReceived});

        this.onItemsAssignment(products);
      }
      this.onShipNoticeSelect({...sN});

      window.scrollTo(0, 0);
    },
    data() {
      return {
        isShipNoticeValid: false
      }
    },
    unmounted() {
      this.onResetNewShipNotice();
      this.onItemsAssignment(new Map());
    },
    computed:{
      ...mapGetters('asn', ['selectedShipNotice']),
      isNoticeInTransitOrCreated() {
        return this.selectedShipNotice.Status == 'In Transit' || this.selectedShipNotice.Status == 'Created'
      }
    },
    methods:{ 
      ...mapActions('asn', ['onShipNoticeSelect', 'onResetNewShipNotice']),
      ...mapActions(['onItemsAssignment'])
    }
  }
</script>
