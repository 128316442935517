<template>
  <CollapsiblePanel v-if="packages.length" class="detail-panel" heading="Packages">
    <div style="padding: 10px">
      <i v-if="carrierIcon" class="carrier-icon" :class="`fab fa-${carrierIcon}`"></i>
      <span class="entity-text" style="vertical-align: middle">{{ order.ShippedServiceLevel }}</span>
    </div>
    <div class="packages-container">
      <div v-for="p in packages" :key="p.PackageId">
        <div class="package-header theme-blue-bg">
          {{p.PackageId}}
        </div>
        <div style="margin: 10px">
          <div v-if="haveTrackingNumber(p) || order.ShippingLabel || showUCCLabel(p)"
            class="text-size-big"
            style="margin: 15px 0 10px">
            <div v-if="haveTrackingLink(p)">
              <div style="text-align: center">
                <img :id="`barcode_${p.PackageId}`" />
              </div>
              Track:
              <a :href="p.TrackingDetails.Url" target="_blank" class="theme-blue">
                {{ p.TrackingDetails.TrackingNumber }}
              </a>
            </div>
            <div v-else-if="order.ShippingLabel">
              Track:
              <a @click="filePreviewComp.show()" class="theme-blue">
                {{ getShippingLabelLinkText() }}
              </a>
            </div>
            <div v-else-if="haveTrackingNumber(p)">
              Track: {{ p.TrackingDetails.TrackingNumber }}
            </div>
            <div v-if="showUCCLabel(p)">
              Box Label: {{ p.TrackingDetails.UCCLabel }}
            </div>
          </div>
          <div v-for="i in p.Items" :key="i.Product.Name">
            <div class="item-header">
              <div class="entity-text">{{i.Product.Name}}</div>
              <div class="entity-text">Qty: {{i.Quantity}}</div>
            </div>
            <div style="margin-bottom: 8px">
              <div v-if="i.SerialNumbers && i.SerialNumbers.length" style="display: flex">
                <div class="entity-subtext" style="width: 90px">Serial No's:</div>
                <div class="entity-subtext">{{ i.SerialNumbers.join(', ') }}</div>
              </div>
              <div v-if="i.LotNumbers && i.LotNumbers.length" style="display: flex">
                <div class="entity-subtext" style="width: 90px">Lot No's:</div>
                <div class="entity-subtext">{{ i.LotNumbers.join(', ') }}</div>
              </div>
              <div v-if="i.ExpirationDates && i.ExpirationDates.length" style="display: flex">
                <div class="entity-subtext" style="width: 90px">Exp. Dates:</div>
                <div class="entity-subtext">{{ i.ExpirationDates.map(x => util.formatAndGetDateOnly(x)).join(', ') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CollapsiblePanel>
  <FilePreview v-if="order.ShippingLabel"
    ref="filePreviewComp"
    titleOverride="Shipping Label"
    :fileName="order.ShippingLabel"
    :fileData="loadedFiles.ShippingLabel"
    :fileLoadFunc="() => loadOrderFile('ShippingLabel', order.ShippingLabel)" />
</template>

<script setup>
const carrierIcons = ['fedex', 'usps', 'ups', 'dhl'];

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import JsBarcode from 'jsbarcode';
import util from '../../lib/util.js'
import { loadOrderFile } from '../../composables/order.js';
import CollapsiblePanel from '../Common/CollapsiblePanel.vue';
import FilePreview from '../Common/FilePreview.vue';
import { checkCurrentClientFeature } from '../../composables/client_config.js';

const { order } = defineProps(['order']);
const store = useStore();
const showUCCLabels = checkCurrentClientFeature('VUE_APP_UCC_LABELS_CLIENTS');

const packages = order.Fulfillment && order.Fulfillment.Packages || [];
const shippedCarrier = order.ShippedCarrier || order.Carrier;
const carrierIcon = shippedCarrier && carrierIcons.find(x => shippedCarrier.toLowerCase().startsWith(x));
const loadedFiles = computed(() => store.getters['order/loadedFiles']);
const filePreviewComp = ref(null);

onMounted(() => packages
  .filter(p => haveTrackingLink(p))
  .forEach(p => JsBarcode(`#barcode_${p.PackageId}`, p.TrackingDetails.TrackingNumber, { displayValue: false, height: 70 })));

function haveTrackingNumber(p) {
  return p.TrackingDetails && p.TrackingDetails.TrackingNumber;
};
function haveTrackingLink(p) {
  return haveTrackingNumber(p) && p.TrackingDetails.Url;
}
function showUCCLabel(p) {
  return showUCCLabels && p.TrackingDetails.UCCLabel;
}
function getShippingLabelLinkText() {
  let filePath = (order.ShippingLabel || '').replace('.pdf', '');
  const pathParts = filePath.split('/');

  let fileName = pathParts[pathParts.length - 1];
  const nameParts = fileName.split('_');
  if(nameParts.length == 3)
    fileName = nameParts.slice(1).join('_');
  
  return fileName;
}
</script>

<style lang="less" scoped>
.detail-panel {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 2px;
}
.carrier-icon {
  font-size: 2.5em;
  line-height: .5em;
  vertical-align: middle;
  margin-right: 10px;
}
.packages-container {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}
.package-header {
  color: white;
  font-weight: bold;
  margin: 5px 2px;
  padding: 8px;
  border-radius: 7px;
}
.item-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
</style>