import { createStore } from "vuex";
import util from '../lib/util';

import { order } from './modules/order';
import { product } from './modules/product';
import { shipment } from './modules/asn';
import { manage } from './modules/manage';
import { resource } from './modules/resource';

export default createStore({
  state: {
    loggedInUser: {},
    currentClient: {},
    clientsList: new Map(),
    preferredClients: [],
    pageError: {
      haveError: false,
      errorId: null
    },
    // https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md#414-2022-08-22
    // Implement one-time params that don't persist if the user refreshes the page.
    navigationParams: {},
    isListFullScreen: false,
    isMenuHoverEnabled: false,
    screenSize: window.screen.width,
    isScreenSizeLessThanEqualTo1460: window.screen.width <= 1460,
    originalSelectedItems: new Map(),
    selectedItems: new Map(),
    selectedFiles: {},
    apiStatus: newApiStatus(),
    warehouses: [],
    carriers: new Map(),
    serviceLevels: [],
    countries: new Map(),
    states: {}
  },

  mutations: {
    setLoggedInUser: (state, user) => state.loggedInUser = { ...user },
    setCurrentClient: (state, client) => state.currentClient = { ...client },
    setClients: (state, clients) => {
      state.clientsList = new Map(clients.clients.map(x => [x._id, x]));
      state.preferredClients = clients.preferredClients;
    },
    setPageError: (state, errorId) => state.pageError = { haveError: true, errorId },
    setNavigationParams: (state, params) => state.navigationParams = { ...state.navigationParams, ...params },
    resetNavigationParams: state => state.navigationParams = {},
    toggleListFullScreenFlag: state => state.isListFullScreen = !state.isListFullScreen,
    setMenuHoverEnabled: (state, enabled) => state.isMenuHoverEnabled = enabled,
    addToSelectedItems: (state, item) => state.selectedItems.set(item.StockNumber, { ...item, Quantity: 1 }),
    removeFromSelectedItems: (state, stockNumber) => state.selectedItems.delete(stockNumber),
    incrementQuantityOfSelectedItem: (state, {stockNumber, qty}) => state.selectedItems.get(stockNumber).Quantity = qty,
    updateSelectedItem: (state, { stockNumber, item }) =>
      state.selectedItems.set(stockNumber, { ...state.selectedItems.get(stockNumber), ...item }),
    emptySelectedItems: state => {
      state.selectedItems = new Map();
      state.originalSelectedItems = new Map();
    },
    assignItems: (state, items) => {
      state.selectedItems = new Map(items);
      state.originalSelectedItems = new Map();
      items.forEach((val, key) => state.originalSelectedItems.set(key, { ...val }));
    },

    setSelectedFile: (state, { type, file }) => {
      if(file)
        state.selectedFiles[type] = file;
      else
        delete state.selectedFiles[type];
    },

    toggleInProcessModal: state => state.apiStatus.isInProcess = !!state.apiStatus.inProcessText,

    setInProcessText: (state, text) => state.apiStatus.inProcessText = text,
    setResponseContent: (state, res) => {
      state.apiStatus.isInProcess = true;
      state.apiStatus.responseStatus = res.responseStatus;
      state.apiStatus.responseText = res.responseText;
      state.apiStatus.responseErrorId = res.responseErrorId;
      state.apiStatus.responseAvailable = true;
      state.apiStatus.promise = res.promise;
    },
    finishAPICalls: (state) => {
      const promise = state.apiStatus.promise;
      const success = state.apiStatus.responseStatus;
      state.apiStatus = newApiStatus();
      if(promise)
        promise.resolve(success);
    },
    setToOriginalState: (state) => {
      state.isListFullScreen = false
      state.isMenuHoverEnabled = false
      state.selectedItems = new Map()
      state.selectedFiles = {}
      state.apiStatus = newApiStatus()
    },
    setWarehouses: (state, w) => state.warehouses = new Map(w.map(x => [x.Code, x])),
    setCarriers: (state, c) => state.carriers = new Map(c.map(x => [x._id, x])),
    setServiceLevels: (state, l) => state.serviceLevels = [...l],
    setCountries: (state, c) => state.countries = new Map(c.map(x => [x.countryCode, x])),
    setStates: (state, s) => {
      state.states = {};
      for(const [ country, states ] of Object.entries(s))
        state.states[country] = new Map(states.map(x => [ x.stateAbbr, x ]));
    }
  },
  actions: {
    onLoadSetLoggedInUser: ({commit}, user) => commit('setLoggedInUser', user),
    onSetCurrentClient: ({commit}, client) => commit('setCurrentClient', client),
    onLoadSetClients: ({commit}, clients) => commit('setClients', clients),
    onSetPageError: ({commit}, errorId) => commit('setPageError', errorId),
    onSetNavigationParams: ({commit}, params) => commit('setNavigationParams', params),
    onResetNavigationParams: ({commit}) => commit('resetNavigationParams'),
    onToggleListFullScreenFlag: ({ commit }) => commit('toggleListFullScreenFlag'),
    onSetMenuHoverEnabled: ({commit}, enabled) => commit('setMenuHoverEnabled', enabled),
    onItemSelect: ({commit}, item ) => commit('addToSelectedItems', item),
    onItemRemove: ({commit}, stockNumber) => commit('removeFromSelectedItems', stockNumber),
    onItemQtyChange:({commit}, change) => commit('incrementQuantityOfSelectedItem', change),
    onUpdateSelectedItem: ({commit}, itemData) => commit('updateSelectedItem', itemData),
    onItemsAssignment: ({commit}, items) => commit('assignItems', items),
    onEmptySelectedItems: ({ commit }) => commit('emptySelectedItems'),
    onSetSelectedFile: ({ commit }, fileInfo) => commit('setSelectedFile', fileInfo),
    onMakingAPICalls: ({commit}, text) => {
      commit('setInProcessText', text)
      commit('toggleInProcessModal')
    },
    onSetResponseContent: ({commit}, res) => {
      const promise = util.makePromiseWithResolvers();
      commit('setResponseContent', { ...res, promise });
      return promise;
    },
    onFinishAPICalls: ({commit}) => commit('finishAPICalls'),
    onSetToOriginalState: ({commit}) => commit('setToOriginalState'),
    onLoadSetWarehouses: ({commit}, w) => commit('setWarehouses', w),
    onLoadSetCarriers: ({commit}, c) => commit('setCarriers', c),
    onLoadSetServiceLevels: ({commit}, l) => commit('setServiceLevels', l),
    onLoadSetCountries: ({commit}, c) => commit('setCountries', c),
    onLoadSetStates: ({commit}, s) => commit('setStates', s)
  },
  getters:{
    loggedInUser: state => state.loggedInUser,
    currentClient: state => state.currentClient,
    clientsList: state => state.clientsList,
    preferredClients: state => state.preferredClients,
    isUserAdmin: state => state.loggedInUser.Role == 'Admin',
    isUserInternal: state => ['Admin', 'Manager'].indexOf(state.loggedInUser.Role) >= 0,
    isUserInternalOrOwner: state => ['Admin', 'Manager', 'Owner'].indexOf(state.loggedInUser.Role) >= 0,
    isUserOwner: state => state.loggedInUser.Role == 'Owner',
    pageError: state => state.pageError,
    navigationParams: state => state.navigationParams,
    isListFullScreen: state => state.isListFullScreen,
    isMenuHoverEnabled: state => state.isMenuHoverEnabled,
    screenSize: state => state.screenSize,
    isScreenSizeLessThanEqualTo1460: state => state.isScreenSizeLessThanEqualTo1460,
    originalSelectedItems: state => state.originalSelectedItems,
    selectedItems: state => state.selectedItems,
    selectedFiles: state => state.selectedFiles,
    apiStatus: state => state.apiStatus,
    apiInProcessText: state => state.apiStatus.inProcessText,
    warehouses: state => state.warehouses,
    carriers: state => state.carriers,
    serviceLevels: state => state.serviceLevels,
    countries: state => state.countries,
    states: state => state.states
  },
  modules: {
    order: { ...order },
    product: { ...product },
    asn: { ...shipment },
    manage: { ...manage },
    resource: { ...resource }
  }
});

function newApiStatus() {
  return {
    isInProcess: false,
    inProcessText: '',
    responseText: '',
    responseStatus: false,
    responseErrorId: '',
    responseAvailable: false,
    promise: null
  }
}
