import clients from './api-clients';

const { apiGet, apiPost, apiPut, apiDelete, setClientId: setClientId_api } = clients;

const getErrorMessage = (message) => `${message}. Network Issue.`;

export default {
  setClientId: id => setClientId_api(id),
  verifyToken: async () =>
    await apiGet(
      "ui/identities/verify-token",
      "Error verifying token. Network issue."
    ),
  verifyAdminToken: async () =>
    await apiGet(
      "ui/identities/verify-token-admin",
      "Error verifying token. Network issue."
    ),
  login: async (email, password) => {
    const data = new FormData();
    data.append("username", email);
    data.append("password", password);
    return await apiPost(
      "identities/request/access",
      data,
      "Issue logging in. Network Issue."
    );
  },
  logout: async () =>
    await apiPost(
      "identities/request/revoke-access",
      null,
      "Issue logging out. Network Issue."
    ),
  getLoggedInUser: async () =>
    await apiGet(
      "ui/logged-in-users/current",
      "Issue getting logged in user. Network issue."
    ),
  requestPasswordReset: async email =>
    await apiPost(
      "identities/request/password-reset",
      { email },
      "Issue requesting password reset. Network Issue."
    ),
  resetPassword: async (requestId, code, password) =>
    await apiPost(
      `identities/password-reset/${requestId}`,
      { code, password },
      "Issue resetting password. Network Issue."
    ),
  generateApiToken: async (clientId) =>
    await apiPost(
      'identities/request-access-token-api-user',
      { ClientId: clientId },
      'Issue getting API token. Network issue.'
    ),
  getCountriesAndStates: async () =>
    await apiGet(
      "ui/resource/countries-states",
      "Issue getting countries and states info. Network Issue."
    ),
  getCarriers: async () =>
    await apiGet(
      `ui/resource/carriers`,
      "Issue getting carriers. Network issue."
    ),
  getServiceLevels: async () =>
    await apiGet(
      `ui/resource/service-levels`,
      "Issue getting service levels. Network issue."
    ),
  getClientDeliveryOptionsAndReturnServiceLevels: async () =>
    await apiGet(
      "ui/resource/client-ship-methods",
      "Issue getting client ship methods. Network issue."
    ),
  addServiceLevel: async (carrierId, serviceLevel) =>
    await apiPost(
      `resources/service-level`,
      { CarrierId: carrierId, ServiceLevelText: serviceLevel },
      "Issue adding service level. Network Issue."
    ),
  addCustomServiceLevel: async (serviceLevelId, customServiceLevel) =>
    await apiPost(
      `resources/custom-service-level`,
      { ServiceLevelId: serviceLevelId, CustomServiceLevelText: customServiceLevel },
      "Issue adding custom service level. Network Issue."
    ),
  removeServiceLevels: async (ids) =>
    await apiDelete(
      `resources/remove-service-levels`,
      { Ids: ids },
      "Issue removing service levels. Network Issue."
    ),
  removeCustomServiceLevels: async (ids) =>
    await apiDelete(
      `resources/remove-custom-service-level`,
      { CustomServiceLevelIds: ids },
      "Issue removing custom service levels. Network Issue."
    ),
  assignAllServiceLevels: async serviceLevels =>
    await apiPost(
      `ui/resource/assign-service-levels`,
      { serviceLevels },
      "Issue assigning service levels. Network Issue."
    ),
  unassignServiceLevels: async serviceLevels =>
    await apiPost(
      `ui/resource/remove-service-levels`,
      { serviceLevels },
      "Issue removing service levels. Network Issue."
    ),
  getWarehouses: async () =>
    await apiGet(
      "ui/resource/warehouses",
      "Issue getting warehouses. Network issue."
    ),
  querySupportTicketOptions: async () =>
    await apiGet(
      "ui/resource/support-ticket-options",
      "Error getting support ticket options. Network issue."
    ),
  submitSupportTicket: async req =>
    await apiPost(
      "ui/resource/support-ticket",
      req,
      "Issue submitting ticket. Network issue."
    ),
  getPowerBiConfig: async () =>
    await apiGet(
      "ui/resource/powerbi-config",
      "Error getting Power BI config. Network issue."
    ),
  getClients: async (searchTerm, page, pageSize) => {
    const params = { searchTerm, page, pageSize };
    return await apiGet(
      "ui/clients",
      "Issue getting clients. Network issue.",
      { params }
    );
  },
  getClientsList: async () =>
    await apiGet("ui/clients/list", "Issue getting clients. Network issue."),
  getCurrentClient: async () =>
    await apiGet(`ui/clients/current`, "Issue getting client. Network issue."),
  createClient: async client =>
    await apiPost("accounts", client, "Issue creating client. Network Issue."),
  updateClient: async client =>
    await apiPut(
      "accounts/update",
      client,
      "Issue updating client. Network Issue."
    ),
  updateClientConfig: async clientConfig =>
    await apiPut(
      "accounts/update-configuration",
      clientConfig,
      "Issue updating client configuration. Network Issue."
    ),
  activateClient: async(clientId) =>
    await apiPut(
      "accounts/activate",
      { ClientId: clientId },
      "Issue activating client. Network Issue."
    ),
  deactivateClient: async(clientId) =>
    await apiPut(
      "accounts/deactivate",
      { ClientId: clientId },
      "Issue activating client. Network Issue."
    ),
  updateClientOnboardingFlags: async(clientId, flags) =>
    await apiPut(
      'accounts/update-onboarding-flags',
      { Id: clientId, ...flags },
      getErrorMessage('Issue updating onboarding flags')
    ),
  getInventoryData: async (page, status, searchTerm, pageSize) => {
    const params = { page, status, searchTerm, pageSize };
    return await apiGet("ui/items", "Issue getting items. Network issue.", {
      params
    });
  },
  getItemGroups: async () =>
    await apiGet(
      'ui/items/item-groups',
      'Issue getting item groups. Network issue.'
    ),
  createItemGroup: async(name) =>
    await apiPost(
      'items/create-item-group',
      { GroupName: name },
      'Issue creating item group. Network issue.'
    ),
  updateItemGroup: async(id, name) =>
    await apiPost(
      'items/update-item-group',
      { Id: id, GroupName: name },
      'Issue updating item group. Network issue.'
    ),
  queryItems: async (searchTerm, isActive, excludeKits) =>
    await apiGet(
      "ui/items/search",
      "Issue getting items. Network issue.",
      { params: { searchTerm, isActive, excludeKits } }
    ),
  getItemsFile: async (file) =>
    await apiPost(
      `items/get-file`,
      { File: file },
      "Issue getting file. Network Issue."
    ),
  getItemLotNumbers: async stockNumber =>
    await apiGet(
      `ui/items/lot-numbers/${encodeURIComponent(stockNumber)}`,
      "Issue getting item lot numbers. Network issue."),
  getItemTags: async stockNumber =>
    await apiGet(
      `ui/items/tags/${encodeURIComponent(stockNumber)}`,
      "Issue getting item tags. Network issue."),
  getItemTagsInWarehouse: async (stockNumber, wh) =>
    await apiGet(
      `ui/items/tags/${wh}/${encodeURIComponent(stockNumber)}`,
      "Issue getting item tags. Network issue."),
  createItem: async item =>
    await apiPost("items", item, "Issue creating item. Network Issue."),
  updateItem: async (stockNumber, data) =>
    await apiPut(
      "items/update",
      { Id: stockNumber, ...data },
      `Error updating ${stockNumber}. Network issue.`
    ),
  updateItemStatus: async (stockNumber, active, deactivatingItemKitsOption, activatingKitComponentsOption) => {
    const params = {
        StockNumber: stockNumber,
        IsActive: active,
        DeactivatingItemKitsOption: deactivatingItemKitsOption,
        ActivatingKitComponentsOption: activatingKitComponentsOption
      };
    return await apiPut(
      "items/update-status",
      params,
      `Error updating item status. Network issue.`
    )
  },
  importItem: async(stockNumber) =>
    await apiPost(
      `items/import-item`,
      { StockNumber: stockNumber },
      "Issue importing item. Network Issue."
    ),
  updateItemsInventory: async(stockNumbers) =>
    await apiPost(
      `items/import-items-inventory`,
      { StockNumbers: stockNumbers },
      "Issue importing item. Network Issue."
    ),
  getDashboardData: async () =>
    await apiGet(
      "ui/orders/dashboard",
      "Issue getting dashboard data. Network issue."
    ),
  getOrderData: async (page, pageSize, params) =>
    await apiGet(
      "ui/orders",
      "Error getting orders. Network error.",
      { params: { page, pageSize, ...params } }
    ),
  getArchivedOrderData: async (year, month, page, pageSize, params) =>
    await apiGet(
      `ui/orders/archived/${year}/${month}`,
      "Error getting orders. Network error.",
      { params: { page, pageSize, ...params } }
    ),
  submitOrder: async order =>
    await apiPost("orders", order, "Issue creating order. Network Issue."),
  updateOrder: async (orderNumber, data) =>
    await apiPut(
      "orders/update",
      { Id: orderNumber, ...data },
      `Issue updating order ${orderNumber}. Network Issue.`
    ),
  submitDeductOrder: async order =>
    await apiPost("orders/deduct", order, "Issue creating deduct order. Network Issue."),
  updateDeductOrder: async (orderNumber, data) =>
    await apiPut(
      "orders/update-deduct",
      { Id: orderNumber, ...data },
      `Issue updating deduct order ${orderNumber}. Network Issue.`
    ),
  getOrderFile: async (file) =>
    await apiPost(
      `orders/get-file`,
      { File: file },
      "Issue getting file. Network Issue."
    ),
  confirmOrders: async ids =>
    await apiPut(
      "orders/confirm",
      { Ids: ids },
      "Error confirming orders. Network issue."
    ),
  approveOrders: async ids =>
    await apiPut(
      "orders/approve",
      { Ids: ids },
      "Error approving orders. Network issue."
    ),
  releaseOrders: async ids =>
    await apiPut(
      "orders/release",
      { Ids: ids },
      "Error releasing orders. Network issue."
    ),
  cancelOrders: async (ids, backOrderIds) =>
    await apiPut(
      "orders/cancel",
      { Ids: ids, BackOrderIds: backOrderIds },
      "Error cancelling orders. Network issue."
    ),
  checkOrderWarehouseStatus: async(orderNumber) =>
    await apiGet(
      `orders/get/warehouse-status/${orderNumber}`,
      'Issue checking order warehouse status. Network issue.'
    ),
  fixAndCloseOrder: async(orderNumber) =>
    await apiPost(
      `orders/check-and-fix`,
      { Id: orderNumber },
      'Issue fixing and closing order. Network Issue.'
    ),
  forceCloseOrder: async(orderNumber, shipDate, carrier, serviceLevel, trackingNumbers) => {
    const params = {
        Id: orderNumber,
        ShippedDate: shipDate,
        ShippedCarrier: carrier,
        ShippedServiceLevel: serviceLevel,
        TrackingNumbers: trackingNumbers
      };
    return await apiPost(
      `orders/force-close`,
      params,
      'Issue force closing order. Network Issue.'
    );
  },
  importOrders: async (data, ext) =>
    await apiPost(
      'orders/upload-file',
      { FileData: data, Ext: ext },
      'Issue importing orders. Network Issue.'
    ),
  importItems: async (data, ext) =>
    await apiPost(
      'items/upload-file',
      { FileData: data, Ext: ext },
      'Issue importing items. Network Issue.'
    ),
  importAllItemsData: async(clientId) =>
    await apiPost(
      'items/import-all-data',
      { ClientId: clientId },
      getErrorMessage('Issue importing item data')
    ),
  getImports: async (type, page, pageSize) =>
    await apiGet(
      'ui/resource/import-requests',
      'Issue getting imports. Network Issue.',
      { params: { type, page, pageSize } }
    ),
  getImportDetails: async (id, field, page, pageSize) =>
    await apiGet(
      `ui/resource/import-details/${id}`,
      'Issue getting import details. Network Issue.',
      { params: { field, page, pageSize } }
    ),
  getRecipients: async (searchTerm, page, pageSize) =>
    await apiGet(
      "ui/recipients",
      "Issue getting recipients. Network issue.",
      { params: { searchTerm, page, pageSize } }
    ),
  queryRecipients: async (searchTerm, firstName, lastName, company) =>
    await apiGet(
      "ui/recipients/search",
      "Issue searching for recipients. Network issue.",
      { params: { searchTerm, firstName, lastName, company } }
    ),
  createRecipient: async recipient =>
    await apiPost(
      "ui/recipients",
      recipient,
      "Issue creating recipient. Network issue."
    ),
  updateRecipient: async (id, recipient) =>
    await apiPut(
      `ui/recipients/${id}`,
      recipient,
      "Issue updating recipient. Network issue."
    ),
  createReturn: async (trans, data) =>
    await apiPost(
      "orders/returns",
      { Id: trans, ...data },
      `Issue creating return for order ${trans}. Network issue.`
    ),
  createShippingLabel: async req =>
    await apiPost(
      "orders/returns/create-return-label",
      req,
      "Error generating shipping label. Network issue."
    ),
  getCreateReturnData: async trans =>
    await apiGet(
      `ui/orders/returns/form-data/${trans}`,
      "Error getting return data. Network issue."
    ),
  getAsnData: async (
    page,
    status,
    searchTerm,
    dateFrom,
    dateTo,
    warehouse,
    pageSize
  ) => {
    const params = {
      page,
      status,
      dateFrom,
      dateTo,
      warehouse,
      pageSize,
      searchTerm
    };
    return await apiGet(
      "ui/shipment-notices",
      "Error getting ASNs. Network issue.",
      { params });
  },
  createAsn: async data =>
    await apiPost(
      "advance-shipment-notices",
      data,
      "Issue creating shipment notice. Network issue."
    ),
  updateAsn: async (asnId, data) =>
    await apiPut(
      "advance-shipment-notices/update",
      { ShipmentNoticeId: asnId, ...data },
      `Error updating ASN ${asnId}. Network issue.`
    ),
  cancelAsns: async ids =>
    await apiPut(
      "advance-shipment-notices/cancel",
      { ShipmentNoticeIds: ids },
      `Error cancelling shipments. Network issue.`
    ),
  getUsers: async (isAdminSection, role, searchTerm, page, pageSize) => {
      const params = {
        isAdminSection,
        role,
        searchTerm,
        page,
        pageSize
      };
      return await apiGet(
        'ui/identities',
        'Issue getting users. Network issue.',
        { params }
      );
    },
  getCurrentUser: async req =>
    await apiGet(
      'ui/identities/current',
      req,
      'Issue current user. Network issue.'
    ),
  createUser: async user =>
    await apiPost(
      'identities',
      user,
      'Issue creating user. Network Issue.'
    ),
  deleteUser: async id =>
    await apiDelete(
      'identities/delete-identity',
      { UserId: id },
      'Issue deleting user. Network Issue.'
    ),
  updateUserProfile: async req =>
    await apiPut(
      'identities/update-profile',
      req,
      'Issue updating user. Network issue.'
    ),
  updateUserConfig: async req =>
    await apiPut(
      'identities/update-identity-configuration',
      req,
      'Issue updating user. Network issue.'
    ),
  updateUserCredentials: async req =>
    await apiPut(
      'identities/update-creds',
      req,
      'Issue updating user. Network issue.'
    ),
  updateUserAssignedClients: async req =>
    await apiPut(
      'identities/modify-assigned-clients',
      req,
      'Issue updating assigned clients. Network issue.'
    ),
  updateUserPreferredClients: async req =>
    await apiPut(
      'identities/modify-client-preference',
      req,
      'Issue updating preferred clients. Network issue.'
    ),
  createApiUser: async clientId =>
    await apiPost(
      'identities/api-user',
      { ClientId: clientId },
      'Issue creating API user. Network issue'
    ),
  createWebhook: async req =>
    await apiPut(
      'accounts/add-webhook',
      req,
      'Issue creating webhook. Network issue.'
    ),
  updateWebhook: async req =>
    await apiPut(
      'accounts/update-webhook',
      req,
      'Issue updating webhook. Network issue.'
    ),
  deleteWebhooks: async ids =>
    await apiDelete(
      'accounts/remove-webhooks',
      { Ids: ids },
      'Issue remove webhooks. Network issue.'
    )
};
