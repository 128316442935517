<template>
  <Container title="Import Items" :haveError="haveError" :errorId="errorId">
    <template #actions>
      <ItemImportActions @uploadFile="showImportModal = true" />
    </template>

    <ImportCreate v-if="showImportModal" @close="showImportModal = false" />

    <Imports
      importType="Items"
      loadingString="Loading Import Data. Please Wait..."
      :handleApiResultFunc="handleApiResult"
      @importSelected="importSelected" />
  </Container>
</template>

<script>
  import ItemImportActions from './Header/ItemImportActions.vue'
  import Container from  './../../Common/Container';
  import Imports from  './../../Common/Imports';
  import ImportCreate from './ImportCreate.vue'
  export default {
    name: 'ItemImports',
    data() {
      return {
        haveError: false,
        errorId: null,
        showImportModal: false
      }
    },
    components: { ItemImportActions, Container, Imports, ImportCreate },
    methods: {
      handleApiResult(res) {
        if(res.error) {
          this.haveError = true;
          this.errorId = res.errorId;
        }
      },
      importSelected() {
        this.$router.push({ name: 'ItemImportDetail' });
      }
    }
  }
</script>
