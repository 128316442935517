<template>
  <Container :subtitle="util.formatAndGetDate(order.CreatedDate)">
    <template #title>
      <span class="theme-blue">Deduct Order </span>
      <span class="theme-orange">#{{ order.OrderNumber }}</span>
    </template>

    <template #actions>
      <DeductOrderActions />
    </template>

    <EntityData gapClass="is-5" style="margin: 3em">
      <template #EntityBasic>
        <div class="column is-8 entity-column">
          <DeductOrderBasic />
          <WorkOrderBasic />
          <DeductOrderItems />
        </div>
      </template>
      <template #EntityExtra>
        <div class="column is-4 entity-column">
          <OrderHistory :order="order" />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import util from '../../lib/util';
import Container from './../Common/Container.vue';
import DeductOrderActions from './Header/DeductOrderActions.vue';
import EntityData from '../Common/EntityData.vue';
import DeductOrderBasic from './DeductOrderBasic.vue';
import WorkOrderBasic from './WorkOrderBasic.vue';
import DeductOrderItems from './DeductOrderItems.vue';
import OrderHistory from './OrderHistory.vue';

const order = useStore().getters['order/selectedOrder'];

onMounted(() => window.scrollTo(0, 0));
</script>

<style lang="less" scoped>
.entity-column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
</style>
