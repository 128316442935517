<template>
  <div class="order-actions-container">
    <i class="fa fa-bars" style="font-size: 1.75em; padding: .3em"></i>
    <PopUpPanel>
      <div class="order-actions-panel">
        <div class="theme-blue" @click="checkStatus">Check Warehouse Status</div>
        <div class="theme-blue" @click="fixAndCloseOrder(order.OrderNumber)">Fix and Close Order</div>
        <div class="theme-red" @click="showForceCloseModal = true">Force Close Order</div>
      </div>
    </PopUpPanel>
    <ForceCloseOrder v-if="showForceCloseModal"
      :order="order"
      @close="showForceCloseModal = false" />
    <Modal v-if="orderStatus"
      class="text-size"
      :defaultOkCancelBtnData="['Ok']"
      :hideCloseBtn="true"
      @close="orderStatus = null">
      The order's warehouse status is:
      <span class="order-status-text">{{ orderStatus }}</span>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import PopUpPanel from '../../Common/PopUpPanel.vue';
import Modal from '../../Common/Modal.vue';
import { checkOrderWarehouseStatus, fixAndCloseOrder } from '../../../composables/order';
import ForceCloseOrder from '../ForceCloseOrder.vue';

const { order } = defineProps(['order']);
const showForceCloseModal = ref(false);
const orderStatus = ref(null);

async function checkStatus() {
  orderStatus.value = await checkOrderWarehouseStatus(order.OrderNumber);
}
</script>

<style lang="less" scoped>
.order-actions-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.order-actions-panel {
  background: white;
  border: 1px #333 solid;
  border-radius: 3px;

  * {
    cursor: pointer;
    text-align: center;
    padding: .5em 1.2em;
  }
  * + * {
    border-top: 1px #333 solid;
  }
}
.order-status-text {
  font-size: calc(1em + 2px);
  font-weight: bold;
}
</style>
