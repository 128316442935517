<template>
  <Container title="Create User">
    <template #actions>
      <UserCreateActions :isUserValid="isUserValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <UserHeaderInfo
          :isNewUser="true"
          :validateRole="isUserInternal"
          @isUserValid="isUserValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <div class="form-section-heading">
            ACCOUNT INFORMATION
          </div>
          <UserAccount
            :isNewUser="true"
            :enableEditingEmailAndPassword="true"
            :enableEditingRole="isUserInternal"
            class="end-form-section" />
          <div class="form-section-heading">
            USER INFORMATION
          </div>
          <UserBasic :isNewUser="true" />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script>
import { mapGetters } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import UserCreateActions from './Header/UserCreateActions.vue';
import UserHeaderInfo from '../Manage/UserHeaderInfo.vue';
import UserAccount from '../Manage/UserAccount.vue';
import UserBasic from '../Manage/UserBasic.vue';
export default {
  name: 'UserCreate',
  components: { Container, EntityData, UserHeaderInfo, UserCreateActions, UserBasic, UserAccount },
  data() {
    return {
      isUserValid: false,
    }
  },
  computed: {
    ...mapGetters(['isUserInternal'])
  }
}
</script>

<style lang="less" scoped>
.form-section-heading {
  font-size: 16px;
  font-weight: 350;
  letter-spacing: .5;
  margin-bottom: 20px;
  
  :not(:first-child) {
    margin-top: 20px;
  }
}
.end-form-section {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
</style>