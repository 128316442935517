<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <label class="label">Dimensions<br />
          <span class="dim-info">Length & Width & Height in <b>inches</b></span>
        <br />
        <span class="dim-info">Weight in <b>lbs (pounds)</b></span>
        </label>
        
      </div>
    </div>
     <div class="columns">
       <div class="column is-12">
        <div class="columns">
          <div class="column is-3">
            <div class="field">
              <label class="label">LENGTH</label>
              <input @input="onDimensionChanged($event,'Length')" class="input" type="number" :value="dimension.Length" />
            </div>
          </div>
          <div class="column is-3">
            <div class="field">
              <label class="label">WIDTH</label>
              <input @input="onDimensionChanged($event,'Width')" class="input" type="number" :value="dimension.Width" />
            </div>
          </div>
          <div class="column is-3">
            <div class="field">
              <label class="label">HEIGHT</label>
              <input @input="onDimensionChanged($event,'Height')" class="input" type="number" :value="dimension.Height" />
            </div>
          </div>
          <div class="column is-3">
            <div class="field">
              <label class="label">WEIGHT</label>
              <input @input="onDimensionChanged($event,'Weight')" class="input" type="number" :value="dimension.Weight" />
            </div>
          </div>
        </div>
      </div>
     </div>
     
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Dimensions',
    computed:{
      ...mapGetters('product', ['dimension'])
    },
    methods:{
      ...mapActions('product',['onProdDimChange']),
      onDimensionChanged(e, prop){
        this.onProdDimChange({d: this.dimension, prop, val: e.target.value});
      }
    }
  }
</script>

<style lang="less" scoped>
  .dims-custom{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dim-info {
    font-size: 14px;
    font-style: italic;
    font-weight: normal !important
  }
</style>