<template>
  <div class="columns" style="">
    <div calss="column is-12" style="width: 100%;display: flex;align-items: center;justify-content:center; height: 360px;">
      <div class="card" style="width: 60%; height: 100%; display: flex;flex-direction: column;justify-content: space-evenly; align-items: center">
          <div>
            <i :class="apiStatus.responseStatus ? 'far fa-thumbs-up theme-success' : 'fas fa-exclamation-triangle theme-error'"
              style="font-size: 5em"></i>
          </div>
          <div style="text-align: center; padding: 0px 20px">
            <h3 class="response-heading" :class="apiStatus.responseStatus ? 'theme-success' : 'theme-error'">
              {{ apiStatus.responseStatus ? 'Everything looks good!' : 'Something went wrong' }}
            </h3>
            <h3 class="response-message">Message: {{ apiStatus.responseText }}</h3>
            <div v-if="apiStatus.responseErrorId" class="response-message" style="padding-top: 20px">
              Please contact support with this Error ID: {{apiStatus.responseErrorId}}
            </div>
          </div>
          <div>
             <button
              class="button is-normal is-outlined  is-link action-btn-custom"
              @click="onCloseModal"
              >
              <i class="icon is-small fas fa-door-closed"></i>
              <span>Close</span>
            </button>
          </div>
      </div>      
    </div>
  </div>
</template>

<script>
 import { mapGetters, mapActions } from 'vuex';
  export default {
    name: 'ResponseUI',
    props: ['status'],
    computed: { 
      ...mapGetters(['apiStatus'])
    },
    methods:{
      ...mapActions(['onFinishAPICalls']),
      async onCloseModal(){
        if(this.apiStatus.responseStatus) {
          if(this.$route.meta.apiRedirectRoute)
            window.location.href = this.$router.resolve({ name: this.$route.meta.apiRedirectRoute }).href;
          else
            window.location.reload();
        } else
          this.onFinishAPICalls();
      }
    }
  }
</script>

<style lang="less" scoped>
.response-heading {
  font-size: 24px;
}
.response-message{
  max-height: 130px;
  overflow-y: auto;
  white-space: pre-line;
  font-size: 16px;
  font-weight: bold;
}
</style>