<template>
  <!-- Because of Teleport, multiple FullScreenModals will show with correct
       stacking because they are appended at the end of the target element. -->
  <Teleport to="body">
    <div v-bind="$attrs" class="fullscreen-modal-container">
      <slot />
    </div>
  </Teleport>
</template>

<script>
  export default {
    name: 'FullScreenModal',
    inheritAttrs: false
  }
</script>

<style lang="less" scoped>
  .fullscreen-modal-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(256, 256, 256, .93); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: auto;
  }
</style>