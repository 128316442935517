<template>
  <div v-bind="$attrs" class="select" :class="size ? 'is-multiple select-is-expanded' : ''">
    <select ref="list"
      :size="size"
      :disabled="disabled"
      style="width: 100%"
      @change="valueChanged">
      <option v-if="instructionLabel" value="" :disabled="!allowUnselect" :selected="!selectedValue">
        {{ instructionLabel }}
      </option>
      <option v-for="(option, key) in entries"
        :key="key"
        :value="option.value"
        :selected="option.selected">
        {{ option.label }}
      </option>
    </select>
  </div>
  <i v-if="allowUnselect && showClearBtn && haveValue" class="fas fa-times clear-btn" @click="clearSelect"></i>
</template>

<script>
// This component is deprecated (except for the cases where "size" is provided, we
// don't have a replacement for that yet). The new component is DropDownList.
export default {
  name: 'SelectList',
  props: [
    'instructionLabel',
    'options',
    'labelField',
    'valueField',
    'useIndexAsValue',
    'selectedValueField',
    'selectedValue',
    'allowUnselect',
    'showClearBtn',
    'disabled',
    'size' ],
  emits: ['update:selectedValue'],
  inheritAttrs: false,
  data() {
    return {
      haveValue: false
    };
  },
  mounted() {
    // For an expanded single select list where we don't use an instruction label,
    // don't let the browser preselect the first value.
    if(!this.selectedValue && !this.instructionLabel && this.size)
      this.$refs.list.selectedIndex = -1;

    this.haveValue = this.getHaveValue();
  },
  computed: {
    minIndexForHaveValue() {
      return this.instructionLabel ? 1 : 0;
    },
    isMap() {
      return this.options instanceof Map;
    },
    isArray() {
      return this.options instanceof Array;
    },
    entries() {
      // This list handles maps, objects, and arrays.
      //
      // valueField and selectedValueField field can be used to specify which values the
      // select list produces from the options, or used to determine the preselected value.
      // If those fields are not used, arrays work by their entries unless useIndexAsValue
      // is set. Maps/objects also use their entries as values unless useIndexAsValue is set,
      // but are preselected by key/property. If the values produced by the list are
      // objects, they are converted to JSON.

      const keys = this.isMap ? [...this.options.keys()] : Object.keys(this.options);
      
      return keys.map(key => {
        const valueObj = this.isMap ? this.options.get(key) : this.options[key];
        
        const label = this.labelField ? valueObj[this.labelField] : valueObj;
        const value = this.getValue(valueObj, key);
        const selected = this.getValueForSelectedCompare(valueObj, key) == this.selectedValue;

        return {
            label,
            value: value instanceof Object ? JSON.stringify(value) : value,
            selected
          };
      });
    }
  },
  methods: {
    getHaveValue() {
      return this.$refs.list && this.$refs.list.selectedIndex >= this.minIndexForHaveValue;
    },
    getValue(obj, key) {
      return this.valueField ? obj[this.valueField]
        : this.useIndexAsValue ? key
        : obj;
    },
    getValueForSelectedCompare(obj, key) {
      return this.selectedValueField ? obj[this.selectedValueField]
        : this.valueField ? obj[this.valueField]
        : this.isArray && !this.useIndexAsValue ? obj
        : key;
    },
    valueChanged() {
      this.haveValue = this.getHaveValue();
      this.$emit('update:selectedValue', this.$refs.list.value);
    },
    clearSelect() {
      this.$refs.list.selectedIndex = this.minIndexForHaveValue - 1;
      this.valueChanged();
      this.$refs.list.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }
}
</script>

<style lang="less" scoped>
.select {
  display: inline-block !important;
  vertical-align: baseline;
  height: unset !important;
}
.select-is-expanded > select {
  // If we are using Bulma's is-multiple class, but without the "multiple" attribute on the select, only a "size"
  height: unset;
  overflow: auto;
  padding: .5em .8em !important;
}
.select-is-expanded > select > option {
  padding: 6px !important;
}
.clear-btn {
  font-size: 1.25em;
  vertical-align: baseline;
  position: relative;
  top: 1px;
  padding: .3em;
  cursor: pointer;
  margin-left: .4em;
}
</style>
