<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderWithMoreActions
        btnText="Search"
        iconName="fas fa-search"
        @expanded="$refs.searchBox.select()">
        <template #options>
          <SearchBox
            ref="searchBox"
            @onSearch="$emit('onSearch', $event)"
            @onSearchReset="$emit('onSearchReset')" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderWithMoreActions
        btnText="Filter"
        iconName="fas fa-filter">
        <template #options>
          <WarehouseAndDateFilter @filterChanged="$emit('filterChanged', $event)" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderButton
        btnText="Create Notice"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="$router.push({ name: 'ShipNoticeCreate' })" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import { mapActions } from 'vuex';
import ComponentHeaderActions from '../../../Common/ComponentHeaderActions.vue';
import ComponentHeaderWithMoreActions from '../../../Common/ComponentHeaderWithMoreActions.vue';
import ComponentHeaderButton from '../../../Common/ComponentHeaderButton.vue';
import SearchBox from '../../../Common/Search/SearchBox.vue';
import WarehouseAndDateFilter from '../../../Common/Search/WarehouseAndDateFilter.vue';
export default {
  name: 'ShipmentHeaderActions',
  components: { ComponentHeaderActions, ComponentHeaderWithMoreActions, ComponentHeaderButton, SearchBox, WarehouseAndDateFilter },
  emits: ['onSearch', 'onSearchReset', 'filterChanged'],
  methods: {
    ...mapActions(['onSetCurrentEntityAction'])
  }
}
</script>
