<template>
  <div class="columns" style="display: block">
    <ValidationMessages v-if="isEditable"
      class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="validationMessages"
      :checks="validationChecks" />
    <div v-if="showProductInfo" class="column product-header">
       <div class="field">
        <label class="label custom-label-style">Item Type</label>
         <p class="">{{ getCorrectValue(selectedProduct.ItemType) }}</p>
      </div>
      <div class="field">
        <label class="label custom-label-style">Created On</label>
        <span class="">{{ getDate( selectedProduct.CreatedDate ) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import util from './../../../lib/util.js'
import ValidationMessages from '../../Common/ValidationMessages.vue';
export default {
  name: 'ProductHeaderInfo',
  props: ['isEditable', 'showProductInfo', 'isNew'],
  components: { ValidationMessages },
  emits: ['isProductValid'],
  methods:{
    getCorrectValue(val){
      return util.getUpperCaseVal(val);
    },
    getDate(date){
      if(date == null)
        return 'Not Available'
      else
        return util.formatAndGetDate(date)
    }
  },
  watch: {
    isProductValid: {
      immediate: true,
      handler: function(val) {
        this.$emit("isProductValid", val)
      }
    }
  },
  computed: {
    ...mapGetters('product', ['newProduct','dimension', 'selectedProduct']),
    ...mapGetters(['selectedItems']),
    validationMessages() {
      return [
          `Required fields: ${this.isNew ? 'Stock Number, ' : ''}UPC/Barcode, Description`,
          ...this.validateProductComponents ? [`${this.isNew ? 'For Item Type Bundled: a' : 'A'}t least 1 item is required`] : [],
          'Dimensions can\'t be zero',
          'Price and Cost must be valid if provided',
          'Cycle Time must be greater than 0 if expiration date is required',
          'Pack Unit must be greater than 1 if the item is caseable'
        ];
    },
    validationChecks() {
      return [
          this.haveProductRequiredFields,
          ...this.validateProductComponents ? [this.haveProductBundledComponents] : [],
          this.haveProductDimensions,
          this.priceAndCostValid,
          this.cycleTimeValid,
          this.packUnitValid
        ];
    },
    validateProductComponents() {
      return this.isNew || this.isBundledProduct;
    },
    haveProductRequiredFields(){
      return this.newProduct.StockNumber &&
      this.newProduct.Barcode &&
      this.newProduct.Description
    },
    isBundledProduct() {
      return this.newProduct.ItemType == 'Bundled'
    },
    haveProductBundledComponents(){
      return !this.isBundledProduct || util.haveItemsAndQuantities(this.selectedItems)
    },
    haveProductDimensions(){
      return this.dimension.Length > 0 && this.dimension.Width > 0 &&
        this.dimension.Height > 0 && this.dimension.Weight > 0
    },
    priceAndCostValid() {
      return (!this.newProduct.Price || this.newProduct.Price > 0) &&
        (!this.newProduct.Cost || this.newProduct.Cost > 0)
    },
    packUnitValid() {
      return !this.newProduct.IsCaseable ||
        (/^\d+$/.test(this.newProduct.PackUnit) && this.newProduct.PackUnit > 1);
    },
    cycleTimeValid() {
      return !this.newProduct.ExpirationDateRequired ||
        (/^\d+$/.test(this.newProduct.CycleTime) && this.newProduct.CycleTime > 0);
    },
    isProductValid(){
     return this.validationChecks.every(Boolean);
    }
  },
}
</script>

<style lang="less" scoped>
  .custom-label-style{
    margin: 0px !important
  }
  .product-header {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    border-bottom: 1px solid #ccc;
  }
</style>