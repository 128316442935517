<template>
  <FullScreenModal>
    <div v-bind="$attrs" class="card modal-container" :style="style">
      <div v-if="title || !hideCloseBtn" class="modal-heading">
        <h1 class="modal-title">{{title}}</h1>
        <i title="Close" class="fas fa-times modal-close-btn" @click="$emit('close')"/>
      </div>
      <div :class="{ 'fullscreen-content': fullScreen }">
        <slot />
      </div>
      <div v-if="computedButtons.length" class="buttons-container">
        <button v-for="(data, idx) in computedButtons"
          :key="idx"
          class="button"
          :class="data.classes"
          :style="data.style"
          :disabled="data.disabled"
          @click="$emit('close', data.value)">
          <span>{{ data.label }}</span>
        </button>
      </div>
    </div>
  </FullScreenModal>
</template>

<script>
import FullScreenModal from './FullScreenModal.vue';
export default {
  name: 'Modal',
  components: { FullScreenModal },
  inheritAttrs: false,
  props: ['title', 'hideCloseBtn', 'width', 'maxWidth', 'fullScreen', 'buttons', 'defaultOkCancelBtnData'],
  emits: ['close'],
  computed: {
    style() {
      let style = '';
      if(this.fullScreen)
        style += 'width: 100%; height: 100%';
      else {
        if(this.width)
          style += `width: ${this.width};`
        if(this.maxWidth)
          style += `max-width: ${this.maxWidth};`
      }
      return style;
    },
    computedButtons() {
      const buttons = [];
      
      if(this.defaultOkCancelBtnData && this.defaultOkCancelBtnData[0])
        buttons.push(this.getDefaultButtonData(this.defaultOkCancelBtnData[0], 1, 'blue-button'));

      if(this.buttons)
        buttons.push(...this.buttons);
      
      if(this.defaultOkCancelBtnData && this.defaultOkCancelBtnData[1])
        buttons.push(this.getDefaultButtonData(this.defaultOkCancelBtnData[1], 0, 'red-button'));
      
      return buttons;
    }
  },
  methods: {
    getDefaultButtonData(data, value, classes) {
      return {
          label: data.label || data,
          classes,
          style: 'padding: 0 1.5em',
          disabled: !!data.disabled,
          value
        };
    }
  }
}
</script>

<style lang="less" scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  padding: 35px;
  flex-grow: 0;
  margin: 0 10px;
}
.modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.modal-title {
  font-size: 32px;
  color: #222;
}
.modal-close-btn {
  font-size: 28px;
  cursor: pointer;
  margin-left: 30px;
}
.fullscreen-content {
  height: 1px;
  flex-grow: 1;
}
.buttons-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  .modal-title {
    font-size: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1460px){
  .modal-title {
    font-size: 28px;
  }
}
</style>
