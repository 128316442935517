<template>
  <Container :title="title">
    <template #actions>
      <RecipientEditActions :isRecipientValid="isRecipientValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <RecipientHeaderInfo @isRecipientValid="isRecipientValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <RecipientBasic />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import RecipientEditActions from './Header/RecipientEditActions.vue';
import RecipientHeaderInfo from './Header/RecipientHeaderInfo.vue';
import RecipientBasic from './RecipientBasic.vue';
export default {
  name: 'RecipientDetail',
  components: { Container, EntityData, RecipientEditActions, RecipientHeaderInfo, RecipientBasic },
  data() {
    return {
      isRecipientValid: false
    }
  },
  created() {
    const recipient = this.selectedRecipient;

    this.onSetNewRecipient({
      FirstName: recipient.FirstName,
      LastName: recipient.LastName,
      OriginalCustomerNumber: recipient.OriginalCustomerNumber,
      Company: recipient.Company,
      Email: recipient.Email,
      Address1: recipient.Address1,
      Address2: recipient.Address2,
      City: recipient.City,
      State: recipient.State,
      ZipCode: recipient.ZipCode,
      Country: recipient.Country,
      Phone: (recipient.Phone || '').replace(/[^\d]+/g, ''),
      Notes: recipient.Notes
    });
  },
  computed: {
    ...mapGetters('manage', ['selectedRecipient']),
    title() {
      return [this.selectedRecipient.FirstName, this.selectedRecipient.LastName].filter(Boolean).join(' ')
        || this.selectedRecipient.Company;
    }
  },
  methods: {
    ...mapActions('manage', ['onSetNewRecipient'])
  }
}
</script>
