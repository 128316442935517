<template>
  <div class="column" :class="isNewNotice ? 'is-7' : 'is-5'">
    <ItemSearchInput v-if="!disableEditing" :excludeKits="true" />
    <ItemListEdit
      :items="selectedItems"
      :showReceivedQty="disableEditing"
      :disableEditing="disableEditing" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ItemListEdit from './../../Common/ItemListEdit';
import ItemSearchInput from './../../Common/ItemSearchInput';

defineProps(['isNewNotice', 'disableEditing']);
const store = useStore();
const selectedItems = computed(() => store.getters['selectedItems']);
</script>
