<template>
  <div class="collapsible-panel-header entity-heading" @click="toggle">
    <div style="flex-grow: 1">
      <slot></slot>
    </div>
    <i class="fas toggle-button" :class="visible ? 'fa-caret-up' : 'fa-caret-down'"></i>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const emit = defineEmits(['expanded']);

const visible = ref(true);

function toggle() {
  visible.value = !visible.value;
  emit('expanded', visible.value);
}
</script>

<style lang="less" scoped>
.collapsible-panel-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.toggle-button {
  padding-left: .5em;
  font-size: 1.25em;
  color: #666;
}
</style>
