<template>
  <!-- Don't use v-show, this modal can conflict with others, and we want the
       latest one to be on top. -->
  <FullScreenModal v-if="apiStatus.isInProcess">
    <div class="container">
      <div v-if="!apiStatus.responseAvailable" class="content">
        <i class="fas fa-cog fa-spin" style="font-size: 4em" ></i>
        <div class="waiting-text-container">
          <h3 class="waiting-text">{{ apiStatus.inProcessText }}</h3>
        </div>
      </div>
      <ResponseUI v-else />
    </div>
  </FullScreenModal>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ResponseUI from './ResponseUI';
import FullScreenModal from './FullScreenModal.vue';
const apiStatus = computed(() => useStore().getters['apiStatus']);
</script>

<style lang="less" scoped>
.waiting-text-container{
  height: 60px;
  margin-top: 20px;
}
.waiting-text {
  font-size: 28px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>