<template>
  <template v-if="isInline">
    <div v-if="fileName" class="inline-preview-container entity-value" @click="filePreviewComp.show()">
      <i class="far theme-blue" :class="isFileImage ? 'fa-file-image' : isFilePdf ? 'fa-file-pdf' : 'fa-file'" />
      Click to Preview
    </div>
    <p v-else class="entity-value-empty">NONE</p>
  </template>
  <div v-else v-show="fileName" class="preview-container">
    <div style="cursor: pointer" @click="filePreviewComp.show()">
      <img v-if="isFileImage" :src="fileData" class="preview-image-btn" />
      <span v-else class="preview-file-btn" >
        <i class="far preview-icon" :class="isFilePdf ? 'fa-file-pdf' : 'fa-file'" />
        <span class="preview-icon-label has-text-link">Click to preview</span>
      </span>
    </div>
  </div>
  <FilePreview ref="filePreviewComp"
    :titleOverride="titleOverride"
    :fileName="fileName"
    :fileData="fileData"
    :fileLoadFunc="fileLoadFunc" />
</template>

<script setup>
import { ref, computed } from 'vue';
import util from '../../lib/util';
import FilePreview from './FilePreview';

const props = defineProps(['isInline', 'titleOverride', 'fileName', 'fileData', 'fileLoadFunc']);
const filePreviewComp = ref(null);

const isFilePdf = computed(() => util.checkFileType('pdf', props.fileName));
const isFileImage = computed(() => util.checkFileType('image', props.fileName));
</script>

<style lang="less" scoped>
.preview-container {
  display: flex;
  justify-content: center;
  padding: 18px 0 9px 0;
}
.inline-preview-container {
  cursor: pointer;
  font-style: italic;

  i {
    vertical-align: middle;
    width: 1em;
    font-size: 1.3em;
  }
}
.preview-image-btn {
  max-height: 120px;
  max-width: 100%;
}
.preview-file-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}
.preview-icon {
  font-size: 4.5em;
  vertical-align: middle;
}
.preview-icon-label {
  white-space: nowrap;
}
</style>
