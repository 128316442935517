<template>
  <div class="dock" v-show="showLeft || showCenter || showClientSwitcher">
    <div v-show="showCenter"
      ref="dockCenterDiv"
      id="dockCenterDiv"
      class="first-line-div"
      :class="{ 'first-line-absolute': compressLines }">
    </div>
    <div v-show="showLeft || showClientSwitcher" class="second-line-div">
      <div v-show="showLeft" ref="dockLeftDiv" id="dockLeftDiv">
      </div>
      <div ref="clientSwitcherDiv" v-if="showClientSwitcher" style="margin-left: auto">
        <ClientSwitcher />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ClientSwitcher from '../Manage/ClientSwitcher.vue';

// TODO: haveError should come from the store, once Container only gets it from the store
const props = defineProps(['haveError']);
const store = useStore();
const route = useRoute();

const dockLeftDiv = ref(null);
const dockCenterDiv = ref(null);
const clientSwitcherDiv = ref(null);

const haveCenter = ref(false);
const haveLeft = ref(false);
const showCenter = computed(() => haveCenter.value && !props.haveError);
const showLeft = computed(() => haveLeft.value && !props.haveError);
const showClientSwitcher = route.meta.showClientSwitcher && store.getters['isUserInternal'];
const compressLines = ref(false);

const observer = new MutationObserver(determineLayout);

onMounted(setupLayoutObservation);
onBeforeUnmount(teardownLayoutObservation);

function setupLayoutObservation() {
  const config = { attributes: true, childList: true, subtree: true };
  observer.observe(dockLeftDiv.value, config);
  observer.observe(dockCenterDiv.value, config);

  window.addEventListener('resize', determineLayout);

  determineLayout();
}

function teardownLayoutObservation() {
  observer.disconnect();
  window.removeEventListener('resize', determineLayout);
}

function determineLayout() {
  // Make sure no elements are in dockLeftDiv or dockCenterDiv if they don't have
  // anything to show, not even elements with display:none or dimensions of 0.
  haveCenter.value = !!dockCenterDiv.value.childElementCount;
  haveLeft.value = !!dockLeftDiv.value.childElementCount;
  compressLines.value = false;

  if(!haveCenter.value || (!haveLeft.value && !showClientSwitcher))
    return;

  const centerDivRect = dockCenterDiv.value.getBoundingClientRect();
  compressLines.value = !(
      (haveLeft.value && (dockLeftDiv.value.getBoundingClientRect().right > centerDivRect.left)) ||
      (showClientSwitcher && (clientSwitcherDiv.value.getBoundingClientRect().left < centerDivRect.right))
    );
}
</script>

<style lang="less" scoped>
.dock {
  position: relative;
  padding: 5px 5px 5px 15px;
  border-top: 1px solid rgba(162, 166, 176, 0.8);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}
.first-line-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.first-line-absolute {
  position: absolute;
  z-index: 10;
}
.second-line-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  z-index: 1;
  width: 100%;
}
</style>