<template>
  <SelectList
    :key="carrierId"
    :instructionLabel="serviceLevels.length ? '-- Choose a Service Level --' : null"
    :options="serviceLevels"
    :disabled="!carrierId"
    labelField="name"
    :valueField="valueField || '_id'" />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SelectList from './SelectList.vue';

const props = defineProps(['carrierId', 'valueField']);

const store = useStore();
const serviceLevels = computed(() =>
  store.getters['serviceLevels'].filter(x => x.carrierId == props.carrierId));
</script>
