<template>
  <div v-if="renderWO" id="printWorkOrderDiv" style="display: none">
    <h1 style="margin: 10px 0">Work Order #{{ workOrder._id }}</h1>

    <table class="has-border columns-table" style="max-width: 800px">
      <tr>
        <td>Order Date:</td>
        <td>{{ util.formatAndGetDate(order.CreatedDate) }}</td>
        <td>Completed By Date:</td>
        <td>{{ util.formatAndGetDateOnly(workOrder.CompletedByDate) }}</td>
      </tr>
      <tr>
        <td>Client ID:</td>
        <td>{{ order.ClientId }}</td>
        <td>Client Name:</td>
        <td>{{ client.Name }}</td>
      </tr>
      <tr>
        <td>Deduct #:</td>
        <td>{{ order.Name }}</td>
        <td>ASN #:</td>
        <td>{{ workOrder.ShipmentNoticeId }}</td>
      </tr>
      <tr>
        <td>Description:</td>
        <td colspan="3">{{ order.ShipmentNote }}</td>
      </tr>
    </table>
    
    <br />
    
    <table class="has-border">
      <tr>
        <td>Quantity</td>
        <td>SKU</td>
        <td>Description</td>
        <td>Lot #</td>
        <td>Exp. Date</td>
      </tr>
      <tr v-for="(line, i) in tagLines" :key="i">
        <td>{{ line.Quantity }}</td>
        <td style="white-space: nowrap">{{ line.StockNumber }}</td>
        <td>{{ line.Description }}</td>
        <td style="white-space: nowrap">{{ line.LotNo }}</td>
        <td>{{ line.ExpDate ? util.formatAndGetDateOnly(line.ExpDate) : null }}</td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import util from '../../lib/util';

const emit = defineEmits(['close']);
const store = useStore();

const client = store.getters['currentClient'];
const order = store.getters['order/selectedOrder'];
const workOrder = order.WorkOrder;
const renderWO = ref(false);
const tagLines = getTagLines();

function getTagLines() {
  const tagLines = [];

  for(const line of order.Items)
    if(line.Tags && line.Tags.length) {
      const tagLinesByLot = util.aggregateObjects(
        line.Tags.map(x => ({ ...x})),
        compareTagLotNosAndExpDates,
        (g, t) => g.Quantity += t.Quantity);

      tagLines.push(...tagLinesByLot.map(x => ({
          Quantity: x.Quantity,
          StockNumber: line.Product.Name,
          Description: line.Description,
          LotNo: x.LotNo,
          ExpDate: x.ExpDate
        })));
    } else
      tagLines.push({
          Quantity: line.QuantityRequested,
          StockNumber: line.Product.Name,
          Description: line.Description
        });
  
  return tagLines;

  function compareTagLotNosAndExpDates(existing, current) {
    return ((existing.LotNo || null) == (current.LotNo || null))
      && ((existing.ExpDate || null) == (current.ExpDate || null))
  }
}

async function print() {
  renderWO.value = true;
  await nextTick();

  const popup = window.open('/print.html');
  popup.addEventListener('load', () => {
      popup.document.getElementById('printContent').innerHTML = document.getElementById('printWorkOrderDiv').innerHTML;
      emit('close');
    });
}

defineExpose({ print });
</script>
