<template>
  <Container title="Create a Support Ticket" :haveError="haveError" :errorId="errorId">
    <MaxWidthContainer class="card content-container">
      <form v-on:submit.prevent="onSubmitTicket" enctype="multipart/form-data" >
        <div class="columns">
          <div class="column is-6">
            <p style=" font-size:16px; margin-bottom:5px;">PRIORITY</P>
              <div class="control">
                <div class="select">
                  <select v-model="priority">
                    <option 
                      v-for="(option, index) in priorities" 
                      :key="index"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
          </div>
          <div class="column is-6">
            <p style=" font-size:16px; margin-bottom:5px;">WAREHOUSE</p>
              <div class="control has-icons-right">
                <div class="select ">
                  <select v-model="warehouse">
                    <option disabled>
                      -- Select a Warehouse --
                    </option>
                    <option 
                      v-for="(option, index) in warehouses" 
                      :key="index"
                    >
                      {{ option }}
                    </option>
                  </select>
                  <span class="icon is-right" :class="getRequiredCheckboxClass(warehouse)" style="margin-right: -50px">
                    <i class="fa fa-check"></i>
                  </span>
                </div>
              </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <p style=" font-size:16px; margin-bottom:5px;">EMAIL</p>
            <div
              class="control has-icons-right"
              style="color:#999"
            >
              <input v-model="email" class="input" type="email" placeholder="Email" />
              <span class="icon is-right" :class="getRequiredCheckboxClass(isEmailValid)">
                <i class="fa fa-check"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <p style=" font-size:16px; margin-bottom:5px;">SUBJECT</p>
            <div
              class="control  has-icons-right"
              style="color:#999"
            >
              <input v-model="subject" class="input" type="text" placeholder="Subject" />
              <span class="icon is-right" :class="getRequiredCheckboxClass(subject)">
                <i class="fa fa-check"></i>
              </span>
            </div>
          </div>
        </div>
          <div class="columns">
          <div class="column is-12">
            <p style=" font-size:16px; margin-bottom:5px;">MESSAGE</p>
            <div
              class="control has-icons-right"
              style="color:#999"
            >
              <textarea v-model="message" class="textarea" placeholder="Describe your concern/issue" />
              
              <span class="icon is-right" :class="getRequiredCheckboxClass(message)">
                <i class="fa fa-check"></i>
              </span>
            </div>
          </div>
          </div>
        <div class="columns">
          <div class="column is-12">
            <FileUpload ref="fileUpload" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <p style=" font-size:16px; margin-bottom:5px;">ORDER NUMBER</p>
            <div
              class="control"
              style="color:#999"
            >
              <input v-model="orderNumber" class="input" placeholder="Enter Order Number" />
            </div>
          </div>
          <div class="column is-6">
            <p style=" font-size:16px; margin-bottom:5px;">SERVICE DOWN</p>
            <div
              class="control has-icons-right"
              style="color:#999"
            >
              <label class="checkbox">
                <input type="checkbox" v-model="isServiceDown" />
              </label>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <p style=" font-size:16px; margin-bottom:5px;">SERVICE</p>
              <div class="control">
                <div class="select">
                  <select v-model="service">
                    <option disabled>
                      -- Select a Service --
                    </option>
                    <option 
                      v-for="(option, index) in services" 
                      :key="index"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
          </div>
          <div class="column is-6">
            <p style=" font-size:16px; margin-bottom:5px;">CATEGORY</p>
              <div class="control">
                <div class="select">
                  <select v-model="category">
                    <option disabled>
                      -- Select a Category --
                    </option>
                    <option 
                      v-for="(option, index) in categories" 
                      :key="index"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
          </div>
        </div>
            <div class="field"  style="margin-top: 20px; text-align: right; margin-bottom: 10px">
              <button
              type="submit"
              class="button blue-button bigger-button"
              style="margin-right: 20px"
              :disabled="!canSubmit"
            >
              <span>Submit Ticket</span>
            </button>
            <button
              @click.prevent="onResetAll"

              class="button red-button bigger-button"
            >
              <span>Reset All</span>
            </button>
            
            </div>
      </form>
    </MaxWidthContainer>
  </Container>
</template>

<script>
  import { mapActions } from 'vuex';
  import api from "./../../lib/api-gateway";
  import util from "./../../lib/util";
  import Container from './../Common/Container.vue';
  import FileUpload from './../Common/FileUpload.vue'
  import MaxWidthContainer from '../Common/MaxWidthContainer.vue';
  export default {
    name: 'SupportContainer',
    components: { FileUpload, Container, MaxWidthContainer },
    data(){
      return{
        haveError: false,
        errorId: null,
        priority: "",
        email: "",
        subject: "",
        message: "",
        warehouse: "",
        warehouses: [],
        orderNumber: "",
        service:"",
        services: [],
        priorities: [],
        isServiceDown: false,
        category: "",
        categories: []
      }
    },
    inject: [ 'alert' ],
    async created(){
      const res = await api.querySupportTicketOptions();
      if(!res.error)
      { 
        this.priorities = res.result.priorities;
        this.priority = res.result.priorities[0];
        this.warehouses = res.result.warehouses;
        this.services = res.result.services;
        this.categories = res.result.categories;
        this.resetServiceAndCategory();
      }      
      else {
        this.haveError = true;
        this.errorId = res.errorId;
      }
    },
    computed: {
      canSubmit(){
        return this.warehouse != "" && this.isEmailValid != "" && this.subject != "" & this.message != "" 
      },
      isEmailValid() {
        return util.testEmail(this.email);
      }
    },
    methods: {
      ...mapActions(['onMakingAPICalls', 'onSetResponseContent']),
      getRequiredCheckboxClass(value) {
        return value ? 'has-text-dark' : 'has-text-grey-lighter'
      },
      async makeApiCall(){
        let content = '<div>';
        if(this.priority)
          content += `<p>Priority: ${ this.priority }</p>`;
        if(this.warehouse)
          content += `<p>Warehouse: ${ this.warehouse }</p>`;
        if(this.orderNumber)
          content += `<p>Order Number: ${ this.orderNumber }</p>`;
        if(this.message)
          content += `<p>Message: ${ this.message }</p>`;
        if(this.service)
          content += `<p>Service: ${ this.service }</p>`;
        if(this.isServiceDown)
          content += `<p>Service Down:  ${ this.isServiceDown }</p>`;
        if(this.category)
          content += `<p>Category: ${ this.category }</p>`;
        content += '</div>';
        
        const supportTicketRequest = {
          Subject: this.subject,
          UserEmail: this.email,
          Content: content,
          ImageBase64String: this.selectedFileBase64
        }

        this.onMakingAPICalls('Submitting ticket...');
        const res = await api.submitSupportTicket(supportTicketRequest);

        if(!res.error)
          this.onSetResponseContent({ responseText: 'Ticket submitted successfully.', responseStatus: true });
        else
          this.onSetResponseContent({ responseText: res.message, responseStatus: false });
      },
      async onSubmitTicket(){
        if(this.$refs.fileUpload.hasFile) {
          try {
            this.selectedFileBase64 = await this.$refs.fileUpload.getFileBase64();
          } catch(err) {
            this.alert(err.message);
            return;
          }
        }
        
        await this.makeApiCall();
      },
      onResetAll(){
        this.priority = this.priorities[0];
        this.subject = "";
        this.email = "";
        this.warehouse = "";
        this.orderNumber = "";
        this.isServiceDown = false;
        this.service = "";
        this.category = "";
        this.message = "";
        this.$refs.fileUpload.clearAttachment();
        this.resetServiceAndCategory();
      },
      resetServiceAndCategory() {
        this.services.forEach(x => {
          if(x.toLowerCase() == 'fulfillify')
            this.service = x;
        });
        this.categories.forEach(x => {
          if(x.toLowerCase() == 'fulfillify')
            this.category = x;
        });
      }
    },
    
  }
</script>