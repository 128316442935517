<template>
  <DropDownList instructionLabel="-- Select a Client --"
    :options="clientOptions"
    :modelValue="modelValue"
    :enableSearch="true"
    minWidth="200"
    labelField="Name">
    <template #default v-if="enablePreferredClients">
      <div class="dropdown-item" style="cursor: pointer" @click="showAllClients = !showAllClients">
        <input type="checkbox" :checked="showAllClients">&nbsp;&nbsp;<i>Show all clients</i>
      </div>
    </template>
  </DropDownList>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import DropDownList from '../Common/DropDownList.vue';
import util from '../../lib/util';

const props = defineProps(['modelValue', 'useLoggedInUser', 'noPreferredClients']);
const store = useStore();

const userBeingEdited = !props.useLoggedInUser ? store.getters['manage/selectedUser'] : null;
const preferredClients = props.useLoggedInUser ? store.getters['preferredClients'] : (userBeingEdited.PreferredClients || []);

const showAllClients = ref(false);

const clientsList = getAllClients();
const enablePreferredClients = !props.noPreferredClients
  && (props.useLoggedInUser || userBeingEdited._id == store.getters['loggedInUser'].UserId)
  && preferredClients.length
  && preferredClients.length != clientsList.size;
const clientOptions = computed(() => enablePreferredClients ? getClientOptionsWhenPreferredClientsAreEnabled() : clientsList);

function getAllClients() {
  return util.filterMap(
    store.getters['clientsList'],
    ([id]) => props.useLoggedInUser || userBeingEdited.RoleId == 0 || userBeingEdited.AssignedClients.includes(id))
}
function getClientOptionsWhenPreferredClientsAreEnabled() {
  return util.filterMap(
    clientsList,
    ([id]) => id == props.modelValue || showAllClients.value || preferredClients.includes(id));
}
</script>
