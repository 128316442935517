<template>
  <Container title="Items">
    <template #actions>
      <InventoryHeaderActions 
        @onSearch="onSearch"
        @onSearchReset="onSearchReset"/>
    </template>

    <ItemStatusUpdater ref="itemStatusUpdater" :skipInsteadOfCancel="true" />

    <EntityContainer
      ref="container"
      loadingString="Loading Items. Please Wait..."
      :headers="headers"
      :statusCards="statusCards"
      :cardStat="cardStat"
      :apiFunc="getItems"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enablePagination="true"
      :enableRowSelection="true"
      @changeRow="changeSelectedItem"
      @selectionChanged="selectedIds = $event"
      @actionsChanged="actionsChanged">
      <template #entityCell="{ rowData: { _id, warehouses } }">
        <template v-if="warehouses && warehouses.length">
          <i class="fas toggle-button" :class="showingWhInvItems.has(_id) ? 'fa-caret-up' : 'fa-caret-down'"></i>
          <Tooltip text="Show Warehouse Inventory" top="90%" left="10px" />
        </template>
      </template>
      <template #afterEntityRow="{ _id, warehouses }">
        <tr v-show="showingWhInvItems.has(_id)">
          <td colspan="100">
            <div class="wh-inv-container">
              <table class="wh-inv-table">
                <thead>
                  <tr>
                    <th class="wh-cell"></th>
                    <th>WAREHOUSE</th>
                    <th>BALANCE ON HAND</th>
                    <th>ORDERED</th>
                    <th>UNSHIPPABLE</th>
                    <th>AVAILABLE TO ORDER</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="w in warehouses" :key="w.Code">
                    <td class="wh-cell" :style="{ background: getWarehouseColor(w.Code) }"></td>
                    <td>{{ w.Code }}</td>
                    <td>{{ w.BalanceOnHand }}</td>
                    <td>{{ w.Allocated }}</td>
                    <td>{{ w.Unshippable }}</td>
                    <td>{{ w.Available }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </template>
    </EntityContainer>
    
    <EntityDockActions
      :actionsData="actionsData"
      :handler="doAction" />
  </Container>
</template>

<script>
const actionsData = [
  {
    name: 'updateInventory',
    buttonClasses: 'button blue-button',
    label: 'Update Inventory'
  }, {
    name: 'activate',
    buttonClasses: 'button blue-button',
    label: 'Activate Items'
  }, {
    name: 'deactivate',
    buttonClasses: 'button red-button',
    label: 'Deactivate Items'
  }
];
const importItemActionData = {
  name: 'importItem',
  buttonClasses: 'button blue-button',
  label: 'Import Item'
};

import api from "./../../../lib/api-gateway";
import util from "./../../../lib/util";
import { mapGetters, mapActions } from 'vuex';
import Container from './../../Common/Container';
import EntityContainer from './../../Common/EntityContainer';
import EntityDockActions from './../../Common/EntityDockActions';
import Tooltip from './../../Common/Tooltip.vue';
import InventoryHeaderActions from './Header/InventoryHeaderActions';
import ItemStatusUpdater from './ItemStatusUpdater';
import ui from "../../../lib/ui";

export default {
  name: "Inventory",
  components: {
    Container,
    EntityContainer,
    EntityDockActions,
    InventoryHeaderActions,
    ItemStatusUpdater,
    Tooltip
  },
  data() {
    return {
      headers: [
        "",
        "STOCK NUMBER",
        "TYPE",
        "STATUS",
        "BALANCE ON HAND",
        "ORDERED",
        "UNSHIPPABLE",
        "AVAILABLE TO ORDER",
      ],
      currentPageItems: {},
      showingWhInvItems: new Set(),
      selectedIds: [],
      actionsData: [],
      totalItemCount: 0,
      activeCount: 0,
      inactiveCount: 0,
      searchTerm: "",
      statusCards: [
        {
          name: "All",
          value: "AllProducts",
          iconType: "fas fa-boxes",
        },
        {
          name: "Active",
          value: "Active",
          iconType: "fas fa-check",
        },
        {
          name: "Inactive",
          value: "Inactive",
          iconType: "fas fa-minus",
        }
      ],
      getWarehouseColor: ui.getWarehouseColor
    };
  },
  mounted() {
    this.actionsChanged([]);
  },
  inject: ['prompt'],
  computed: {
    ...mapGetters(['isUserInternal']),
    cardStat(){
      return {
        AllProducts: this.totalItemCount,
        Active: this.activeCount,
        Inactive: this.inactiveCount
      }
    }
  },
  methods: {
    ...mapActions('product', ['onChangeSelectedProduct']),
    changeSelectedItem(val){
      this.onChangeSelectedProduct(this.currentPageItems[val]);
      this.$router.push({ name: 'ProductDetail' });
    },
    async refreshResults() {
      return await this.$refs.container.refreshResults();
    },
    async getItems(page, pageSize, currentTab) {
      return await api.getInventoryData(
        page,
        currentTab,
        this.searchTerm,
        pageSize
      );
    },
    handleApiResult(res, products) {
      this.showingWhInvItems = new Set();
      
      this.currentPageItems = products;
      this.totalItemCount = res.result.totalItemCount;
      this.activeCount = res.result.activeCount;
      this.inactiveCount = res.result.inactiveCount;
    },
    prepareTableRow(prod) { 
      return {
        actions: this.getActionsForItem(prod),
        warehouses: prod.Warehouses,
        row: [
          { useSlot: true, classes: ['tooltip-container'], clickHandler: this.toggleShowWhInv },
          { value: prod.StockNumber, subTexts: [{ text: prod.Description, class: 'theme-gray' }] },
          { value: util.getUpperCaseVal(prod.ItemType) },
          { value: prod.IsActive ? 'Active' : 'Inactive' },
          { value: prod.InventorySummary ? prod.InventorySummary.BalanceOnHand : 0 },
          { value: prod.InventorySummary ? prod.InventorySummary.Allocated : 0 },
          { value: prod.InventorySummary ? prod.InventorySummary.Unshippable : 0 },
          { value: prod.InventorySummary ? prod.InventorySummary.Available : 0 }
        ]
      };
    },
    getActionsForItem(item) {
      const actions = this.isUserInternal ? ['updateInventory'] : [];
      actions.push(item.IsActive ? 'deactivate' : 'activate');
      return actions;
    },
    toggleShowWhInv(cellData, rowData) {
      if(rowData.warehouses && rowData.warehouses.length) {
        const itemId = rowData._id;

        if(this.showingWhInvItems.has(itemId))
          this.showingWhInvItems.delete(itemId);
        else
          this.showingWhInvItems.add(itemId);

        return true;
      }
    },
    async onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      await this.refreshResults();
    },
    async onSearchReset() {
      this.searchTerm = '';
      await this.refreshResults();
    },
    doAction(action) {
      const stockNumbers = this.selectedIds.map(id => id.split('_')[1]);

      switch(action) {
        case 'updateInventory':
          this.updateItemsInventory(stockNumbers);
          break;
        case 'importItem':
          this.importItem(stockNumbers.length == 1 ? stockNumbers[0] : null);
          break;
        default:
          this.changeItemStatuses(action == 'activate', stockNumbers);
      }
    },
    async updateItemsInventory(stockNumbers) {
      this.$refs.container.startApiCall('Updating inventory for items...');
      const res = await api.updateItemsInventory(stockNumbers);
      this.$refs.container.showApiResult(res, res);
    },
    async importItem(selectedStockNumber) {
      const stockNumber = await this.prompt(
          'Enter the stock number of the item you would like to import:',
          selectedStockNumber
        );
      
      if(stockNumber) {
        this.$refs.container.startApiCall(`Importing item ${stockNumber}...`);
        const res = await api.importItem(stockNumber);
        this.$refs.container.showApiResult(res, `Item ${stockNumber} imported successfully.`);
      }
    },
    async changeItemStatuses(active, stockNumbers) {
      const errors = [];

      for(const stockNumber of stockNumbers) {
        const action = active ? 'Activating' : 'Deactivating';

        this.$refs.container.startApiCall(`${action} item ${stockNumber}...`)
        const res = await this.$refs.itemStatusUpdater.updateItemStatus(stockNumber, active);
        
        if(res.error) {
          if(res.data && res.data.kitOption < 0)
            continue;
          errors.push(`Error ${action.toLowerCase()} item ${stockNumber}: ${res.message}`);
        }
      }

      this.$refs.container.showBulkApiResult(
          errors.length ? { error: true, message: errors.join('\n\n') } : {},
          `Item(s) ${active ? 'activated' : 'deactivated'} successfully.`
        );
    },
    actionsChanged(actions) {
      this.actionsData = this.isUserInternal ? [importItemActionData] : [];
      this.actionsData.push(...actionsData.filter(x => actions.indexOf(x.name) >= 0));
    }
  }
};
</script>

<style lang="less" scoped>
.toggle-button {
  font-size: 1.25em;
  color: #666;
}
.wh-inv-container {
  max-width: 1200px;
  margin: auto;
  padding: 5px;
}
.wh-inv-table {
  width: 100%;
  // Undo some of Bulma
  border-collapse: initial;
  border-spacing: 0;

  thead > tr:first-child {
    background: var(--theme-gray-bg);

    th:first-child {
      border-left: 1px solid #e0e0e0;
      border-top-left-radius: 2px;
    }
    th:last-child {
      border-right: 1px solid #e0e0e0;
      border-top-right-radius: 2px;
    }
    th {
      border-top: 1px solid #e0e0e0;
    }
  }
  th, td {
    border-bottom: 1px solid #e0e0e0;
    border-bottom-width: 1px !important;
  }
  th:last-child, td:last-child {
    border-right: 1px solid #e0e0e0;
  }
}
.wh-cell {
  width: 17px;
  padding: 0;
}
</style>
