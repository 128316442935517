import { newOrder, newDeductOrder, newWorkOrder, newRecipient, newReturn } from './index'

export const mutations  = {
  changeSelectedOrder: (state, ord) => state.selectedOrder = {...ord},
  setLoadedFile: (state, fileData) => state.loadedFiles[fileData.file] = fileData.data,
  changeNewOrderData: (state, d) => state.newOrder = {...d},
  changeNewDeductOrderData: (state, {prop, val}) => state.newDeductOrder[prop] = val,
  changeNewWorkOrderData: (state, {prop, val}) => state.newWorkOrder[prop] = val,
  setIsOrderValid: (state, v) => state.isOrderValid = v,
  changeNewReturnData: (state, r) => state.newReturn = {...r},
  setRecipient: (state, r) => state.recipient = {...r},
  setOrder: (state, o) => state.newOrder = {...o},
  setDeductOrder: (state, o) => state.newDeductOrder = {...o},
  setWorkOrder: (state, o) => state.newWorkOrder = { ...o },
  setDeliveryOptionsAndReturnServiceLevels: (state, d) => {
    state.deliveryOptions = [...d.supportedServiceLevels];
    state.customServiceLevels = [...d.customServiceLevels];
    state.returnServiceLevels = [...d.supportedReturnServiceLevels]
  },
  toggleShowReturnModal: (state) => state.showReturnModal = !state.showReturnModal,
  toggleShowReturnsDetailModal: (state) => state.showReturnsDetailModal = !state.showReturnsDetailModal,
  resetLoadedFiles: (state) => state.loadedFiles = {},
  resetRecipient: (state) => state.recipient = newRecipient(),
  resetNewOrder: (state) => state.newOrder = newOrder(),
  resetNewDeductOrder: (state) => state.newDeductOrder = newDeductOrder(),
  resetNewWorkOrder: (state) => state.newWorkOrder = newWorkOrder(),
  resetNewReturn: (state) => state.newReturn = newReturn()
}