<template>
  <div>
    <p v-if="lotNumbers">
      Lot No:
      <SelectList v-if="lotNumbers"
        :options="lotNumbers"
        instructionLabel="-- Lot Number --"
        :selectedValue="item.LotNo"
        :allowUnselect="true"
        :showClearBtn="true"
        @change="selectLotNumber(item.StockNumber, $event.target.value)" />
    </p>
    <p v-else-if="item.LotNo">
      Lot No: {{ item.LotNo }}
      (<a @click="loadTags(item.StockNumber)">edit</a>)
    </p>
    <a v-else @click="loadTags(item.StockNumber)">Select Lot Number</a>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useStore } from 'vuex';
import SelectList from '../Common/SelectList.vue';
import api from '../../lib/api-gateway';
import { makeApiCallGetResponse } from '../../composables/api';

defineProps(['item']);
const alert = inject('alert');

const store = useStore();
const lotNumbers = ref(null);

async function loadTags(stockNumber) {
  const res = await makeApiCallGetResponse(
    `Getting lot numbers for item ${stockNumber}`,
    async () => await api.getItemLotNumbers(stockNumber));
  
  if(res) {
    if (res.result.lotNumbers.length)
      lotNumbers.value = res.result.lotNumbers;
    else
      alert(`No lot number data found for item ${stockNumber}.`);
  }
}

function selectLotNumber(stockNumber, lotNumber) {
  store.dispatch('onUpdateSelectedItem', { stockNumber, item: { LotNo: lotNumber }});
}
</script>
