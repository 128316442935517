<template>
  <ComponentHeaderButton
    :useButtonStyle="true"
    :btnText="apiUserToken ? 'Show API Token' : 'Generate API Token'"
    iconName="fas fa-key"
    @handler="generateOrShowApiToken" />
  <Modal v-if="showTokenModal"
    :hideCloseBtn="true"
    :defaultOkCancelBtnData="['Copy to Clipboard', 'Close']"
    @close="modalBtnPressed($event)">
    <p style="margin-bottom: 10px">API Token:</p>
    <textarea class="token-textarea" :value="apiUserToken" readonly></textarea>
    <p class="copied-to-clipboard-div">
      {{ copiedToClipboard ? 'Copied to clipboard' : '&nbsp;' }}
    </p>
  </Modal>
</template>

<script>
import api from '../../lib/api-gateway';
import { mapGetters, mapActions } from 'vuex';
import ComponentHeaderButton from './../Common/ComponentHeaderButton.vue';
import Modal from './../Common/Modal.vue';
export default {
  name: 'GenerateApiUserToken',
  components: { ComponentHeaderButton, Modal },
  data() {
    return {
      apiUserToken: '',
      showTokenModal: false,
      copiedToClipboard: false
    }
  },
  computed: {
    ...mapGetters('manage', ['selectedClient'])
  },
  methods: {
    ...mapActions(['onMakingAPICalls', 'onSetResponseContent']),
    async generateOrShowApiToken() {
      if(!this.apiUserToken) {
        this.onMakingAPICalls('Generating token. Please wait...');
        
        const res = await api.generateApiToken(this.selectedClient._id);

        if(res.error) {
          this.onSetResponseContent({ responseStatus: false, responseText: res.message });
          return;
        }

        this.onMakingAPICalls();
        this.apiUserToken = res.accessToken;
      }

      this.showTokenModal = true;
    },
    modalBtnPressed(value) {
      if(value)
        navigator.clipboard.writeText(this.apiUserToken);
      else
        this.showTokenModal = false;
      
      this.copiedToClipboard = !!value;
    }
  }
}
</script>

<style lang="less" scoped>
.token-textarea {
  display: block; // Remove space after textarea
  width: 400px;
  height: 80px;
  padding: 5px;
}
.copied-to-clipboard-div {
  height: 13px;
  color: red;
  text-align: center;
}
</style>