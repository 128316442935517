<template>
  <SelectList
    instructionLabel="-- Choose a Carrier --"
    :options="carriers"
    labelField="name"
    valueField="_id" />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SelectList from './SelectList.vue';

const store = useStore();
const carriers = computed(() => store.getters['carriers']);
</script>
