<template>
  <ManageUsers>
    <template #actions="{ onSearch, onSearchReset, filterChanged }">
      <UserHeaderActions
        @onSearch="onSearch"
        @onSearchReset="onSearchReset"
        @filterChanged="filterChanged" />
    </template>
  </ManageUsers>
</template>

<script>
import ManageUsers from './../Manage/ManageUsers.vue';
import UserHeaderActions from './Header/UserHeaderActions.vue';
export default {
  name: 'UsersSettings',
  components: { ManageUsers,  UserHeaderActions }
}
</script>

<style lang="less" scoped>
</style>