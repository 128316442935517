<template>
  <li class="tooltip-container" :style="sidebarItem.style">
    <div
      v-if="sidebarItem.subItems"
      @click="toggleSubItems()"
      class="item-with-sub-items"
    >
      <i :class="sidebarItem.iconType"></i>
      <i class="fa expand-icon" :class="[isExpanded ? 'fa-angle-up' : 'fa-angle-down']"></i>
    </div>
    <router-link
      v-else-if="sidebarItem.linkName"
      :to="{ name: sidebarItem.linkName }"
      custom
      v-slot="{ href, navigate, isExactActive }"
    >
      <a :href="href" @click="navigate" class="centered-contents full-size">
        <p v-bind="getAdditionalButtonAttributes()">
          <i :class="sidebarItem.iconType" v-bind="getAdditionalIconAttributes(isExactActive)"></i>
        </p>
      </a>
    </router-link>
    <a v-else @click="$emit('itemClicked', sidebarItem.name)" class="centered-contents full-size">
      <p v-bind="getAdditionalButtonAttributes()">
        <i :class="sidebarItem.iconType"></i>
      </p>
    </a>
    <Tooltip :text="sidebarItem.name" />
  </li>
</template>

<script>
import Tooltip from "./Tooltip";
export default {
  name: "SidebarItem",
  components: { Tooltip },
  props: ['sidebarItem'],
  emits: ['itemClicked'],
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleSubItems() {
      this.isExpanded = !this.isExpanded;
    },
    getAdditionalButtonAttributes() {
      return this.sidebarItem.bgColor
        ? { class: 'item-with-bg centered-contents', style: `background: ${this.sidebarItem.bgColor}`}
        : null;
    },
    getAdditionalIconAttributes(isExactActive) {
      return isExactActive || this.$route.path.indexOf(`/${this.sidebarItem.urlSection}`) == 0
        ? { class: 'theme-orange '}
        : { style: 'color: #bbb' }
    }
  },
};
</script>

<style lang="less" scoped>
.tooltip-container {
  font-size: 28px;
  cursor: pointer;
}
.tooltip-container:hover {
  background: whitesmoke !important;
  cursor: pointer;
}
.item-with-sub-items {
  height: 70%;
  padding-top: 10px;
}
.item-with-bg {
  font-size: 24px;
  width: 48px;
  height: 48px;
  margin: 0 11px;
  border-radius: 8px;

  i {
    color: white;
  }
}
.full-size {
  width: 100%;
  height: 100%;
}
.centered-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}
.expand-icon {
  display: block;
  font-size: 24px;
  font-weight: bold;
  color: #666861;
}
@media screen and (max-width: 1024px){
  .tooltip-container {
     font-size: 24px;
  }
  .item-with-bg {
    font-size: 22px;
    width: 44px;
    height: 44px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1460px){
  .tooltip-container {
    font-size: 26px;
  }
  .item-with-bg {
    font-size: 23px;
    width: 46px;
    height: 46px;
  }
}
</style>