<template>
  <Container title="Archived Orders">
    <template #actions>
      <ArchivedOrderHeaderActions />
    </template>

    <div style="margin: 10px 10px 0 10px">
      <SelectList
        instructionLabel="Select Year"
        :options="yearsOptions"
        @change="setYearOrMonth('year', $event.target.value)" />
      <span class="arrow">&xrarr;</span>
      <SelectList
        instructionLabel="Select Month"
        :options="months"
        :disabled="!year"
        :useIndexAsValue="true"
        @change="setYearOrMonth('month', $event.target.value)" />
    </div>

    <OrdersContainer v-if="year && month"
      ref="ordersContainer"
      :listingOnly="true"
      :getOrdersFunc="getOrders"
      :dateFilterMin="dateFilterMin"
      :dateFilterMax="dateFilterMax" />
  </Container>
</template>

<script>
import { mapGetters } from 'vuex';
import api from './../../lib/api-gateway';
import util from './../../lib/util';
import Container from '../Common/Container.vue';
import SelectList from '../Common/SelectList.vue';
import OrdersContainer from './OrdersContainer.vue';
import ArchivedOrderHeaderActions from './Header/ArchivedOrderHeaderActions.vue';

export default {
  name: 'ArchivedOrdersContainer',
  components: { Container, SelectList, OrdersContainer, ArchivedOrderHeaderActions },
  data() {
    return {
      months: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
      year: 0,
      month: 0
    }
  },
  computed: {
    ...mapGetters(['currentClient']),
    yearsOptions() {
      var clientCreatedYear = new Date(this.currentClient.createdAt).getFullYear();
      return util.range(new Date().getFullYear(), clientCreatedYear, -1);
    },
    dateFilterMin() {
      return this.year && this.month
        ? util.formatAndGetDateOnlyISO(new Date(this.year, this.month, 1))
        : null;
    },
    dateFilterMax() {
      return this.year && this.month
        ? util.formatAndGetDateOnlyISO(new Date(this.year, this.month + 1, 0))
        : null;
    },
  },
  methods: {
    setYearOrMonth(field, value) {
      this[field] = parseInt(value);
      // First time, this will be false and OrdersContainer will load by itself
      if(this.year && this.month && this.$refs.ordersContainer)
        this.$refs.ordersContainer.refreshResults();
    },
    getOrders(...args) {
      return api.getArchivedOrderData(this.year, this.months[this.month], ...args);
    }
  }
};
</script>

<style lang="less" scoped>
.arrow {
  font-size: 1.5em;
  margin: 0 8px;
}
</style>