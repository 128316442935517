<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        v-if="isUserAdmin && isEditedUserManager"
        btnText="Assigned Clients"
        iconName="fas fa-bookmark"
        :useButtonStyle="true"
        :isOutlinedButton="true"
        @handler="$emit('editAssignedClients')" />
      <EntityActions
        v-if="showUpdateUser"
        :showUpdateUser="true"
        :isUserValid="isUserValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script setup>
import { useStore } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../Common/EntityActions.vue';

defineEmits(['editAssignedClients']);
defineProps(['isUserValid', 'showUpdateUser']);

const store = useStore();
const isUserAdmin = store.getters['isUserAdmin'];
const isEditedUserManager = store.getters['manage/selectedUser'].Role == 'Manager';
</script>
