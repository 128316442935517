<template>
  <div style="padding: 10px">
    <div class="field">
      <label class="label">Role</label>
      <div class="control">
        <RoleList :isSearch="true" :allowUnselect="true" :showClearBtn="true" />
      </div>
    </div>
  </div>
</template>

<script>
import RoleList from '../RoleList.vue';
export default {
  name: 'RoleFilter',
  components: { RoleList }
}
</script>
