<template>
  <Container>
    <CardsNavigation :cards="[
        { label: 'Clients', iconClass: 'fas fa-user-lock', component: 'ManageClients' },
        { label: 'Users', iconClass: 'fas fa-user', component: 'ManageUsers' },
        { label: 'Service Levels', iconClass: 'fas fa-shipping-fast', component: 'ManageServiceLevels' },
        { label: 'Item Groups', iconClass: 'fas fa-tasks', component: 'ManageItemGroups' }
      ]" />
  </Container>
</template>

<script>
import Container from '../Common/Container.vue';
import CardsNavigation from '../Common/CardsNavigation.vue';
export default {
  name: 'Manage',
  components: { Container, CardsNavigation }
}
</script>
