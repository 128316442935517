<template>
  <div class="card-container">
    <div v-for="card in cards"
      :key="card.component"
      class="card"
      :style="{ '--card-shadow-color': getCardHighlightColor(card) }"
      @click="$router.push({ name: card.component })">
      <div class="theme-blue" style="text-align: center">{{card.label}}</div>
      <i :class="card.iconClass" />
      <p v-if="card.highlight" class="theme-red" style="font-size: .9em">
        ({{ card.highlight }})
      </p>
    </div>
  </div>
</template>

<script setup>
defineProps(['cards']);

function getCardHighlightColor(card) {
  return card.highlight ? 'var(--theme-red)' : 'var(--theme-gray)';
}
</script>

<style lang="less" scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  padding: 5em 3em 3em;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0 auto;
  gap: 40px;
}
.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
  width: 270px;
  padding: 50px;
  font-size: 20px;
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 2.5px 5px 10px var(--card-shadow-color);
  cursor: pointer;
}
</style>
