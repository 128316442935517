<template>
  <div v-show="showListData" class="entity-container">
    <slot name="filters" />

    <div v-if="statusCards">
      <FilterTab
        :tabs="statusCards"
        :data="cardStat"
        :initialTab="currentTab"
        @onTabChange="changeNowShowing">
      </FilterTab>
    </div>
    
    <EntityList
      ref="entityList"
      :headers="headers"
      :tableDataArray="tableDataArray"
      :enableRowSelection="enableRowSelection"
      @changeRow="entitySelected"
      @selectionChanged="$emit('selectionChanged', $event)"
      @actionsChanged="$emit('actionsChanged', $event)">
      <template #cellSlot="props">
        <slot name="entityCell" v-bind="props"></slot>
      </template>
      <template #afterRowSlot="props">
        <slot name="afterEntityRow" v-bind="props"></slot>
      </template>
    </EntityList>
      
    <DeferredTeleport v-if="enablePagination" to="#dockCenterDiv">
      <PageNavigation
        :currentPage="currentPage"
        :totalPages="totalPages"
        @onNextPage="gotoNextPage"
        @onPreviousPage="gotoPreviousPage"
        @onGoToPage="gotoPage" />
    </DeferredTeleport>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EntityList from './../Common/EntityList.vue';
import DeferredTeleport from './../Common/DeferredTeleport';
import PageNavigation from './../Common/PageNavigation.vue';
import FilterTab from './../Common/FilterTab.vue';
export default {
  name: 'EntityContainer',
  props: {
    loadingString: String,
    headers: Array,
    statusCards: Array,
    cardStat: Object,
    apiFunc: Function,
    handleApiResultFunc: Function,
    tableRowPrepareFunc: Function,
    pageSize: { default: 50 },
    enablePagination: Boolean,
    enableRowSelection: Boolean
  },
  emits: ['selectionChanged', 'actionsChanged', 'changeRow'],
  components: {
    EntityList,
    DeferredTeleport,
    PageNavigation,
    FilterTab
  },
  data() {
    return {
      currentPage: 1,
      totalEntityLookupCount: 0,
      tableDataArray: [],
      currentTab: '',
      loadingData: true,
      scrollPosition: 0,
      active: true,
      refreshTimer: null
    };
  },
  computed: {
    ...mapGetters(['pageError']),
    showListData() {
      return !this.loadingData && !this.pageError.haveError;
    },
    totalPages() {
      if (this.totalEntityLookupCount % this.pageSize === 0)
        return Math.floor(this.totalEntityLookupCount / this.pageSize);
      return Math.floor(this.totalEntityLookupCount / this.pageSize) + 1;
    }
  },
  async mounted() {
    await this.makeApiCall();
  },
  created() {
    this.onSetToOriginalState();

  if(this.statusCards)
    this.currentTab = this.$route.params.selectedTab || this.statusCards[0].value;
  },
  activated() {
    if(this.scrollPosition) {
      window.scrollTo(0, this.scrollPosition);
      this.scrollPosition = 0;
    }
    this.active = true;
    if(!this.refreshTimer)
      this.makeApiCall();
  },
  deactivated() {
    this.active = false;
  },
  unmounted() {
    this.active = false;
    this.checkStatusAndHandleRefreshTimer();
  },
  methods: {
    ...mapActions(['onMakingAPICalls', 'onSetResponseContent', 'onSetToOriginalState', 'onSetPageError']),
    async changeNowShowing(val) {
      this.currentPage = 1;
      this.currentTab = val;
      await this.makeApiCall();
    },
    entitySelected(event) {
      this.scrollPosition = window.scrollY;
      this.$emit('changeRow', event);
    },
    async makeApiCall() {
      // Reset the timeout on every API call, like searching/pagination.
      // Let it keep running if the component is disabled only for a short time
      // (i.e. navigate away and then back).
      if(!this.checkStatusAndHandleRefreshTimer())
        return;

      this.loadingData = true;
      this.onMakingAPICalls(this.loadingString);

      const res = this.enablePagination
        ? await this.apiFunc(this.currentPage, this.pageSize, this.currentTab)
        : await this.apiFunc(this.currentTab);

      this.loadingData = false;
      this.onMakingAPICalls('');

      if(!res.error) {
        if(this.handleApiResultFunc) {
          const entitiesObj = res.result.entities.reduce((o, x) => { o[x._id] = x; return o; }, {});
          this.handleApiResultFunc(res, entitiesObj);
        }

        this.tableDataArray = res.result.entities.map(x => ({ _id: x._id, ...this.tableRowPrepareFunc(x) }));
        this.totalEntityLookupCount = res.result.totalEntityLookupCount;
        
        if(this.$refs.entityList)
          this.$refs.entityList.selectAll(false);
      } else
        this.onSetPageError();
    },
    checkStatusAndHandleRefreshTimer() {
      clearTimeout(this.refreshTimer);

      if(this.pageError.haveError)
        this.active = false;

      this.refreshTimer = this.active
        ? setTimeout(this.makeApiCall.bind(this), 600000)
        : null;
      
      return this.active;
    },
    async gotoNextPage() {
      this.currentPage++;
      await this.makeApiCall();
    },
    async gotoPreviousPage() {
      this.currentPage--;
      await this.makeApiCall();
    },
    async gotoPage(page) {
      this.currentPage = page;
      await this.makeApiCall();
    },
    async refreshResults() {
      this.currentPage = 1;
      await this.makeApiCall();
    },
    startApiCall(message) {
      this.onMakingAPICalls(message);
    },
    async showApiResult(res, successMsg) {
      await this.onSetResponseContent({
          responseStatus: !res.error,
          responseText: res.error ? res.message : successMsg
        });
    },
    async showBulkApiResult(res, successMsg) {
      await this.showApiResult(res, successMsg);

      // If there are any failures in a bulk operation, we don't know
      // what succeeded or what failed, so refresh. (ResponseUI reloads
      // the page on success.)
      if(res.error)
        await this.makeApiCall();
    }
  },
};
</script>

<style lang="less" scoped>
.entity-container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  padding: 1.5em;
}
</style>
