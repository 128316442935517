<template>
  <Container title="Add New Item">
    <template #actions>
      <InventoryCreateActions :isProductValid="isProductValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <ProductHeaderInfo 
        :isEditable="true"
        :isNew="true"
        @isProductValid="isProductValid = $event" />
      </template>
      <template #EntityBasic>
        <ProductBasic :isCreateProduct="true" />
      </template>
      
      <template #EntityItems>
        <ProductDimensionAndComponents :isCreateProduct="true" />
      </template>
    
    </EntityData>
  </Container>
</template>

<script>
import {mapActions} from 'vuex';
import Container from './../../Common/Container.vue';
import EntityData from './../../Common/EntityData.vue';
import InventoryCreateActions from './Header/InventoryCreateActions.vue';
import ProductHeaderInfo from './ProductHeaderInfo.vue';
import ProductDimensionAndComponents from './ProductDimensionAndComponents';
import ProductBasic from './ProductBasic';
export default {
    name: 'ProductCreate',
    components: {
      Container,
      EntityData,
      InventoryCreateActions,
      ProductHeaderInfo,
      ProductBasic,
      ProductDimensionAndComponents },
    data() {
      return {
        isProductValid: false
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    unmounted() {
      this.onResetNewProduct();
      this.onEmptySelectedItems();
    },
    methods:{
      ...mapActions(['onEmptySelectedItems']),
      ...mapActions('product', ['onResetNewProduct'])
    },
  }
</script>
