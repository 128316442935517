<template>
  <Container title="Webhooks" :haveError="haveError" :errorId="errorId">
    <template #actions>
      <WebhookHeaderActions />
    </template>
    
    <EntityContainer
      ref="container"
      loadingString="Loading Webhooks. Please Wait..."
      :headers="headers"
      :apiFunc="getWebhooks"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enablePagination="true"
      :enableRowSelection="true"
      :enableCheckboxSelection="true"
      @changeRow="webhookSelected"
      @selectionChanged="selectedIds = $event"
      @actionsChanged="actionsChanged" />
    
    <EntityDockActions
      :actionsData="actionsData"
      :handler="doWebhooksAction" />
  </Container>
</template>

<script>
const deleteActionData = {
  name: 'delete',
  buttonClasses: 'button red-button',
  label: 'Delete Webhooks'
};

import api from '../../lib/api-gateway';
import { mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityDockActions from './../Common/EntityDockActions.vue';
import EntityContainer from './../Common/EntityContainer.vue';
import WebhookHeaderActions from './Header/WebhookHeaderActions.vue';
export default {
  name: 'Webhooks',
  components: { Container, EntityDockActions, EntityContainer, WebhookHeaderActions },
  data() {
    return {
      currentPageWebhooks: {},
      headers: [
        'TYPE',
        'METHOD',
        'URL',
        'IS ACTIVE'
      ],
      selectedIds: [],
      actionsData: [],
      haveError: false,
      errorId: null
    }
  },
  activated() {
    this.resetState();
  },
  mounted() {
    this.resetState();
  },
  methods: {
    ...mapActions(['onSetCurrentClient']),
    ...mapActions('manage', ['onSetSelectedWebhook', 'onResetNewWebhook']),
    resetState() {
      this.onResetNewWebhook();
    },
    async refreshResults() {
      return await this.$refs.container.refreshResults();
    },
    async getWebhooks() {
      // Refresh client info
      const res = await api.getCurrentClient();

      if(!res.error) {
        this.onSetCurrentClient(res.result.client);
        const entities = res.result.client.Webhooks || [];
        return { result: { entities, totalEntityLookupCount: entities.length } };
      } else
        return res;
    },
    handleApiResult(res, webhooks) {
      if(!res.error) {
        this.currentPageWebhooks = webhooks;
      } else {
        this.haveError = true;
        this.errorId = res.errorId;
      }
    },
    prepareTableRow(webhook) {
      return {
        actions: ['delete'],
        row: [
          { value: webhook.WebhookEventType },
          { value: webhook.HttpMethod },
          { value: webhook.HttpUrl },
          { value: webhook.IsActive ? 'Yes' : 'No' }
        ]
      }
    },
    webhookSelected(id) {
      this.onSetSelectedWebhook(this.currentPageWebhooks[id]);
      this.$router.push({ name: 'WebhookDetail' });
    },
    async doWebhooksAction() {
      this.$refs.container.startApiCall('Deleting webhook(s)...');
      const res = await api.deleteWebhooks(this.selectedIds);
      this.$refs.container.showBulkApiResult(res, 'Webhook(s) deleted successfully.');
    },
    actionsChanged(actions) {
      this.actionsData = actions.length ? [deleteActionData] : [];
    }
  }
}
</script>
