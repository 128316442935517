<template>
  <ClientsSelection title="Assigned Clients" :selectedClients="selectedUser.AssignedClients" @close="doAction" />
</template>

<script setup>
import { inject } from 'vue';
import { useStore } from 'vuex';
import api from '../../lib/api-gateway';
import ClientsSelection from './ClientsSelection.vue';
import { makeApiCall } from '../../composables/api';

const emit = defineEmits(['close']);
const alert = inject('alert');

const selectedUser = useStore().getters['manage/selectedUser'];

function doAction(clients) {
  if(clients) {
    if(!clients.length) {
      alert('At least one client must be assigned.');
      return;
    }
    
    makeApiCall(
      'Updating assigned clients. Please wait...',
      'Assigned clients updated!',
      () => api.updateUserAssignedClients({ UserId: selectedUser._id, ClientIds: clients }));
  } else
    emit('close');
}
</script>
