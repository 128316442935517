<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Shipments' })" />
      <EntityActions :showNoticeCreate="true" :isShipNoticeValid="isShipNoticeValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../../Common/EntityActions.vue';
export default {
  name: 'ShipmentCreateActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, EntityActions },
  props: [ 'isShipNoticeValid' ]
}
</script>

<style lang="less" scoped>
</style>
