<template>
  <Container v-if="!listingOnly" title="Orders">
    <template #actions>
      <OrderHeaderActions />
    </template>
    <EntityDockActions :actionsData="actionsData" :handler="doOrdersAction" />
  </Container>
  <Teleport to="#mainContent" :disabled="listingOnly">
    <EntityContainer
      ref="container"
      loadingString="Loading Orders. Please Wait..."
      :headers="headers"
      :apiFunc="getOrders"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enablePagination="true"
      :enableRowSelection="true"
      @changeRow="orderSelected"
      @selectionChanged="selectedIds = $event"
      @actionsChanged="actionsChanged">
      <template #filters>
        <OrderFilters
          :categoryStats="cardStat"
          :initialStatus="$route.params.selectedStatus"
          :minDate="dateFilterMin"
          :maxDate="dateFilterMax"
          @filterChanged="filterChanged" />
      </template>
      <template #entityCell="{ cellData: { order } }">
        <OrderListingActions v-if="orderIsReleased(order)" :order="order" />
      </template>
    </EntityContainer>
  </Teleport>
</template>

<script>
const actionsData = [
  {
    name: 'confirm',
    func: api.confirmOrders,
    buttonClasses: 'button blue-button',
    label: 'Confirm Orders',
    inProcessMsg: 'Confirming order(s)...',
    successMsg: 'Order(s) confirmed successfully.'
  }, {
    name: 'approve',
    func: api.approveOrders,
    buttonClasses: 'button blue-button',
    label: 'Approve Orders',
    inProcessMsg: 'Approving order(s)...',
    successMsg: 'Order(s) approved successfully.'
  }, {
    name: 'release',
    func: api.releaseOrders,
    buttonClasses: 'button blue-button',
    label: 'Release Orders',
    inProcessMsg: 'Releasing order(s)...',
    successMsg: 'Order(s) released successfully.'
  }, {
    name: 'cancel',
    func: api.cancelOrders,
    buttonClasses: 'button red-button',
    label: 'Cancel Orders',
    inProcessMsg: 'Cancelling order(s)...',
    successMsg: 'Order(s) cancelled successfully.'
  }
];
const cancelAllActionData = {
  name: 'cancelAll',
  func: api.cancelOrders,
  buttonClasses: 'button red-button',
  label: 'Cancel All Orders',
  inProcessMsg: 'Cancelling order(s)...',
  successMsg: 'Order(s) cancelled successfully.'
};

import api from './../../lib/api-gateway';
import util from './../../lib/util';
import ui from './../../lib/ui';
import { mapGetters, mapActions } from 'vuex';
import Container from './../Common/Container';
import EntityContainer from './../Common/EntityContainer';
import EntityDockActions from './../Common/EntityDockActions';
import OrderListingActions from './Header/OrderListingActions.vue';
import OrderHeaderActions from './Header/OrderHeaderActions.vue';
import OrderFilters from './OrderFilters';

export default {
  name: 'OrdersContainer',
  components: { Container, EntityContainer, EntityDockActions, OrderListingActions, OrderHeaderActions, OrderFilters },
  props: ['listingOnly', 'getOrdersFunc', 'dateFilterMin', 'dateFilterMax'],
  data() {
    return {
      headers: [
        'ORDER NAME',
        'ORDER NO.',
        'CREATED DATE',
        'RECIPIENT',
        'STATUS',
        'WH. STATUS'
      ],
      currentPageOrders: {},
      selectedIds: [],
      actionsData: [],
      totalOrderCount: 0,
      submittedCount: 0,
      confirmedCount: 0,
      releasedCount: 0,
      onHoldCount: 0,
      shippedCount: 0,
      partialOrdersCount: 0,
      backordersCount: 0,
      cancelledCount: 0,
      deductCount: 0,
      searchParams: {}
    };
  },
  inject: ['confirm'],
  computed: {
    ...mapGetters(['isUserInternal']),
    cardStat() {
      return {
        all: this.totalOrderCount,
        submitted: this.submittedCount,
        confirmed: this.confirmedCount,
        released: this.releasedCount,
        onHold: this.onHoldCount,
        cancelled: this.cancelledCount,
        partialOrders: this.partialOrdersCount,
        backorders: this.backordersCount,
        shipped: this.shippedCount,
        deduct: this.deductCount
      };
    },
    areAnyOrdersReleased() {
      return Object.values(this.currentPageOrders).some(x => this.orderIsReleased(x));
    }
  },
  created() {
    this.searchParams.status = this.$route.params.selectedStatus;
  },
  async mounted() {
    const res = await api.getClientDeliveryOptionsAndReturnServiceLevels();
    
    if(!res.error)
      this.onSetDeliveryOptionsAndReturnServiceLevels(res.result.clientShipMethods)
    else
      this.onSetPageError(res.errorId);
  },
  methods: {
    ...mapActions(['onSetPageError']),
    ...mapActions('order', [
      'onChangeSelectedOrder',
      'onSetDeliveryOptionsAndReturnServiceLevels'
    ]),
    async getOrders(page, pageSize) {
      const apiFunc = this.getOrdersFunc || api.getOrderData;
      return await apiFunc(page, pageSize, this.searchParams);
    },
    async refreshResults() {
      return await this.$refs.container.refreshResults();
    },
    handleApiResult(res, orders) {
      if(!res.error) {
        this.currentPageOrders = orders;
        this.totalOrderCount = res.result.totalOrderCount;
        this.submittedCount = res.result.submittedCount;
        this.confirmedCount = res.result.confirmedCount;
        this.releasedCount = res.result.releasedCount;
        this.onHoldCount = res.result.onHoldCount;
        this.cancelledCount = res.result.cancelledCount;
        this.partialOrdersCount = res.result.partialOrdersCount;
        this.backordersCount = res.result.backordersCount;
        this.shippedCount = res.result.shippedCount;
        this.deductCount = res.result.deductCount;
      } else {
        this.onSetPageError(res.errorId);
      }
    },
    orderSelected(id) {
      const order = this.currentPageOrders[id];
      this.onChangeSelectedOrder(order);
      const pathPrefix = this.$route.path.indexOf('/orders/archive') == 0 ? 'Archived'
        : order.OrderType == 'Deduct' ? 'Deduct'
        : '';
      this.$router.push({ name: `${pathPrefix}${(this.orderIsEditable(order) ? 'OrderEdit' : 'OrderDetail')}` });
    },
    orderIsEditable(order) {
      return ['Submitted', 'Approved', 'OnHold', 'OnHoldDueToInsufficientStock'].indexOf(order.Status) >= 0;
    },
    orderIsReleased(order) {
      return ['Released', 'Closed'].indexOf(order.Status) >= 0;
    },
    prepareTableRow(order) {
      return {
        actions: this.getActionsForOrder(order),
        row: [
          {
            value: order.Name,
            style: { 'font-weight': 'bold' },
            subTexts: this.getSubOrderLabels(order)
          },
          { value: order.OrderNumber },
          { value: util.formatAndGetDate(order.CreatedDate) },
          { value: this.getOrderRecipient(order) },
          {
            value: util.addSpacesBeforeCapitals(order.Status),
            bulletColor: ui.getOrderStatusColor(order.Status),
            note: order.Status.slice(0, 6) == 'OnHold' ? order.OnHoldReason : null,
            subTexts: order.Status == 'Submitted' && order.WaitingForClientApproval
              ? [{ text: 'Order is awaiting approval', tagClass: 'is-warning is-light' }]
              : null
          },
          {
            value: order.WarehouseStatus,
            bulletColor: ui.getWarehouseStatusColor(order.WarehouseStatus)
          },
          ...this.getExtraOrderRowValues(order),
          { warehouseCode: order.Warehouse }
        ]
      };
    },
    getExtraOrderRowValues(order) {
      return this.isUserInternal && this.areAnyOrdersReleased
        ? [order.Status == 'Released' ? { order, useSlot: true } : {}]
        : [];
    },
    getSubOrderLabels(order) {
      if(order.OrderType == 'Deduct')
        return [{ text: 'Deduct Order', tagColor: ui.deductOrderColor }];

      const data = [
          { label: 'Partial Orders', subOrders: order.PartialOrders },
          { label: 'Backorders', subOrders: order.BackOrders }
        ].filter(x => x.subOrders && x.subOrders.length);

      return data.map(({ label, subOrders }) => {
          const allFulfilled = subOrders.every(x => x.Status == 'Closed');
          const allCancelled = subOrders.every(x => x.Status == 'Cancelled');
          return {
              text: `${label} (${subOrders.length})`,
              tagColor: ui.getSubOrdersStatusColor(allFulfilled, allCancelled)
            };
        });
    },
    getOrderRecipient(order) {
      return order.OrderType != 'Deduct'
        ? [order.Recipient.FirstName, order.Recipient.LastName].filter(Boolean).join(' ') || order.Recipient.Company
        : '--';
    },
    getActionsForOrder(order) {
      const status = order.Status;
      const actions = [];

      if(this.isUserInternal) {
        if(status == 'Submitted') actions.push('approve');
        if(status == 'Approved') actions.push('confirm');
        if(status == 'Confirmed') actions.push('release');
      }

      if(status == 'Submitted' || status == 'Approved' || status.slice(0, 6) == 'OnHold')
        actions.push('cancel');
      
      return actions;
    },
    async doOrdersAction(action) {
      const isCancel = action.slice(0, 6) == 'cancel';
      if(isCancel && !(await this.confirm('Are you sure you want to cancel these order(s)?')))
        return; 

      const actionData = this.actionsData.filter(x => x.name == action)[0];
      const ids = isCancel && !this.selectedIds.length ? Object.keys(this.currentPageOrders) :this.selectedIds;
      const orderNumbers = ids.map(id => parseInt(id.split('_')[1]));

      this.$refs.container.startApiCall(actionData.inProcessMsg);
      const res = await actionData.func(orderNumbers);
      this.$refs.container.showBulkApiResult(res, actionData.successMsg);
    },
    async filterChanged(filters) {
      for(const filter of filters)
        this.searchParams[filter.field] = filter.value;
      await this.refreshResults();
    },
    actionsChanged(actions) {
      this.actionsData = actionsData.filter(x => actions.indexOf(x.name) >= 0);

      if(actions.indexOf('cancel') == -1
        && !this.selectedIds.length
        && Object.keys(this.currentPageOrders).length
        && (this.searchParams.status == 'submitted' || this.searchParams.status == 'onHold'))
        this.actionsData.push(cancelAllActionData);
    }
  },
};
</script>
