<template>
  <Container title="Service Levels" :haveError="haveError" :errorId="errorId">
    <template #actions>
      <ServiceLevelActions />
    </template>

    <AssignServiceLevelsModal v-if="loaded && showAssignServiceLevelsModal" />
    <AddCustomServiceLevelModal v-if="loaded && showAddCustomServiceLevelModal" />
    
    <EntityContainer
      v-if="loaded"
      ref="container"
      loadingString="Loading Service Levels. Please Wait..."
      :headers="headers"
      :statusCards="statusCards"
      :apiFunc="getServiceLevels"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enableCheckboxSelection="true"
      @selectionChanged="selectedIds = $event"
      @actionsChanged="actionsChanged" />
    
    <EntityDockActions
      :actionsData="actionsData"
      :handler="doServiceLevelsAction" />
  </Container>
</template>

<script>
const removeActionData = {
  name: 'remove',
  buttonClasses: 'button blue-button',
  label: 'Remove Service Levels'
};

import api from './../../lib/api-gateway';
import { mapGetters, mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityContainer from './../Common/EntityContainer.vue';
import EntityDockActions from './../Common/EntityDockActions.vue';
import ServiceLevelActions from './Header/ServiceLevelActions.vue';
import AssignServiceLevelsModal from './AssignServiceLevelsModal.vue';
import AddCustomServiceLevelModal from './AddCustomServiceLevelModal.vue';
export default {
  name: 'ServiceLevels',
  components: {
    Container,
    EntityContainer,
    EntityDockActions,
    ServiceLevelActions,
    AssignServiceLevelsModal,
    AddCustomServiceLevelModal
  },
  data() {
    return {
      haveError: false,
      errorId: null,
      currentServiceLevels: {},
      selectedIds: [],
      loadedClientDeliveryOptions: false,
      headers: [
        'CARRIER',
        'SERVICE LEVEL',
        'CUSTOM SERVICE LEVEL'
      ],
      actionsData: []
    }
  },
  async created() {
    const res = await api.getClientDeliveryOptionsAndReturnServiceLevels();
    
    if(!res.error) {
      await this.onSetDeliveryOptionsAndReturnServiceLevels(res.result.clientShipMethods);
      this.loadedClientDeliveryOptions = true;
    } else {
      console.log(res.message);
      this.haveError = true;
    }
  },
  computed: {
    ...mapGetters(['carriers', 'serviceLevels']),
    ...mapGetters('order', ['deliveryOptions', 'customServiceLevels']),
    ...mapGetters('manage', ['showAssignServiceLevelsModal', 'showAddCustomServiceLevelModal']),
    loaded() {
      return this.carriers.size
        && this.serviceLevels.length
        && this.loadedClientDeliveryOptions;
    },
    serviceLevelData() {
      const o = {};

      this.deliveryOptions.forEach(deliveryOption => {
          const cSL = this.customServiceLevels.filter(x => x.Name == deliveryOption)[0];
          const sL = this.serviceLevels
            .filter(x => cSL ? x._id == cSL.ServiceLevelId : x.name == deliveryOption)[0];
            
          o[deliveryOption] = { cSL, sL };
        });
      
      return o;
    },
    serviceLevelsByCarrier() {
      const o = {};

      for(const deliveryOption in this.serviceLevelData) {
        const { sL, cSL } = this.serviceLevelData[deliveryOption];

        const serviceLevelEntity = {
            _id: deliveryOption,
            carrier: this.carriers.get(sL.carrierId).name,
            serviceLevel: sL.name,
            customServiceLevel: cSL ? deliveryOption : null
          };
        
        for(const tab of ['all', sL.carrierId].concat(cSL ? ['custom'] : [])) {
          o[tab] = o[tab] || [];
          o[tab].push(serviceLevelEntity);
        }
      }

      return o;
    },
    statusCards() {
      let cards = [{ name: 'All', value: 'all' }];
      
      if(Object.keys(this.serviceLevelsByCarrier).length) {
        cards = cards.concat(
            [...this.carriers.entries()]
              .filter(x => this.serviceLevelsByCarrier[x[0]])
              .map(x => ({ name: x[1].name, value: x[0] }))
          );
      
        if(this.serviceLevelsByCarrier['custom'])
          cards.push({ name: 'Custom', value: 'custom' });
      }
        
      return cards;
    }
  },
  methods: {
    ...mapActions('order', ['onSetDeliveryOptionsAndReturnServiceLevels']),
    async getServiceLevels(tab) {
      return { result: { entities: this.serviceLevelsByCarrier[tab] } };
    },
    prepareTableRow(entity) {
      return {
          actions: ['remove'],
          row: [
            { value: entity.carrier },
            { value: entity.serviceLevel },
            { value: entity.customServiceLevel }
          ]
        };
    },
    handleApiResult(res, serviceLevels) {
      this.currentServiceLevels = serviceLevels;
    },
    async doServiceLevelsAction() {
      const customServiceLevelIds = this.selectedIds
        .filter(x => this.serviceLevelData[x].cSL)
        .map(x => this.serviceLevelData[x].cSL._id);
      const serviceLevels = this.selectedIds
        .filter(x => !this.serviceLevelData[x].cSL)
        .map(x => this.serviceLevelData[x].sL.name);

      this.$refs.container.startApiCall('Removing service levels...');
      
      const calls =
        [ { func: api.removeCustomServiceLevels, param: customServiceLevelIds },
          { func: api.unassignServiceLevels, param: serviceLevels } ];
      
      let res;
      for(const call of calls)
        if(call.param.length) {
          res = await call.func(call.param);
          if(res.error) {
            this.$refs.container.showBulkApiResult(res);
            return;
          }
        }
      
      this.$refs.container.showBulkApiResult(res, 'Service levels removed successfully.');
    },
    actionsChanged(actions) {
      this.actionsData = actions.length ? [removeActionData] : [];
    }
  }
}
</script>
