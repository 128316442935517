<template>
  <CollapsiblePanel class="detail-panel" heading="Order History">
    <div style="padding: .75rem 0">
      <template v-for="(log, i) in orderHistory" :key="i">
        <div class="history-log-div">
          <div class="history-checkmark" :style="{ color: getHistoryEntryColor(log) }">
            <i class="fas fa-check"></i>
          </div>
          <div class="history-status">
            <template v-if="log.date">
              <p class="entity-heading">{{ log.status }}</p>
              <p class="text-size-big theme-gray">{{ util.formatAndGetDate(log.date) }}</p>
            </template>
            <p v-else class="entity-heading theme-gray">{{ log.status }}</p>
          </div>
        </div>
        <div v-if="i < orderHistory.length - 1" class="dashed-connector"></div>
      </template>
    </div>
  </CollapsiblePanel>
</template>

<script setup>
import { useStore } from 'vuex';
import util from '../../lib/util';
import CollapsiblePanel from '../Common/CollapsiblePanel.vue';
const { order } = defineProps(['order']);

const orderHistory = [{ status: 'Order Submitted', date: order.CreatedDate || order.createdAt }];

if(useStore().getters['isUserInternal']) {
  const statuses = ['Order Confirmed', 'Released', 'Picked', ...(order.OrderType == 'Deduct' ? [] : ['Packed']), 'Shipped'];
  for(const status of statuses) {
    const historyEntry = order.OrderHistory ? order.OrderHistory.find(x => x.Status == status) : null;
    orderHistory.push({ status, date: historyEntry ? historyEntry.Date : null });
  }
}

const shippedHistoryEntry = order.OrderHistory && order.OrderHistory.find(x => x.Status == 'Shipped');
const closeDate = shippedHistoryEntry ? shippedHistoryEntry.Date : order.ShippedDate;
orderHistory.push({ status: 'Closed', date: closeDate });

function getHistoryEntryColor(log) {
  return log.date ? 'var(--theme-green)' : 'var(--theme-gray)';
}
</script>

<style lang="less" scoped>
.detail-panel {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 2px;
}
.history-log-div {
  display: flex;
  align-items: center;
}
.history-checkmark {
  width: 60px;
  height: 60px;
  margin: 3px 15px 3px 3px;
  border: 3px currentColor solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  flex-shrink: 0;

  i {
    color: inherit;
  }
}
.history-status {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  & > p:first-child {
    font-weight: bold;
  }
}
.dashed-connector {
  width: 34px;
  height: 48px;
  border-right: 3px dashed var(--theme-gray);
}
</style>
