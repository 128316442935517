<template>
  <ComponentHeaderWithMoreActions iconName="fas fa-caret-down" btnText="Manage">
    <template #options>
      <ComponentHeaderButton
        :isSubmenuBtn="true"
        iconName="fas fa-user-lock"
        btnText="Clients"
        @handler="$router.push({ name: 'ManageClients' })" />
      <br />
      <ComponentHeaderButton
        :isSubmenuBtn="true"
        iconName="fas fa-user"
        btnText="Users"
        @handler="$router.push({ name: 'ManageUsers' })" />
      <br />
      <ComponentHeaderButton
        :isSubmenuBtn="true"
        iconName="fas fa-shipping-fast"
        btnText="Service Levels"
        @handler="$router.push({ name: 'ManageServiceLevels' })" />
      <br />
      <ComponentHeaderButton
        :isSubmenuBtn="true"
        iconName="fas fa-shipping-fast"
        btnText="Item Groups"
        @handler="$router.push({ name: 'ManageItemGroups' })" />
    </template>
  </ComponentHeaderWithMoreActions>
</template>

<script>
import ComponentHeaderWithMoreActions from '../../Common/ComponentHeaderWithMoreActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
export default {
  name: 'NavigationMenuComponent',
  components: { ComponentHeaderWithMoreActions, ComponentHeaderButton }
}
</script>

<style lang="less" scoped>

</style>
