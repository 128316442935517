<template>
  <ComponentHeaderActions
    @onSearch="$emit('onSearch', $event)"
    @onSearchReset="$emit('onSearchReset')">
    <template #actions>
      <ComponentHeaderWithMoreActions
        btnText="Search"
        iconName="fas fa-search"
        @expanded="$refs.searchBox.select()">
        <template #options>
          <SearchBox
            ref="searchBox"
            @onSearch="$emit('onSearch', $event)"
            @onSearchReset="$emit('onSearchReset')" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderButton
        btnText="Add Item"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="$router.push({ name: 'ProductCreate' })" />
      <ComponentHeaderWithMoreActions
        btnText="More"
        iconName="fas fa-ellipsis-h">
        <template #options>
          <ComponentHeaderButton
            btnText="Import Items"
            iconName="fa fa-upload"
            :isSubmenuBtn="true"
            @handler="$router.push({ name: 'ItemImports' })" />
        </template>
      </ComponentHeaderWithMoreActions> 
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../../Common/ComponentHeaderButton.vue';
import ComponentHeaderWithMoreActions from '../../../Common/ComponentHeaderWithMoreActions.vue';
import SearchBox from '../../../Common/Search/SearchBox.vue';
export default {
  name: 'InventoryHeaderActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, ComponentHeaderWithMoreActions, SearchBox },
  emits: ['onSearch', 'onSearchReset']
}
</script>
