<template>
  <div ref="root" :class="{ columns: widthClasses && widthClasses.length }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OptionalColumns',
  props: ['widthClasses'],
  mounted() {
    if(this.widthClasses && this.widthClasses.length) {
      const root = this.$refs.root;
      const items = [...root.children];
      root.textContent = '';

      const columnDivs = [];
      let i = 0;

      for(const item of items) {
        const columnDiv = document.createElement('div');
        columnDiv.classList.add('column');

        const widthClass = this.widthClasses[i++];
        if(widthClass)
          columnDiv.classList.add(widthClass);

        columnDiv.appendChild(item);
        columnDivs.push(columnDiv);
      }
      
      root.append(...columnDivs);
    }
  },
  updated() {
    // Does the same logic need to happen here?
    // https://stackoverflow.com/questions/47280869/move-elements-passed-into-a-component-using-a-slot
  }
}
</script>

<style lang="less" scoped>
</style>
