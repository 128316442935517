<template>
  <Container title="Service Levels">
    <template #actions>
      <slot name="actions">
        <ServiceLevelActions />
      </slot>
    </template>

    <MaxWidthContainer class="card content-container">
      <div class="columns is-variable is-4">
        <div class="column is-4 service-level-column">
          <label class="label">Carriers:</label>
          <SelectList
            :options="carriers"
            labelField="name"
            valueField="_id"
            size="10"
            @change="selectCarrier($event.target.value)" />
        </div>
        <div class="column is-8 service-level-column">
          <label class="label">Service Levels:</label>
          <div class="add-service-level">
            <input class="input"
              style="flex-grow: 1"
              :placeholder="!carrierId ? 'Select a carrier' : 'New service level name'"
              v-model.trim="newServiceLevelName" />
            <button class="button blue-button" :disabled="!carrierId || !newServiceLevelName" @click="addNewServiceLevel">
              <span>Add</span>
            </button>
          </div>
          <div class="custom-list custom-list-hover" style="height: 50vh">
            <template v-if="carrierId">
              <div v-for="serviceLevel in carrierServiceLevels"
                :key="serviceLevel._id"
                class="service-level-item"
                @click="toggleServiceLevelSelected(serviceLevel._id)">
                <input type="checkbox" :checked="selectedServiceLevels.includes(serviceLevel._id)" style="margin: 0 5px" />
                <p class="custom-list-text">
                  {{ serviceLevel.name }}
                </p>
              </div>
            </template>
          </div>
          <button class="button red-button"
            style="align-self: flex-end"
            :disabled="!selectedServiceLevels.length"
            @click="removeServiceLevels">
            <span>Remove Selected</span>
          </button>
        </div>
      </div>
    </MaxWidthContainer>
  </Container>
</template>

<script>
import api from '../../lib/api-gateway';
import { mapGetters, mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import MaxWidthContainer from './../Common/MaxWidthContainer.vue';
import SelectList from './../Common/SelectList.vue';
import ServiceLevelActions from './Header/ServiceLevelActions.vue';
export default {
  name: 'ServiceLevels',
  components: { Container, MaxWidthContainer, SelectList, ServiceLevelActions },
  data() {
    return {
      carrierId: null,
      newServiceLevelName: '',
      selectedServiceLevels: []
    }
  },
  computed: {
    ...mapGetters(['carriers', 'serviceLevels']),
    carrierServiceLevels() {
      return this.serviceLevels
        .filter(x => x.carrierId == this.carrierId)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  methods: {
    ...mapActions(['onMakingAPICalls', 'onSetResponseContent']),
    selectCarrier(id) {
      this.carrierId = id;
      this.selectedServiceLevels = [];
    },
    toggleServiceLevelSelected(id) {
      const idx = this.selectedServiceLevels.indexOf(id);
      if(idx >= 0)
        this.selectedServiceLevels.splice(idx, 1);
      else
        this.selectedServiceLevels.push(id);
    },
    async addNewServiceLevel() {
      this.onMakingAPICalls('Adding service level...');
      const res = await api.addServiceLevel(this.carrierId, this.newServiceLevelName);
      this.onSetResponseContent({
          responseStatus: !res.error,
          responseText: res.error ? res.message : 'Service level added successfully'
        });
    },
    async removeServiceLevels() {
      this.onMakingAPICalls('Removing service level...');
      const res = await api.removeServiceLevels(this.selectedServiceLevels);
      this.onSetResponseContent({
          responseStatus: !res.error,
          responseText: res.error ? res.message : 'Service level removed successfully'
        });
    }
  }
}
</script>

<style lang="less" scoped>
.service-level-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  & .label {
    margin-left: 6px;
  }
}
.service-level-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-service-level {
  display: flex;
  gap: 10px;
}
</style>
