<template>
  <Container title="Clients"
    :haveError="haveError"
    :errorId="errorId">
    <template #actions>
      <ClientHeaderActions
        @onSearch="onSearch"
        @onSearchReset="onSearchReset" />
    </template>
    
    <EntityContainer
      ref="container"
      loadingString="Loading Clients. Please Wait..."
      :headers="headers"
      :apiFunc="getClients"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enablePagination="true"
      :enableRowSelection="true"
      @changeRow="clientSelected" />
  </Container>
</template>

<script>
import api from '../../lib/api-gateway';
import { mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityContainer from './../Common/EntityContainer.vue';
import ClientHeaderActions from './Header/ClientHeaderActions.vue';
export default {
  name: 'ManageClients',
  components: { Container, EntityContainer, ClientHeaderActions },
  data() {
    return {
      currentPageClients: {},
      headers: [
        'CLIENT ID',
        'NAME',
        'DEFAULT CARRIER',
        'DEFAULT RELEASE WAREHOUSE',
        'IS ACTIVE'
      ],
      haveError: false,
      errorId: null,
      searchTerm: ''
    }
  },
  activated() {
    this.resetState();
  },
  mounted() {
    this.resetState();
  },
  methods: {
    ...mapActions('manage', ['setNewClient', 'onSetSelectedClient', 'onResetNewClient', 'onResetNewUser']),
    resetState() {
      this.onResetNewClient();
      this.onResetNewUser();
    },
    async getClients(page, pageSize) {
      return api.getClients(this.searchTerm, page, pageSize);
    },
    handleApiResult(res, clients) {
      if(!res.error) {
        this.currentPageClients = clients;
      } else {
        this.haveError = true;
        this.errorId = res.errorId;
      }
    },
    prepareTableRow(client) {
      return {
        row: [
          { value: client._id },
          { value: client.Name },
          { value: client.DefaultCarrier },
          { value: client.DefaultReleaseWarehouse },
          { value: client.IsActive ? 'Yes' : 'No' }
        ]
      }
    },
    clientSelected(id) {
      this.onSetSelectedClient(this.currentPageClients[id]);
      this.$router.push({ name: 'ClientDetailManage' });
    },
    onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      this.$refs.container.refreshResults();
    },
    onSearchReset() {
      this.searchTerm = '';
      this.$refs.container.refreshResults();
    }
  }
}
</script>

<style lang="less" scoped>

</style>