<template>
  <div>
    <div>  
      <label class="label">Selected Items</label>
    </div>
    <ul>
      <li v-for="([key, value], i) in items" :key='key'>
        <div class="item-row">
          <div class="item-container">
            <div class="item-text-box">
              <strong>Item</strong>
              <p>{{ value.StockNumber }}</p>
              <div v-if="!disableEditing && !value.Quantity" class="error-text theme-error">
                Enter a valid quantity
              </div>
              <slot name="item-slot" :item="value" :i="i"></slot>
            </div>
            <div class="item-quantity-box">
              <div style="flex-grow: 1">
                <strong>QTY</strong>
                <input type="number" 
                  class="item-quantity input input-no-arrows"
                  :value="value.Quantity" 
                  @input="onInputValidateQty($event, value.StockNumber)"
                  :disabled="disableEditing || disableEditingItemQty(value.StockNumber)" />
              </div>
              <i v-if="!disableEditing" @click="removeItem(value.StockNumber)" class="fa fa-times remove-item-icon"></i>
            </div>
            <div v-if="showReceivedQty" class="item-quantity-box">
              <div style="flex-grow: 1">
                <strong>RECEIVED</strong>
                <input type="number" 
                  class="item-quantity input input-no-arrows"
                  :value="value.QuantityReceived" 
                  :disabled="true" />
              </div>
            </div>
          </div>
          <div v-if="$slots['qty-slot']" class="qty-slot-div">
            <slot name="qty-slot" :item="value" :i="i" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

const props = defineProps(['items', 'disableEditing', 'disableEditingQtyItems', 'showReceivedQty']);
const store = useStore();

function disableEditingItemQty(stockNumber) {
  return props.disableEditingQtyItems && props.disableEditingQtyItems.includes(stockNumber);
}
function onInputValidateQty(e, stockNumber) {
  let qty = e.target.value;
  if(qty < 0)
    qty = 0;
  store.dispatch('onItemQtyChange', { stockNumber, qty });
}
function removeItem(stockNumber) {
  store.dispatch('onItemRemove', stockNumber);
}
</script>

<style lang="less" scoped>
  .item-row {
    border-radius: 2px;
    font-size: 16px;
    padding:8px;
    padding-right: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .item-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .item-text-box{
    width: 75%;
  }
  .item-quantity-box {
    width: 23%;
    display: flex;
    align-items: flex-start;
  }
  .item-quantity{
    width: 100%;
    outline: none;
  }
  .item-quantity[disabled] {
    border: none !important;
    background: none !important;
  }
  .remove-item-icon{
    color: #363636;
    cursor: pointer;
    font-size: 20px;
    margin: .1em;
  }
  .error-text{
    font-size: 14px;
    margin:0px;
    padding:0px
  }
  .qty-slot-div {
    display: flex;
    justify-content: flex-end;
  }
</style>