// getters
export const getters = {
  selectedOrder: state => state.selectedOrder,
  loadedFiles: state => state.loadedFiles,
  deliveryOptions: state => state.deliveryOptions,
  customServiceLevels: state => state.customServiceLevels,
  returnServiceLevels: state => state.returnServiceLevels,
  newOrder: state => state.newOrder,
  newDeductOrder: state => state.newDeductOrder,
  newWorkOrder: state => state.newWorkOrder,
  isOrderValid: state => state.isOrderValid,
  newReturn: state => state.newReturn,
  recipient: state => state.recipient,
  showReturnModal: state => state.showReturnModal,
  showReturnsDetailModal: state => state.showReturnsDetailModal
};