<template>
  <ComponentHeaderActions>
    <template #actions>
      <EntityActions v-if="workOrder && workOrder._id" :showPrintWorkOrderButton="true" />
    </template>
  </ComponentHeaderActions>
</template>

<script setup>
import { useStore } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import EntityActions from '../../Common/EntityActions.vue';
const workOrder = useStore().getters['order/selectedOrder'].WorkOrder;
</script>
