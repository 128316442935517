<template>
  <ClientsSelection title="Preferred Clients" :selectedClients="selectedUser.PreferredClients" @close="doAction" />
</template>

<script setup>
import { useStore } from 'vuex';
import api from '../../lib/api-gateway';
import ClientsSelection from '../Manage/ClientsSelection.vue';
import { makeApiCall } from '../../composables/api';

const emit = defineEmits(['close']);
const selectedUser = useStore().getters['manage/selectedUser'];

function doAction(clients) {
  if(clients)
    makeApiCall(
      'Updating preferred clients. Please wait...',
      'Preferred clients updated!',
      () => api.updateUserPreferredClients({ ClientIds: clients }));
  else
    emit('close');
}
</script>
