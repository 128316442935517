<template>
   <div class="column" :class="isNewOrder ? 'is-5' : 'is-4'">
    <div class="field">
      <label class="label">Order Name</label>
      <input class="input" type="text"
        maxlength="80"
        @input="onOrderChanged($event,'Name')"
        :value="newOrder.Name" />
    </div>
    <Recipient />
    <div v-if="isNewOrder && !currentClient.NeedOrderApproval" class="field">
      <label class="label">Order Requires Approval</label>
      <OnOffSwitch @toggled="onOrderChanged($event, 'WaitForClientApproval')" />
    </div>
    <div v-if="checkCurrentClientFeature('VUE_APP_UCC_LABELS_CLIENTS')" class="field">
      <label class="label">Request Box Labels</label>
      <OnOffSwitch @toggled="onOrderChanged($event, 'RequestUCCLabels')" :on="newOrder.RequestUCCLabels" />
    </div>
    <div class="field" style="min-height: 3.75em">
      <label class="label">Ship Date</label>
      <div class="control">
        <label class="checkbox">
          <input v-model="specifyDateToShip" @change="onOrderDateToShipChanged()" type="checkbox" style="margin: 0 10px" />
          <strong>I want to specify a date to ship&nbsp;</strong>
          <strong style="font-style: italic">(Don't ship immediately)</strong>
        </label>
        <div v-if="specifyDateToShip" style="margin-top: 8px">
          <input
            type="date"
            class="input"
            style="width: 165px"
            @change="onOrderDateToShipChanged($event)"
            :value="newOrder.ShipByDate"
          />
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Delivery Option</label>
      <div class="control">
        <SelectList
          instructionLabel="-- Select a Delivery Option --"
          :options="deliveryOptions"
          :selectedValue="newOrder.DeliveryOption"
          @change="onOrderChanged($event, 'DeliveryOption')" />
      </div>
    </div>
    <div class="field">
      <label class="label">Customer Invoice</label>
      <FileUploadWithPreview
        title="Customer Invoice"
        fileType="pdf"
        :fileName="newOrder.InvoiceFile"
        :fileData="loadedFiles.InvoiceFile"
        :fileLoadFunc="getFileLoadFunc('InvoiceFile')"
        @fileChanged="onFileChanged($event, 'InvoiceFile')" />
    </div>
    <div class="field">
      <label class="label">Shipping Label</label>
      <FileUploadWithPreview
        title="Shipping Label"
        fileType="pdf"
        :fileName="newOrder.ShippingLabel"
        :fileData="loadedFiles.ShippingLabel"
        :fileLoadFunc="getFileLoadFunc('ShippingLabel')"
        @fileChanged="onFileChanged($event, 'ShippingLabel')" />
    </div>
    <div class="field">
      <label class="label">Shipping Note</label>
      <div class="control">
        <textarea
          @input="onOrderChanged($event, 'Notes')"
          class="textarea"
          :value="newOrder.Notes"
          placeholder="Enter shipping note"
          rows="3"
          maxlength="256">
        </textarea>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SelectList from './../Common/SelectList' ;
  import OnOffSwitch from './../Common/OnOffSwitch' ;
  import Recipient from './../Common/Recipient' ;
  import FileUploadWithPreview from './../Common/FileUploadWithPreview.vue';
  import util from '../../lib/util';
  import { loadOrderFile } from '../../composables/order';
  import { checkCurrentClientFeature } from '../../composables/client_config';
  export default {
    name: 'OrderBasicEdit',
    components: { SelectList, OnOffSwitch, Recipient, FileUploadWithPreview },
    props: ['isNewOrder'],
    data() {
      return {
        queriedRecipients: [],
        specifyDateToShip: false,
        checkCurrentClientFeature
      }
    },
    mounted(){
      this.specifyDateToShip = !!this.newOrder.ShipByDate
    },
    computed:{
      ...mapGetters(['currentClient']),
      ...mapGetters('order', ['loadedFiles', 'selectedOrder', 'newOrder', 'deliveryOptions'])
    },
    methods:{
      ...mapActions(['onSetSelectedFile']),
      ...mapActions('order', ['onOrderDataChange']),
      onOrderChanged(event, prop){
        this.onOrderDataChange({o: this.newOrder, prop, val: event.target ? event.target.value : event});
      },
      onOrderDateToShipChanged(event) {
        this.onOrderDataChange({
          o: this.newOrder,
          prop: 'ShipByDate',
          val: this.specifyDateToShip
            ? event ? event.target.value : '//'
            : ''
        });
      },
      onFileChanged(event, prop) {
        this.onSetSelectedFile({ type: prop, file: event });
      },
      getFileLoadFunc(field) {
        return this.selectedOrder[field] ? () => loadOrderFile(field, this.selectedOrder[field]) : null;
      },
      formatAndGetDate: util.formatAndGetDateOnly
    }
  }
</script>
