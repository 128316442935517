import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

const shipment = { 
  namespaced: true,
  state: {
    selectedShipNotice: {},
    newShipNotice: newShipNotice()
  },
  mutations: { ...mutations },
  actions: { ...actions }, 
  getters: { ...getters }
}

function newShipNotice() {
  return {
    WarehouseCode: "",
    ExpectedDate: "",
    Carrier: "",
    TrackingNumber : "",
    PONumber: "",
    PackageCount: "",
    PalletCount: "",
    Vendor: ""
  };
}

export { shipment, newShipNotice }
