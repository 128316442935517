import store from '../store';

export async function makeApiCall(message, successMessage, call) {
  store.dispatch('onMakingAPICalls', message);

  const res = await call();
  const responseText = res.error
    ? res.message
    : typeof(successMessage) == 'function' ? successMessage(res) : successMessage;

  return await store.dispatch('onSetResponseContent', { responseStatus: !res.error, responseText });
}

export async function makeApiCallGetResponse(message, call) {
  store.dispatch('onMakingAPICalls', message);

  const res = await call();

  if(res.error) {
    await store.dispatch('onSetResponseContent', { responseStatus: false, responseText: res.message });
    return null;
  } else {
    await store.dispatch('onFinishAPICalls');
    return res;
  }
}

export async function setApiError(message) {
  return await store.dispatch('onSetResponseContent', { responseStatus: false, responseText: message });
}
