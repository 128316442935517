<template>
  <div class="columns" style="display: block">
    <ValidationMessages class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="validationMessages"
      :checks="validationChecks" />
    <!-- <p><b>NOTE:</b><i>Your shopping cart system will not reflect any orders placed through this screen. Be sure to update your cart system's inventory to account for this order.</i></p> -->
    <div v-if="!isNewOrder" class="column order-header">
      <div class="field">
        <label class="label custom-label-style">Created by</label>
        <p class="">{{ getCorrectValue(selectedOrder.OrderBy) }}</p>
      </div>
      <div class="field">
        <label class="label custom-label-style">Warehouse</label>
        <p class="">{{ getCorrectValue(selectedOrder.Warehouse) }}</p>
      </div>
      <div class="field">
        <div>
          <label class="label custom-label-style">Created On</label>
          <span class="">{{ getDate( selectedOrder.CreatedDate ) }}</span>
        </div>
        <div v-if="selectedOrder.ShippedDate">
          <label class="label custom-label-style" style="padding-top: 10px">Shipped Date</label>
          <span class="">{{ getDate( selectedOrder.ShippedDate ) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import util from './../../lib/util.js'
  import ValidationMessages from '../Common/ValidationMessages.vue';
  export default {
    name: 'OrderHeaderInfo',
    props: ["isNewOrder"],
    components: { ValidationMessages },
    methods:{
      ...mapActions('order', ['onSetIsOrderValid']),
      getCorrectValue(val){
        return util.getUpperCaseVal(val);
      },
      getDate(date){
        return util.formatAndGetDate(date)
      }
    },
    watch: {
      isOrderValid: {
        immediate: true,
        handler: function(val) {
          this.onSetIsOrderValid(val);
        }
      }
    },
    computed: {
      ...mapGetters('order', ['newOrder', 'recipient', 'selectedOrder']),
      ...mapGetters(['selectedItems']),
      validationMessages() {
        return [
            'Required fields: Order Name, Recipient, Delivery Option',
            'At least 1 item',
            'If Shipment Date is present, it must be in the future'
          ];
      },
      validationChecks() {
        return [
            this.haveRequiredFields,
            this.haveItems,
            this.haveValidDate
          ];
      },
      isRecipientEmpty() {
        return !this.recipient || (this.recipient['FirstName'] == '' && this.recipient['LastName'] == ''
          && this.recipient['Address1'] == '' && this.recipient['Address2'] == '' && this.recipient['City'] == ''
          && this.recipient['State'] == '' && this.recipient['Country'] == '')
      },
      haveRequiredFields() {
        return this.newOrder.Name && this.newOrder.DeliveryOption && !this.isRecipientEmpty
      },
      haveItems() {
        return util.haveItemsAndQuantities(this.selectedItems)
      },
      haveValidDate() {
        return !this.newOrder.ShipByDate || util.isDateInTheFuture(this.newOrder.ShipByDate)
      },
      isOrderValid() {
        return this.validationChecks.every(Boolean);
      }
    }
  }
</script>

<style lang="less" scoped>
  .custom-label-style{
    margin: 0px !important
  }
  .order-header {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    border-bottom: 1px solid #ccc;
  }
</style>