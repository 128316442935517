<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Recipients' })" />
      <EntityActions
        :showUpdateRecipient="true"
        :isRecipientValid="isRecipientValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../Common/EntityActions.vue';
export default {
  name: 'RecipientEditActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, EntityActions },
  props: ['isRecipientValid']
}
</script>

<style scoped>

</style>