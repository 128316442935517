<template>
  <div>
    <div class="field">
      <label class="label">
        <CheckboxSwitch
          style="margin-right: 6px"
          :checked="newClientConfig.UsesMultipleWarehouses"
          :disabled="!isUserInternal"
          @change="clientConfigDataChanged('UsesMultipleWarehouses', $event.target.checked)" />
        Use Multiple Warehouses
      </label>
      <template v-if="newClientConfig.UsesMultipleWarehouses">
        <div class="control" v-for="(label, field) in multipleWarehouseSettings" :key="field">
          <label>
            <CheckboxSwitch
              class="checkbox"
              :checked="newClientConfig[field]"
              :disabled="!isUserInternal"
              @change="clientConfigDataChanged(field, $event.target.checked)"/>
            {{ label }}
          </label>
        </div>
        <div style="margin-top: 8px">
          <div class="control">
            <label>Split Order Release Preference:</label>
            <div class="control" v-for="(label, value) in releasePreferences" :key="value">
              <label>
                <input type="radio"
                  name="releasePreference"
                  class="checkbox"
                  :value="value"
                  :disabled="!newClientConfig.AllowOrderSplit"
                  :checked="value == newClientConfig.ReleasePreference"
                  @change="clientReleasePreferenceChanged" />
                {{ label }}
              </label>
            </div>
          </div>
        </div>
        <div style="margin-top: 8px">
          <div class="control">
            <label>Release Warehouses:</label>
            <div class="control" v-for="[ Code, { Name } ] in warehouses" :key="Code">
              <label>
                <input type="checkbox"
                  class="checkbox"
                  :checked="isDefaultWarehouse(Code) || newClientConfig.ReleaseWarehouses.indexOf(Code) >= 0"
                  :disabled="isDefaultWarehouse(Code) || !isUserInternal"
                  @change="clientReleaseWarehouseChanged(Code, $event)" />
                {{ Name }}
              </label>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="field">
      <label class="label">Order Settings</label>
      <div class="control" v-for="(label, field) in orderSettings" :key="field">
        <label>
          <CheckboxSwitch
            @change="clientDataChanged(field, $event.target.checked)"
            class="checkbox"
            :checked="newClient[field]"
            :disabled="!isUserInternalOrOwner" />
          {{ label }}
        </label>
      </div>
      <div v-if="isUserInternal && newClient.UsePreconfiguredTracking" class="control" style="margin-top: 8px">
        <label style="margin-right: 10px">Preconfigured Tracking Number:</label>
        <input class="input"
          @change="clientDataChanged('PreconfiguredTrackingNumber', $event.target.value)"
          style="width: 200px"
          :value="newClient.PreconfiguredTrackingNumber" />
      </div>
      <div class="control" style="margin-top: 8px">
        <label style="margin-right: 10px">Order Processing Wait Time (minutes):</label>
        <input class="input"
          @change="clientConfigDataChanged('WaitTimeBeforeSystemStartProcessingOrder', $event.target.value)"
          style="width: 75px"
          :value="newClientConfig.WaitTimeBeforeSystemStartProcessingOrder"
          :disabled="!isUserInternal" />
      </div>
    </div>
    <div class="field">
      <label class="label">Item Settings</label>
      <div class="control" v-for="(label, field) in itemSettings" :key="field">
        <label>
          <CheckboxSwitch
            @change="clientConfigDataChanged(field, $event.target.checked)"
            class="checkbox"
            :checked="newClientConfig[field]"
            :disabled="!itemSettingsPermissions[field]" />
          {{ label }}
        </label>
      </div>
    </div>
    <div class="field">
      <label class="label">Notification Types</label>
      <div class="control" v-for="(label, field) in notificationTypes" :key="field">
        <label>
          <CheckboxSwitch
            @change="clientNotificationSettingsChanged(field, $event)"
            class="checkbox"
            :checked="newClient.NotificationSettings[field]"
            :disabled="!isUserInternalOrOwner" />
          {{ label }}
        </label>
      </div>
    </div>
    <div class="field">
      <label class="label">Notify On:</label>
      <div class="control" v-for="(label, field) in notificationFlags" :key="field">
        <label>
          <CheckboxSwitch
            @change="clientNotificationSettingsChanged(field, $event)"
            class="checkbox"
            :checked="newClient.NotificationSettings[field]"
            :disabled="!isUserInternalOrOwner" />
          {{ label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CheckboxSwitch from '../Common/CheckboxSwitch.vue';
export default {
  name: 'ClientExtra',
  components: { CheckboxSwitch },
  data() {
    return {
      orderSettings: {
        AllowOrderSubmission: 'Allow Order Submission',
        AllowOrderConfirmation: 'Allow Order Confirmation',
        AllowOrderApproval: 'Allow Order Approval',
        AllowOrderRelease: 'Allow Order Release',
        AllowBackOrders: 'Allow Backorders',
        NeedOrderApproval: 'Need Order Approval',
        UsePreconfiguredTracking: 'Use Preconfigured Tracking'
      },
      itemSettings: {
        SupportsItemGroups: 'Use Item Groups'
      },
      multipleWarehouseSettings: {
        AllowOrderSplit: 'Allow Splitting Orders',
        AllowWarehouseSwitch: 'Allow Switching Warehouses',
        ForceDefaultWarehouse: 'Force Default Warehouse',
        ForceRequestedWarehouse: 'Force Requested Warehouse'
      },
      releasePreferences: {
        SingleWarehouse: 'Prefer single warehouse that can fulfill the order',
        PreferClosestWarehouse: 'Prefer closest warehouse even if it splits the order'
      },
      notificationTypes: {
        AllowEmailNotification: 'Allow email notification',
        //AllowSmsNotification: 'Allow SMS notification' // Hide for now
      },
      notificationFlags: {
        NotifyOnOrderSubmit: 'Order Submit',
        NotifyOnOrderConfirmation: 'Order Confirmation',
        NotifyOnOrderRelease: 'Order Release',
        NotifyOnOrderShip: 'Order Ship',
        NotifyOnOrderUpdate: 'Order Update',
        NotifyOnItemCreate: 'Item Create',
        NotifyOnItemUpdate: 'Item Update',
        //NotifyOnReturnRequest: 'Return Request',
        //NotifyOnReturnReceive: 'Return Receive',
        NotifyOnShipmentNoticeRequest: 'Shipment Notice Request',
        NotifyOnShipmentNoticeReceive: 'Shipment Notice Receive',
        NotifyOnInsufficientInventoryIssue: 'Insufficient Inventory Issue',
        NotifyOnAccountChangeOrUpdate: 'Account Change or Update',
        NotifyOnNewUser: 'New User',
        NotifyOnBackOrder: 'Backorder',
        NotifyRecipient: 'Notify Recipient'
      }
    }
  },
  computed: {
    ...mapGetters(['isUserInternal', 'isUserInternalOrOwner', 'warehouses']),
    ...mapGetters('manage', ['newClient', 'newClientConfig']),
    itemSettingsPermissions() {
      return {
        SupportsItemGroups: this.isUserInternal
      };
    }
  },
  methods: {
    ...mapActions('manage', ['onSetNewClientData', 'onSetNewClientConfigData', 'onSetNewClientNotificationSettingsData']),
    clientDataChanged(field, value) {
      this.onSetNewClientData({ field, value });

      if(field == 'UsePreconfiguredTracking'
        && value
        && !this.newClient.PreconfiguredTrackingNumber)
        this.onSetNewClientData({ field: 'PreconfiguredTrackingNumber', value: 'LABEL_PROVIDED' });
    },
    clientConfigDataChanged(field, value) {
      this.onSetNewClientConfigData({ field, value });
    },
    clientReleasePreferenceChanged(event) {
      if(event.target.checked)
        this.onSetNewClientConfigData({ field: 'ReleasePreference', value: event.target.value });
    },
    clientReleaseWarehouseChanged(code, event) {
      const releaseWarehouses = this.newClientConfig.ReleaseWarehouses;
      
      if(event.target.checked)
        releaseWarehouses.push(code);
      else
        releaseWarehouses.splice(releaseWarehouses.indexOf(code), 1);

      this.onSetNewClientConfigData({ field: 'ReleaseWarehouses', value: releaseWarehouses });
    },
    clientNotificationSettingsChanged(field, event) {
      this.onSetNewClientNotificationSettingsData({ field, value: event.target.checked });
    },
    isDefaultWarehouse(code) {
      return code == this.newClientConfig.DefaultReleaseWarehouse;
    }
  }
}
</script>

<style lang="less" scoped>
.control {
  margin-left: 15px;
}
.input {
  vertical-align: baseline;
}
.addl-notification-email-line {
  display: flex;
  margin-bottom: 10px;
}
.addl-notification-email-input {
  width: unset;
  flex-grow: 1;
}
.addl-notification-email-btn {
  border: none;
  background: none;
  width: 3em;
  cursor: pointer;
}
.addl-notification-email-btn-icon {
  font-size: 1.5em;
}
.checkbox {
  margin-right: 10px;
}
</style>