<template>
  <ComponentHeaderActions>
    <template #actions>
      <NavigationMenuComponent />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import NavigationMenuComponent from './NavigationMenuComponent.vue';
export default {
  name: 'ItemGroupActions',
  components: { ComponentHeaderActions, NavigationMenuComponent }
}
</script>
