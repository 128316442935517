<template>
  <div class="column" :class="isNewOrder ? 'is-6' : 'is-5'" >
    <DeductOrderItemTagsModal v-if="stockNumber"
      :stockNumber="stockNumber"
      :warehouse="warehouseCode"
      @close="stockNumber = null" />
    <ItemSearchInput :searchForActiveItems="true" :excludeKits="true" />
    <ItemListEdit
      :items="selectedItems"
      :disableEditingQtyItems="disableEditingQtyItems">
      <template #qty-slot="{ item }">
        <DeductOrderItemTags
          :itemTags="item.Tags"
          @editItemTags="showTagsModal(item.StockNumber)" />
      </template>
    </ItemListEdit>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import ItemSearchInput from './../Common/ItemSearchInput';
import ItemListEdit from './../Common/ItemListEdit';
import DeductOrderItemTags from './DeductOrderItemTags.vue';
import DeductOrderItemTagsModal from './DeductOrderItemTagsModal.vue';

const { isNewOrder } = defineProps(['isNewOrder']);
const alert = inject('alert');
const store = useStore();

const stockNumber = ref(null);
const selectedItems = computed(() => store.getters['selectedItems']);
const disableEditingQtyItems = computed(
  () => [...selectedItems.value.values()].filter(x => x.Tags && x.Tags.length).map(x => x.StockNumber));
const warehouseCode = computed(() => store.getters['order/newDeductOrder'].WarehouseCode);
    
function showTagsModal(showStocknumber) {
  if(!warehouseCode.value) {
    alert('Select a warehouse to be able to choose item tags.');
    return;
  }

  stockNumber.value = showStocknumber;
}
</script>
