<template>
  <div style="text-align: right">
    <div v-if="itemTags" class="tags-list">
      <p v-for="tag in itemTags.values()" :key="tag.TagNumber"
        class="text-size"
        style="text-wrap: nowrap">
        {{ tag.TagNumber }} / {{ tag.Quantity }}
      </p>
    </div>
    <p v-if="!disableEditing" style="margin-top: 3px">
      <a class="text-size" @click="emit('editItemTags')">Add/Remove Tags</a>
    </p>
  </div>
</template>

<script setup>
defineProps(['itemTags', 'disableEditing']);
const emit = defineEmits(['editItemTags']);
</script>

<style lang="less" scoped>
.tags-list {
  display: inline-block;
  background-color: lightgrey;
  padding: 4px 15px;
  text-align: left;
}
</style>
