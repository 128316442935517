<template>
  <div class="column" :class="isNewOrder ? 'is-6' : 'is-5'">
    <ItemSearchInput :searchForActiveItems="true" />
    <ItemListEdit :items="selectedItems">
      <template v-if="checkCurrentClientFeature('VUE_APP_LOT_NO_UI_CLIENTS')" #item-slot="{ item }">
        <OrderEditItemLotNos v-if="item.ItemType != 'Bundled'" :item="item" />
      </template>
    </ItemListEdit>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ItemSearchInput from './../Common/ItemSearchInput';
import ItemListEdit from './../Common/ItemListEdit';
import OrderEditItemLotNos from './OrderEditItemLotNos.vue';
import { checkCurrentClientFeature } from '../../composables/client_config.js';

defineProps(['isNewOrder']);

const selectedItems = computed(() => useStore().getters['selectedItems']);
</script>
