import { newProduct } from './index';

export const actions = {
  onSetInventoryTags: ({commit}, t) => commit('setInventoryTags', t),
  onSetItemGroups: ({commit}, g) => commit('setItemGroups', g),
  onChangeSelectedProduct: ({commit}, prod) => commit('changeSelectedProduct', prod),
  onSetItemFile: ({commit}, data) => commit('setItemFile', data),
  onProductDataChange: ({commit}, data) => commit('changeNewProductData', data),
  onProductSelect: ({commit}, p) => commit('setProduct', p),
  onDimensionSelect: ({commit}, d) => commit('setDimension', d),
  onResetNewProduct: ( {dispatch}) => {
    const d = {
      Length: 0,
      Width: 0,
      Height: 0,
      Weight: 0
    };
    dispatch('onProductSelect', newProduct());
    dispatch('onDimensionSelect', d);
  },
  onProdDimChange: ({commit}, {d, prop, val}) => {
    const changedDims = {...d};
    changedDims[prop] = val;
    commit('setDimension', changedDims);
  },
  onResetInventoryTags: ({commit}) => commit('resetInventoryTags')
}

