export const actions = {
  onChangeSelectedOrder: ({commit}, order) => {
    commit('changeSelectedOrder', order);
    commit('resetLoadedFiles');
  },
  onSetLoadedFile: ({commit}, fileData) => commit('setLoadedFile', fileData),
  onOrderDataChange: ({commit}, {o, prop, val}) => {
      const changedOrder = {...o};
      changedOrder[prop] = val;
      commit('changeNewOrderData',  changedOrder)
  },
  onDeductOrderDataChange: ({commit}, d) => commit('changeNewDeductOrderData', d),
  onWorkOrderDataChange: ({commit}, d) => commit('changeNewWorkOrderData', d),
  onSetIsOrderValid: ({ commit }, v) => commit('setIsOrderValid', v),
  onReturnDataChange: ({ commit }, {r, prop, val}) => {
    const changedReturn = r;
    changedReturn[prop] = val;
    commit('changeNewReturnData', changedReturn);
  },
  onOrderSelect: ({commit}, o) => commit('setOrder', o),
  onDeductOrderSelect: ({commit}, o) => commit('setDeductOrder', o),
  onWorkOrderSelect: ({commit}, o) => commit('setWorkOrder', o),
  onRecipientSelect: ({commit}, r) => commit('setRecipient', r),
  onRecipientDataChange: ({commit}, {r, prop, val}) => {
    const changedRecipient = {...r};
    changedRecipient[prop] = val;
    commit('setRecipient', changedRecipient);
  },
  onSetDeliveryOptionsAndReturnServiceLevels: async ({commit}, d) =>
    commit('setDeliveryOptionsAndReturnServiceLevels', d),
  onToggleShowReturnModal:({commit}) => commit('toggleShowReturnModal'),
  onToggleReturnsDetailModal: ({commit}) => commit('toggleShowReturnsDetailModal'),
  onResetRecipient: ({commit}) => commit('resetRecipient'),
  onResetNewOrder: ({commit}) => commit('resetNewOrder'),
  onResetNewDeductOrder: ({commit}) => commit('resetNewDeductOrder'),
  onResetNewWorkOrder: ({commit}) => commit('resetNewWorkOrder'),
  onResetNewReturn: ({commit}) => commit('resetNewReturn')
}

