<template>
  <tr class="entity-row"
    :class="{ 'entity-row-hover' : enableRowSelect }"
    @click="selectRow(tableData._id)">
    <td v-if="enableCheckboxSelection" @click.stop="toggleCheckbox">
      <div class="row-item">
        <input type="checkbox" class="checkbox" :checked="checked" :disabled="checkboxDisabled" />
      </div>
    </td>
    <td v-for="(item, index) in tableData.row" :key="index"
      :class="getCellClasses(item)"
      @click="handleCellClick($event, item, tableData)">
      <slot v-if="item.useSlot" v-bind="{ cellData: item, rowData: tableData }" :i="index"></slot>
      <div v-if="item.warehouseCode" class="warehouse-container">
        <div class="warehouse-div" :style="getWarehouseStyle(item.warehouseCode)">
          <span v-for="c in item.warehouseCode" :key="c" style="color: white">{{ c }}</span>
        </div>
      </div>
      <template v-else>
        <p class="row-item">
          <span v-if="item.bulletColor" class="item-bullet" :style="{ color: item.bulletColor }">
            &bull;
          </span>
          <span :class="getTextClass(item)" :style="getTextStyle(item)">
            {{ item.value == 0 ? item.value : item.value || "" }}
          </span>
          <span v-if="item.note" class="note-p" @click.stop="alert(item.note)">
            <i class="note-i fas fa-info-circle"></i>
          </span>
        </p>
        <p v-if="item.subTexts && item.subTexts.length"
          class="subtexts-container"
          :style="getTagSubtextsStyle(item.subTexts)">
          <span v-for="(subText, i) in item.subTexts"
            :key="`${i}_${subText.text}`"
            :class="getTextClass(subText, true)"
            :style="getTextStyle(subText)">
            {{ subText.text }}
          </span>
        </p>
      </template>
    </td>
  </tr>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ui from './../../lib/ui';

  export default {
    name: 'EntityRow',
    props: [ 'tableData', 'enableRowSelection', 'enableCheckboxSelection', 'checkboxDisabled' ],
    emits: ['selectThis', 'checkboxToggled'],
    data() {
      return {
        checked: false
      }
    },
    inject: ['alert'],
    computed:{
      ...mapGetters(['isScreenSizeLessThanEqualTo1460']),
      enableRowSelect() {
        return this.enableRowSelection || this.tableData._enableRowSelection;
      }
    },
    methods:{
      getRowHoverAttrs() {
        return this.enableRowSelect
          ? { class: 'entity-row-hover', style: 'cursor: pointer' }
          : null;
      },
      getCellClasses(data) {
        const classes = [];

        if(data.warehouseCode)
          classes.push('warehouse-cell');
        if(data.classes)
          classes.push(...data.classes);

        return classes;
      },
      getWarehouseStyle(wh) {
        return { background: ui.getWarehouseColor(wh) };
      },
      getTextClass(textInfo, noResize) {
        const tagClasses = textInfo.tagClass || textInfo.tagColor
          ? `tag ${textInfo.tagClass} ${!noResize && !this.isScreenSizeLessThanEqualTo1460 ? 'is-medium' : ''}`
          : '';
        return [textInfo.class, tagClasses].filter(Boolean).join(' ');
      },
      getTextStyle(textInfo) {
        const style = { ...textInfo.style };
        if(textInfo.tagColor)
          style.background = textInfo.tagColor;
        return style;
      },
      getTagSubtextsStyle(subTexts) {
        return subTexts.some(x => x.tagClass || x.tagColor) ? 'margin-top: 3px' : '';
      },
      selectRow(id) {
        if(this.enableRowSelect)
          this.$emit('selectThis', id);
      },
      handleCellClick(event, cellData, rowData) {
        if(cellData.clickHandler && cellData.clickHandler(cellData, rowData))
          event.stopImmediatePropagation();
      },
      toggleCheckbox() {
        if(this.checkboxDisabled)
          return;
        
        this.checked = !this.checked;
        this.$emit('checkboxToggled', this.checked);
      },
      selectCheckbox(checked) {
        this.checked = checked;
      }
    }
  }
</script>

<style lang="less" scoped>
.entity-row {
  background: white;
  border-bottom: 2px solid var(--theme-gray-bg);
  transition: .15s;
}
.entity-row-hover {
  cursor: pointer;
}
.entity-row-hover:hover {
  transform: scaleX(1.005);
  box-shadow: 0px 2px 4px var(--theme-gray);
}
.row-item {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.item-bullet {
  font-size: 2.2em;
  line-height: .4em;
  position: relative;
  bottom: .05em;
}
td {
  height: 70px;
  vertical-align: middle !important;
}
.subtexts-container {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: .75em;
}
.tag-subtexts-container {
  margin-top: 3px;
}
.tag {
  border-radius: 1em;
}
.note-p {
  padding: 7px;
  padding-left: 4px;
  &:hover .note-i {
    text-shadow: 0px 0px 5px grey;
  }
}
.warehouse-cell {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.warehouse-container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.warehouse-div {
  width: 1.9em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: .9em;
  line-height: .6;
  padding: 12px 0;
}
</style>