<template>
  <Container title="Create Deduct Order">
    <template #actions>
      <DeductOrderCreateActions />
    </template>
    
    <EntityData class="card content-container" gapClass="is-4">
      <template #EntityHeaderInfo>
        <DeductOrderHeaderInfo :isNewOrder="true" />
      </template>
      <template #EntityBasic>
        <DeductOrderBasicEdit :isNewOrder="true" />
      </template>
      <template #EntityItems>
        <DeductOrderEditItems :isNewOrder="true" />
      </template>
    </EntityData>
  </Container>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import DeductOrderCreateActions from './Header/DeductOrderCreateActions.vue'
import DeductOrderBasicEdit from './DeductOrderBasicEdit.vue'
import DeductOrderHeaderInfo from './DeductOrderHeaderInfo.vue'
import DeductOrderEditItems from './DeductOrderEditItems.vue'

const store = useStore();

onMounted(() => window.scrollTo(0, 0));
onUnmounted(() => {
  store.dispatch('order/onResetNewDeductOrder');
  store.dispatch('product/onResetInventoryTags');
  store.dispatch('onEmptySelectedItems');
});
</script>
