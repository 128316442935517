export const mutations = {
  setNewClient: (state, c) => state.newClient = { ...c },
  setNewClientData: (state, {field, value}) => state.newClient[field] = value,
  setNewClientConfig: (state, c) => state.newClientConfig = { ...c },
  setNewClientConfigData: (state, {field, value}) => state.newClientConfig[field] = value,
  setNewClientNotificationSettingsData: (state, {field, value}) => state.newClient.NotificationSettings[field] = value,
  setNewUser: (state, user) => state.newUser = { ...user },
  setNewUserData: (state, {field, value}) => state.newUser[field] = value,
  setNewRecipient: (state, recipient) => state.newRecipient = { ...recipient },
  setNewRecipientData: (state, {field, value}) => state.newRecipient[field] = value,
  setNewWebhook: (state, webhook) => state.newWebhook = { ...webhook },
  setNewWebhookData: (state, {field, value}) => state.newWebhook[field] = value,
  setSelectedClient: (state, client) => state.selectedClient = { ...client },
  setSelectedUser: (state, user) => state.selectedUser = { ...user },
  setSelectedRecipient: (state, recipient) => state.selectedRecipient = { ...recipient },
  setSelectedWebhook: (state, webhook) => state.selectedWebhook = { ...webhook },
  toggleShowAssignServiceLevelsModal: (state) => state.showAssignServiceLevelsModal = !state.showAssignServiceLevelsModal,
  toggleShowAddCustomServiceLevelModal: (state) => state.showAddCustomServiceLevelModal = !state.showAddCustomServiceLevelModal
}
