<template>
  <div
    class="actions-container"
    @mouseleave="onSetMenuHoverEnabled(false)">
    <ComponentHeaderBackButton />
    <slot name="actions" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ComponentHeaderBackButton from './ComponentHeaderBackButton.vue';
export default {
  name: 'ComponentHeaderActions',
  components: { ComponentHeaderBackButton },
  methods: {
    ...mapActions([ 'onSetMenuHoverEnabled' ])
  }
}
</script>

<style lang="less" scoped>
.actions-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: .8vw;
}
</style>