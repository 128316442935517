<template>
   <div class="field custom-section-sty">
     <label v-if="disableEditing" class="label">Recipient</label>
     <template v-else>
      <NewRecipient v-if="showRecipientDetails" 
        @closeRecipientModal="toggleShowRecipient"/>
      <div class="recipient-label-sec">
        <label class="label">Recipient</label>
        <a v-if="!isSelected" 
          @click="toggleShowRecipient"
          style="font-size: .8em">Add New Recipient</a>
        <div v-if="isSelected" class="recipient-actions">
          <i class="fas fa-edit recipient-action-icon" @click="toggleShowRecipient"/>
          <i class="fas fa-times recipient-action-icon" @click="clearFilter"/>
        </div>
      </div>
    </template>
    <div class="control">
      <div style="position: relative">
        <input v-if="!disableEditing"
          class="input is-normal recipient-input-custom-sty"
          type="text"
          placeholder="Search First/Last Name or Company"
          v-model.trim="recipientText"
          :disabled="isSelected"
        />
        <p v-else style="margin-left: 10px">{{ recipientText }}</p>
        <i
          v-if="queryIsActive"
          title="clear filter"
          class="far fa-times-circle clear-filter-button"
          @click="clearFilter()"
        ></i>
      </div>
      <RecipientSearch :search="searchTerm"
        @activeChanged="queryIsActive = $event"
        @recipientSelected="selectRecipient" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import util from "../../lib/util";
import NewRecipient from './../Common/NewRecipient'
import RecipientSearch from './../Common/RecipientSearch'
  export default {
    name: 'Recipient',
    props: ['disableEditing'],
    components: { NewRecipient, RecipientSearch },
     data() {
      return {
        recipientText: '',
        queryIsActive: false,
        showRecipientDetails: false,
      };
    },
    mounted() {
      if(this.isSelected) {
        this.recipientText = util.getRecipientText(this.recipient)
      }
    },
    computed:{
      ...mapGetters('order',['newOrder', 'recipient']),
      searchTerm() {
        return this.isSelected ? null : this.recipientText;
      },
      isSelected(){
        return !util.isRecipientEmpty(this.recipient);
      }
    },
    methods: {
      ...mapActions('order', ['onRecipientSelect', 'onResetRecipient']),
      clearFilter() {
        this.recipientText = '';
        this.onResetRecipient();
      },
      selectRecipient(res) {
        this.recipientText = util.getRecipientText(res);
        this.onRecipientSelect(res);
      },
      toggleShowRecipient(){
        this.showRecipientDetails = !this.showRecipientDetails;
        if(!this.showRecipientDetails) {
          this.recipientText = util.getRecipientText(this.recipient)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.recipient-action-icon{
  cursor: pointer;
}
.recipient-label-sec{
  align-content: center;
  display: flex; 
  justify-content: space-between;
  align-items: center;
}
.filter-item {
  padding: 4px;
  background-color: #f6f6f6;
}
.filter-item-valid:hover {
  background: #ddd;
  cursor: pointer;
}
.component-item-qty-control {
  display: flex;
  align-items: center;
  justify-content: center;
  .qty-input {
    width: 40%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.item-icon {
  font-size: 36px;
  cursor: pointer;
}
.delete-icon {
  padding-right: 15px;
}
.clear-filter-button {
  position: absolute;
  top: 50%;
  cursor: pointer;
  opacity: 70%;
  font-size: 20px;
  margin-top: -9px;
  right: 10px;
}
.recipient-actions{
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  column-gap: 8px;
}

@media screen and (max-width: 1024px){
  .filter-item {
    font-size: 12px !important;
    padding: 4px;
  }
  .custom-section-sty{
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1460px){
  .filter-item {
    font-size: 14px !important;
    padding: 4px;
  }
  .recipient-input-custom-sty{
    font-size: 14px;
  }
}

</style>
