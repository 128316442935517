<template>
  <Container :haveError="haveError" :errorId="errorId">
    <div class="analytics-container">
      <div id="container"></div>
    </div>
  </Container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as pbi from 'powerbi-client';
import api from "./../../lib/api-gateway";
import Container from './../Common/Container.vue';
export default {
  name: 'Analytics',
  components: { Container },
  data() {
    return {
      powerBi: new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory),
      haveError: false,
      errorId: null,
      updateConfigInterval: null,
      tokenExpiration: null,
      retryCount: 0,
      retryTimer: null
    }
  },
  unmounted() {
    clearInterval(this.updateConfigInterval);
  },
  created() {
    if(!this.currentClient.PowerBiReportId)
      this.$router.push({ name: 'Dashboard' });
  },
  async mounted() {
    await this.getConfigAndSetup();

    document.addEventListener("visibilitychange", function() {
      if (!document.hidden) {
        this.checkTokenAndUpdate()
      }
    }.bind(this));
  },
  computed: {
    ...mapGetters(['currentClient'])
  },
  methods: {
    ...mapActions(['onMakingAPICalls']),
    createPowerClient(powerBiConfig){
      var reportContainer = document.getElementById('container');
      var report = this.powerBi.embed(reportContainer, powerBiConfig);

      report.on('error', function() {
        this.haveError = true;

        // Throttle, I've seen duplicate errors come out quickly
        if(this.retryCount < 2 && !this.retryTimer)
          this.retryTimer = setTimeout(() => {
            this.retryTimer = null;
            this.retryCount++;
            this.getConfigAndSetup()
          }, 2000);
      }.bind(this));
      
      report.on('loaded', function() {
        this.retryCount = 0
      }.bind(this));
      
      this.updateConfigInterval = setInterval(this.checkTokenAndUpdate, 300000);
    },
    async getConfigAndSetup(isRefresh){
      if(!isRefresh) {
        clearInterval(this.updateConfigInterval);
        this.onMakingAPICalls('Initializing Reports. Please Wait...');
      }

      this.errorId = null;
      this.haveError = false;
      this.tokenExpiration = null;

      const res = await api.getPowerBiConfig();

      if(!isRefresh)
        this.onMakingAPICalls('');

      if(res.error) {
        this.haveError = true;
        this.errorId = res.errorId;
      } else {
        if(isRefresh) {
          const report = this.powerBi.get(document.getElementById('container'));
          report.setAccessToken(res.result.powerbiConfig.accessToken)
        } else {
          this.createPowerClient({...res.result.powerbiConfig})
        }
        this.tokenExpiration = Date.parse(res.result.tokenExpiration);
      }
    },
    checkTokenAndUpdate() {
      if(this.tokenExpiration) {
        // Update the token if it is about to expire
        const cutoffTime = (this.tokenExpiration - 600000)
        if(Date.now() > cutoffTime)
          this.getConfigAndSetup(true);
      }
    }
  }
}
</script>

<style lang="less">
.analytics-container {
  width:100%;
  height: 860px;
}
#container {
  width: 100%;
  height: 100%;
}
</style>
