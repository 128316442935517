<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Import Orders"
        iconName="fa fa-upload"
        :useButtonStyle="true"
        :isOutlinedButton="true"
        @handler="navigate('OrderImports')" />
      <ComponentHeaderButton
        btnText="Create Order"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="navigate('OrderCreate')" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import { mapActions } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
export default {
  name: 'ArchivedOrderHeaderActions',
  components: { ComponentHeaderActions,  ComponentHeaderButton },
  methods: {
    ...mapActions(['onSetNavigationParams']),
    navigate(name) {
      this.onSetNavigationParams({ returnPath: 'ArchivedOrders' });
      this.$router.push({ name })
    }
  }
}
</script>
