<template>
  <div class="search-container">
    <div class="search-input-container">
      <input
        ref="searchBox"
        class="input search-input"
        placeholder="Enter Your Search"
        @keydown.enter="doSearch" />
      <i v-show="haveSearch" class="fa fa-times clear-search-button" @click="resetSearch" />
    </div>
    <button class="search-button" @click="doSearch">
      <i class="fa fa-search search-button-icon theme-blue" style="font-size: 1.25rem" />
    </button>
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  emits: ['onSearch', 'onSearchReset'],
  data() {
    return {
      haveSearch: false
    }
  },
  methods: {
    select() {
      this.$refs.searchBox.select();
    },
    doSearch() {
      this.haveSearch = !!this.$refs.searchBox.value;
      this.$emit('onSearch', this.$refs.searchBox.value);
    },
    resetSearch() {
      this.$refs.searchBox.value = '';
      this.haveSearch = false;
      this.$emit('onSearchReset');
    }
  }
};
</script>

<style lang="less" scoped>
.search-container {
  white-space: nowrap;
  padding: 2px;
}
.search-input-container {
  display: inline-block;
  position: relative;
}
.search-input {
  width: 250px;
  // Undo Bulma effects
  vertical-align: revert;
}
.clear-search-button {
  position: absolute;
  font-size: 20px;
  padding: 5px;
  right: 5px;
  top: 50%;
  transform: translateY(-15px);
  z-index: 10;
  cursor: pointer;
}
.search-button {
  border: none;
  outline: none;
  background: white;
  margin-left: 12px;
  cursor: pointer;
}
</style>
