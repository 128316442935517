<template>
  <div class="columns" style="display: block">
    <ValidationMessages v-if="isEditable"
      class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="validationMessages"
      :checks="validationChecks" />
    <div v-if="!isNewShipNotice" class="column ship-notice-header">
      <div class="field">
        <label class="label custom-label-style">Warehouse</label>
        <p class="">{{ getCorrectValue(selectedShipNotice.Warehouse.Name) }}</p>
      </div>
      <div class="field">
        <label class="label custom-label-style">Status</label>
        <p class="">{{ getCorrectValue(selectedShipNotice.Status) }}</p>
      </div>
      <div class="field">
        <label class="label custom-label-style">Created On</label>
        <span class="">{{ getDate( selectedShipNotice.CreatedDate ) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import util from './../../../lib/util.js'
  import ValidationMessages from '../../Common/ValidationMessages.vue';
  export default {
    name: 'ShipNoticeHeaderInfo',
    props: ['selectedShipNotice', 'isNewShipNotice', 'isEditable'],
    emits: ['isShipNoticeValid'],
    components: { ValidationMessages },
    watch: {
      isShipNoticeValid: {
        immediate: true,
        handler: function(val) {
          this.$emit("isShipNoticeValid", val)
        }
      }
    },
    computed:{
      ...mapGetters(['selectedItems']),
      ...mapGetters('asn', ['newShipNotice']),
      validationMessages() {
        return [
          `Required fields: ${this.isNewShipNotice ? 'Warehouse, ' : ''} Expected Date`,
          'Expected Date must be in the future',
          'At least 1 item',
          'Package Count and Pallet Count must be greater than 0 if provided'
        ];
      },
      validationChecks() {
        return [
          this.haveShipNoticeRequiredFields,
          this.haveShipNoticeValidDate,
          this.haveShipNoticeItems,
          this.haveValidPackageAndPalletCount
        ];
      },
      haveShipNoticeRequiredFields(){
        return this.newShipNotice.WarehouseCode &&
        this.newShipNotice.ExpectedDate
      },
      haveShipNoticeItems() {
        return util.haveItemsAndQuantities(this.selectedItems)
      },
      haveValidPackageAndPalletCount() {
        return (!this.newShipNotice.PackageCount || this.newShipNotice.PackageCount > 0) &&
          (!this.newShipNotice.PalletCount || this.newShipNotice.PalletCount > 0)
      },
      haveShipNoticeValidDate(){
        return util.isDateInTheFuture(this.newShipNotice.ExpectedDate)
      },
      isShipNoticeValid(){
        return this.validationChecks.filter(x => !x).length == 0;
      },
    },
     methods:{
      getCorrectValue(val){
        return util.getUpperCaseVal(val);
      },
      getDate(date){
        return util.formatAndGetDate(date)
      }
    }
  }
</script>

<style lang="less" scoped>
.custom-label-style{
  margin: 0px !important
}
.ship-notice-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  column-gap: 10px;
}
</style>