<template>
  <div class="columns" style="display: block">
    <ValidationMessages class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="validationMessages"
      :checks="validationChecks" />
    <div v-if="!isNewOrder" class="column order-header">
      <div class="field">
        <label class="label custom-label-style">Created by</label>
        <p class="">{{ getCorrectValue(selectedOrder.OrderBy) }}</p>
      </div>
      <div class="field">
        <label class="label custom-label-style">Warehouse</label>
        <p class="">{{ getCorrectValue(selectedOrder.Warehouse) }}</p>
      </div>
      <div class="field">
        <div>
          <label class="label custom-label-style">Created On</label>
          <span class="">{{ getDate( selectedOrder.CreatedDate ) }}</span>
        </div>
        <div v-if="selectedOrder.ShippedDate">
          <label class="label custom-label-style" style="padding-top: 10px">Shipped Date</label>
          <span class="">{{ getDate( selectedOrder.ShippedDate ) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import util from './../../lib/util.js'
  import ValidationMessages from '../Common/ValidationMessages.vue';
  export default {
    name: 'DeductOrderHeaderInfo',
    props: ["isNewOrder"],
    components: { ValidationMessages },
    methods:{
      ...mapActions('order', ['onSetIsOrderValid']),
      getCorrectValue(val){
        return util.getUpperCaseVal(val);
      },
      getDate(date){
        return util.formatAndGetDate(date)
      }
    },
    watch: {
      isOrderValid: {
        immediate: true,
        handler: function(val) {
          this.onSetIsOrderValid(val);
        }
      }
    },
    computed: {
      ...mapGetters('order', ['newDeductOrder', 'newWorkOrder', 'selectedOrder']),
      ...mapGetters(['selectedItems']),
      validateWorkOrder() {
        return this.isNewOrder || this.newDeductOrder.WorkOrderId;
      },
      validationMessages() {
        return [
            `Required fields: Order Name${this.isNewOrder ? ', Warehouse' : ''}`,
            ...this.validateWorkOrder ? ['Required fields for a Work Order: Completed by Date that is in the future'] : [],
            'At least 1 item'
          ];
      },
      validationChecks() {
        return [
            this.haveRequiredFields,
            ...this.validateWorkOrder ? [this.haveWorkOrderRequiredFields] : [],
            this.haveItems
          ];
      },
      haveRequiredFields() {
        return this.newDeductOrder.Name && (!this.isNewOrder || this.newDeductOrder.WarehouseCode);
      },
      haveWorkOrderRequiredFields() {
        return !(this.newDeductOrder.CreateWorkOrder || this.newDeductOrder.WorkOrderId)
          || util.isDateInTheFuture(this.newWorkOrder.CompletedByDate);
      },
      haveItems() {
        return util.haveItemsAndQuantities(this.selectedItems);
      },
      isOrderValid() {
        return this.validationChecks.every(Boolean);
      }
    }
  }
</script>

<style lang="less" scoped>
  .custom-label-style{
    margin: 0px !important
  }
  .order-header {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    border-bottom: 1px solid #ccc;
  }
</style>