<template>
  <Modal :title="`${(isNewRecipient ? 'Add New' : 'Edit')} Recipient`"
    width="1300px"
    :defaultOkCancelBtnData="buttons"
    @close="doAction">
    <ValidationMessages :messages="validationMessages" :checks="validationChecks" class="validation" />
    <div class="columns bulma-columns-margin-fix">
      <div class="column is-4">
        <div class="field">
          <label class="label">First Name (required if no Company)</label>
          <input class="input is-normal" type="text" v-model="editRecipient.FirstName" />
        </div>
        <div class="field">
          <label class="label">Last Name (required if no Company)</label>
          <input class="input is-normal" type="text" v-model="editRecipient.LastName" />
        </div>
        <div class="field">
          <label class="label">Original Customer Number</label>
          <input class="input is-normal" type="text" v-model="editRecipient.OriginalCustomerNumber" />
        </div>
        <div class="field">
          <label class="label">Company (required if no Name)</label>
          <input class="input is-normal" type="text" v-model="editRecipient.Company" />
        </div>
        <div class="field">
          <label class="label">Email</label>
          <input class="input is-normal" type="email" maxlength="256" v-model="editRecipient.Email" />
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Address 1 *</label>
          <input class="input is-normal" type="text" v-model="editRecipient.Address1" />
        </div>
        <div class="field">
          <label class="label">Address 2</label>
          <input class="input is-normal" type="text" v-model="editRecipient.Address2" />
        </div>
        <div class="field">
          <label class="label">City *</label>
          <input class="input is-normal" type="text" v-model="editRecipient.City" />
        </div>
        <div class="field">
          <label class="label">Zip Code *</label>
          <input class="input is-normal" type="text" maxlength="10" v-model="editRecipient.ZipCode" />
        </div>
        <div class="field">
          <label class="label">Phone *</label>
          <input class="input is-normal" type="text" maxlength="50" v-model="editRecipient.Phone" />
        </div>
      </div>
      <div class="column is-4">
        <CountryAndState
          :selectedCountry="recipient.Country"
          :selectedState="recipient.State"
          :showAsterisk="true"
          style="margin-bottom: 15px"
          @update="countryOrStateChanged" />
        <div class="field">
          <label class="label">Notes</label>
          <textarea class="textarea is-normal" rows="2" v-model="editRecipient.Notes" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import Modal from './Modal.vue'
  import ValidationMessages from '../Common/ValidationMessages.vue';
  import CountryAndState from './CountryAndState.vue';
  export default {
    name: 'NewRecipient',
    emits: ['closeRecipientModal'],
    components: { Modal, ValidationMessages, CountryAndState },
    async created() {
      for(var prop in this.recipient)
        if(this.recipient[prop]) {
          this.isNewRecipient = false
          this.editRecipient[prop] = this.recipient[prop]
        }
    },
    data(){
      return{
        isNewRecipient: true,
        editRecipient: {
          FirstName: '',
          LastName: '',
          Company: '',
          OriginalCustomerNumber: '',
          Email:'',
          Address1: '',
          Address2:'',
          City: '',
          State: '',
          ZipCode: '',
          Phone: '',
          Country: '',
          Notes: ''
        },
        validationMessages: [
          'First Name and Last Name, or Company',
          'Complete address: Address 1, City, Zip Code (5 digits)*, Phone (10 digits)* & Country (* = format for USA)',
          'If Country USA or Canada: State is required'
        ]
      }
    },
    computed:{
      ...mapGetters('order', ['recipient']),
      buttons() {
        return [
            { label: this.isNewRecipient ? 'Add Recipient' : 'Save Changes', disabled: this.validationChecks.some(x => !x) },
            'Cancel'
          ];
      },
      validationChecks() {
        return [
            this.isFirstNameOrCompanyPresent,
            this.isAddressComplete && this.isPhoneTenDigit && this.isZipFiveDigit,
            this.isStatePresentWhenCountryIsUSAOrCA
          ];
      },
      isFirstNameOrCompanyPresent(){
        const recipient = this.editRecipient;
        return (recipient.FirstName && recipient.LastName) || (recipient.Company)
      },
      isAddressComplete(){
        const recipient = this.editRecipient;
        return recipient.Address1 &&  recipient.ZipCode &&  recipient.City &&  recipient.Phone && recipient.Country;
      },
      isPhoneTenDigit(){
        return this.editRecipient.Country != 'US' || /^\d{10}$/.test(this.editRecipient.Phone)
      },
      isZipFiveDigit(){
        return this.editRecipient.Country != 'US' || /^\d{5}$/.test(this.editRecipient.ZipCode)
      },
      isStatePresentWhenCountryIsUSAOrCA(){
        return (this.editRecipient.Country != 'US' && this.editRecipient.Country != 'CA') || this.editRecipient.State
      }
    },
    methods: {
      ...mapActions('order', ['onRecipientSelect']),
      doAction(value) {
        if(value) {
          let r = this.recipient

          for(var prop in this.editRecipient)
            r[prop] = this.editRecipient[prop]

          this.onRecipientSelect(r);
        }

        this.$emit("closeRecipientModal");
      },
      countryOrStateChanged({ field, value }) {
        if(field == 'country')
          this.editRecipient.Country = value;
        else if(field == 'state')
          this.editRecipient.State = value;
      }
    }
  }
</script>

<style lang="less" scoped>
.validation {
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 14px;
}
</style>