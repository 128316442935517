<template>
  <div class="columns">
    <div class="column is-6 assign-list-column">
      <slot name="left-heading" />
      <ClearableTextBox v-if="showFilter" placeholder="Search" v-model:value="filter" style="width: 100%" />
      <div class="custom-list assign-list" :style="{ height }">
        <template v-for="{ val, lbl } in computedOptions" :key="val">
          <div v-if="!modelValue.includes(val) && filterMatches(lbl)"
            class="custom-list-text assign-list-item"
            @click="assignOption(val)">
            <i class="fas fa-plus-circle item-btn-icon" style="color: green" />
            <span>{{ lbl }}</span>
          </div>
        </template>
      </div>
    </div>
    <div class="column is-6 assign-list-column">
      <slot name="right-heading" />
      <div class="custom-list assign-list" :style="{ height }">
        <template v-for="{ val, lbl } in computedOptions" :key="val">
          <div v-if="modelValue.includes(val)"
            class="custom-list-text assign-list-item"
            @click="removeOption(val)">
            <i class="fas fa-times-circle item-btn-icon" style="color: red" />
            <span>{{ lbl }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import util from '../../lib/util';
import ClearableTextBox from './ClearableTextBox.vue';
export default {
  name: 'AssignList',
  props: ['showFilter', 'options', 'modelValue', 'valueField', 'labelField', 'height'],
  emits: ['update:modelValue'],
  components: { ClearableTextBox },
  data() {
    return {
      filter: ''
    }
  },
  computed: {
    filterRegex() {
      return this.filter ? new RegExp(util.escapeRegExp(this.filter), 'i') : null;
    },
    computedOptions() {
      return this.options
        ? this.options.map(x => ({
            val: this.valueField ? x[this.valueField] : x,
            lbl: this.labelField ? x[this.labelField] : x
          }))
        : [];
    }
  },
  methods: {
    filterMatches(lbl) {
      return !this.filterRegex || this.filterRegex.test(lbl);
    },
    assignOption(val) {
      const newValues = [...this.modelValue];
      newValues.push(val);
      this.$emit('update:modelValue', newValues);
    },
    removeOption(val) {
      const newValues = [...this.modelValue];
      newValues.splice(newValues.indexOf(val), 1);
      this.$emit('update:modelValue', newValues);
    }
  }
}
</script>

<style lang="less" scoped>
.assign-list-column {
  display: flex;
  flex-direction: column;
  gap: .5em;
}
.assign-list {
  overflow-y: auto;
  flex-grow: 1;
}
.assign-list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.item-btn-icon {
  font-size: 1.5em;
  padding-right: 8px;
}
</style>
