<template>
  <div>
    <div v-if="!disableEditing" class="file-upload-container">
      <FileUpload
        ref="fileUploadComp"
        :buttonOnly="fileName"
        label="Choose file..."
        :fileType="fileType"
        :maxFileSizeMb="maxFileSizeMb"
        @fileChanged="onFileChanged($event)" />
      <template v-if="fileName">
        <span v-if="newFileName || fileRemoved"
          class="icon icon-btn is-medium"
          @click="resetAttachmentToOriginal()">
          <i class="fas fa-undo-alt" style="font-size: 1.25em"></i>
        </span>
        <button v-else
          class="button red-button"
          style="margin-left: 15px"
          @click="removeExistingAttachment()">
          <i class="icon is-small fas fa-times" style="font-size: 1.25em"></i>
          <span style="font-weight: lighter">Remove</span>
        </button>
      </template>
    </div>
    <FilePreviewField
      :titleOverride="getFileDataForPreview(title, null)"
      :fileName="getFileDataForPreview(fileName, newFileName)"
      :fileData="getFileDataForPreview(fileData, newFileData)"
      :fileLoadFunc="getFileDataForPreview(fileLoadFunc, null)" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import FileUpload from './FileUpload';
import FilePreviewField from './FilePreviewField';

defineProps(['title', 'fileType', 'fileName', 'fileData', 'fileLoadFunc', 'disableEditing', 'maxFileSizeMb']);
const emit = defineEmits(['fileChanged']);

const fileUploadComp = ref(null);
const newFileName = ref(null);
const newFileData = ref(null);
const fileRemoved = ref(false);

function getFileDataForPreview(data, newData) {
  return newFileName.value ? newData
    : !fileRemoved.value ? data
    : null;
}
function resetAttachmentToOriginal() {
  newFileName.value = null;
  newFileData.value = null;
  fileRemoved.value = false;
  fileUploadComp.value.clearAttachment();
  emit('fileChanged', null);
};
function removeExistingAttachment() {
  newFileName.value = null;
  newFileData.value = null;
  fileRemoved.value = true;
  emit('fileChanged', { file: null, delete: true });
};
function onFileChanged(file) {
  newFileName.value = file ? file.name : null;
  newFileData.value = file ? URL.createObjectURL(file) : null;
  fileRemoved.value = false;
  emit('fileChanged', file ? { file, delete: false } : null);
};
</script>

<style lang="less" scoped>
.file-upload-container {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon-btn {
  cursor: pointer;
  min-width: 2.3em !important;
  height: 2.5em !important;
}
</style>
