<template>
  <CollapsiblePanel v-if="workOrder && workOrder._id" class="detail-panel" :heading="`Work Order ${workOrder._id}`">
    <div class="columns" style="padding: .75rem 0">
      <div class="column is-6 detail-column">
        <div class="field">
          <label class="label">Completed By Date</label>
          <div class="entity-value">
            {{ util.formatAndGetDateOnly(workOrder.CompletedByDate) }}
          </div>
        </div>
      </div>
      <div class="column is-6 detail-column">
        <div class="field">
          <label class="label">Shipment Notice ID</label>
          <div v-if="workOrder.ShipmentNoticeId" class="entity-value">
            {{ workOrder.ShipmentNoticeId }}
          </div>
          <div v-else class="entity-value-empty">
            NOT PROVIDED
          </div>
        </div>
      </div>
    </div>
  </CollapsiblePanel>
</template>

<script setup>
import { useStore } from 'vuex';
import util from '../../lib/util';
import CollapsiblePanel from '../Common/CollapsiblePanel.vue';

const workOrder = useStore().getters['order/selectedOrder'].WorkOrder;
</script>

<style lang="less" scoped>
.detail-panel {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 2px;
}
.detail-column {
  display: flex;
  flex-direction: column;
  row-gap: .75rem;
}
.label {
  font-weight: normal;
}
</style>
