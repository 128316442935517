<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        v-if="false"
        btnText="Archived Orders"
        iconName="fas fa-folder"
        :useButtonStyle="true"
        :isOutlinedButton="true"
        @handler="$router.push({ name: 'ArchivedOrders' })" /> <!-- Hide for now -->
      <ComponentHeaderButton
        btnText="Import Orders"
        iconName="fa fa-upload"
        :useButtonStyle="true"
        :isOutlinedButton="true"
        @handler="$router.push({ name: 'OrderImports' })" />
      <ComponentHeaderButton
        btnText="Create Order"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="$router.push({ name: 'OrderCreate' })" />
      <ComponentHeaderButton
        v-if="isUserInternal"
        btnText="Create Deduct Order"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="$router.push({ name: 'DeductOrderCreate' })" />
    </template>
  </ComponentHeaderActions>
</template>

<script setup>
import { useStore } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
const isUserInternal = useStore().getters['isUserInternal'];
</script>
