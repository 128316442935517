
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

const product = { 
  namespaced: true,
  state: {
    inventoryTags: {},
    itemGroups: [],
    selectedProduct: {},
    itemImage: null,
    itemPdf: null,
    dimension: {
      Length: 0,
      Width: 0,
      Height: 0,
      Weight: 0
    },
    newProduct: newProduct()
  },
  mutations: { ...mutations },
  actions: { ...actions }, 
  getters: { ...getters }
}

function newProduct() {
  return {
    StockNumber: "",
    Barcode: "",
    Description: "",
    RequiresSerialNumber: false,
    LotNumberRequired: false,
    HarmonizationCode: "",
    Price: "",
    ExpirationDateRequired: false,
    CycleTime: "",
    Cost: "",
    IsCaseable: false,
    PackUnit: "",
    ItemType: "Standard",
    ReorderPoint: "",
    ReorderQty: "",
    OriginalStockNUmber: ""
  }
}

export { product, newProduct }
