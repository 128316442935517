<template>
  <div class="textbox-container">
    <input ref="searchBox"
      class="input textbox"
      :placeholder="placeholder"
      v-model.trim="text"
      :disabled="disabled"
      @input="$emit('update:value', text)"
      @keydown.enter="$emit('enter', text)" />
    <i v-show="canClear"
      class="far fa-times-circle clear-text-button input-icon-font-size"
      @click="clearText" />
  </div>
</template>

<script>
export default {
  name: 'ClearableTextBox',
  props: [ 'value', 'placeholder', 'haveValue', 'disabled' ],
  emits: ['update:value', 'enter', 'clear'],
  data() {
    return {
      text: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.text = value;
      }
    }
  },
  computed: {
    canClear() {
      // Allow overriding whether to show the clear button.
      return this.haveValue != null ? this.haveValue : this.text;
    }
  },
  methods: {
    clearText() {
      this.text = '';
      this.$emit('update:value', '');
      this.$emit('clear');
    },
    focus() {
      this.$refs.searchBox.focus();
    }
  }
}
</script>

<style lang="less" scoped>
.textbox-container {
  display: inline-flex;
  position: relative;
}
.textbox {
  width: 100%;
  vertical-align: revert; // Undo Bulma
}
.clear-text-button {
  background-color: white;
  position: absolute;
  align-self: center;
  padding: .3em;
  right: .2em;
  cursor: pointer;
}
.textbox[disabled]+.clear-text-button {
  background-color: whitesmoke; // Bulma
}
</style>