<template>
  <div class="columns">
    <div class="column is-6">
      <div v-if="!isNewWebhook" class="field">
        <label class="label">Active</label>
        <div class="control">
          <OnOffSwitch @toggled="webhookDataChanged('IsActive', $event)" :on="newWebhook.IsActive" />
        </div>
      </div>
      <div v-if="isNewWebhook" class="field">
        <label class="label">Event Type</label>
        <div class="control">
          <SelectList
            instructionLabel="-- Select an Event Type --"
            :options="webhookEventTypes"
            :useIndexAsValue="true"
            :selectedValue="newWebhook.WebhookEvent"
            @change="webhookDataChanged('WebhookEvent', $event)" />
        </div>
      </div>
      <div class="field">
        <label class="label">HTTP Method</label>
        <div class="control">
          <SelectList
            instructionLabel="-- Select an HTTP Method --"
            :options="['POST', 'PUT']"
            :selectedValue="newWebhook.HttpMethod"
            @change="webhookDataChanged('HttpMethod', $event)" />
        </div>
      </div>
      <div class="field">
        <label class="label">URL</label>
        <div class="control">
          <input @input="webhookDataChanged('HttpUrl', $event)" class="input" :value="newWebhook.HttpUrl" />
        </div>
      </div>
      <div class="field">
        <label class="label">Notification Email</label>
        <div class="control">
          <input @input="webhookDataChanged('NotificationEmail', $event)" class="input" :value="newWebhook.NotificationEmail" />
        </div>
        <span class="note">
          Defaults to the customer notification email when left empty.
        </span>
      </div>
    </div>
    <div class="column is-6">
      <div class="field">
        <label class="label">HTTP Headers</label>
        <ListEdit
          :items="newWebhook.HttpHeaders"
          :addItemFunc="addHeader"
          :removeItemFunc="removeHeader">
          <template v-slot="{ idx, item }">
            <input @input="setHeader(idx, 'Name', $event)" class="input http-header-name" :value="item.Name" />
            <span class="http-header-separator">:</span>
            <input @input="setHeader(idx, 'Value', $event)" class="input http-header-value" :value="item.Value" />
          </template>
        </ListEdit>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import data from '../../lib/data';
import OnOffSwitch from '../Common/OnOffSwitch.vue';
import SelectList from '../Common/SelectList.vue';
import ListEdit from '../Common/ListEdit.vue';
export default {
  name: 'WebhookBasic',
  components: { OnOffSwitch, SelectList, ListEdit },
  props: ['isNewWebhook'],
  data() {
    return {
      webhookEventTypes: data.webhookEventTypes
    }
  },
  computed: {
    ...mapGetters('manage', ['newWebhook'])
  },
  methods: {
    ...mapActions('manage', ['onSetNewWebhookData']),
    webhookDataChanged(field, event) {
      this.onSetNewWebhookData({ field, value: field == 'IsActive' ? event : event.target.value });
    },
    addHeader() {
      const headers = [...this.newWebhook.HttpHeaders];
      headers.push({ Name: '', Value: '' });
      this.onSetNewWebhookData({ field: 'HttpHeaders', value: headers });
    },
    removeHeader(idx) {
      const headers = [...this.newWebhook.HttpHeaders];
      headers.splice(idx, 1);
      this.onSetNewWebhookData({ field: 'HttpHeaders', value: headers });
    },
    setHeader(idx, field, event) {
      const headers = [...this.newWebhook.HttpHeaders];
      headers[idx][field] = event.target.value;
      this.onSetNewWebhookData({ field: 'HttpHeaders', value: headers });
    }
  }
}
</script>

<style lang="less" scoped>
.note {
  padding-top: 10px;
  padding-left: 10px;
  font-size: .9em;
  font-style: italic;
  font-weight: bold;
}
.http-header-name {
  flex-basis: 150px;
  flex-grow: 1;
  vertical-align: baseline;
}
.http-header-value {
  flex-basis: 300px;
  flex-grow: 3;
  vertical-align: baseline;
}
.http-header-separator {
  margin: 0 10px 0 5px;
  align-self: center;
}
</style>
