<template>
  <ListEdit
    :items="stringValues"
    :addItemFunc="addItem"
    :removeItemFunc="removeItem"
    :disabled="disabled"
    v-slot="{ idx, item }">
    <input class="input"
      style="flex-grow: 1"
      :value="item"
      :disabled="disabled"
      @input="setItem(idx, $event.target.value)" />
  </ListEdit>
</template>

<script setup>
import { ref } from 'vue';
import ListEdit from './ListEdit.vue';

const { array } = defineProps(['array', 'disabled']);

const stringValues = ref(array);

function addItem() {
  stringValues.value.push('');
}
function removeItem(idx) {
  stringValues.value.splice(idx, 1);
}
function setItem(idx, value) {
  stringValues.value[idx] = value;
}
</script>
