<template>
  <Container>
    <template #title>
      <span class="theme-blue">Deduct Order </span>
      <span class="theme-orange">#{{ order.OrderNumber }}</span>
    </template>

    <template #actions>
      <DeductOrderEditActions />
    </template>
    
    <EntityData class="card content-container" gapClass="is-4">
      <template #EntityHeaderInfo>
        <DeductOrderHeaderInfo />
      </template>
      <template #EntityBasic>
        <DeductOrderBasicEdit />
      </template>
      <template #EntityItems>
        <DeductOrderEditItems />
      </template>
      <template #EntityExtra>
        <OrderExtra />
      </template>
    </EntityData>
  </Container>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import DeductOrderEditActions from './Header/DeductOrderEditActions.vue'
import DeductOrderHeaderInfo from './DeductOrderHeaderInfo.vue';
import DeductOrderBasicEdit from './DeductOrderBasicEdit.vue';
import DeductOrderEditItems from './DeductOrderEditItems.vue';
import OrderExtra from './OrderExtra.vue';
import { setupOrderItemsForEdit } from '../../composables/order';

const store = useStore();

const order = store.getters['order/selectedOrder'];

const o = {
    Name: order.Name,
    Notes: order.ShipmentNote,
    WarehouseCode: order.Warehouse,
    WorkOrderId: order.WorkOrder ? order.WorkOrder._id : null
  };

store.dispatch('order/onDeductOrderSelect', o);
setupOrderItemsForEdit(order);

if(order.WorkOrder) {
  const cBD = order.WorkOrder.CompletedByDate;
  const wo = {
      CompletedByDate: cBD ? cBD.split('T')[0] : null,
      ShipmentNoticeId: order.WorkOrder.ShipmentNoticeId
    };
  store.dispatch('order/onWorkOrderSelect', wo);
}

onMounted(() => window.scrollTo(0, 0));
onUnmounted(() => {
    store.dispatch('order/onResetNewDeductOrder');
    store.dispatch('order/onResetNewWorkOrder');
    store.dispatch('product/onResetInventoryTags');
    store.dispatch('onEmptySelectedItems');
  });
</script>
