import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

const resource = { 
  namespaced: true,
  state: {
    selectedImport: {}
  },
  mutations: { ...mutations },
  actions: { ...actions }, 
  getters: { ...getters }
}

export { resource }
