<template>
  <Container title="Recipients"
    :haveError="haveError"
    :errorId="errorId">
    <template #actions>
      <RecipientHeaderActions
        @onSearch="onSearch"
        @onSearchReset="onSearchReset" />
    </template>
    
    <EntityContainer
      ref="container"
      loadingString="Loading Recipients. Please Wait..."
      :headers="headers"
      :apiFunc="getRecipients"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enablePagination="true"
      :enableRowSelection="true"
      @changeRow="recipientSelected" />
  </Container>
</template>

<script>
import api from '../../lib/api-gateway';
import util from '../../lib/util';
import { mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityContainer from './../Common/EntityContainer.vue';
import RecipientHeaderActions from './Header/RecipientHeaderActions.vue';
export default {
  name: 'Recipients',
  components: { Container, EntityContainer, RecipientHeaderActions },
  data() {
    return {
      currentPageRecipients: {},
      headers: [
        'NAME',
        'COMPANY',
        'EMAIL',
        'ADDRESS',
        'PHONE'
      ],
      haveError: false,
      errorId: null,
      searchTerm: null
    }
  },
  activated() {
    this.resetState();
  },
  mounted() {
    this.resetState();
  },
  methods: {
    ...mapActions('manage', ['onSetSelectedRecipient', 'onResetNewRecipient']),
    resetState() {
      this.onResetNewRecipient();
    },
    async refreshResults() {
      return await this.$refs.container.refreshResults();
    },
    async getRecipients(page, pageSize) {
      return await api.getRecipients(this.searchTerm, page, pageSize);
    },
    handleApiResult(res, clients) {
      if(!res.error) {
        this.currentPageRecipients = clients;
      } else {
        this.haveError = true;
        this.errorId = res.errorId;
      }
    },
    prepareTableRow(recipient) {
      return {
        row: [
          { value: [recipient.FirstName, recipient.LastName].filter(Boolean).join(' ') },
          { value: recipient.Company },
          { value: recipient.Email },
          { value: util.getAddress(recipient) },
          { value: recipient.Phone }
        ]
      }
    },
    recipientSelected(id) {
      this.onSetSelectedRecipient(this.currentPageRecipients[id]);
      this.$router.push({ name: 'RecipientDetail' });
    },
    async onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      await this.refreshResults();
    },
    async onSearchReset() {
      this.searchTerm = '';
      await this.refreshResults();
    }
  }
}
</script>
