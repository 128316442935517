<template>
  <tr class="header-row">
    <th v-if="enableCheckboxSelection" @click.stop="emit('checkboxToggled')" class="column-header">
      <input type="checkbox"
        class="checkbox"
        :checked="checkboxChecked"
        :disabled="checkboxDisabled" />
    </th>
    <th v-for="(header, index) in headers" :key="index" class="column-header">
      {{ header }}
    </th>
  </tr>
</template>

<script setup>
defineProps(['headers', 'enableCheckboxSelection', 'checkboxChecked', 'checkboxDisabled']);
const emit = defineEmits(['checkboxToggled']);
</script>

<style lang="less" scoped>
.header-row {
  border-bottom: 3px solid var(--theme-gray-bg);
  font-size: calc(1em + 1px);
}
.column-header {
  color: #222;
  vertical-align: middle;
  padding-top: 1.1em;
  padding-bottom: .9em;
  font-weight: bold;
  letter-spacing: 0.5px;
}
</style>