<template>
  <div class="column is-12">
    <div @click="onToggleDLSection" class="deliveryLogs-header">
        <h3 class="dl-header-heading">Delivery Logs</h3>
        <i :class="[showDeliveryLogs ? 'fas fa-caret-up' : 'fas fa-caret-down']"
        ></i>
    </div>
    <div v-show="showDeliveryLogs" class="dl-section" >
      
      <div  style="width: 100%;" v-for="d in deliveryLogs" :key="d.DeliveryLogId" class="message blue-message">
        <div class="message-header">
          <p>{{d.WarehouseId}}_{{d.DeliveryLogId}}</p>
          <p>Status: {{ d.StatusString }}</p>
        </div>
        <div  class="message-body">
          <div>
            <span style="font-size: 14px;color: #777">Delivered On</span>
            <p style="font-size: 18px">{{ getDate(d.DeliveryDate) }}</p>
          </div>
          <div>
            <span style="font-size: 14px;color: #777">Skids Delivered</span>
            <p style="font-size: 18px">{{ d.TotalSkids }}</p>
          </div>
          <div>
            <p style="font-size: 14px;color: #777">Cartons Delivered</p>
            <p style="font-size: 18px">{{ d.TotalCartons }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from './../../../lib/util.js'
  export default {
    name: 'DeliveryLog',
    data(){
      return {
        showDeliveryLogs: false,
      }
    },
    props: ['deliveryLogs'],
    methods:{
        getDate(date){
        return util.formatAndGetDate(date)
      },
      onToggleDLSection(){
        this.showDeliveryLogs = !this.showDeliveryLogs;
      }
    }
  }
</script>

<style lang="less" scoped>
.deliveryLogs-header {
  align-items: center;
  border-bottom: 2px solid #ddd;
  cursor: pointer;
  display: flex; 
  justify-content: space-between;
}
.dl-section{
  margin-top: 8px;
  width:100%;
}
.dl-text-label{
  color: #777;
  font-size: 14px;
}
.dl-text{
  color: #000;
  font-size: 18px;
}
.dl-header-heading{
  font-size: 20px; 
  font-weight: bold
}
</style>