<template>
  <Modal width="1000px" :defaultOkCancelBtnData="['Save Changes', 'Cancel']" @close="doAction">
    <AssignList
      :showFilter="true"
      :options="clientsArr"
      v-model="currentClients"
      valueField="_id"
      labelField="Name"
      height="300px"
      :sortFunc="(a, b) => a.Name.localeCompare(b.Name)">
      <template #left-heading>
        All clients:
      </template>
      <template #right-heading>
        Selected clients:
      </template>
    </AssignList>
  </Modal>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import Modal from '../Common/Modal.vue';
import AssignList from '../Common/AssignList.vue';

const emit = defineEmits(['close']);
const props = defineProps(['selectedClients']);

const clientsArr = [...useStore().getters['clientsList'].values()];
const currentClients = ref([...props.selectedClients || []]);

function doAction(value) {
  emit('close', value ? currentClients.value : null);
}
</script>

<style lang="less" scoped>
.client-selection-header {
  display: inline-block;
  cursor: pointer;
  & * {
    cursor: pointer;
  }
}
.client-selection-container {
  max-width: 1000px;
  padding-top: 0;
  padding-bottom: 15px;
}
</style>