<template>
  <Teleport v-if="domMounted" v-bind="$attrs">
    <slot></slot>
  </Teleport>
</template>

<script setup>
// This is a Teleport that lets the DOM be mounted first so that target components exist.
import { ref, nextTick } from 'vue';
defineOptions({ inheritAttrs: false });
const domMounted = ref(false);
nextTick(() => domMounted.value = true);
</script>
