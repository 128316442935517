<template>
  <div :class="containerClass">
      <WarehouseList 
        v-if="isNewNotice"
        label="Warehouse" 
        @selectWarehouse="setWarehouse"
        :disableEditing="disableEditing"
      />
      <div class="field">
        <label class="label">Expected Date</label>
        <div  class="control">
            <input 
              type="date"
              class="input"
              style="width: 165px"
              @change="onShipNoticeChange($event,'ExpectedDate')"
              :value="localeDate.toString()"
              :disabled="disableEditing"
            />
        </div>
      </div>
      <div class="field">
        <label class="label">Carrier</label>
        <input @input="onShipNoticeChange($event,'Carrier')" class="input" type="text" :value="newShipNotice.Carrier" :disabled="disableEditing" />
      </div>
      <div class="field">
        <label class="label">Shipment ID/Tracking Number</label>
        <input @input="onShipNoticeChange($event,'TrackingNumber')" class="input" type="text" :value="newShipNotice.TrackingNumber" :disabled="disableEditing" />
      </div>
      <div class="field">
        <label class="label">PO Number</label>
        <input @input="onShipNoticeChange($event,'PONumber')" class="input" type="text" :value="newShipNotice.PONumber" :disabled="disableEditing" />
      </div>
      <div class="field">
        <label class="label">Package Count</label>
        <input @input="onShipNoticeChange($event,'PackageCount')" class="input" type="text" :value="newShipNotice.PackageCount" :disabled="disableEditing" />
      </div>
      <div class="field">
        <label class="label">Pallet Count</label>
        <input @input="onShipNoticeChange($event,'PalletCount')" class="input" type="text" :value="newShipNotice.PalletCount" :disabled="disableEditing" />
      </div>
      <div class="field">
        <label class="label">Vendor</label>
        <input @input="onShipNoticeChange($event,'Vendor')" class="input" type="text" :value="newShipNotice.Vendor" :disabled="disableEditing" />
      </div>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from 'vuex';
  import WarehouseList from './../../Common/WarehouseList';
  export default {
    name: 'ShipNoticeBasic',
    props: ['isNewNotice', 'containerClass', 'disableEditing'],
    components: { WarehouseList },
    computed:{
      ...mapGetters('asn', ['newShipNotice', 'selectedShipNotice']),
      localeDate(){
        if(!this.newShipNotice.ExpectedDate)
          return ""
        let date = new Date(this.newShipNotice.ExpectedDate);
        return date.toISOString().split('T')[0];
      }
    },
    methods:{
      ...mapActions('asn', ['onShipNoticeDataChange']),
      onShipNoticeChange(e, prop){
        
        let val = '';
        if(e.target)
          val = e.target.value;
        else
          val = e

        if(prop == 'PackageCount' || prop == 'PalletCount')
          val = val.replace(/[^0-9]/, '');
        
        this.onShipNoticeDataChange({o: this.newShipNotice, prop, val});
      },
      setWarehouse(warehouse){
        this.onShipNoticeChange(warehouse.Code, 'WarehouseCode');
      }

    }
  }
</script>
