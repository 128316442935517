<template>
  <div class="field">
    <label class="label">Type item name or description to search</label>
    <div class="control">
      <div style="position: relative">
        <input
          class="input is-normal"
          type="text"
          placeholder="Enter Stock Number or Description"
          v-model.trim="query"
          @input="searchAndUpdateProductsTimer()"
        />
        <i
          v-if="queryIsActive || showQueryResults"
          title="clear filter"
          class="far fa-times-circle clear-filter-button"
          @click="resetSearch()"
        ></i>
      </div>
      <div style="max-height: 305px; overflow: auto; z-index: 90; padding-bottom: 15px; position: relative" v-if="queryIsActive || showQueryResults">
        <div v-show="queryIsActive" class="cover-div">
          <div style="padding: 20px">
            <i class="fas fa-cog fa-spin" style="font-size: 2.5em"></i>
          </div>
        </div>
        <template v-if="!queriedProducts.length">
          <div v-if="queryIsActive" style="height: 70px"><!-- spacer --></div>
          <div v-else class="input">No results found..</div>
        </template>
        <div
          v-for="(item, index) in queriedProducts"
          :key="index"
          @click="addItem(item)"
          class="filter-item input is-normal"
          style="margin-top:2px; margin-bottom:2px"
        >
          {{ item.StockNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./../../lib/api-gateway";

import {mapGetters, mapActions} from 'vuex';
export default {
  name: "ItemSearchInput",
  props: ['searchForActiveItems', 'excludeKits'],
  data() {
    return {
      query: "",
      state: '', // 'q' = querying, 'r' = showing results
      queryTimer: null,
      queriedProducts: []
    };
  },
  computed:{
    ...mapGetters(['selectedItems']),
    queryIsActive() {
      return this.state == 'q'
    },
    showQueryResults() {
      return this.state == 'r'
    }
  },
  methods: {
    ...mapActions(['onItemSelect']),
    searchAndUpdateProductsTimer(){
      this.clearTimer();
      if(this.query) {
        this.queryTimer = setTimeout(this.searchAndUpdateProducts, 750)
      } else {
        this.state = null;
        this.queriedProducts = [];
      }
    },
    async searchAndUpdateProducts(){
      this.state = 'q'
      this.queriedProducts = []

      const result = await api.queryItems(this.query, this.searchForActiveItems, this.excludeKits);
      
      // Make sure user didn't cancel
      if(!result.error && this.queryTimer) {
        // Skip added items
        outer: for(const item of result.result.items) {
          for(const addedItem of this.selectedItems.values())
            if(addedItem.StockNumber == item.StockNumber)
              continue outer

          this.queriedProducts.push(item);
        }

        this.state = 'r';
      } else {
        this.state = null;
      }
    },
    addItem(item) {
      this.onItemSelect(item);
      this.resetSearch();
    },
    clearTimer() {
      if(this.queryTimer) {
        clearTimeout(this.queryTimer)
        this.queryTimer = null
      }
    },
    resetSearch() {
      this.clearTimer();
      this.state = null;
      this.query = "";
      this.queriedProducts = [];
    }
  },
};
</script>

<style lang="less" scoped>
.filter-item {
  justify-content: space-between;
}
.filter-item:hover {
  background: #ddd;
}
.component-item-qty-control {
  display: flex;
  align-items: center;
  justify-content: center;
  .qty-input {
    width: 40%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.item-icon {
  font-size: 36px;
  cursor: pointer;
}
.delete-icon {
  padding-right: 15px;
}
.clear-filter-button {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  opacity: 70%;
  font-size: 20px;
  margin-top: -9px;
}
.cover-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: white;
  z-index: 100;
}
</style>