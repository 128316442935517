<template>
  <Container :subtitle="util.formatAndGetDate(order.CreatedDate)">
    <template #title>
      <span class="theme-blue">Order </span>
      <span class="theme-orange">#{{ order.OrderNumber }}</span>
    </template>

    <div style="margin: 3em">
      <Tabs style="margin-bottom: 2em"
        :tabs="tabs"
        @onTabChange="selectedOrdersField = $event" />
      <MainOrderDetail v-show="selectedOrdersField == 'OrderDetails'" :order="order" />
      <SubOrderDetail v-show="selectedOrdersField == 'PartialOrders'"
        v-for="order in order.PartialOrders"
        :key="order._id"
        :order="order" />
      <SubOrderDetail v-show="selectedOrdersField == 'BackOrders'"
        v-for="order in order.BackOrders"
        :key="order._id"
        :order="order">
        <template #headingActions>
          <BackOrderEditActions :order="order" />
        </template>
      </SubOrderDetail>
    </div>
  </Container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import util from '../../lib/util';
import Container from './../Common/Container.vue';
import Tabs from '../Common/Tabs.vue';
import BackOrderEditActions from './Header/BackOrderEditActions.vue';
import MainOrderDetail from './MainOrderDetail.vue';
import SubOrderDetail from './SubOrderDetail.vue';

const order = useStore().getters['order/selectedOrder'];
const selectedOrdersField = ref('OrderDetails');
const tabs = getTabs();

onMounted(() => window.scrollTo(0, 0));

function getTabs() {
  const tabs = [{ name: 'Order Details', value: 'OrderDetails' }];
  
  if (order.PartialOrders && order.PartialOrders.length)
    tabs.push({ name: 'Partial Orders', value: 'PartialOrders' });
  if (order.BackOrders && order.BackOrders.length)
    tabs.push({ name: 'Backorders', value: 'BackOrders' });

  return tabs;
}
</script>
