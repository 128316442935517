<template>
  <Container title="Profile">
    <template #actions>
      <UserProfileEditActions :isUserValid="isUserValid" @editPreferredClients="editPreferredClients" />
    </template>

    <PreferredClientsSelection v-if="showPreferredClientsModal" @close="showPreferredClientsModal = false" />
    
    <EntityData v-if="userDataLoaded" class="card content-container">
      <template #EntityHeaderInfo>
        <UserHeaderInfo @isUserValid="isUserValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <UserAccount :enableEditingClient="isUserInternal" class="end-form-section"/>
          <UserBasic />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script setup>
import { ref, inject, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import api from '../../lib/api-gateway';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import UserProfileEditActions from './Header/UserProfileEditActions.vue';
import UserHeaderInfo from '../Manage/UserHeaderInfo.vue';
import UserAccount from '../Manage/UserAccount.vue';
import PreferredClientsSelection from './PreferredClientsSelection.vue';
import UserBasic from '../Manage/UserBasic.vue';
import { makeApiCallGetResponse } from '../../composables/api';

const confirm = inject('confirm');
const store = useStore();

const isUserInternal = store.getters['isUserInternal']
const userDataLoaded = ref(false);
const userDataChanged = ref(false);
const isUserValid = ref(false);
const showPreferredClientsModal = ref(false);

onMounted(loadUserData);
    
async function loadUserData() {
  const res = await makeApiCallGetResponse('Loading Profile. Please Wait...', api.getCurrentUser);
    
  if(res) {
    store.dispatch('manage/onSetSelectedUser', res.result.identity);
    store.dispatch('manage/onSetEditSelectedUser');

    userDataLoaded.value = true;
    watch(store.getters['manage/newUser'], () => userDataChanged.value = true);
  }
}
async function editPreferredClients() {
  if(userDataChanged.value &&
    !await confirm('Making changes to preferred clients will lose unsaved changes in this form. Continue?'))
    return;

  showPreferredClientsModal.value = true;
}
</script>

<style lang="less" scoped>
.end-form-section {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
</style>