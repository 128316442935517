<template>
  <Modal :title="`Force Close Order #${order.OrderNumber}`"
    :width="isDeduct ? '600px' : '800px'"
    :defaultOkCancelBtnData="[{ label: 'Close Order', disabled: !shipDate }, 'Cancel']"
    @close="doAction">
    <div class="columns" style="margin-bottom: .5em">
      <div class="column is-6">
        <div class="field">
          <label class="label">Ship Date (required):</label>
          <div class="control">
            <input type="date" class="input is-normal" style="width: 165px" v-model="shipDate" />
          </div>
        </div>
        <template v-if="!isDeduct">
          <div class="field">
            <label class="label">Carrier:</label>
            <div class="control" style="margin-bottom: 10px">
              <CarrierList v-model:selectedValue="carrierId"
                :allowUnselect="true"
                :showClearBtn="true" />
            </div>
          </div>
          <div class="field">
            <label class="label">Service Level:</label>
            <div class="control">
              <ServiceLevelList v-model:selectedValue="serviceLevel"
                style="max-width: 275px"
                :carrierId="carrierId"
                valueField="name"
                :allowUnselect="true"
                :showClearBtn="true" />
            </div>
          </div>
        </template>
      </div>
      <div v-if="!isDeduct" class="column is-6">
        <div class="field">
          <label class="label">Tracking Numbers:</label>
          <div class="control">
            <StringListEdit :array="trackingNumbers" />
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import Modal from '../Common/Modal.vue';
import CarrierList from '../Common/CarrierList.vue';
import ServiceLevelList from '../Common/ServiceLevelList.vue';
import StringListEdit from '../Common/StringListEdit.vue';
import { forceCloseOrder } from '../../composables/order';

const emit = defineEmits(['close']);
const { order } = defineProps(['order']);

const isDeduct = order.OrderType == 'Deduct';
const carriers = useStore().getters['carriers'];
const shipDate = ref('');
const carrierId = ref('');
const serviceLevel = ref('');
const trackingNumbers = ref([]);

watch(carrierId, () => serviceLevel.value = '');

async function doAction(value) {
  if(!value || await forceCloseOrder(
    order.OrderNumber,
    shipDate.value,
    carrierId.value ? carriers.get(parseInt(carrierId.value)).name : null,
    serviceLevel.value,
    trackingNumbers.value))
    emit('close');
}
</script>

<style lang="less" scoped>
.label {
  font-weight: normal;
}
</style>
