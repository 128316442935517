<template>
  <Container>
    <CardsNavigation :cards="cards" />
  </Container>
</template>

<script setup>
import { useStore } from 'vuex';
import Container from '../Common/Container.vue';
import CardsNavigation from '../Common/CardsNavigation.vue';

const store = useStore();
const isUserInternal = store.getters['isUserInternal'];
const isUserInternalOrOwner = store.getters['isUserInternalOrOwner'];
const { OnboardingFlags: { AllSetup } } = store.getters['currentClient'];

const cards = [
    { label: 'General', iconClass: 'fas fa-cogs', component: 'ClientSettings' },
    { label: 'Profile', iconClass: 'fas fa-address-card', component: 'UserProfile' },
    { label: 'Users', iconClass: 'fas fa-user', component: 'UsersSettings' },
    { label: 'Recipients', iconClass: 'fas fa-user-friends', component: 'Recipients' },
    { label: 'Webhooks', iconClass: 'fas fa-wrench', component: 'Webhooks' },
    { label: 'Service Levels', iconClass: 'fas fa-shipping-fast', component: 'SettingsServiceLevels' },
    { label: 'Onboarding', iconClass: 'fas fa-users-cog', component: 'Onboarding', highlight: AllSetup ? null :'Needs Attention' }]
  .filter(c =>
    (c.label != 'Webhooks' || isUserInternalOrOwner) &&
    (c.label != 'Service Levels' || isUserInternal) &&
    (c.label != 'Onboarding' || isUserInternal));
</script>
