<template>
  <div v-if="tags">
    <SectionEntityList v-if="tags.length" :headers="headers" :tableDataArray="tags">
      <template v-slot="{ tableData, i }">
        <tr v-if="i" style="height: 2em"></tr>
        <tr>
          <td colspan="6" class="wh-header">
            <label>{{ warehouses.get(tableData.warehouseCode).Name }}</label>
          </td>
        </tr>
      </template>
    </SectionEntityList>
    <i v-else>
      No tag data available.
    </i>
  </div>
</template>

<script>
const headers = ['TAG', 'LOCATION', 'LOT NO.', 'EXP. DATE', 'DAMAGED', 'AVL.'];
</script>
<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import api from '../../../lib/api-gateway';
import util from '../../../lib/util';
import { makeApiCallGetResponse } from '../../../composables/api';
import SectionEntityList from '../../Common/SectionEntityList.vue';

const store = useStore();
const warehouses = store.getters['warehouses'];
const tags = ref(null);

onMounted(async () => {
  const res = await makeApiCallGetResponse(
    'Loading item tags...',
    () => api.getItemTags(store.getters['product/selectedProduct'].StockNumber));

  if(res) {
    const loadedTags = [];

    for(const whTags of res.result.tags) {
      loadedTags.push({ isSectionBreak: true, warehouseCode: whTags.WarehouseCode });
      loadedTags.push(...whTags.Tags.map(x => getTagTableRow(x)));
    }

    tags.value = loadedTags;
  }
});

function getTagTableRow(tag) {
  return {
      _id: tag._id,
      row: [
        { value: tag.TagNumber },
        { value: tag.Location },
        { value: tag.LotNumber },
        { value: tag.ExpirationDate ? util.formatAndGetDateOnly(tag.ExpirationDate) : null },
        { value: tag.IsDamaged ? 'YES' : 'NO' },
        { value: tag.AvailableToOrder }
      ]
    };
}
</script>

<style lang="less" scoped>
.wh-header {
  font-size: 16px;
  padding: .5em .75em;
  letter-spacing: .5px;
  font-weight: bold;
}
</style>