<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Shipments' })" />
      <EntityActions v-if="isNoticeInTransitOrCreated"
        :showCancelShipNotice="true" 
        :showUpdateShipNotice="true" 
        :isShipNoticeValid="isShipNoticeValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import { mapGetters } from 'vuex';
import ComponentHeaderActions from '../../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../../Common/EntityActions.vue';
export default {
  name: 'ShipmentEditActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, EntityActions },
  props: [ 'isShipNoticeValid' ],
  computed:{
    ...mapGetters('asn', ['selectedShipNotice']),
    isNoticeInTransitOrCreated() {
      return this.selectedShipNotice.Status == 'In Transit' || this.selectedShipNotice.Status == 'Created'
    }
  }
}
</script>

<style lang="less" scoped>
</style>
