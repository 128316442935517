<template>
  <ClientDetail v-if="loaded" :title="selectedClient.Name">
    <template #actions="{ isClientValid }">
      <ClientSettingsEditActions :isClientValid="isClientValid" />
    </template>
  </ClientDetail>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '../../lib/api-gateway';
import ClientDetail from '../Manage/ClientDetail.vue';
import ClientSettingsEditActions from './Header/ClientSettingsEditActions.vue';
export default {
  name: 'ClientSettings',
  components: { ClientDetail, ClientSettingsEditActions },
  data() {
    return {
      isClientValid: false,
      loaded: false
    }
  },
  async beforeMount() {
    this.onMakingAPICalls('Loading Account Settings. Plase Wait...');
    
    // Refresh client info
    const { error, result, errorId } = await api.getCurrentClient();

    this.onMakingAPICalls();

    if(!error) {
      this.onSetCurrentClient(result.client);
      this.onSetSelectedClient(result.client);
      this.loaded = true;
    } else
      this.onSetPageError(errorId);
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    ...mapGetters('manage', ['selectedClient'])
  },
  methods: {
    ...mapActions(['onMakingAPICalls', 'onSetPageError', 'onSetCurrentClient']),
    ...mapActions('manage', ['onSetSelectedClient'])
  }
}
</script>
