<template>
  <Container title="Item Groups">
    <template #actions>
      <slot name="actions">
        <ItemGroupActions />
      </slot>
    </template>

    <MaxWidthContainer class="card content-container">
      <div class="columns is-variable is-4">
        <div class="column is-4 item-groups-column">
          <label class="label">Current Groups:</label>
          <SelectList
            :options="itemGroups"
            labelField="GroupName"
            size="15"
            @change="selectGroup($event.target.value)" />
        </div>
        <div class="column is-8 item-groups-column">
          <label class="label">Add New Group:</label>
          <div class="add-service-level" style="margin-bottom: 10px; border-bottom: 1px #ddd solid; padding-bottom: 20px">
            <input class="input"
              style="flex-grow: 1"
              placeholder="Group name"
              v-model.trim="newGroupName" />
            <button class="button blue-button" @click="addGroup">
              <span>Add</span>
            </button>
          </div>
          <label class="label">Update Group:</label>
          <div class="add-service-level">
            <input class="input"
              style="flex-grow: 1"
              placeholder="Select a group"
              v-model.trim="selectedGroupName"
              :disabled="!selectedGroupId" />
            <button class="button blue-button" :disabled="!selectedGroupName" @click="updateGroup">
              <span>Update</span>
            </button>
          </div>
        </div>
      </div>
    </MaxWidthContainer>
  </Container>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import api from '../../lib/api-gateway';
import { makeApiCall } from '../../composables/api';
import Container from './../Common/Container.vue';
import MaxWidthContainer from './../Common/MaxWidthContainer.vue';
import SelectList from './../Common/SelectList.vue';
import ItemGroupActions from './Header/ItemGroupActions.vue';

export default {
  name: 'ItemGroups',
  components: { Container, MaxWidthContainer, SelectList, ItemGroupActions },
  setup() {
    const store = useStore();

    const newGroupName = ref('');
    const selectedGroupId = ref(null);
    const selectedGroupName = ref('');

    function selectGroup(group) {
      group = JSON.parse(group);
      selectedGroupId.value = group._id;
      selectedGroupName.value = group.GroupName;
    };

    async function addGroup() {
      return await makeApiCall(
        'Creating item group...',
        'Item group created successfully.',
        async () => await api.createItemGroup(newGroupName.value)
      );
    }

    async function updateGroup() {
      return await makeApiCall(
        'Updating item group...',
        'Item group updated successfully.',
        async () => await api.updateItemGroup(selectedGroupId.value, selectedGroupName.value)
      )
    }

    return {
      itemGroups: computed(() => store.getters['product/itemGroups']),
      newGroupName,
      selectedGroupId,
      selectedGroupName,
      selectGroup,
      addGroup,
      updateGroup
    }
  }
}
</script>

<style lang="less" scoped>
.item-groups-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  & .label {
    margin-left: 6px;
  }
}
.add-service-level {
  display: flex;
  gap: 10px;
}
</style>
