import store from '../store';
import api from '../lib/api-gateway';
import { makeApiCallGetResponse } from './api'

export async function loadItemFile(prop, fileName, hideOverlay) {
  const res = hideOverlay
    ? await api.getItemsFile(fileName)
    : await makeApiCallGetResponse(
      'Loading file...',
      () => api.getItemsFile(fileName));
  
  if(res)
    store.dispatch('product/onSetItemFile', { file: prop, data: res.File });
}

export async function loadItemTagInventory(stockNumber, warehouse) {
  const itemTags = store.getters['product/inventoryTags'][stockNumber];

  if(itemTags && itemTags[warehouse])
    return true;

  const res = await makeApiCallGetResponse(
      'Loading inventory...',
      () => api.getItemTagsInWarehouse(stockNumber, warehouse));

  if(res && res.result.tags && res.result.tags.length) {
    store.dispatch('product/onSetInventoryTags', { stockNumber, warehouse, tags: res.result.tags });
    return true;
  }
}
