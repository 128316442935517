<template>
  <router-view />
</template>

<script>

export default {
  name: "BETTERFULFILLIFY",
};
</script>

<style lang="less">
body {
  margin: 0px;
  padding: 0px;
  width: 100% !important;
}
</style>
