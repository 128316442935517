<template>
  <Container :haveError="haveError" :errorId="errorId">
    <div v-if="dataLoadedSuccessfully" class="dashboard-items">
      <div class="dashboard-row1">
        <div class="dashboard-section border-right border-bottom">
          <h3 class="section-header-text theme-blue">
            Last two months we closed/shipped
            <span class="theme-blue" style="font-size: 40px">{{ lastTwoMonthsOrderCount }}</span>
            orders
          </h3>

          <div class="chart-container">
            <BarChartComponent
              chart-type="ColumnChart"
              :ordersStat="selectedOrderData"
              :height="300"
              :width="650"
              @onOrderDataChange="onOrderDataChange"
            />
          </div>
        </div>
        <div class="dashboard-section border-bottom">
          <h3 class="section-header-text theme-blue">Order Shipped by Warehouse</h3>
          <div class="chart-container">
            <LineChartComponent
              chart-type="LineChart"
              :ordersStat="selectedWarehouseData"
              :height="400"
              :width="650"
              @onWarehouseDataChange="onWarehouseDataChange"
            />
          </div>
        </div>
      </div>
      <div class="dashboard-row2">
        <div class="product-section border-right">
          <h3 class="section-header-text theme-blue">Top Shipped Products</h3>
          <div class="chart-container-product">
            <ColumnChartComponent
              chart-type="BarChart"
              :ordersStat="selectedProductData"
              :height="400"
              :width="600"
              @onProductDataChange="onProductDataChange"
            />
          </div>

        </div>
        <OrderStats 
          :statData="statData" 
          class="section-padding-left border-right"
        />
        <BackOrderItems 
          :productBackOrderData="productBackOrderData" 
          class="section-padding-left"
        />
      </div>
    </div>
  </Container>
</template>

<script>
import { mapActions } from 'vuex'
import BarChartComponent from "./../Charts/BarChartComponent";
import ColumnChartComponent from "./../Charts/ColumnChartComponent";
import LineChartComponent from "./../Charts/LineChartComponent";
import OrderStats from "./OrderStats";
import BackOrderItems from "./BackOrderItems";
import Container from './../Common/Container.vue';

import api from "./../../lib/api-gateway";

export default {
  name: "DashboardContainer",
  components: {
    BarChartComponent,
    ColumnChartComponent,
    LineChartComponent,
    OrderStats,
    BackOrderItems,
    Container
   },
  data() {
    return {
      orderData: {},
      orderDataSelected: "",
      warehouseData: {},
      warehouseDataSelected: "",
      productData: {},
      productDataSelected: "",
      productBackOrderData: {},
      statData: {},
      dataLoadedSuccessfully: false,
      haveError: false,
      errorId: null
    };
  },
  methods: {
    ...mapActions(['onMakingAPICalls']),
    onOrderDataChange(value) {
      this.orderDataSelected = value;
    },
    onWarehouseDataChange(value) {
      this.warehouseDataSelected = value;
    },
    onProductDataChange(value) {
      this.productDataSelected = value;
    },
  },
  async beforeMount() {
    this.onMakingAPICalls('Loading Account...');

    const res = await api.getDashboardData();

    this.onMakingAPICalls('');

    if(!res.error){
        const {
        OrderData,
        ProductBackOrderData,
        ProductData,
        StatData,
        WarehouseData,
        lastTwoMonthsOrderCount
      } = res.result;
      this.orderData = OrderData;
      this.orderDataSelected = "thisWeek";
      this.warehouseData = WarehouseData;
      this.warehouseDataSelected = "thisWeek";
      this.productData = ProductData;
      this.productDataSelected = "lastWeek";
      this.statData = StatData;
      this.productBackOrderData = ProductBackOrderData;
      this.dataLoadedSuccessfully = true
      this.lastTwoMonthsOrderCount = lastTwoMonthsOrderCount;
    }else {
      this.haveError = true;
      this.errorId = res.errorId;
    }
    
  },
  computed: {
    selectedOrderData() {
      return this.orderData[this.orderDataSelected];
    },
    selectedWarehouseData() {
      return this.warehouseData[this.warehouseDataSelected];
    },
    selectedProductData() {
      return this.productData[this.productDataSelected];
    },
  },
};
</script>

<style lang="less" scoped>

.dashboard-container{
  box-sizing: border-box;
  padding-left: 2.8em;
  position:relative;
  width: 100%;
}
.dashboard-items {
  width: 100%;
  padding: 8px;
}
.section-padding-left {
  padding-left: 15px;
}
.section-header-text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 8px;
  font-weight: 400;
}
.section-sub-header {
  font-size: 20px;
  font-weight: bold;
  color: #222;
}
.chart-container {
  display: flex;
  flex-direction: row;
  max-width: 98%;
  overflow: auto;
  padding-bottom: 20px;
}
th,
td {
  height: 60px;
  vertical-align: middle;
}

.dashboard-section{
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 2.5px 5px 10px #888888;
  width: 48%;
}
.dashboard-row1{
  width:100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center; 
  padding-left: 20px;
  padding-right: 60px;
  
}
.dashboard-row2{
  width:100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 5%;
  padding-left: 20px;
  padding-right: 40px;
}
.product-section{
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 2.5px 5px 10px #888888;
}
@media screen and (min-width: 881px)
  and (max-width: 1400px){
    .dashboard-row2{
      padding-left: 5%;
      padding-right: 8%;
    }
    .product-section{
      text-align: center;
    }
    
  }
@media screen and (min-width: 561px)
  and (max-width: 880px){
    .dashboard-container{
      position:relative;
      width: 100%;
      margin-left: 0px;
      padding-left: 0;
    }
    .dashboard-row1{
      padding-right: 10px;
      padding-left: 10px;
      display: block;
    }
    .dashboard-section{
      background: white;
      border: 1px solid #eee;;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      width: 100%;
      margin-bottom: 24px;
    }
    .dashboard-row2{
      padding-left: 20px;
      padding-right: 20px;
    }
    .product-section{
      margin-top: 28px;
      margin-bottom: 28px;
       overflow: auto;
    }
  }
  @media screen and (min-width: 270px)
  and (max-width: 560px){
    .dashboard-container{
      position:relative;
      width: 100%;
      margin-left: 0px;
      padding: 0;
    }
    .dashboard-row1{
      padding-right: 10px;
      padding-left: 10px;
      display: block;
    }
    .dashboard-section{
      background: white;
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      width: 100%;
      margin-bottom: 24px;
    }
    .dashboard-row2{
      padding-left: 10px;
      padding-right: 10px;
    }
    .product-section{
      margin-top: 28px;
      margin-bottom: 28px;
       max-width: 100%;
      overflow: auto;
      padding-bottom: 24px;
    }
    .chart-container {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      overflow: auto;
      padding-bottom: 24px;
    }
    .section-header-text {
      font-size: 18px;
      
    }
  }


</style>
