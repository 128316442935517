<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Upload File"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="$emit('uploadFile')" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
export default {
  name: 'OrderImportActions',
  components: { ComponentHeaderActions, ComponentHeaderButton },
  emits: ['uploadFile']
}
</script>
