<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Settings' })" />
      <ComponentHeaderWithMoreActions
        btnText="Search"
        iconName="fas fa-search"
        @expanded="$refs.searchBox.select()">
        <template #options>
          <SearchBox
            ref="searchBox"
            @onSearch="$emit('onSearch', $event)"
            @onSearchReset="$emit('onSearchReset')" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderWithMoreActions
        btnText="Filter"
        iconName="fas fa-filter">
        <template #options>
          <RoleFilter @change="$emit('filterChanged', { field: 'role', value: $event.target.value })" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderButton
        v-if="isUserInternalOrOwner"
        btnText="Add User"
        :useButtonStyle="true"
        iconName="fa fa-plus"
        @handler="$router.push({ name: 'UserCreateSettings' })" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import { mapGetters } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderWithMoreActions from '../../Common/ComponentHeaderWithMoreActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import SearchBox from '../../Common/Search/SearchBox.vue';
import RoleFilter from '../../Manage/Header/RoleFilter.vue';
export default {
  name: 'UserHeaderActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, ComponentHeaderWithMoreActions, SearchBox, RoleFilter },
  emits: ['onSearch', 'onSearchReset', 'filterChanged'],
  computed: {
    ...mapGetters(['isUserInternalOrOwner'])
  }
}
</script>
