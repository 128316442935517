<template>
  <div>
    <p>Requirements will turn green when they are met:</p>
    <slot />
    <ul style="margin: 10px 0" class="validation-ul">
      <li v-for="(message, idx) in messages" :key="idx" :class="getStatusClass(topChecks[idx])">
        <i class="icon is-small fa"
          :class="topChecks[idx] ? 'fa-check-circle theme-success' : 'fa-times-circle theme-error'"
          style="margin-right: 6px" />
        {{ Array.isArray(message) ? message[0] : message }}
        <ul v-if="Array.isArray(message)">
          <li v-for="(subMessage, subIdx) in message.slice(1)"
            :key="`${idx}_${subIdx}`"
            :class="getStatusClass(checks[idx][subIdx])">
            {{ subMessage }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps(['messages', 'checks']);
const topChecks = computed(() => props.checks.map(c => Array.isArray(c) ? !c.filter(sc => !sc).length : c));

function getStatusClass(success) {
  return success ? 'theme-success' : 'theme-error';
}
</script>

<style lang="less" scoped>
.validation-ul ul {
  list-style: revert;
  margin-left: 60px;
}
</style>
