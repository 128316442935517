<template>
  <Modal v-if="showing && fileData"
    :title="titleOverride || fileName"
    :fullScreen="true"
    @close="showing = false">
    <div class="preview-container">
      <embed v-if="isPreviewPdf" :src="fileData" type="application/pdf" class="pdf-preview" allowfullscreen />
      <img v-else-if="isPreviewImage" :src="fileData" class="image-preview" />
      <span v-else>
        Cannot preview this type of file.
      </span>
    </div>
  </Modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import Modal from './Modal.vue';
import util from '../../lib/util';

const props = defineProps(['titleOverride', 'fileName', 'fileData', 'fileLoadFunc']);
defineExpose({ show });

const showing = ref(false);
const isPreviewPdf = computed(() => util.checkFileType('pdf', props.fileName));
const isPreviewImage = computed(() => util.checkFileType('image', props.fileName));

async function show() {
  if(!props.fileData && props.fileLoadFunc)
    await props.fileLoadFunc();

  showing.value = true;
}
</script>

<style lang="less" scoped>
.preview-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdf-preview {
  width: 100%;
  height: 100%;
}
.image-preview {
  max-width: 100%;
  max-height: 100%;
}
</style>