<template>
  <div>
    <GChart
      id="chart_div"
      class="chart"
      ref="$chart"
      :type="chartType"
      :data="ordersStat"
      :options="chartOptions"
      @ready="chartReady"
    />
    <svg
      style="width: 0; height: 0; position: absolute"
      aria-hidden="true"
      focusable="false"
    >
      <pattern
        id="pattern-fill"
        x="0"
        y="0"
        width="8"
        height="8"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(30)"
      >
        <rect
          x="0"
          y="0"
          width="4"
          height="8"
          style="stroke: none; fill: #f1efef"
        />
      </pattern>
    </svg>

    <!-- radios -->
    <div class="control">
      <ChartRadioGroup
        class="chart-radio"
        :options="radioOptions"
        initialSelected="thisWeek"
        @onRadioChange="onRadioChange"
      />
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import ChartRadioGroup from "./ChartRadioGroup";
export default {
  name: "bar-chart-component",
  props: {
    chartType: {
      type: String,
      required: true,
    },
    ordersStat: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  emits: ['onOrderDataChange'],
  components: { GChart, ChartRadioGroup },
  data() {
    return {
      chartOptions: {
        title: "Orders Shipped This Week",
        titleTextStyle: {
          color: "#777",
        },
        subtitle: "",
        hAxis: {
          textStyle: { color: "#757575" },
          titleTextStyle: { color: "#757575" },
          minorGridlines: { color: "transparent" },
          format: "MMM yy",
          gridlines: { color: "#f1efef" },
        },
        vAxis: {
          format: "#'%'",
          textStyle: { color: "#757575" },
          titleTextStyle: { color: "#757575" },
          minorGridlines: { color: "transparent" },
          gridlines: { color: "#f1efef" },
          textPosition: "none",
        },
        height: this.height || 300,
        width: this.width || 300,
        bar: { groupWidth: "10%" },
        legend: { position: "none" },
      },
      radioOptions: [
        { value: "thisWeek", text: "This Week" },
        { value: "lastWeek", text: "Last Week" },
        { value: "lastMonth", text: "Last Month" },
        { value: "lastYear", text: "Last Year" },
      ],
      radioValue: "lastWeek"
    };
  },
  methods: {
    onRadioChange(value) {
      this.chartOptions.title = 'Orders Shipped ' + this.radioOptions.filter(x => x.value == value)[0].text;
      this.$emit("onOrderDataChange", value);
    },
    chartReady(chart) {
      var gridLines = chart.getContainer().getElementsByTagName("rect");
      Array.prototype.forEach.call(gridLines, function (line) {
        if (line.getAttribute("fill") === "#f1efef") {
          line.setAttribute("fill", "url(#pattern-fill) #f1efef");
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.chart {
  display: flex;
  min-width: 350px;
  margin-left: -4%;
}
.chart:first-child {
  margin-left: 0px;
}
.chart-radio {
  color: #999;
  margin-left: 40px;
}
@media screen and (min-width: 561px)
  and (max-width: 880px){
  .chart {
    min-width: 200px !important;
    margin-left:-10%;
  }
  .chart:first-child {
    margin-left: 0px;
  }
  .chart-radio {
    color: #999;
    margin-left: 10px;
  }
  }
</style>
