<template>
  <div class="tabs-container entity-heading-small">
    <template v-for="tab in tabs" :key="tab.value">
      <div class="tab-button theme-blue"
        v-bind="getTabClassAndStyle(tab.value)"
        @click="selectTab(tab.value)">
        <slot :tab="tab">
          {{ tab.name }}
        </slot>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const { tabs, initialTab } = defineProps(['tabs', 'initialTab']);
const emit = defineEmits(['onTabChange']);

const selectedTab = ref(initialTab || tabs[0].value);

function selectTab(value) {
  if(selectedTab.value != value) {
    selectedTab.value = value;
    emit('onTabChange', value);
  }
}
function getTabClassAndStyle(tabValue) {
  return tabValue == selectedTab.value
    ? { class: 'theme-gray-bg', style: 'cursor: revert' }
    : null;
}
</script>

<style lang="less" scoped>
.tabs-container {
  background: white;
  display: flex;
  flex-wrap: wrap;
  column-gap: .8em;
  padding: 8px;
  border-radius: 2px;
}
.tab-button {
  font-weight: bold;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 7px;
}
</style>
