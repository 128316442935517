<template>
  <Container :title="title || selectedClient.Name">
    <template #actions>
      <slot name="actions" :isClientValid="isClientValid">
        <ClientEditActions :isClientValid="isClientValid" />
      </slot>
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <ClientHeaderInfo @isClientValid="isClientValid = $event" />
      </template>
      <template #EntityBasic>
        <ClientBasic class="column is-6" />
      </template>
      <template #EntityExtra>
        <ClientExtra class="column is-6" />
      </template>
    </EntityData>
  </Container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import ClientEditActions from './Header/ClientEditActions.vue';
import ClientHeaderInfo from './ClientHeaderInfo.vue';
import ClientBasic from './ClientBasic.vue';
import ClientExtra from './ClientExtra.vue';
export default {
  name: 'ClientDetail',
  components: { Container, EntityData, ClientEditActions, ClientHeaderInfo, ClientBasic, ClientExtra },
  props: ['title'],
  data() {
    return {
      isClientValid: false
    }
  },
  created() {
    const sC = this.selectedClient;
    
    const newClient = {
      Id: sC._id,
      Description: sC.Description,
      AllowOrderRelease: sC.AllowOrderRelease,
      AllowOrderConfirmation: sC.AllowOrderConfirmation,
      AllowOrderSubmission: sC.AllowOrderSubmission,
      AllowOrderApproval: sC.AllowOrderApproval,
      AllowBackOrders: sC.AllowBackOrders,
      NeedOrderApproval: sC.NeedOrderApproval,
      UsePreconfiguredTracking: sC.UsePreconfiguredTracking,
      PreconfiguredTrackingNumber: sC.PreconfiguredTrackingNumber,
      NotificationSettings: {
        DefaultNotificationEmail: sC.NotificationSettings.DefaultNotificationEmail,
        SecondaryNotificationEmails: (sC.NotificationSettings.SecondaryNotificationEmails || '').split(/\[|\]|,| /).filter(Boolean),
        AllowEmailNotification: sC.NotificationSettings.AllowEmailNotification,
        AllowSmsNotification: sC.NotificationSettings.AllowSmsNotification,
        NotifyOnOrderConfirmation: sC.NotificationSettings.NotifyOnOrderConfirmation,
        NotifyOnOrderSubmit: sC.NotificationSettings.NotifyOnOrderSubmit,
        NotifyOnOrderRelease: sC.NotificationSettings.NotifyOnOrderRelease,
        NotifyOnOrderShip: sC.NotificationSettings.NotifyOnOrderShip,
        NotifyOnOrderUpdate: sC.NotificationSettings.NotifyOnOrderUpdate,
        NotifyOnItemCreate: sC.NotificationSettings.NotifyOnItemCreate,
        NotifyOnItemUpdate: sC.NotificationSettings.NotifyOnItemUpdate,
        NotifyOnReturnRequest: sC.NotificationSettings.NotifyOnReturnRequest,
        NotifyOnReturnReceive: sC.NotificationSettings.NotifyOnReturnReceive,
        NotifyOnShipmentNoticeRequest: sC.NotificationSettings.NotifyOnShipmentNoticeRequest,
        NotifyOnShipmentNoticeReceive: sC.NotificationSettings.NotifyOnShipmentNoticeReceive,
        NotifyOnInsufficientInventoryIssue: sC.NotificationSettings.NotifyOnInsufficientInventoryIssue,
        NotifyOnAccountChangeOrUpdate: sC.NotificationSettings.NotifyOnAccountChangeOrUpdate,
        NotifyOnNewUser: sC.NotificationSettings.NotifyOnNewUser,
        NotifyOnBackOrder: sC.NotificationSettings.NotifyOnBackOrder,
        NotifyRecipient: sC.NotificationSettings.NotifyRecipient
      }
    };

    const newClientConfig = {
      Name: sC.Name,
      DefaultCarrier: sC.DefaultCarrier,
      DefaultServiceLevel: sC.DefaultServiceLevel,
      DefaultReleaseWarehouse: sC.DefaultReleaseWarehouse,
      PowerBiReportId: sC.PowerBiReportId,
      SupportsItemGroups: sC.SupportsItemGroups,
      UsesMultipleWarehouses: sC.UsesMultipleWarehouses,
      AllowOrderSplit: sC.AllowOrderSplit,
      AllowWarehouseSwitch: sC.AllowWarehouseSwitch,
      ForceDefaultWarehouse: sC.ForceDefaultWarehouse,
      ForceRequestedWarehouse: sC.ForceRequestedWarehouse,
      ReleasePreference: sC.ReleasePreference,
      ReleaseWarehouses: sC.ReleaseWarehouses || [],
      WaitTimeBeforeSystemStartProcessingOrder: sC.WaitTimeBeforeSystemStartProcessingOrder
    };
    
    this.onSetNewClient(newClient);
    this.onSetNewClientConfig(newClientConfig);
  },
  computed: {
    ...mapGetters('manage', ['selectedClient'])
  },
  methods: {
    ...mapActions('manage', ['onSetNewClient', 'onSetNewClientConfig'])
  }
}
</script>
