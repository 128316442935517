<template>
  <div ref="menu" class="dropdown">
    <div class="dropdown-trigger">
      <button class="button expand-button" @click="toggleMenu">
        <span style="overflow: hidden">{{ label }}</span>
      </button>
    </div>
    <div ref="menuPopup" class="dropdown-menu">
      <div class="dropdown-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
// This is a Bulma drop down menu that is styled to look exactly like a Bulma select.
import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { registerHideOnOutsideClickHandlers, constrainWithinViewportVertically } from '../../composables/ui';

defineProps(['label']);
const emit = defineEmits(['expanded']);

const menu = ref(null);
const menuPopup = ref(null);

registerHideOnOutsideClickHandlers(onMounted, menu, menuPopup, hidePanel);
constrainWithinViewportVertically(onMounted, onBeforeUnmount, menu, menuPopup);

function toggleMenu() {
  menu.value.classList.toggle('is-active');
  emitEvent();
};
function hidePanel() {
  if(menu.value.classList.contains('is-active')) {
    menu.value.classList.remove('is-active');
    emitEvent();
  }
};
function emitEvent() {
  nextTick(() => emit('expanded', menu.value.classList.contains('is-active')));
}

defineExpose({ hidePanel });
</script>

<style lang="less" scoped>
.dropdown-trigger {
  width: 100%;
}
.expand-button {
  width: 100%;
  justify-content: flex-start;

  // Copied from Bulma to get the same down arrow as the selects.
  padding-right: 2.5em;

  &::after {
    border: 3px solid #485fc7;;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
    right: 1.125em;
  }
}
</style>
