<template>
  <div v-if="enableEditingRoleClientOrStatus || enableEditingEmailAndPassword" class="columns">
    <div v-if="enableEditingRoleClientOrStatus"
      class="column"
      :class="enableEditingEmailAndPassword ? 'is-6' : 'is-12'">
      <OptionalColumns :widthClasses="!enableEditingEmailAndPassword ? ['is-6', 'is-6'] : []">
        <div v-if="enableEditingRole" class="field">
          <label class="label">Role</label>
          <div class="control">
            <RoleList
              :isNewUser="isNewUser"
              :selectedValue="newUser.Role"
              @change="userDataChanged('Role', $event.target.value)" />
          </div>
        </div>
        <div v-if="enableEditingClient" class="field">
          <label class="label">Default Client</label>
          <div class="control">
            <ClientsList
              :useLoggedInUser="isNewUser"
              :noPreferredClients="isNewUser"
              :modelValue="newUser.ClientId"
              @change="userClientChanged($event)" />
          </div>
        </div>
        <div v-if="enableActivatingDeactivating" class="field">
          <label class="label">Active</label>
          <div class="control">
            <OnOffSwitch :on="newUser.Active" @toggled="userDataChanged('Active', $event)" />
          </div>
        </div>
      </OptionalColumns>
    </div>
    <div v-if="enableEditingEmailAndPassword" class="column" :class="enableEditingRoleClientOrStatus ? 'is-6' : 'is-12'">
      <OptionalColumns :widthClasses="enableEditingRoleClientOrStatus ? [] : ['is-6', 'is-6']">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input @input="userDataChanged('Email', $event.target.value)" class="input" :value="newUser.Email" />
          </div>
        </div>
        <div class="field">
          <label class="label">Password</label>
          <div class="control" style="margin-bottom: 10px">
            <input @input="userDataChanged('Password', $event.target.value)" type="password" class="input" />
          </div>
          <div class="control">
            <input @input="userDataChanged('ConfirmPassword', $event.target.value)" type="password" class="input" placeholder="Confirm Password" />
          </div>
        </div>
      </OptionalColumns>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import OptionalColumns from '../Common/OptionalColumns.vue';
import OnOffSwitch from '../Common/OnOffSwitch.vue';
import RoleList from '../Manage/RoleList.vue';
import ClientsList from '../Manage/ClientsList.vue';

const props = defineProps([
  'isNewUser',
  'enableEditingRole',
  'enableEditingClient',
  'enableEditingEmailAndPassword',
  'enableActivatingDeactivating']);

const enableEditingRoleClientOrStatus = props.enableEditingRole
  || props.enableEditingClient
  || props.enableActivatingDeactivating;

const store = useStore();
const newUser = computed(() => store.getters['manage/newUser']);

function userDataChanged(field, value) {
  store.dispatch('manage/onSetNewUserData', { field, value });
}
function userClientChanged(event) {
  const client = JSON.parse(event.target.value);
  store.dispatch('manage/onSetNewUserData', { field: 'ClientId', value: client._id });
}
</script>
