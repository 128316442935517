export function setupSelectListEntries(props) {
  // Setup select list entries, handling objects, maps, and arrays.
  //
  // valueField can be used to specify which values the select list produces from the options.
  // If not used, arrays work by their entries unless useIndexAsValue is set. Maps/objects also
  // use their entries as values unless useIndexAsValue is set, but are preselected by key/property.
  // If the values produced by the list are objects, they are converted to JSON.
  const isMap = props.options instanceof Map;
  const isArray = props.options instanceof Array;
  const keys = isMap ? [...props.options.keys()] : Object.keys(props.options);
  
  let i = 0;
  return keys.map(key => {
    const valueObj = isMap ? props.options.get(key) : props.options[key];
    
    const label = props.labelField ? valueObj[props.labelField] : valueObj;
    const value = getValue(valueObj, key);
    const selected = getValueForSelectedCompare(valueObj, key) == props.modelValue;

    return {
        i: i++,
        label,
        value: value instanceof Object ? JSON.stringify(value) : value,
        selected
      };

    function getValue(obj, key) {
      return props.valueField ? obj[props.valueField]
        : props.useIndexAsValue ? key
        : obj;
    }
    function getValueForSelectedCompare(obj, key) {
      return props.valueField ? obj[props.valueField]
        : isArray && !props.useIndexAsValue ? obj
        : key;
    }
  });
}

export function estimateSelectListWidth(entries) {
  return Math.max(...entries.map(x => x.label.trim().length * 10)) + 35; // need space for arrow also
}
