<template>
  <div class="columns">
    <div class="column is-6">
      <div class="field">
        <label class="label">First Name</label>
        <div class="control">
          <input @input="recipientDataChanged('FirstName', $event)" class="input" :value="newRecipient.FirstName" />
        </div>
      </div>
      <div class="field">
        <label class="label">Last Name</label>
        <div class="control">
          <input @input="recipientDataChanged('LastName', $event)" class="input" :value="newRecipient.LastName" />
        </div>
      </div>
      <div class="field">
        <label class="label">Original Customer Number</label>
        <div class="control">
          <input @input="recipientDataChanged('OriginalCustomerNumber', $event)" class="input" :value="newRecipient.OriginalCustomerNumber" />
        </div>
      </div>
      <div class="field">
        <label class="label">Company</label>
        <div class="control">
          <input @input="recipientDataChanged('Company', $event)" class="input" :value="newRecipient.Company" />
        </div>
      </div>
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input @input="recipientDataChanged('Email', $event)" class="input" :value="newRecipient.Email" />
        </div>
      </div>
      <div class="field">
        <label class="label">Phone</label>
        <div class="control">
          <input @input="recipientDataChanged('Phone', $event)" class="input" style="max-width: 250px" :value="newRecipient.Phone" />
        </div>
      </div>
      <div class="field">
        <label class="label">Notes</label>
        <div class="control">
          <textarea @input="recipientDataChanged('Notes', $event)" class="textarea" rows="3" :value="newRecipient.Notes" />
        </div>
      </div>
    </div>
    <div class="column is-6">
      <div class="field">
        <label class="label">Address</label>
        <div class="control" style="margin-bottom: 10px">
          <input @input="recipientDataChanged('Address1', $event)" class="input" :value="newRecipient.Address1" />
        </div>
        <div class="control">
          <input @input="recipientDataChanged('Address2', $event)" class="input" :value="newRecipient.Address2" />
        </div>
      </div>
      <div class="city-zip-container">
        <div class="field city">
          <label class="label">City</label>
          <div class="control">
            <input @input="recipientDataChanged('City', $event)" class="input" :value="newRecipient.City" />
          </div>
        </div>
        <div class="field zip">
          <label class="label">Zip</label>
          <div class="control">
            <input @input="recipientDataChanged('ZipCode', $event)" class="input" :value="newRecipient.ZipCode" />
          </div>
        </div>
      </div>
      <CountryAndState
        @update="recipientCountryOrStateChanged($event)"
        class="field"
        :selectedCountry="newRecipient.Country"
        :selectedState="newRecipient.State" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CountryAndState from '../Common/CountryAndState.vue';
export default {
  name: 'RecipientBasic',
  components: { CountryAndState },
  props: ['isNewRecipient'],
  computed: {
    ...mapGetters('manage', ['newRecipient'])
  },
  methods: {
    ...mapActions('manage', ['onSetNewRecipientData']),
    recipientDataChanged(field, event) {
      this.onSetNewRecipientData({ field, value: event.target.value });
    },
    recipientCountryOrStateChanged(event) {
      const field = event.field == 'country' ? 'Country' : 'State';
      this.onSetNewRecipientData({ field, value: event.value });
    }
  }
}
</script>

<style lang="less" scoped>
.city-zip-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
}
.city {
  flex-grow: 1;
  min-width: 200px;
}
.zip {
  flex-grow: 1;
  min-width: 200px;
  margin-bottom: .75rem;
}
</style>
