<template>
<div class="menu theme-blue-bg">
  <ul @click.stop="void 0">
    <li @click="$emit('onShowHideMenu')" v-for="(item, index) in filteredMenuItems"
          :key="index">
      <router-link :to="{name: item.linkName}" class="menu-item">
        <i :class="item.iconType"></i>
        <span style="width: 80%">{{ item.name }}</span>
      </router-link>
    </li>
    <li @click="$emit('onSignOut')">
      <div class="menu-item">
          <i class="fa fa-sign-out"></i>
          <span style="width: 80%">Sign Out</span>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Menu',
  emits: ['onShowHideMenu', 'onSignOut'],
  data() {
    return {
      menuItems: [
        {
          name: "Dashboard",
          linkName: "Dashboard",
          iconType: "fas fa-home",
        },
        {
          name: "Orders",
          linkName: "Orders",
          iconType: "fas fa-box-open",
        },
        {
          name: "Inventory",
          linkName: "Inventory",
          iconType: "fas fa-pallet"
        },
        {
          name: "Shipments",
          linkName: "Shipments",
          iconType: "fas fa-truck"
        },
        {
          name: "Analytics",
          linkName: "Analytics",
          iconType: "fa fa-chart-pie",
        },
        {
          name: "Manage",
          linkName: "Manage",
          iconType: "fas fa-user-lock"
        },
        {
          name: "Settings",
          linkName: "Settings",
          iconType: "fas fa-cogs",
        },
        {
          name: "Support",
          linkName: "Support",
          iconType: "fas fa-headset",
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['isUserInternal', 'currentClient']),
    filteredMenuItems() {
      return this.menuItems.filter(i =>
        i.name == 'Manage' ? this.isUserInternal :
        i.name == 'Analytics' ? !!this.currentClient.PowerBiReportId :
        true
      );
    }
  }
};
</script>
<style scoped>
.menu{
  color: #fff ;
  right: 0;
  outline: none;
  position: absolute;
  top: 45px;
  z-index: 10;
  width: 100%;
}
ul{
  margin: 0;
  outline:one;
  padding: 0;
  text-align: left;
}
li{
  cursor: pointer;
  list-style: none;
  margin: 12px;
  outline: none;
  padding: 4px;
}
li:hover{
  background: orange;
  border-radius: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
  transform: scale(1);
}
.menu-item{
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: space-around;
  width: 100%;
}
.menu-item * {
  color: #fff;
}
@media screen and (min-width: 561px)
  and (max-width: 880px){
  .menu{
    width: 40%;
    border-radius: 5px;
  }
}
@media screen and (min-width: 881px){
}
</style>