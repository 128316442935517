import { newShipNotice } from './index'

export const actions = {
  onChangeSelectedShipNotice: ({commit}, sN) => commit('changeSelectedShipNotice', sN),
  onShipNoticeSelect: ({commit}, a) => commit('setShipNotice', a),
  onResetNewShipNotice: ({commit}) => commit('setShipNotice', newShipNotice()),
  onShipNoticeDataChange: ({commit}, {o, prop, val}) => {
    const changedShipNotice = {...o};
    changedShipNotice[prop] = val;
    commit('setShipNotice', changedShipNotice);
  }
}