<template>
  <div>
    <div class="field">
      <label class="label">Default Notification Email</label>
      <div class="control">
        <input
          type="email"
          class="input"
          :disabled="!isUserInternalOrOwner"
          :value="notificationSettings.DefaultNotificationEmail"
          @input="updateEmailField('DefaultNotificationEmail', $event.target.value)" />
      </div>
    </div>
    <div class="field">
      <label class="label">Additional Notification Emails</label>
      <ListEdit
        :items="notificationSettings.SecondaryNotificationEmails"
        :addItemFunc="addNewClientSecondaryNotificationEmailSlot"
        :removeItemFunc="removeNewClientSecondaryNotificationEmail"
        :disabled="!isUserInternalOrOwner">
        <template v-slot="{ idx, item }">
          <input class="input"
            style="flex-grow: 1"
            type="email"
            @input="setNewClientSecondaryNotificationEmail(idx, $event)"
            :disabled="!isUserInternalOrOwner"
            :value="item" />
        </template>
      </ListEdit>
    </div>
  </div>
</template>

<script>
// We have this component only because we want to position it
// in different places in ClientBasic.
import { mapGetters, mapActions } from 'vuex';
import ListEdit from '../Common/ListEdit.vue';
export default {
  name: 'NotificationEmails',
  props: ['isNewClient'],
  components: { ListEdit },
  computed: {
    ...mapGetters(['isUserInternalOrOwner']),
    ...mapGetters('manage', ['newClient']),
    notificationSettings() {
      return this.isNewClient ? this.newClient : this.newClient.NotificationSettings;
    }
  },
  methods: {
    ...mapActions('manage', ['onSetNewClientData', 'onSetNewClientNotificationSettingsData']),
    updateEmailField(field, value) {
      if(this.isNewClient)
        this.onSetNewClientData({ field, value });
      else
        this.onSetNewClientNotificationSettingsData({ field, value });
    },
    addNewClientSecondaryNotificationEmailSlot() {
      const secondaryNotificationEmails = [...this.notificationSettings.SecondaryNotificationEmails];
      secondaryNotificationEmails.push('');
      this.updateEmailField('SecondaryNotificationEmails', secondaryNotificationEmails);
    },
    removeNewClientSecondaryNotificationEmail(idx) {
      const secondaryNotificationEmails = [...this.notificationSettings.SecondaryNotificationEmails];
      secondaryNotificationEmails.splice(idx, 1);
      this.updateEmailField('SecondaryNotificationEmails', secondaryNotificationEmails);
    },
    setNewClientSecondaryNotificationEmail(idx, event) {
      const secondaryNotificationEmails = [...this.notificationSettings.SecondaryNotificationEmails];
      secondaryNotificationEmails.splice(idx, 1, event.target.value);
      this.updateEmailField('SecondaryNotificationEmails', secondaryNotificationEmails);
    }
  }
}
</script>

<style lang="less" scoped>
</style>
