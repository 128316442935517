<template>
  <div class="control item-line" v-for="(item, idx) in items" :key="idx">
    <slot :idx="idx" :item="item" />
    <button v-if="!disabled" class="item-btn" @click="removeItemFunc(idx)">
      <i class="fas fa-times-circle item-btn-icon" style="color: red" />
    </button>
  </div>
  <button v-if="!disabled" class="item-btn" @click="addItemFunc()" style="height: 2.5em">
    <i class="fas fa-plus-circle item-btn-icon" style="color: green" />
  </button>
</template>

<script>
export default {
  name: 'ListEdit',
  props: ['items', 'disabled', 'addItemFunc', 'removeItemFunc']
}
</script>

<style lang="less" scoped>
.item-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.item-btn {
  border: none;
  background: none;
  width: 3em;
  cursor: pointer;
}
.item-btn-icon {
  font-size: 1.5em;
}
</style>
