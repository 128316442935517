<template>
  <div>
    <label class="radio chart-radio" v-for="(option, index) in options" :key="index">
      <input
        type="radio"
        :id="index"
        :value="option.value"
        :checked="option.value === value"
        @change="onChangeValue(option.value)"
      />
      {{option.text}}
    </label>
  </div>
</template>

<script>

export default {
  name: "ChartRadioGroup",
  props: ["options", "initialSelected"],
  emits: ['onRadioChange'],
  data(){
    return{
      value: this.initialSelected
    }
  },
  methods: {
    onChangeValue(val){
      this.value = val
      this.$emit('onRadioChange', val)
    }
  }
};
</script>

<style lang="less" scoped>
.chart-radio {
  color: #999; 
  margin-left: 24px;
}
</style>