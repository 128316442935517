<template>
  <div class="columns" style="display: block">
    <ValidationMessages v-if="!disableValidation"
      class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="validationMessages"
      :checks="validationChecks" />
    <div v-if="!isNewUser" class="column" style="border-bottom: 1px solid #ccc">
      <div class="columns">
        <div class="column is-12 existing-user-header">
          <div class="field">
            <label class="label custom-label-style">Email</label>
            <p class="">{{ getCorrectValue(selectedUser.UserEmail) }}</p>
          </div>
          <div v-if="!validateRole" class="field">
            <label class="label custom-label-style">Role</label>
            <p class="">{{ getCorrectValue(selectedUser.Role) }}</p>
          </div>
          <div v-if="this.isAdminSection && !validateClient" class="field">
            <label class="label custom-label-style">Default Client</label>
            <p class="">{{ clientsList.get(selectedUser.ClientId).Name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from './../../lib/util.js'
import ValidationMessages from '../Common/ValidationMessages.vue';
export default {
  name: 'UserHeaderInfo',
  // The validate flags also hide these fields from the header for existing users
  props: ['isNewUser', 'forNewClient', 'validateRole', 'validateClient', 'disableValidation'],
  components: { ValidationMessages },
  emits: ['isUserValid'],
  watch: {
    isUserValid: {
      immediate: true,
      handler: function(val) {
        this.$emit("isUserValid", val)
      }
    }
  },
  computed: {
    ...mapGetters(['clientsList']),
    ...mapGetters('manage', ['selectedUser', 'newUser']),
    isAdminSection() {
      return this.$route.params.isAdminSection;
    },
    validationMessages() {
      let messages = [
        'All fields are required except State/Province if the Country is not US or Canada',
        'If Country is US: Zip must be 5 digits and Phone must be 10 digits',
      ];

      if(this.isNewUser) {
        if(!this.forNewClient)
          messages.push('Email must be valid');
        messages.push('Password and Confirm Password must match');
      }

      return messages;
    },
    validationChecks() {
      let checks = [ this.haveRequiredFields, this.isZipValid && this.isPhoneValid ];

      if(this.isNewUser) {
        if(!this.forNewClient)
          checks.push(this.isEmailValid);
        checks.push(util.trimString(this.newUser.Password) && this.passwordsMatch);
      }

      return checks;
    },
    haveRequiredFields() {
      return (!this.validateClient || this.newUser.ClientId) &&
        (!this.validateRole || this.newUser.Role) &&
        (!this.isNewUser || util.trimString(this.newUser.Email)) &&
        (!this.isNewUser || util.trimString(this.newUser.Password)) &&
        util.trimString(this.newUser.FirstName) &&
        util.trimString(this.newUser.LastName) &&
        util.trimString(this.newUser.Phone) &&
        util.trimString(this.newUser.Address1) &&
        util.trimString(this.newUser.City) &&
        util.trimString(this.newUser.Zip) &&
        util.trimString(this.newUser.Country) &&
        (!(this.newUser.Country == 'US' || this.newUser.Country == 'CA') ||
          util.trimString(this.newUser.State))
    },
    isZipValid() {
      return this.newUser.Country != 'US' || /^\s*\d{5}\s*$/.test(this.newUser.Zip);
    },
    isPhoneValid() {
      return this.newUser.Country != 'US' || /^\s*\d{10}\s*$/.test(this.newUser.Phone);
    },
    isEmailValid() {
      return util.testEmail(this.newUser.Email);
    },
    passwordsMatch() {
      return this.newUser.Password == this.newUser.ConfirmPassword;
    },
    isUserValid() {
      return !this.validationChecks.filter(c => !c).length;
    }
  },
  methods: {
    getCorrectValue(value) {
      return util.getUpperCaseVal(value);
    }
  }
}
</script>

<style lang="less" scoped>
.existing-user-header {
  display: flex;
  justify-content: space-between;
}
.custom-label-style {
  margin: 0px !important
}
</style>