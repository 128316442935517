<template>
  <Modal v-if="showing"
    :hideCloseBtn="true"
    :buttons="buttons"
    width="600px"
    style="z-index: 5000"
    @close="close">
    <div style="text-align: center" class="text-size">
      {{message}}
      <div v-if="isPrompt" style="margin: 15px 0 10px">
        <input ref="prompt"
          class="input is-normal"
          style="width: 300px"
          v-model.trim="promptValue"
          @input="promptValueUpdated"
          @keydown.enter="close(true)" />
      </div>
    </div>
  </Modal>
</template>

<script>
const okButtonData = {
  label: 'Ok',
  classes: 'blue-button',
  style: 'width: 90px',
  value: true
};
const cancelButtonData = {
  label: 'Cancel',
  classes: 'red-button',
  style: 'width: 105px',
  value: false
};

import Modal from '../Common/Modal';
export default {
  name: 'Alert',
  components: { Modal },
  data() {
    return {
      showing: false,
      message: null,
      isPrompt: false,
      promptValue: null,
      buttons: [],
      resolvePromiseFunc: null
    }
  },
  methods: {
    alert(message) {
      this.message = message;
      this.buttons = [ okButtonData ];
      return this.show();
    },
    confirm(message) {
      this.message = message;
      this.buttons = [ okButtonData, cancelButtonData ];
      return this.show();
    },
    prompt(message, initialValue) {
      this.message = message;
      this.isPrompt = true;
      this.buttons = [ { ...okButtonData }, cancelButtonData ];
      this.promptValue = (initialValue || '').trim();
      this.promptValueUpdated();
      
      const promise = this.show();
      this.$nextTick(() => this.$refs.prompt.focus());
      return promise;
    },
    promptValueUpdated() {
      this.buttons[0].disabled = !this.promptValue;
    },
    show() {
      this.showing = true;
      return new Promise(resolve => { this.resolvePromiseFunc = resolve; });
    },
    close(value) {
      if(this.isPrompt) {
        // Prompt does not return true/false.
        if(value) {
          if(!this.promptValue)
            return;
          this.resolvePromiseFunc(this.promptValue);
        } else
          this.resolvePromiseFunc(null);
      } else
        this.resolvePromiseFunc(value);
      
      this.showing = false;
      this.message = null,
      this.isPrompt = false,
      this.promptValue = null;
      this.buttons = [];
      this.resolvePromiseFunc = null;
    }
  }
}
</script>
