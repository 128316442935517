<template>
  <div class="column" :class="isNewOrder ? 'is-5' : 'is-4'">
    <div class="field">
      <label class="label">Order Name</label>
      <div class="control">
        <input class="input" type="text"
          maxlength="80"
          @input="onOrderChanged('Name', $event)"
          :value="newDeductOrder.Name" />
      </div>
    </div>
    <div v-if="isNewOrder" class="field">
      <label class="label">Warehouse</label>
      <div class="control">
        <SelectList
          @change="onWarehouseChanged($event)"
          :options="warehouses"
          valueField="Code"
          labelField="Name"
          instructionLabel="-- Select a Warehouse --"
          :selectedValue="newDeductOrder.WarehouseCode" />
      </div>
    </div>
    <div v-if="isNewOrder || newDeductOrder.WorkOrderId" class="field">
      <label class="label" style="margin-top: 20px">
        <template v-if="isNewOrder">
          <input @change="onCreateWorkOrderChanged" type="checkbox" style="margin-right: 5px" />
          Create Work Order
        </template>
        <template v-else>Work Order {{ newDeductOrder.WorkOrderId }}:</template>
      </label>
    </div>
    <div v-if="editingWorkOrder" class="field">
      <label class="label">
        Completed By Date
      </label>
      <div class="control">
        <input class="input" type="date"
          style="width: 165px"
          @input="onWorkOrderChanged('CompletedByDate', $event)"
          :value="newWorkOrder.CompletedByDate" />
      </div>
    </div>
    <div v-if="editingWorkOrder" class="field">
      <label class="label">
        Shipment Notice ID
      </label>
      <div class="control">
        <input class="input input-no-arrows" type="number"
          maxlength="10"
          style="width: 150px"
          @input="onWorkOrderChanged('ShipmentNoticeId', $event)"
          :value="newWorkOrder.ShipmentNoticeId" />
      </div>
    </div>
    <div class="field">
      <label class="label">Notes</label>
      <div class="control">
        <textarea
          @input="onOrderChanged('Notes', $event)"
          class="textarea"
          :value="newDeductOrder.Notes"
          placeholder="Enter notes"
          rows="3"
          maxlength="256">
        </textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import SelectList from './../Common/SelectList' ;

defineProps(['isNewOrder']);
const store = useStore();
const confirm = inject('confirm');

const warehouses = store.getters['warehouses'];
const newDeductOrder = computed(() => store.getters['order/newDeductOrder']);
const newWorkOrder = computed(() => store.getters['order/newWorkOrder']);
const editingWorkOrder = computed(() => newDeductOrder.value.CreateWorkOrder || newDeductOrder.value.WorkOrderId);
 
async function onWarehouseChanged(event) {
  const currentWh = store.getters['order/newDeductOrder'].WarehouseCode;
  const itemsWithTags = [...store.getters['selectedItems'].values()]
    .filter(x => x.Tags && x.Tags.length)
    .map(x => x.StockNumber);
  
  store.dispatch('order/onDeductOrderDataChange', { prop: 'WarehouseCode', val: event.target.value });

  if(currentWh && itemsWithTags.length) {
    if(!await confirm('All entered tag data for items in this order will be cleared if you change the warehouse. Continue?')) {
      store.dispatch('order/onDeductOrderDataChange', { prop: 'WarehouseCode', val: currentWh });
      return;
    }
    
    itemsWithTags.forEach(x =>
      store.dispatch('onUpdateSelectedItem', { stockNumber: x, item: { Quantity: 1, Tags: null }}));
  }

  store.dispatch('product/onResetInventoryTags');
}
function onOrderChanged(prop, event) {
  store.dispatch('order/onDeductOrderDataChange', { prop, val: event.target.value });
}
function onCreateWorkOrderChanged(event) {
  store.dispatch('order/onDeductOrderDataChange', { prop: 'CreateWorkOrder', val: event.target.checked });
  if(!event.target.checked)
    store.dispatch('order/onResetNewWorkOrder');
}
function onWorkOrderChanged(prop, event) {
  let val = event.target.value;

  if(prop == 'ShipmentNoticeId')
    val = val.replace(/[^0-9]/, '');

  store.dispatch('order/onWorkOrderDataChange', { prop, val });
}
</script>
