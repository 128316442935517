<template>
  <CollapsiblePanel class="detail-panel" heading="Order Details">
    <div class="columns" style="padding: .75rem 0">
      <div class="column is-6 detail-column">
        <div class="field">
          <label class="label">Order Name</label>
          <div class="entity-value">
            {{ order.Name }}
          </div>
        </div>
        <div class="field">
          <label class="label">Warehouse</label>
          <div class="entity-value">
            {{ warehouses.get(order.Warehouse).Name }}
          </div>
        </div>
        <div class="field">
          <label class="label">Created By</label>
          <div class="entity-value">
            {{ order.OrderBy }}
          </div>
        </div>
      </div>
      <div class="column is-6 detail-column">
        <div class="field">
          <label class="label">Current Status</label>
          <div class="entity-value">
            {{ util.addSpacesBeforeCapitals(order.Status) }}
          </div>
        </div>
        <div class="field">
          <label class="label">Current Warehouse Status</label>
          <div class="entity-value">
            {{ util.addSpacesBeforeCapitals(order.WarehouseStatus) }}
          </div>
        </div>
      </div>
    </div>
  </CollapsiblePanel>
</template>

<script setup>
import { useStore } from 'vuex';
import util from '../../lib/util';
import CollapsiblePanel from '../Common/CollapsiblePanel.vue';

const store = useStore();

const order = store.getters['order/selectedOrder'];
const warehouses = store.getters['warehouses'];
</script>

<style lang="less" scoped>
.detail-panel {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 2px;
}
.detail-column {
  display: flex;
  flex-direction: column;
  row-gap: .75rem;
}
.label {
  font-weight: normal;
}
</style>
