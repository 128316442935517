<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Settings' })" />
      <GenerateApiUserToken v-if="isUserInternal" />
      <EntityActions v-if="isUserInternalOrOwner"
        :showUpdateClient="true"
        :isSettingsView="true"
        :isClientValid="isClientValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import { mapGetters } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../Common/EntityActions.vue';
import GenerateApiUserToken from '../../Manage/GenerateApiUserToken.vue';
export default {
  name: 'ClientSettingsEditActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, EntityActions, GenerateApiUserToken },
  props: ['isClientValid'],
  computed: {
    ...mapGetters(['isUserInternal', 'isUserInternalOrOwner'])
  }
}
</script>

<style scoped>

</style>