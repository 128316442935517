<template>
  <div>
    <div class="control">
      <ChartRadioGroup 
        :options="radioOptions"
        initialSelected="lastWeek"
        @onRadioChange="onRadioChange"
      />
    </div>
    <GChart
      id="chart_div"
      ref="$chart"
      :type="chartType"
      :data="ordersStat"
      :options="chartOptions"
      @ready="chartReady"
    />
    <svg
      style="width:0;height:0;position:absolute;"
      aria-hidden="true"
      focusable="false"
    >
      <pattern
        id="pattern-fill"
        x="0"
        y="0"
        width="8"
        height="8"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(30)"
      >
        <rect
          x="0"
          y="0"
          width="4"
          height="8"
          style="stroke: none; fill: #f1efef;"
        />
      </pattern>
    </svg>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import ChartRadioGroup from './ChartRadioGroup'
export default {
  name: "column-chart-component",
  props: {
    chartType: {
      type: String,
      required: true
    },
    ordersStat: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      required: false
    },
    height: {
      type: Number,
      required: false
    }
  },
  emits: ['onProductDataChange'],
  components: { GChart, ChartRadioGroup },
  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartOptions: {
        title: "Top 5 shipped items",
        titleTextStyle: {
          color: "#777"
        },
        subtitle: "",
        hAxis: {
          textStyle: { color: "#757575" },
          titleTextStyle: { color: "#757575" },
          minorGridlines: { color: "transparent" },
          // format: "MMM yy",
          gridlines: { color: "#f1efef" }
        },
        vAxis: {
          format: "#'%'",
          textStyle: { color: "#757575" },
          titleTextStyle: { color: "#757575" },
          minorGridlines: { color: "transparent" },
          gridlines: { color: "#f1efef" },
          
        },
        height: this.height || 300,
        width: this.width || 900,
        bar: { groupWidth: "20%" },
        legend: { position: "none" }
      },
      radioOptions: [
        { value: "lastWeek", text: "Last Week" },
        { value: "lastMonth", text: "Last Month" },
        { value: "lastYear", text: "Last Year" }
      ],
      radioValue: "lastWeek"
    };
  },
  methods:{
    onRadioChange(value){
      this.$emit('onProductDataChange', value)
    },
    chartReady(chart) {
      var gridLines = chart.getContainer().getElementsByTagName("rect");
      Array.prototype.forEach.call(gridLines, function(line) {
        if (line.getAttribute("fill") === "#f1efef") {
          line.setAttribute("fill", "url(#pattern-fill) #f1efef");
        }
      });
    }
  }
};
</script>

<style>
.chart {
  display: flex;
  min-width: 350px;
  margin-left: -4%;
}
.chart:first-child {
  margin-left: 0px;
}
</style>
