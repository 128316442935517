<template>
  <div class="columns" style="display: block">
    <ValidationMessages
      class="column"
      style="border-bottom: 1px solid #ccc"
      :messages="[
        `Required fields: ${isNewWebhook ? 'Event Type, HTTP Method, and ' : ''}URL`,
        'URL must be valid',
        'Notification Email must be valid if provided',
        'HTTP headers must have names and values if provided'
      ]"
      :checks="validationChecks" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from './../../../lib/util.js'
import ValidationMessages from '../../Common/ValidationMessages.vue';
export default {
  name: 'WebhookHeaderInfo',
  components: { ValidationMessages },
  props: ['isNewWebhook'],
  emits: ['isWebhookValid'],
  watch: {
    isWebhookValid: {
      immediate: true,
      handler: function(val) {
        this.$emit("isWebhookValid", val)
      }
    }
  },
  computed: {
    ...mapGetters('manage', ['newWebhook']),
    isWebhookValid() {
      return !this.validationChecks.filter(x => !x).length;
    },
    validationChecks() {
      return [ this.haveRequiredFields, this.urlIsValid, this.emailIsValid, this.headersAreValid ];
    },
    haveRequiredFields() {
      return (!this.isNewWebhook || util.trimString(this.newWebhook.WebhookEvent)) &&
        util.trimString(this.newWebhook.HttpMethod) &&
        util.trimString(this.newWebhook.HttpUrl);
    },
    emailIsValid() {
      return !util.trimString(this.newWebhook.NotificationEmail) || util.testEmail(this.newWebhook.NotificationEmail);
    },
    urlIsValid() {
      return util.testUrl(this.newWebhook.HttpUrl)
    },
    headersAreValid() {
      return !this.newWebhook.HttpHeaders.some(w =>
        util.testEmptyOrWhitespace(w.Name) != util.testEmptyOrWhitespace(w.Value));
    }
  }
}
</script>
