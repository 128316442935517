import _cookie from 'js-cookie';
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import PasswordResetRequest from "../views/PasswordResetRequest.vue";
import PasswordReset from "../views/PasswordReset.vue";
import DashboardContainer from "../components/Dashboard/DashboardContainer.vue";
import OrdersContainer from "../components/Orders/OrdersContainer.vue";
import OrderDetail from "../components/Orders/OrderDetail.vue";
import OrderCreate from "../components/Orders/OrderCreate.vue";
import OrderEdit from "../components/Orders/OrderEdit.vue";
import DeductOrderDetail from "../components/Orders/DeductOrderDetail.vue";
import DeductOrderCreate from "../components/Orders/DeductOrderCreate.vue";
import DeductOrderEdit from "../components/Orders/DeductOrderEdit.vue";
import ArchivedOrdersContainer from "../components/Orders/ArchivedOrdersContainer.vue";
import OrderImports from "../components/Orders/Imports.vue";
import OrderImportDetail from "../components/Orders/ImportDetail.vue";
import AnalyticsContainer from "../components/Analytics/AnalyticsContainer";
import SupportContainer from "../components/Support/SupportContainer"
import Inventory from "../components/Warehouse/Inventory/Inventory.vue";
import ProductCreate from "../components/Warehouse/Inventory/ProductCreate.vue";
import ProductDetail from "../components/Warehouse/Inventory/ProductDetail.vue";
import ItemImports from "../components/Warehouse/Inventory/Imports.vue";
import ItemImportDetail from "../components/Warehouse/Inventory/ImportDetail.vue";
import Shipments from "../components/Warehouse/Shipment/Shipments";
import ShipNoticeCreate from "../components/Warehouse/Shipment/ShipNoticeCreate.vue";
import ShipNoticeDetail from "../components/Warehouse/Shipment/ShipNoticeDetail.vue";
import Manage from "../components/Manage/Manage.vue";
import ManageClients from "../components/Manage/ManageClients.vue";
import ClientCreate from "../components/Manage/ClientCreate.vue";
import ClientDetail from "../components/Manage/ClientDetail.vue";
import ManageUsers from "../components/Manage/ManageUsers.vue";
import UserCreate from "../components/Manage/UserCreate.vue";
import UserDetail from "../components/Manage/UserDetail.vue";
import ManageServiceLevels from "../components/Manage/ServiceLevels";
import ManageItemGroups from "../components/Manage/ItemGroups";
import Settings from "../components/Settings/Settings.vue";
import ClientSettings from "../components/Settings/ClientSettings.vue";
import UsersSettings from "../components/Settings/UsersSettings.vue";
import UserProfile from "../components/Settings/UserProfile.vue";
import UserCreateSettings from "../components/Settings/UserCreate.vue";
import UserDetailSettings from "../components/Settings/UserDetail.vue";
import Recipients from "../components/Settings/Recipients.vue";
import RecipientDetail from "../components/Settings/RecipientDetail.vue";
import RecipientCreate from "../components/Settings/RecipientCreate.vue";
import Webhooks from "../components/Settings/Webhooks.vue";
import WebhookDetail from "../components/Settings/WebhookDetail.vue";
import WebhookCreate from "../components/Settings/WebhookCreate.vue";
import SettingsServiceLevels from "../components/Settings/ServiceLevels.vue";
import Onboarding from '../components/Settings/Onboarding.vue';

import store from '../store';
import api  from "./../lib/api-gateway";

const openRoutes = {
  Login: 'Login',
  PasswordResetRequest: 'PasswordResetRequest',
  PasswordReset: 'PasswordReset'
};

const adminRoutes = {
  DeductOrderDetail: 'DeductOrderDetail',
  DeductOrderCreate: 'DeductOrderCreate',
  DeductOrderEdit: 'DeductOrderEdit',
  Manage: 'Manage',
  ManageClients: 'ManageClients',
  ClientDetailManage: 'ClientDetailManage',
  ClientCreateManage: 'ClientCreateManage',
  ManageUsers: 'ManageUsers',
  UserDetailManage: 'UserDetailManage',
  UserCreateManage: 'UserCreateManage',
  ManageServiceLevels: 'ManageServiceLevels',
  ManageItemGroups: 'ManageItemGroups',
  Onboarding: 'Onboarding'
}

const redirectInitialNavigationRoutes = {
  OrderDetail: 'Orders',
  OrderCreate: 'Orders',
  OrderEdit: 'Orders',
  [adminRoutes.DeductOrderDetail]: 'Orders',
  [adminRoutes.DeductOrderCreate]: 'Orders',
  [adminRoutes.DeductOrderEdit]: 'Orders',
  ArchivedOrderDetail: 'ArchivedOrders',
  OrderImportDetail: 'OrderImports',
  ProductDetail: 'Inventory',
  ProductCreate: 'Inventory',
  ItemImportDetail: 'ItemImports',
  ShipNoticeDetail: 'Shipments',
  ShipNoticeCreate: 'Shipments',
  [adminRoutes.ClientDetailManage]: adminRoutes.ManageClients,
  [adminRoutes.ClientCreateManage]: adminRoutes.ManageClients,
  [adminRoutes.UserDetailManage]: adminRoutes.ManageUsers,
  [adminRoutes.UserCreateManage]: adminRoutes.ManageUsers,
  UserDetailSettings: 'UsersSettings',
  UserCreateSettings: 'UsersSettings',
  RecipientDetail: 'Recipients',
  RecipientCreate: 'Recipients',
  WebhookDetail: 'Webhooks',
  WebhookCreate: 'Webhooks'
}

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: DashboardContainer,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/orders",
        name: "Orders",
        component: OrdersContainer,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/orders/detail",
        name: "OrderDetail",
        component: OrderDetail,
        props: true,
        meta: {
          apiRedirectRoute: "Orders",
          returnPath: 'Orders'
        }
      },
      {
        path: "/orders/create",
        name: "OrderCreate",
        component: OrderCreate,
        props: true,
        meta: {
          apiRedirectRoute: "Orders",
          returnPath: 'Orders'
        }
      },
      {
        path: "/orders/edit",
        name: "OrderEdit",
        component: OrderEdit,
        props: true,
        meta: {
          apiRedirectRoute: 'Orders',
          returnPath: 'Orders'
        }
      },
      {
        path: "/orders/deduct-detail",
        name: "DeductOrderDetail",
        component: DeductOrderDetail,
        props: true,
        meta: {
          apiRedirectRoute: "Orders",
          returnPath: 'Orders'
        }
      },
      {
        path: "/orders/create-deduct",
        name: "DeductOrderCreate",
        component: DeductOrderCreate,
        props: true,
        meta: {
          apiRedirectRoute: "Orders",
          returnPath: 'Orders'
        }
      },
      {
        path: "/orders/edit-deduct",
        name: "DeductOrderEdit",
        component: DeductOrderEdit,
        props: true,
        meta: {
          apiRedirectRoute: "Orders",
          returnPath: 'Orders'
        }
      },
      {
        path: "/orders/archive",
        name: "ArchivedOrders",
        component: ArchivedOrdersContainer,
        props: true,
        meta: {
          showClientSwitcher: true,
          returnPath: 'Orders',
          returnPathLabel: 'Back to Recent Orders'
        }
      },
      {
        path: "/orders/archive/detail",
        name: "ArchivedOrderDetail",
        component: OrderDetail,
        props: true,
        meta: {
          returnPath: 'ArchivedOrders',
          showClientSwitcher: true
        }
      },
      {
        path: "/orders/imports",
        name: "OrderImports",
        component: OrderImports,
        props: true,
        meta: {
          showClientSwitcher: true,
          returnPath: 'Orders'
        }
      },
      {
        path: "/orders/imports/detail",
        name: "OrderImportDetail",
        component: OrderImportDetail,
        props: true,
        meta: {
          apiRedirectRoute: "OrderImports"
        }
      },
      {
        path: "/inventory",
        name: "Inventory",
        component: Inventory,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/inventory/detail",
        name: "ProductDetail",
        component: ProductDetail,
        props: true,
        meta: {
          apiRedirectRoute: "Inventory"
        }
      },
      {
        path: "/inventory/create",
        name: "ProductCreate",
        component: ProductCreate,
        props: true,
        meta: {
          apiRedirectRoute: "Inventory"
        }
      },
      {
        path: "/inventory/imports",
        name: "ItemImports",
        component: ItemImports,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/inventory/imports/detail",
        name: "ItemImportDetail",
        component: ItemImportDetail,
        props: true,
        meta: {
          apiRedirectRoute: "ItemImports"
        }
      },
      {
        path: "/shipments",
        name: "Shipments",
        component: Shipments,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/shipments/detail",
        name: "ShipNoticeDetail",
        component: ShipNoticeDetail,
        props: true,
        meta: {
          apiRedirectRoute: "Shipments"
        }
      },
      {
        path: "/shipments/create",
        name: "ShipNoticeCreate",
        component: ShipNoticeCreate,
        props: true,
        meta: {
          apiRedirectRoute: "Shipments"
        }
      },
      {
        path: "/manage",
        name: adminRoutes.Manage,
        component: Manage,
        props: true
      },
      {
        path: "/manage/clients",
        name: adminRoutes.ManageClients,
        component: ManageClients,
        props: true
      },
      {
        path: "/manage/clients/detail",
        name: adminRoutes.ClientDetailManage,
        component: ClientDetail,
        props: true,
        meta: {
          apiRedirectRoute: adminRoutes.ManageClients
        }
      },
      {
        path: "/manage/clients/create",
        name: adminRoutes.ClientCreateManage,
        component: ClientCreate,
        props: true,
        meta: {
          apiRedirectRoute: adminRoutes.ManageClients
        }
      },
      {
        path: "/manage/users",
        name: adminRoutes.ManageUsers,
        component: ManageUsers,
        props: true,
        meta: {
          returnPath: adminRoutes.Manage
        }
      },
      {
        path: "/manage/users/detail",
        name: adminRoutes.UserDetailManage,
        component: UserDetail,
        props: true,
        meta: {
          apiRedirectRoute: adminRoutes.ManageUsers,
          returnPath: adminRoutes.ManageUsers
        }
      },
      {
        path: "/manage/users/create",
        name: adminRoutes.UserCreateManage,
        component: UserCreate,
        props: true,
        meta: {
          apiRedirectRoute: adminRoutes.ManageUsers,
          returnPath: adminRoutes.ManageUsers
        }
      },
      {
        path: "/manage/service-levels",
        name: adminRoutes.ManageServiceLevels,
        component: ManageServiceLevels,
        props: true,
        meta: {
          returnPath: adminRoutes.Manage
        }
      },
      {
        path: "/manage/item-groups",
        name: adminRoutes.ManageItemGroups,
        component: ManageItemGroups,
        props: true,
        meta: {
          returnPath: adminRoutes.Manage
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: '/settings/general',
        name: 'ClientSettings',
        component: ClientSettings,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/settings/users",
        name: "UsersSettings",
        component: UsersSettings,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/settings/users/detail",
        name: "UserDetailSettings",
        component: UserDetailSettings,
        props: true,
        meta: {
          apiRedirectRoute: "UsersSettings"
        }
      },
      {
        path: '/settings/users/create',
        name: 'UserCreateSettings',
        component: UserCreateSettings,
        props: true,
        meta: {
          apiRedirectRoute: "UsersSettings"
        }
      },
      {
        path: '/settings/profile',
        name: 'UserProfile',
        component: UserProfile,
        props: true,
        meta: {
          returnPath: 'Settings',
          allResourcesRequired: true
        }
      },
      {
        path: '/settings/recipients',
        name: 'Recipients',
        component: Recipients,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: '/settings/recipients/detail',
        name: 'RecipientDetail',
        component: RecipientDetail,
        props: true,
        meta: {
          apiRedirectRoute: 'Recipients'
        }
      },
      {
        path: '/settings/recipients/create',
        name: 'RecipientCreate',
        component: RecipientCreate,
        props: true,
        meta: {
          apiRedirectRoute: 'Recipients'
        }
      },
      {
        path: '/settings/webhooks',
        name: 'Webhooks',
        component: Webhooks,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: '/settings/webhooks/detail',
        name: 'WebhookDetail',
        component: WebhookDetail,
        props: true,
        meta: {
          apiRedirectRoute: 'Webhooks'
        }
      },
      {
        path: '/settings/webhooks/create',
        name: 'WebhookCreate',
        component: WebhookCreate,
        props: true,
        meta: {
          apiRedirectRoute: 'Webhooks'
        }
      },
      {
        path: "/settings/service-levels",
        name: 'SettingsServiceLevels',
        component: SettingsServiceLevels,
        props: true,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/settings/onboarding",
        name: adminRoutes.Onboarding,
        component: Onboarding,
        props: true,
        meta: {
          showClientSwitcher: true,
          returnPath: 'Settings',
          newUI: true
        }
      },
      {
        path: "/reports",
        name: "Analytics",
        component: AnalyticsContainer,
        meta: {
          showClientSwitcher: true
        }
      },
      {
        path: "/support",
        name: "Support",
        component: SupportContainer,
        meta: {
          showClientSwitcher: true,
          apiRedirectRoute: "Dashboard"
        }
      }
    ]
  },
  {
    path: "/login",
    name: openRoutes.Login,
    component: Login
  },
  {
    path: "/password-reset-request",
    name: openRoutes.PasswordResetRequest,
    component: PasswordResetRequest
  },
  {
    path: "/password-reset/:id",
    name: openRoutes.PasswordReset,
    component: PasswordReset
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  if(redirectInitialNavigationRoutes[to.name] && !from.name) {
    next({ name: redirectInitialNavigationRoutes[to.name] });
    return;
  }

  const isAdminRoute = !!adminRoutes[to.name];
  let isValidUser = false;

  if(_cookie.get('fulfillify-token'))
    isValidUser = !(await (isAdminRoute ? api.verifyAdminToken() : api.verifyToken())).error;

  if (!openRoutes[to.name] && !isValidUser) {
    _cookie.remove("fulfillify-token");
    _cookie.remove("fulfillify-client-id");
    window.location.href = router.resolve({ name: 'Login' }).href;
  } else if (to.name == 'Login' && isValidUser)
    window.location.href = router.resolve({ name: 'Dashboard' }).href;
  else {
    if (isAdminRoute)
      to.params.isAdminSection = true;
    next();
  }
})

router.afterEach(to => {
  to.params = { ...store.getters.navigationParams, ...to.params };
  store.dispatch('onResetNavigationParams');
});

export default router;
