<template>
  <div class="columns">
    <div class="column is-6">
      <div class="field">
        <label class="label">First Name</label>
        <div class="control">
          <input @input="userDataChanged('FirstName', $event)" class="input" :value="newUser.FirstName" :disabled="disableEditing" />
        </div>
      </div>
      <div class="field">
        <label class="label">Last Name</label>
        <div class="control">
          <input @input="userDataChanged('LastName', $event)" class="input" :value="newUser.LastName" :disabled="disableEditing" />
        </div>
      </div>
      <div class="columns" style="margin-bottom: 0">
        <div class="column is-6">
          <div class="field">
            <label class="label">Phone</label>
            <div class="control">
              <input @input="userDataChanged('Phone', $event)" class="input" :value="newUser.Phone" :disabled="disableEditing" />
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Address</label>
        <div class="control" style="margin-bottom: 10px">
          <input @input="userDataChanged('Address1', $event)" class="input" :value="newUser.Address1" :disabled="disableEditing" />
        </div>
        <div class="control" v-if="!disableEditing || newUser.Address2">
          <input @input="userDataChanged('Address2', $event)" class="input" :value="newUser.Address2" :disabled="disableEditing" />
        </div>
      </div>
    </div>
    <div class="column is-6">
      <div class="columns">
        <div class="column is-6">
          <div class="field">
            <label class="label">City</label>
            <div class="control">
              <input @input="userDataChanged('City', $event)" class="input" :value="newUser.City" :disabled="disableEditing" />
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Zip</label>
            <div class="control">
              <input @input="userDataChanged('Zip', $event)" class="input" :value="newUser.Zip" :disabled="disableEditing" />
            </div>
          </div>
        </div>
      </div>
      <CountryAndState
        @update="userCountryOrStateChanged($event)"
        class="field"
        :selectedCountry="newUser.Country"
        :selectedState="newUser.State"
        :disableEditing="disableEditing" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CountryAndState from '../Common/CountryAndState.vue';
export default {
  name: 'UserBasic',
  components: { CountryAndState },
  props: ['disableEditing'],
  computed: {
    ...mapGetters('manage', ['newUser'])
  },
  methods: {
    ...mapActions('manage', ['onSetNewUserData']),
    userDataChanged(field, event) {
      this.onSetNewUserData({ field, value: event.target.value });
    },
    userCountryOrStateChanged(event) {
      const field = event.field == 'country' ? 'Country' : 'State';
      this.onSetNewUserData({ field, value: event.value });
    }
  }
}
</script>

<style lang="less" scoped>
</style>
