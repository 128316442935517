<template>
  <Container title="Users"
    :haveError="haveError"
    :errorId="errorId">
    <template #actions>
      <slot name="actions" :onSearch="onSearch" :onSearchReset="onSearchReset" :filterChanged="filterChanged">
        <UserHeaderActions
          @onSearch="onSearch"
          @onSearchReset="onSearchReset"
          @filterChanged="filterChanged" />
      </slot>
    </template>
    
    <EntityContainer
      ref="container"
      loadingString="Loading Users. Please Wait..."
      :headers="headers"
      :apiFunc="getUsers"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enablePagination="true"
      :enableRowSelection="true"
      @changeRow="userSelected" />
  </Container>
</template>

<script>
import api from '../../lib/api-gateway';
import util from '../../lib/util';
import { mapGetters, mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityContainer from './../Common/EntityContainer.vue';
import UserHeaderActions from './Header/UserHeaderActions.vue';
export default {
  name: 'ManageUsers',
  components: { Container, EntityContainer, UserHeaderActions },
  data() {
    return {
      currentPageUsers: {},
      haveError: false,
      errorId: null,
      searchTerm: '',
      role: ''
    }
  },
  activated() {
    this.resetState();
  },
  mounted() {
    this.resetState();
  },
  computed: {
    ...mapGetters(['clientsList']),
    isAdminSection() {
      return this.$route.params.isAdminSection;
    },
    headers() {
      const headers = [
        'NAME',
        'ROLE',
        'EMAIL',
        'ADDRESS'
      ];
      if(this.isAdminSection)
        headers.splice(2, 0, 'CLIENT');
      return headers;
    }
  },
  methods: {
    ...mapActions('manage', ['onSetSelectedUser', 'onResetNewUser']),
    resetState() {
      this.onResetNewUser();
    },
    async getUsers(page, pageSize) {
      return await api.getUsers(this.isAdminSection, this.role, this.searchTerm, page, pageSize);
    },
    handleApiResult(res, users) {
      if(!res.error) {
        this.currentPageUsers = users;
      } else {
        this.haveError = true;
        this.errorId = res.errorId;
      }
    },
    prepareTableRow(user) {
      const row = [
          { value: `${user.FirstName} ${user.LastName}` },
          { value: user.Role },
          { value: user.UserEmail },
          { value: util.getAddress(user.Address) }
        ];
      if(this.isAdminSection)
        row.splice(2, 0, { value: this.clientsList.get(user.ClientId).Name });
      return { row };
    },
    userSelected(id) {
      this.onSetSelectedUser(this.currentPageUsers[id]);
      this.$router.push({ name: this.isAdminSection ? 'UserDetailManage' : 'UserDetailSettings' });
    },
    onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      this.$refs.container.refreshResults();
    },
    onSearchReset() {
      this.searchTerm = '';
      this.$refs.container.refreshResults();
    },
    filterChanged(event) {
      this.role = event.value;
      this.$refs.container.refreshResults();
    }
  }
}
</script>

<style lang="less" scoped>

</style>