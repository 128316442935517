import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const manage = { 
  namespaced: true,
  state: {
    newClient: newClient(),
    newClientConfig: newClientConfig(),
    newUser: newUser(),
    newRecipient: newRecipient(),
    newWebhook: newWebhook(),
    selectedClient: {},
    selectedUser: {},
    selectedRecipient: {},
    selectedWebhook: {},
    showAssignServiceLevelsModal: false,
    showAddCustomServiceLevelModal: false
  },
  getters: { ...getters },
  mutations: { ...mutations },
  actions: { ...actions }
}

function newClient() {
  return {
    Id: null,
    Description: '',
    DefaultNotificationEmail: '',
    SecondaryNotificationEmails: []
  };
}

function newClientConfig() {
  return {
    Name: '',
    DefaultCarrier: null,
    DefaultServiceLevel: null,
    DefaultReleaseWarehouse: null,
    PowerBiReportId: ''
  }
}

function newUser() {
  return {
    Email: '',
    Password: '',
    ConfirmPassword: '',
    Role: '',
    ClientId: null,
    FirstName: '',
    LastName: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
    Country: '',
    Phone: ''
  };
}

function newRecipient() {
  return {
    FirstName: '',
    LastName: '',
    OriginalCustomerNumber: '',
    Company: '',
    Email: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    ZipCode: '',
    Country: '',
    Phone: '',
    Notes: ''
  }
}

function newWebhook() {
  return {
    WebhookEvent: '',
    HttpMethod: '',
    HttpUrl: '',
    HttpHeaders: [],
    NotificationEmail: ''
  }
}

export { manage, newClient, newClientConfig, newUser, newRecipient, newWebhook }
