<template>
  <!-- This component needs to play well with FileUploadWithPreview so that using
       ellipsis with long file names works. In general that means this should be
       an inline component with baseline alignment, which is good for it to be
       regardless. The inline alignment also makes the icon buttons (like X)
       show up in the right place. -->
  <div class="control file is-info file-upload"
    :class="{ 'has-name': selectedFile, 'note-margin': maxFileSizeMb }">
    <label class="file-label" style="font-size: 16px !important">
      <input class="file-input" type="file" name="file" ref="file"
        :accept="allowedFileTypesAccept"
        @change="onFileChange()">
      <span class="file-cta blue-button" style="border: 1px #dbdbdb solid">
        <span class="file-icon">
          <i class="fas fa-upload"></i>
        </span>
        <span class="file-label" style="font-weight: lighter">
          {{ label ? label : 'Add an attachment...' }}
        </span>
      </span>
      <span v-if="selectedFile" class="file-name selected-file-name theme-light-blue-bg">
        {{ selectedFile.name }}
      </span>
    </label>
    <span v-show="!buttonOnly && selectedFile"
      class="icon icon-btn"
      style="flex-shrink: 0"
      @click="clearAttachmentClicked()">
      <i class="fas fa-times" style="font-size: 1.25em"></i>
    </span>
    <div v-if="maxFileSizeMb" class="note">
      Max file size: {{ maxFileSizeMb }}MB
    </div>
  </div>
</template>

<script>
  const usvcEmailFileTypes = {
    pdf: {
      extensions: ['.pdf'],
      description: 'PDFs'
    },
    image: {
      extensions: ['.jpg', '.jpeg', '.gif', '.png'],
      description: 'images'
    },
    video: {
      extensions: ['.mp4', '.m3u8', '.ts', '.3gp', '.mov', '.avi', '.wmv'],
      description: 'videos'
    }
  };
  const defaultAllowedFilesTypes = {
    ...usvcEmailFileTypes,
    all: {
      extensions: usvcEmailFileTypes.pdf.extensions.concat(
        usvcEmailFileTypes.image.extensions,
        usvcEmailFileTypes.video.extensions),
      description: 'images, videos, and PDFs'
    }
  };

  import util from '../../lib/util';
  export default {
    name: 'FileUpload',
    data() {
      return {
        selectedFile: null
      }
    },
    inject: [ 'alert' ],
    props: [ 'label', 'buttonOnly', 'fileType', 'allowedFileTypes', 'maxFileSizeMb' ],
    emits: ['fileChanged'],
    computed: {
      allowedFileTypesComputed() {
        return this.allowedFileTypes || defaultAllowedFilesTypes[this.fileType || 'all'].extensions;
      },
      allowedFileTypesAccept() {
        return this.allowedFileTypesComputed.join(',');
      },
      allowedFileTypesString() {
        return this.allowedFileTypes
          ? this.allowedFileTypes.map(t => t.slice(1).toUpperCase()).join(', ')
          : defaultAllowedFilesTypes[this.fileType || 'all'].description;
      },
      hasFile() {
        return !!this.selectedFile;
      }
    },
    methods: {
      onFileChange() {
        let file = this.$refs.file.files[0];
        if(!file)
          return;
        
        if(this.allowedFileTypesComputed.indexOf(file.name.slice(file.name.lastIndexOf('.'))) < 0) {
          this.alert(`This type of file is not allowed. Allowed file types are: ${this.allowedFileTypesString}.`);
          this.$refs.file.value = null;
          return;
        }

        if(this.maxFileSizeMb && file.size > (this.maxFileSizeMb * 1048576)) {
          this.alert(`File is too large. Max file size is ${this.maxFileSizeMb} MB.`);
          this.$refs.file.value = null;
          return;
        }
        
        this.selectedFile = file;
        this.$emit('fileChanged', this.selectedFile);
      },
      async getFileBase64() {
        return await util.getFileBase64(this.selectedFile);
      },
      clearAttachmentClicked() {
        this.clearAttachment();
        this.$emit('fileChanged', null);
      },
      clearAttachment() {
        this.$refs.file.value = null;
        this.selectedFile = null;
      }
    }
  }
</script>

<style lang="less" scoped>
.icon-btn {
  position: relative;
  top: 2px;
  cursor: pointer;
  width: 2.5rem !important;
}
.note-margin {
  margin-bottom: 32px;
}
.note {
  position: absolute;
  white-space: nowrap;
  top: 2.5em;
  padding-top: 10px;
  padding-left: 10px;
  font-size: .9em;
  font-style: italic;
}
.file-upload {
  align-items: baseline;
  min-width: 0;
}
</style>