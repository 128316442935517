<template>
  <CollapsiblePanel class="detail-panel" heading="Items to Ship">
    <ItemList :items="items" style="margin: 1rem 0" v-slot="{ item }">
      <DeductOrderItemTags v-if="item.Tags" :itemTags="item.Tags" :disableEditing="true" />
    </ItemList>
    <div v-if="order.ShipmentNote" class="shipment-note-container">
      <div class="columns">
        <div class="column is-narrow text-size-big" style="padding-right: 20px">
          <b class="entity-subtext">Order Note</b>
          &nbsp;
        </div>
        <div class="column text-size-big">
          {{ order.ShipmentNote }}
        </div>
      </div>
    </div>
  </CollapsiblePanel>
</template>

<script setup>
import { useStore } from 'vuex';
import CollapsiblePanel from '../Common/CollapsiblePanel.vue';
import ItemList from '../Common/ItemList.vue';
import DeductOrderItemTags from './DeductOrderItemTags.vue';
import { getOrderItems } from '../../composables/order';

const order = useStore().getters['order/selectedOrder'];
const items = getOrderItems(order);
</script>

<style lang="less" scoped>
.detail-panel {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 2px;
}
.shipment-note-container {
  padding-top: .5rem;
  padding-bottom: .25rem;
  border-top: 2px solid #ddd;
  margin-top: 10px;
}
</style>
