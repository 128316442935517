<template>
  <CollapsiblePanel class="detail-panel" heading="Recipient Details">
    <div class="columns" style="padding: .75rem 0">
      <div class="column is-5 icon-column detail-column">
        <div class="field text-size-big">
          <i class="fas fa-user"></i>
          <span>{{ name }}</span>
        </div>
        <div class="field text-size-big">
          <i class="fas fa-envelope"></i>
          <span v-if="email" style="word-break: break-word">
            {{ email }}
          </span>
          <span v-else style="font-style: italic">
            Not provided
          </span>
        </div>
        <div class="field text-size-big">
          <i class="fas fa-phone"></i>
          <span>{{ phone }}</span>
        </div>
      </div>
      <div class="column is-7 detail-column">
        <div class="field text-size-big">
          <span>{{ address }}</span>
        </div>
        <div v-if="state" class="field text-size-big">
          <span>{{ state }}</span>
        </div>
        <div class="field text-size-big">
          <span>{{ country }}</span>
        </div>
      </div>
    </div>
  </CollapsiblePanel>
</template>

<script setup>
import { useStore } from 'vuex';
import util from '../../lib/util';
import CollapsiblePanel from '../Common/CollapsiblePanel.vue';

const props = defineProps(['recipient']);
const store = useStore();

const { name, email, phone, address, state, country } = setupRecipientInfo();

function setupRecipientInfo() {
  const recipient = props.recipient;
  const countryInfo = store.getters['countries'].get(recipient.Country);
  const statesInfo = store.getters['states'][recipient.Country];
  const stateInfo = statesInfo ? statesInfo.get(recipient.State) : null;

  return {
      name: util.getRecipientName(recipient),
      email: recipient.Email,
      phone: util.formatPhone(recipient.Phone),
      address: [ recipient.Address1, recipient.Address2, recipient.City, recipient.ZipCode ].filter(Boolean).join(', '),
      state: stateInfo ? stateInfo.stateName : recipient.State,
      country: countryInfo ? countryInfo.country : recipient.Country
    };
}
</script>

<style lang="less" scoped>
.detail-panel {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 2px;
}
.detail-column {
  display: flex;
  flex-direction: column;
  row-gap: .75rem;
}
.icon-column > .field {
  display: flex;
  align-items: center;
  & > i {
    flex-shrink: 0;
    width: 30px;
  }
}
.text-size-big {
  * {
    color: #36454F;
  }
  span {
    font-weight: 500;
  }
}
</style>
