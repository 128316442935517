export const mutations  = {
  setInventoryTags: (state, {stockNumber, warehouse, tags}) => {
    if(!state.inventoryTags[stockNumber])
      state.inventoryTags[stockNumber] = {};
    state.inventoryTags[stockNumber][warehouse] = tags;
  },
  setItemGroups: (state, g) => state.itemGroups = [...g],
  changeSelectedProduct: (state, prod) => {
    state.selectedProduct = {...prod};
    state.itemImage = null;
    state.itemPdf = null;
  },
  setItemFile: (state, fileData) => state[fileData.file] = fileData.data,
  changeNewProductData: (state, {field, value}) => state.newProduct[field] = value,
  setDimension: (state, r) => state.dimension = {...r},
  setProduct: (state, p) => state.newProduct = {...p},
  resetInventoryTags: (state) => state.inventoryTags = {}
}