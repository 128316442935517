<template>
  <Modal title="Upload File" width="1000px" @close="$emit('close')">
    <div class="content">
      <slot />
    </div>
    <div class="buttons-container">
      <FileUpload ref="fileUpload"
        label="Choose file..."
        :allowedFileTypes="['.csv', '.xls', '.xlsx']"
        @fileChanged="onFileChanged($event)" />
      <button class="button blue-button" :disabled="!selectedFile" @click="doImport">
        <span>UPLOAD</span>
      </button>
    </div>
  </Modal>
</template>

<script>
  import util from './../../lib/util';
  import { mapActions } from 'vuex';
  import Modal from  './../Common/Modal';
  import FileUpload from  './../Common/FileUpload';
  export default {
    name: 'ImportCreate',
    props: ['uploadFileFunc'],
    emits: ['close'],
    data() {
      return {
        selectedFile: false
      }
    },
    inject: [ 'alert' ],
    components: { Modal, FileUpload },
    methods: {
      ...mapActions(['onMakingAPICalls', 'onSetResponseContent']),
      onFileChanged(file) {
        this.selectedFile = file;
      },
      async doImport() {
        let file;

        try {
          file = await util.getFileBase64(this.selectedFile);
        } catch(err) {
          this.alert(err.message)
          return;
        }

        this.onMakingAPICalls('Starting import. Please wait...');

        const ext = this.selectedFile.name.substring(this.selectedFile.name.lastIndexOf('.') + 1);
        const res = await this.uploadFileFunc(file.split(',')[1], ext);
      
        this.onSetResponseContent({
          responseStatus: !res.error,
          responseText: res.error ? res.message: res
        });
      }
    }
  }
</script>

<style lang="less" scoped>
.buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
</style>