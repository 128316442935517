<template>
  <Container :title="`${selectedUser.FirstName} ${selectedUser.LastName}`">
    <template #actions>
      <UserEditActions
        :isUserValid="isUserValid"
        :showUpdateUser="enableEditingRole || enableEditingClient"
        @editAssignedClients="editAssignedClients" />
    </template>

    <AssignedClientsSelection v-if="showAssignedClientsModal" @close="showAssignedClientsModal = false" />
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <UserHeaderInfo
          :disableValidation="true"
          :validateClient="enableEditingClient"
          :validateRole="enableEditingRole"
          @isUserValid="isUserValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <UserAccount
            :enableEditingRole="enableEditingRole"
            :enableEditingClient="enableEditingClient"
            class="end-form-section" />
          <div class="form-section-heading">
            USER INFORMATION
          </div>
          <UserBasic :disableEditing="true" />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script setup>
import { ref, inject, watch } from 'vue';
import { useStore } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import UserEditActions from './Header/UserEditActions.vue';
import AssignedClientsSelection from './AssignedClientsSelection.vue';
import UserHeaderInfo from './UserHeaderInfo.vue';
import UserAccount from './UserAccount.vue';
import UserBasic from './UserBasic.vue';

const confirm = inject('confirm');
const store = useStore();

const isUserValid = ref(false);
const showAssignedClientsModal = ref(false);
const userDataChanged = ref(false);

const loggedInUser = store.getters['loggedInUser'];
const selectedUser = store.getters['manage/selectedUser'];

const editingSelf = loggedInUser.UserId == selectedUser._id;
const haveGreaterPrivelegeThanUser = loggedInUser.Role == 'Admin' && selectedUser.Role == 'Manager';
const enableEditingRole = haveGreaterPrivelegeThanUser;
const enableEditingClient = haveGreaterPrivelegeThanUser || editingSelf;

store.dispatch('manage/onSetEditSelectedUser');

watch(store.getters['manage/newUser'], () => userDataChanged.value = true);

async function editAssignedClients() {
  if(userDataChanged.value &&
    !await confirm('Making changes to assigned clients will lose unsaved changes in this form. Continue?'))
    return;

  showAssignedClientsModal.value = true;
}
</script>

<style lang="less" scoped>
.form-section-heading {
  font-size: 16px;
  font-weight: 350;
  letter-spacing: .5;
  margin-bottom: 20px;

  :not(:first-child) {
    margin-top: 20px;
  }
}
.end-form-section {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
</style>