<template>
  <Container>
    <template #title>
      <span class="theme-blue">Order </span>
      <span class="theme-orange">#{{ order.OrderNumber }}</span>
    </template>
    
    <template #actions>
      <OrderEditActions />
    </template>

    <EntityData class="card content-container" gapClass="is-4">
      <template #EntityHeaderInfo>
        <OrderHeaderInfo />
      </template>
      <template #EntityBasic>
        <OrderBasicEdit />
      </template>
      <template #EntityItems>
        <OrderEditItems />
      </template>
      <template #EntityExtra>
        <OrderExtra />
      </template>
    </EntityData>
  </Container>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import Container from './../Common/Container.vue';
import OrderEditActions from './Header/OrderEditActions.vue';
import OrderHeaderInfo from './OrderHeaderInfo.vue';
import OrderBasicEdit from './OrderBasicEdit.vue';
import OrderExtra from './OrderExtra.vue';
import OrderEditItems from './OrderEditItems.vue';
import EntityData from './../Common/EntityData.vue';
import { setupOrderItemsForEdit } from '../../composables/order';

const store = useStore();
const order = store.getters['order/selectedOrder'];

const o = {
    Name: order.Name, 
    DeliveryOption: order.DeliveryOption, 
    Notes: order.ShipmentNote,
    ShipByDate: order.ShipByDate ? order.ShipByDate.split('T')[0] : null,
    Recipient: {...order.Recipient},
    InvoiceFile: order.InvoiceFile,
    ShippingLabel: order.ShippingLabel,
    RequestUCCLabels: order.RequestUCCLabels
  };
store.dispatch('order/onOrderSelect', o);
store.dispatch('order/onRecipientSelect', o.Recipient);

setupOrderItemsForEdit(order);

onMounted(() => window.scrollTo(0, 0));
onUnmounted(() => {
    store.dispatch('order/onResetNewOrder');
    store.dispatch('order/onResetRecipient');
    store.dispatch('onEmptySelectedItems');
  });
</script>
