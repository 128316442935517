<template>
  <Modal v-if="showOptionsDialog"
    width="600px"
    ref="alert"
    :hideCloseBtn="true"
    :buttons="buttonsData"
    style="z-index: 5000"
    @close="closeKitOptions">
    <div>
      <template v-if="deactivatingKit">
        The following kits contain item {{ stockNumber }} and other items.
        The kits can be deactivated or have the item removed from them.
        What would you like to do?
      </template>
      <template v-else>
        The following items in kit {{ stockNumber }} are inactive and need
        to be activated before the kit can be activated. Would you like to
        activate them?
      </template>
      <div class="error-items-container" style="margin: 20px; max-height: 200px; overflow-y: auto">
        <ul class="error-items-list">
          <li v-for="item in errorItems" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
      <template v-if="deactivatingKit">
        Kits that only contain this item will be deactivated.
      </template>
    </div>
  </Modal>
</template>

<script>
const deactivatingItemKitsOptionData = [
  {
    value: 0,
    label: 'Deactivate Kit(s)',
    classes: 'blue-button',
    style: 'width: 170px'
  },
  {
    value: 1,
    label: 'Remove Item from Kit(s)',
    classes: 'blue-button',
    style: 'width: 210px'
  }
];
const activatingKitComponentsOptionData = [
  {
    value: 1,
    label: 'Activate Item(s)',
    classes: 'blue-button',
    style: 'width: 150px'
  }
];

const cancelBtn = {
  value: -1,
  label: 'Cancel',
  classes: 'red-button',
  style: 'width: 105px'
};
const skipBtn = {
  value: -1,
  label: 'Skip Kit',
  classes: 'red-button',
  style: 'width: 115px'
};

import { mapGetters, mapActions } from 'vuex';
import Modal from '../../Common/Modal.vue';
import api from './../../../lib/api-gateway';
export default {
  name: 'ItemStatusUpdater',
  props: ['skipInsteadOfCancel'],
  components: { Modal },
  data() {
    return {
      stockNumber: null,
      showOptionsDialog: false,
      deactivatingKit: false,
      errorItems: [],
      resolvePromiseFunc: null
    }
  },
  computed: {
    ...mapGetters(['apiInProcessText']),
    buttonsData() {
      const buttons = [ ...(this.deactivatingKit
        ? deactivatingItemKitsOptionData
        : activatingKitComponentsOptionData) ];
      buttons.push(this.skipInsteadOfCancel ? skipBtn : cancelBtn);
      return buttons;
    }
  },
  methods:{
    ...mapActions(['onMakingAPICalls']),
    async updateItemStatus(stockNumber, isActive) {
      this.stockNumber = stockNumber;

      let res;
      let tryAgain;
      let updateOption;

      do {
        tryAgain = false;

        let deactivatingItemKitsOption = null;
        let activatingKitComponentsOption = false;

        if(updateOption != null) {
          deactivatingItemKitsOption = this.deactivatingKit ? updateOption : null;
          activatingKitComponentsOption = !this.deactivatingKit && updateOption > 0;
        }
        
        res = await api.updateItemStatus(stockNumber, isActive,
          deactivatingItemKitsOption, activatingKitComponentsOption)

        if(res.error && res.data &&
          (res.data.MultipleItemKitsThatContainItem || res.data.InactiveKitComponents)) {
          this.errorItems = res.data.MultipleItemKitsThatContainItem || res.data.InactiveKitComponents;
          this.deactivatingKit = !!res.data.MultipleItemKitsThatContainItem;

          updateOption = await this.getUpdateOption();

          if(updateOption >= 0)
            tryAgain = true;
          else
            res.data.kitOption = updateOption;
        }
      } while(tryAgain);

      return res;
    },
    async getUpdateOption() {
      const message = this.apiInProcessText;

      this.onMakingAPICalls();
      this.showOptionsDialog = true;
      const updateOption = await new Promise((resolve) => { this.resolvePromiseFunc = resolve; });
      this.onMakingAPICalls(message);

      return updateOption;
    },
    closeKitOptions(value) {
      this.resolvePromiseFunc(value);
      this.resolvePromiseFunc = null;
      this.showOptionsDialog = false;
    }
  }
}
</script>

<style lang="less" scoped>
.error-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error-items-list {
  list-style: unset;
  text-align: initial;
}
</style>
