const fileTypeExtensions = {
    pdf: ['pdf'],
    image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
  };

export default {
  formatAndGetDate: (date) => new Date(date).toLocaleString(),
  formatAndGetDateOnly: (date) => new Date(date).toLocaleDateString({}, { timeZone: 'UTC' }),
  formatAndGetDateOnlyISO: (date) => new Date(date).toISOString().slice(0, 10),
  getUpperCaseVal: (val) =>  val ? val.toUpperCase() : '-', 
  isDateInTheFuture(date) {
    const parsedDate = Date.parse(date)
    if(isNaN(parsedDate)) {
      return false;
    } else {
      const now = new Date()
      return parsedDate > new Date(now.getFullYear(), now.getMonth(), now.getDate())
    }
  },
  formatDate(date){
    var createdAt = date
    var tuple = createdAt.split("T")
    var newdate = tuple[0]
    var dateTuple = newdate.split("-")
    var newFormatedDate = [dateTuple[1], dateTuple[2], dateTuple[0]].join("/")
    console.log(newFormatedDate)
    return newFormatedDate
    
  },
  haveItemsAndQuantities(items) {
    for(let prod of items.values())
      if(!prod.Quantity)
        return false
    return !!items.size
  },
  getFileBase64: async(file) => {
    if(!file)
      return null;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(new Error(`Error reading ${file.name}.`));
      reader.readAsDataURL(file);
    });
  },
  trimString(value) {
    return value ? value.trim() : value;
  },
  testEmail(email) {
    return /^\s*[\w-.]+@([\w-]+\.)+[\w-]{2,4}\s*$/.test(email);
  },
  testGuid(guid) {
    return /^\s*[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}\s*$/.test(guid);
  },
  testUrl(url) {
    return /^\s*(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/\S*)?\s*$/.test(url);
  },
  testEmptyOrWhitespace(string) {
    return /^\s*$/.test(string);
  },
  addSpacesBeforeCapitals(string) {
    return string ? string.replace(/([A-Z])/g, ' $1').trim() : string;
  },
  checkFileType(type, file) {
    const exts = fileTypeExtensions[type];
    if(!exts)
      return false;

    const idx = file && file.lastIndexOf('.');
    return idx > 0 && exts.includes(file.slice(idx + 1));
  },
  isRecipientEmpty(recp) {
    return !recp || (recp['FirstName'] == '' && recp['LastName'] == '' && recp['Address1'] == ''
      && recp['Address2'] == '' && recp['City'] == '' && recp['State'] == '' && recp['Country'] == '');
  },
  getRecipientText(recp) {
    return [
        [recp.FirstName, recp.LastName].filter(Boolean).join(' '),
        recp.Company,
        this.getAddress(recp)
      ].filter(Boolean).join(', ');
  },
  getRecipientName(recp) {
    return [
        [recp.FirstName, recp.LastName].filter(Boolean).join(' '),
        recp.Company
      ].filter(Boolean).join(', ')
  },
  getAddress(address) {
    return [
      address.Address1,
      address.Address2,
      address.City,
      [address.State, address.Zip || address.ZipCode].filter(Boolean).join(' '),
      address.Country
    ].filter(Boolean).join(', ');
  },
  formatPhone(phone) {
    return phone && phone.length == 10
      ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
      : phone;
  },
  searchList(list, search, selector) {
    if(!search)
      return list;
    search = search.toLowerCase();

    return list.filter(x => (selector ? selector(x) : x).toLowerCase().includes(search));
  },
  filterMap(map, filter) {
    return new Map([...map.entries()].filter(entry => filter(entry)));
  },
  arraysHaveSameValues(array1, array2) {
    array1 = array1 || [];
    array2 = array2 || [];
    return array1.length == array2.length && array1.every(x => array2.includes(x));
  },
  range(start, end, inc) {
    const res = new Array(Math.floor((end - start) / inc) + 1);
    for(let i = 0, val = start; i < res.length; i++, val += inc)
      res[i] = val;
    return res;
  },
  objectsAreEqual(o1, o2) {
    return o1 && o2
      && [...new Set(Object.keys(o1).concat(Object.keys(o2)))].every(x => o1[x] == o2[x]);
  },
  objectArraysAreEqual(a1, a2) {
    return a1 && a2
      && a1.length == a2.length
      && a1.every((x, i) => this.objectsAreEqual(x, a2[i]));
  },
  aggregateObjects(objects, findExistingFunc, aggregateFunc) {
    const aggrObjs = [];
    
    for(const o of objects) {
      const existingObj = aggrObjs.find(existingObj => findExistingFunc(existingObj, o));
      if(existingObj)
        aggregateFunc(existingObj, o);
      else
        aggrObjs.push(o);
    }

    return aggrObjs;
  },
  // https://stackoverflow.com/questions/26150232/resolve-javascript-promise-outside-the-promise-constructor-scope
  makePromiseWithResolvers(handler) {
    let resolve, reject;

    const promise = new Promise(function(res, rej) {
      resolve = res; 
      reject = rej;
      if(handler) handler(res, rej);
    })
    
    promise.resolve = resolve;
    promise.reject = reject;

    return promise;
  },
  // MDN.
  escapeRegExp(string) {
    if(!string)
      return string;
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  } 
}