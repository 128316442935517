<template>
  <div class="stat-container">
    <div class="stat-section">
      <h3 class="header-text theme-blue">Stats</h3>
      <div class="cards-container">
        <div
          v-for="(row, index) in statsData"
          :key="index"
        >
          <div
            v-for="(item, index) in row"
            :key="index"
            class="card stat-card"
            :style="item.nav ? 'cursor: pointer' : ''"
            @click="onStatClicked(item.nav, item.params)"
          >
            <div class="content card-content">
              <i style="font-size: 56px" :class="item.icon"></i>
              <div>
                <p class="stat-name">{{ item.name }}</p>
                <p class="stat-number" style="font-size: 24px; font-weight: 500">
                  {{ item.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "OrderStats",
  props: ["statData"],
  data() {
    return {
      statsData: [
        [ { name: "On Hold",
            value: this.statData.onHold,
            icon: "fas fa-stop-circle",
            nav: 'Orders',
            params: { selectedStatus: 'onHold'} },
          { name: "In Shipment",
            value: this.statData.inShipments,
            icon: "fas fa-shipping-fast",
            nav: 'Shipments',
            params: { selectedTab: 'Created'} } ],
        [ { name: "Backorders",
            value: this.statData.backOrders,
            icon: "fas fa-pause-circle",
            nav: 'Orders',
            params: { selectedStatus: 'backorders'} },
          { name: "Returns",
            value: this.statData.returns,
            icon: "fas fa-undo-alt" } ]
      ],
    };
  },
  methods: {
    ...mapActions(['onSetNavigationParams']),
    onStatClicked(nav, params) {
      this.onSetNavigationParams(params);
      this.$router.push({ name: nav });
    }
  }
};
</script>

<style lang="less" scoped>
.stat-container{
   background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 2.5px 5px 10px #888888;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.header-text {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 8px;
}
.stat-name {
  color: #777;
  font-weight: bold;
  padding-left: 5px;
}
.stat-number {
  padding-left: 5px;
  margin-top: -15px;
}
.stat-card {
  display: flex;
  justify-content: flex-start;
  flex-basis: 200px;
  height: 85px;
  width: 200px;
  margin: 10px;
}
.card-content {
  padding: 25px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media screen and (min-width: 881px)
  and (max-width: 1400px){
  .stat-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
    margin-top: 28px;
  }
}
@media screen and (min-width: 561px)
  and (max-width: 880px){
  .stat-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
  }
}
@media screen and (min-width: 270px)
  and (max-width: 560px){
  .stat-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
  }
  .stat-section{
    width: 100%;
  }
  .header-text{
    font-size: 18px;
  }
}
</style>