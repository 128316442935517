<template>
  <ComponentHeaderButton
    btnText="Submit Order"
    iconName="fa fa-paper-plane-o"
    :useButtonStyle="true"
    @handler="tryCall(onSubmitOrder)"
    v-if="showSubmitButton"
    :disabled="!isOrderValid"
  />
  <ComponentHeaderButton
    :btnText="`${selectedOrder.Status == 'OnHold' ? 'Submit' : 'Update' } Order`"
    iconName="fa fa-pencil-square-o"
    :useButtonStyle="true"
    @handler="tryCall(onUpdateOrder)"
    v-if="showUpdateButton"
    :disabled="!isOrderValid"
  />
  <ComponentHeaderButton
    btnText="Submit Order"
    iconName="fa fa-paper-plane-o"
    :useButtonStyle="true"
    @handler="tryCall(onSubmitDeductOrder)"
    v-if="showSubmitDeductButton"
    :disabled="!isOrderValid"
  />
  <ComponentHeaderButton
    :btnText="`${selectedOrder.Status == 'OnHold' ? 'Submit' : 'Update' } Order`"
    iconName="fa fa-pencil-square-o"
    :useButtonStyle="true"
    @handler="tryCall(onUpdateDeductOrder)"
    v-if="showUpdateDeductButton"
    :disabled="!isOrderValid"
  />
  <ComponentHeaderButton
    btnText="Print Work Order"
    iconName="fa fa-tasks"
    :useButtonStyle="true"
    @handler="$refs.printWorkOrder.print()"
    v-if="showPrintWorkOrderButton"
  />
  <PrintWorkOrder v-if="showPrintWorkOrderButton" ref="printWorkOrder" />
  <ComponentHeaderButton
    btnText="Create Returns"
    iconName="fas fa-exchange-alt"
    :useButtonStyle="true"
    @handler="onCreateReturn"
    v-if="showReturnButton"
  />
  <ComponentHeaderButton
    btnText="Cancel Order"
    iconName="fa fa-times"
    :useButtonStyle="true"
    :isDanger="true"
    @handler="tryCall(onCancelOrder)"
    v-if="showCancelButton"
  />
  <div class="bare-button red"
    @click="tryCall(onCancelBackOrder)"
    v-if="showCancelBackOrderButton">
    Cancel Backorder
  </div>
  <ComponentHeaderButton
    btnText="Update Item"
    iconName="fa fa-pencil-square-o"
    :useButtonStyle="true"
    @handler="onUpdateProduct"
    v-if="showUpdateProduct"
    :disabled="!isProductValid"
  />
  <ItemStatusUpdater ref="itemStatusUpdater" />
  <ComponentHeaderButton
    btnText="Add Item"
    iconName="far fa-save"
    :useButtonStyle="true"
    @handler="onCreateProduct"
    v-if="showProductCreate"
    :disabled="!isProductValid"
  />
  <ComponentHeaderButton
    btnText="Create Notice"
    iconName="far fa-save"
    :useButtonStyle="true"
    @handler="onCreateNotice"
    v-if="showNoticeCreate"
    :disabled="!isShipNoticeValid"
  />
  <ComponentHeaderButton
    btnText="Update Notice"
    iconName="fa fa-pencil-square-o"
    :useButtonStyle="true"
    @handler="onUpdateNotice"
    v-if="showUpdateShipNotice"
    :disabled="!isShipNoticeValid"
  />
  <ComponentHeaderButton
    btnText="Cancel Notice"
    iconName="fa fa-times"
    :useButtonStyle="true"
    :isDanger="true"
    @handler="onCancelNotice"
    v-if="showCancelShipNotice"
  />
  <ComponentHeaderButton
    btnText="Create Client"
    iconName="far fa-save"
    :useButtonStyle="true"
    @handler="onCreateClient"
    v-if="showCreateClient"
    :disabled="!isClientValid"
  />
  <ComponentHeaderButton
    :btnText="isSettingsView ? 'Save Changes' : 'Update Client'"
    iconName="fa fa-pencil-square-o"
    :useButtonStyle="true"
    @handler="onUpdateClient"
    v-if="showUpdateClient"
    :disabled="!isClientValid"
  />
  <ComponentHeaderButton
    :btnText="isClientActive ? 'Deactivate Client': 'Activate Client'"
    :iconName="'fas ' + (isClientActive ? 'fa-ban' : 'fa-check')"
    :useButtonStyle="true"
    :isDanger="isClientActive"
    @handler="onChangeClientStatus"
    v-if="showChangeClientStatus"
  />
  <ComponentHeaderButton
    btnText="Create User"
    iconName="far fa-save"
    :useButtonStyle="true"
    @handler="onCreateUser"
    v-if="showCreateUser"
    :disabled="!isUserValid"
  />
  <ComponentHeaderButton
    :btnText="isProfileView ? 'Save Changes' : 'Update User'"
    iconName="fa fa-pencil-square-o"
    :useButtonStyle="true"
    @handler="onUpdateUser"
    v-if="showUpdateUser"
    :disabled="!isUserValid"
  />
  <ComponentHeaderButton
    btnText="Delete User"
    iconName="far fa-trash-alt"
    :useButtonStyle="true"
    :isDanger="true"
    @handler="tryCall(onDeleteUser)"
    v-if="showDeleteUser"
  />
  <ComponentHeaderButton
    btnText="Create Recipient"
    iconName="far fa-save"
    :useButtonStyle="true"
    @handler="onCreateRecipient"
    v-if="showCreateRecipient"
    :disabled="!isRecipientValid"
  />
  <ComponentHeaderButton
    btnText="Update Recipient"
    iconName="fa fa-pencil-square-o"
    :useButtonStyle="true"
    @handler="onUpdateRecipient"
    v-if="showUpdateRecipient"
    :disabled="!isRecipientValid"
  />
  <ComponentHeaderButton
    btnText="Create Webhook"
    iconName="far fa-save"
    @handler="onCreateWebhook"
    v-if="showCreateWebhook"
    :disabled="!isWebhookValid"
  />
  <ComponentHeaderButton
    btnText="Update Webhook"
    iconName="fa fa-pencil-square-o"
    @handler="onUpdateWebhook"
    v-if="showUpdateWebhook"
    :disabled="!isWebhookValid"
  />
</template>

<script>
  import api from "../../lib/api-gateway";
  import util from "../../lib/util";
  import { mapGetters, mapActions } from 'vuex'
  import ComponentHeaderButton from './ComponentHeaderButton.vue';
  import ItemStatusUpdater from '../Warehouse/Inventory/ItemStatusUpdater.vue';
  import PrintWorkOrder from "../Orders/PrintWorkOrder.vue";
  export default {
    name: 'EntityActions',
    props: ['showUpdateButton',
      'showSubmitButton',
      'showSubmitDeductButton',
      'showUpdateDeductButton',
      'showPrintWorkOrderButton',
      'showReturnButton',
      'showCancelButton',
      'showCancelBackOrderButton',
      'showUpdateProduct',
      'showProductCreate',
      'showCancelShipNotice',
      'showUpdateShipNotice',
      'showNoticeCreate',
      'showCreateClient',
      'showUpdateClient',
      'showChangeClientStatus',
      'showCreateUser',
      'showUpdateUser',
      'showDeleteUser',
      'showCreateRecipient',
      'showUpdateRecipient',
      'showCreateWebhook',
      'showUpdateWebhook',
      'isProductValid',
      'isShipNoticeValid',
      'isClientValid',
      'isSettingsView',
      'isProfileView',
      'isUserValid',
      'isRecipientValid',
      'isWebhookValid',
      'selectedBackOrder'],
    components: { ComponentHeaderButton, ItemStatusUpdater, PrintWorkOrder },
    inject: [ 'alert', 'confirm' ],
    computed:{
      ...mapGetters(['currentClient', 'isUserInternal', 'isUserInternalOrOwner', 'originalSelectedItems', 'selectedItems', 'selectedFiles']),
      ...mapGetters('order', ['newOrder','newDeductOrder','newWorkOrder','recipient','selectedOrder','isOrderValid']),
      ...mapGetters('product', ['selectedProduct', 'newProduct', 'dimension']),
      ...mapGetters('asn', ['selectedShipNotice', 'newShipNotice']),
      ...mapGetters('manage', ['selectedClient', 'selectedRecipient', 'selectedUser', 'newClient', 'newClientConfig', 'newUser', 'newRecipient', 'newWebhook']),
      isAdminSection() {
        return this.$route.params.isAdminSection;
      },
      isClientActive() {
        return this.selectedClient.IsActive;
      },
      newUserDisplayName() {
        return `${this.newUser.FirstName} ${this.newUser.LastName}`;
      }
    },
    methods:{
      ...mapActions('order', ['onToggleShowReturnModal']),
      ...mapActions(['onMakingAPICalls', 'onSetResponseContent']),
      async onCreateProduct(){
        try {
          const productToCreate =  await this.getProductData();

          await this.callApi(() => api.createItem(productToCreate),
            `Creating item ${this.newProduct.StockNumber}. Please wait...`,
            `Item ${this.newProduct.StockNumber} was created successfully.`);
        } catch(err) {
          this.alert(err.message);
        }
      },
      async onUpdateProduct(){
        try {
          const stockNumber = this.selectedProduct.StockNumber;

          const productToUpdate = await this.getProductData();
          const newProductStatus = productToUpdate.IsActive;
          delete productToUpdate.IsActive;

          const apiCalls = [];

          if(this.selectedProduct.IsActive != newProductStatus)
            apiCalls.push(async () => {
                const res = await this.$refs.itemStatusUpdater.updateItemStatus(stockNumber, newProductStatus);
                if(res.data && res.data.kitOption < 0)
                  res.abort = true;
                return res;
              });
          apiCalls.push(() => api.updateItem(stockNumber, productToUpdate));

          await this.callApi(
            apiCalls,
            `Updating item ${stockNumber}. Please wait...`,
            `Item ${stockNumber} was updated successfully.`);
        } catch(err) {
          this.alert(err.message);
        }
      },
      async onSubmitOrder(){
        await this.callApi(
            async () => api.submitOrder(await this.getOrderData()),
            `Creating order ${this.newOrder.Name}. Please wait...`,
            (res) => `Order ${res.OrderNumber}: ${this.newOrder.Name} was created successfully.`
          );
      },
      async onUpdateOrder(){
        await this.callApi(
            async () => api.updateOrder(this.selectedOrder.OrderNumber, await this.getOrderData()),
            `${this.selectedOrder.Status == 'OnHold' ? 'Submitting' : 'Updating'} order ${this.selectedOrder.Name}. Please wait...`,
            `Order ${this.newOrder.Name} ${this.selectedOrder.Status == 'OnHold' ? 'submitted' : 'updated'} successfully.`
          );
      },
      async onSubmitDeductOrder(){
        await this.callApi(
            async () => api.submitDeductOrder(await this.getDeductOrderData()),
            `Creating order ${this.newDeductOrder.Name}. Please wait...`,
            (res) => `Order ${res.OrderNumber}: ${this.newDeductOrder.Name} was created successfully.`
          );
      },
      async onUpdateDeductOrder(){
        await this.callApi(
            async () => api.updateDeductOrder(this.selectedOrder.OrderNumber, await this.getDeductOrderData()),
            `${this.selectedOrder.Status == 'OnHold' ? 'Submitting' : 'Updating'} order ${this.selectedOrder.Name}. Please wait...`,
            `Order ${this.newDeductOrder.Name} ${this.selectedOrder.Status == 'OnHold' ? 'submitted' : 'updated'} successfully.`
          );
      },
      async onCancelOrder() {
        if(await this.confirm(`Are you sure you want to cancel order ${this.selectedOrder.Name}?`))
          await this.callApi(
              () => api.cancelOrders([this.selectedOrder.OrderNumber]),
              `Cancelling order ${this.selectedOrder.Name}. Please Wait...`,
              `Order ${this.selectedOrder.Name} cancelled successfully.`
            );
      },
      async onCancelBackOrder() {
        const id = this.selectedBackOrder._id;
        if(await this.confirm(`Are you sure you want to cancel backorder ${id}?`))
          await this.callApi(
              () => api.cancelOrders(null, { [this.selectedOrder.OrderNumber]: this.selectedBackOrder.OrderNumber }),
              `Cancelling backorder ${id}. Please wait...`,
              `Backorder ${id} cancelled successfully.`
            );
      },
      async onCancelNotice(){
        if(!(await this.confirm('Are you sure you want to cancel this notice?')))
          return;

        await this.callApi(
            () => api.cancelAsns([this.selectedShipNotice._id]),
            `Cancelling notice ${this.selectedShipNotice.Id}. Please wait...`,
            `Notice ${this.selectedShipNotice.Id} cancelled successfully.`
          );
      },
      async onUpdateNotice(){
        await this.callApi(
            () => api.updateAsn(this.selectedShipNotice._id, this.getAsnData()),
            `Updating notice ${this.selectedShipNotice.Id}. Please Wait...`,
            `Notice ${this.selectedShipNotice.Id} was updated successfully.`
          );
      },
      async onCreateNotice() {
        await this.callApi(
            () => api.createAsn(this.getAsnData()),
            'Creating notice. Please Wait...',
            res => `Notice ${res.Name} created successfully.`
          );
      },
      onCreateReturn(){
        this.onToggleShowReturnModal();
      },
      async onCreateClient() {
        const clientToCreate = this.getClientData();
        const userToCreate = this.getUserData();
        this.handleNewUserRoleAndClientId(userToCreate, clientToCreate);

        await this.callApi(
          [() => api.createClient(clientToCreate), () => api.createUser(userToCreate)], 
          'Creating client. Please wait...',
          `Client ${this.newClientConfig.Name} was created successfully.`
        );
      },
      async onUpdateClient() {
        const { clientData, clientConfigData } = this.getClientUpdateData();

        const apiCalls = [() => api.updateClient(clientData)];
        if(clientConfigData)
          apiCalls.push(() => api.updateClientConfig(clientConfigData));
        
        await this.callApi(
          apiCalls,
          'Updating client. Please wait...',
          `Client ${this.newClientConfig.Name} updated successfully.`
        );
      },
      async onChangeClientStatus() {
        const action = this.selectedClient.IsActive ? 'Deactivat' : 'Activat';
        
        if(!(await this.confirm(`Are you sure you want to ${action.toLowerCase()}e this client?`)))
          return;
        
        await this.callApi(
          this.selectedClient.IsActive
            ? () => api.deactivateClient(this.selectedClient._id)
            : () => api.activateClient(this.selectedClient._id),
          `${action}ing client. Please wait...`,
          `Client ${this.selectedClient.Name} ${action.toLowerCase()}ed successfully.`
        )
      },
      async onCreateUser() {
        const userToCreate = this.getUserData();

        this.handleNewUserRoleAndClientId(userToCreate);

        await this.callApi(
          () => api.createUser(userToCreate),
          'Creating user. Please wait...',
          `User ${this.newUserDisplayName} created successfully.`
        );
      },
      async onUpdateUser() {
        const userToUpdate = this.getUserData();

        const newClientId = userToUpdate.ClientId != this.selectedUser.ClientId ? userToUpdate.ClientId : null;
        const newRoleId = userToUpdate.RoleId != this.selectedUser.RoleId ? userToUpdate.RoleId : null;
        const newStatus = userToUpdate.Active != this.selectedUser.Active ? userToUpdate.Active : null;
        delete userToUpdate.ClientId;
        delete userToUpdate.RoleId;
        delete userToUpdate.Active;

        const calls = [];

        if(this.isProfileView)
          calls.push(() => api.updateUserProfile(userToUpdate));
        if(newClientId || newRoleId != null || newStatus != null)
          calls.push(() => api.updateUserConfig({
              UserId: this.selectedUser._id,
              DefaultClient: newClientId,
              RoleId: newRoleId,
              Active: newStatus
            }));
        
        if(!calls.length) {
          this.alert('No changes to save.');
          return;
        }

        const whoIsUpdated = this.isProfileView ? 'Profile' : `User ${this.newUserDisplayName}`;

        await this.callApi(
          calls,
          `Updating ${this.isProfileView ? 'profile' : 'user'}. Please wait...`,
          `${whoIsUpdated} updated successfully.`
        );
      },
      async onDeleteUser() {
        if(!(await this.confirm(`Are you sure you want to delete user ${this.newUserDisplayName}?`)))
          return;

        await this.callApi(
          () => api.deleteUser(this.selectedUser._id),
          'Deleting user. Please wait...',
          `User ${this.newUserDisplayName} deleted.`
          );
      },
      async onCreateRecipient() {
        let recipientToCreate = this.sanitizeData({ ...this.newRecipient }, true);

        const recipientName = recipientToCreate.FirstName || recipientToCreate.LastName
          ? `${recipientToCreate.FirstName} ${recipientToCreate.LastName}`
          : recipientToCreate.Company;

        await this.callApi(
          () => api.createRecipient(recipientToCreate),
          'Creating recipient. Please wait...',
          `Recipient ${recipientName} created successfully.`
        );
      },
      async onUpdateRecipient() {
        let recipientToUpdate = this.sanitizeData({ ...this.newRecipient }, true);

        const recipientName = recipientToUpdate.FirstName && recipientToUpdate.LastName
          ? `${recipientToUpdate.FirstName} ${recipientToUpdate.LastName}`
          : recipientToUpdate.Company;

        await this.callApi(
          () => api.updateRecipient(this.selectedRecipient._id, recipientToUpdate),
          'Updating recipient. Please wait...',
          `Recipient ${recipientName} updated successfully.`
        );
      },
      async onCreateWebhook() {
        await this.callApi(
          () => api.createWebhook(this.getWebhookData()),
          'Creating webhook. Please wait...',
          'Webhook created successfully.'
        );
      },
      async onUpdateWebhook() {
        await this.callApi(
          () => api.updateWebhook(this.getWebhookData()),
          'Updating webhook. Please wait...',
          'Webhook updated successfully.'
        );
      },
      async callApi(calls, inProgressMessage, successMessage) {
        this.onMakingAPICalls(inProgressMessage);
        let allRes;
        let res;
        
        if(Array.isArray(calls)) {
          allRes = [];
          for(const call of calls) {
            res = await call();
            allRes.push(res);
            if(res.abort || res.error)
              break;
          };
        } else
          allRes = res = await calls();

        if(!res.abort)
          await this.onSetResponseContent({
            responseText: !res.error
              ? typeof(successMessage) == 'function' ? successMessage(allRes) : successMessage
              : res.message,
            responseStatus: !res.error
          });
        else
          this.onMakingAPICalls();

        return allRes;
      },
      async getFiles(files) {
        if(files.some(f => this.selectedFiles[f])) {
          const fileData = {};
          for(const f of files)
            if(this.selectedFiles[f])
              fileData[f] = this.selectedFiles[f].file
                ? (await util.getFileBase64(this.selectedFiles[f].file)).split(',')[1]
                : "";
          return fileData;
        }

        return null;
      },
      getItems(itemChangedFunc) {
        const currentItems = [...this.selectedItems.values()];
        const removedItems = [...this.originalSelectedItems.values()]
          .filter(x => !currentItems.some(c => c.StockNumber == x.StockNumber));
        
        return [
            ...currentItems.map(x => ({ Status: getStatus(this.originalSelectedItems.get(x.StockNumber), x), ...x })),
            ...removedItems.map(x => ({ Status: 'Removed', ...x }))
          ];

        function getStatus(origItem, item) {
          return item.Status = !origItem ? 'Added'
            : item.Quantity != origItem.Quantity || (itemChangedFunc && itemChangedFunc(origItem, item)) ? 'Changed'
            : 'Unchanged';
        }
      },
      async getProductData() {
        const productData = {
          ...this.sanitizeData(this.newProduct),
          ...await this.getFiles([ 'ImageData', 'PdfData' ])
        };

        delete productData.ImageFile;
        delete productData.PdfFile;

        productData.Dimensions = { ...this.sanitizeData(this.dimension) };
        productData.ProductType = productData.ItemType;

        if(productData.ItemType == 'Bundled')
          productData.Components = this.getItems();

        return productData;
      },
      async getOrderData() {
        return this.sanitizeData({
            DeliveryOption: this.newOrder.DeliveryOption,
            ShipByDate: this.newOrder.ShipByDate,
            Name: this.newOrder.Name,
            Recipient: { ...this.sanitizeData(this.recipient) },
            Notes: this.newOrder.Notes,
            Items: this.getItems(areLotNosDifferent),
            Files: await this.getFiles([ 'InvoiceFile', 'ShippingLabel' ]),
            WaitForClientApproval: this.newOrder.WaitForClientApproval,
            RequestUCCLabels: this.newOrder.RequestUCCLabels
          });
        
        function areLotNosDifferent(origItem, newItem) {
          const oLotNo = origItem.LotNo;
          const nLotNo = newItem.LotNo;
          return (oLotNo || nLotNo) && (oLotNo != nLotNo);
        }
      },
      async getDeductOrderData() {
        return this.sanitizeData({
          ...this.newDeductOrder,
          WorkOrder: this.sanitizeData({ ...this.newWorkOrder}),
          Items: this.getItems(areTagsDifferent) });
        
        function areTagsDifferent(origItem, newItem) {
          const haveOTags = origItem.Tags && origItem.Tags.length;
          const haveNTags = newItem.Tags && newItem.Tags.length;
          return (haveOTags || haveNTags) && !util.objectArraysAreEqual(origItem.Tags, newItem.Tags);
        };
      },
      getAsnData() {
        return this.sanitizeData({ ...this.newShipNotice, Products: this.getItems() });
      },
      getClientData() {
        const clientData = {
          ...this.sanitizeData({ ...this.newClient }),
          ...this.sanitizeData({ ...this.newClientConfig })
        };

        clientData.SecondaryNotificationEmails =
          this.joinClientSecondaryNotificationEmails(clientData.SecondaryNotificationEmails);

        return clientData;
      },
      getClientUpdateData() {
        const clientData = this.sanitizeData({
          ...this.newClient,
          NotificationSettings: {
            ...this.sanitizeData({ ...this.newClient.NotificationSettings }),
            SecondaryNotificationEmails:
              this.joinClientSecondaryNotificationEmails(this.newClient.NotificationSettings.SecondaryNotificationEmails)
          }
        });

        const clientConfigData = this.isUserInternal
          ? {
              ...this.sanitizeData({ ...this.newClientConfig }),
              Id: clientData.Id
            }
          : null;
        
        return { clientData, clientConfigData };
      },
      joinClientSecondaryNotificationEmails(emails) {
        return `[${this.sanitizeData(emails).filter(Boolean).join()}]`;
      },
      getUserData() {
        const userData = { ...this.newUser }; // Add sanitize later after DB is not all NOT NULL?
        
        userData.RoleId = this.getRoleId(userData.Role);
        delete userData.Role;
        delete userData.ConfirmPassword;

        return userData;
      },
      getRoleId(role) {
        return ['Admin', 'Manager', 'Owner', 'User', 'API', 'Scheduler'].indexOf(role);
      },
      handleNewUserRoleAndClientId(userData, newClientData) {
        if(newClientData) {
          userData.ClientId = newClientData.Id;
          userData.RoleId = this.getRoleId('Owner');
        } else if(!this.isAdminSection) {
          userData.ClientId = this.currentClient._id;
          if(!this.isUserInternal)
            userData.RoleId = this.getRoleId('User');
        }
      },
      getWebhookData() {
        const webhookData = this.sanitizeData(
          { ...this.newWebhook },
          { emptyFieldsToRemove: 'NotificationEmail' }
        );

        webhookData.NotificationEmail = webhookData.NotificationEmail || null;
        webhookData.HttpHeaders = JSON.stringify(
          webhookData.HttpHeaders
            .map(h => this.sanitizeData({ ...h }))
            .filter(h => h.Name && h.Value)
            .reduce((o, h) => {
                o[h.Name] = h.Value;
                return o;
              }, {}));
        
        return webhookData;
      },
      sanitizeData(d, leaveEmptyFields){
        // leaveEmptyFields parameter is used for data going to QSvc
        for(let k in d) {
          if(typeof d[k] === 'string' && d[k] != '' && d[k] != null)
            d[k] = d[k].trim();
          if(!Array.isArray(d) && !d[k] && d[k] !== false && !leaveEmptyFields)
            delete d[k];
        }
        return d
      },
      async tryCall(func) {
        try {
          await func();
        } catch(err) {
          console.log(err);
          this.onMakingAPICalls();
          this.alert(err.message);
          return;
        }
      }
   }
  }
</script>

<style lang="less" scoped>
.bare-button {
  outline: none;
  border: none;
  background: transparent;
  padding: .5em 1.2em;
  cursor: pointer;

  &.red {
    color: var(--theme-red);
  }
}
</style>