<template>
  <ImportCreate :uploadFileFunc="uploadFileFunc" class="text-size" @close="$emit('close')">
    <div class="columns">
      <div class="column">
        Upload your file with the following rules:<br />
        <ul>
          <li>Should be a .csv or .xls/.xlsx.</li>
          <li>Should have one header row.</li>
          <li>Optional columns can be left empty, but they can't be removed from the spreadsheet.</li>
        </ul>
      </div>
      <div class="column">
        The file should have the following columns:
        <ol>
          <li>SKU</li>
          <li>UPC</li>
          <li>Description</li>
          <li>Length</li>
          <li>Width</li>
          <li>Height</li>
          <li>Weight</li>
          <li>Harmonization Code (optional)</li>
          <li>Price (optional)</li>
        </ol>
      </div>
    </div>
  </ImportCreate>
</template>

<script>
  import api from './../../../lib/api-gateway';
  import ImportCreate from '../../Common/ImportCreate.vue';
  export default {
    name: 'ItemImportCreate',
    components: { ImportCreate },
    emits: ['close'],
    computed: {
      uploadFileFunc() {
        return api.importItems;
      }
    }
  }
</script>
