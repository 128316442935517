<template>
  <FfyColumnsView>
    <template #form>
      <form
        style="width: 430px; max-width: 100%"
        v-on:submit.prevent="signInUser"
      >
        <div>
          <p class="custom-label-style">Email</p>
          <div class="control has-icons-left has-icons-right">
            <input
              v-model.trim="email"
              class="input is-large"
              placeholder="Email"
            />
            <span class="icon is-left is-large">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </span>
            <span class="icon is-right is-large">
              <i class="fa fa-check"></i>
            </span>
          </div>
        </div>
        <div style="margin-top: 20px">
          <p class="custom-label-style">Password</p>
          <div
            class="control has-icons-left has-icons-right"
            style="color:#999"
          >
            <input
              v-model.trim="password"
              class="input is-large"
              type="password"
              placeholder="Password"
            />
            <span class="icon is-left is-large">
              <i class="fa fa-lock" aria-hidden="true"></i>
            </span>
            <span class="icon is-right is-large">
              <i class="fa fa-check"></i>
            </span>
          </div>
        </div>
        <div style="margin-top: 20px">
          <ButtonWithSpinner
            label="Login"
            class="is-fullwidth submit-btn is-large"
            :isSubmit="true"
            :isInProcess="loggingIn"
          />
          <div
            v-if="errMessage"
            style="color:orange; font-weight: 600; text-align: center"
          >
            {{ errMessage }}
          </div>
          <div id="forgot-pass-div">
            <a id="forgot-pass-link"
              style="color: orange !important"
              @click.stop="requestPasswordReset"
              >FORGOT YOUR PASSWORD</a
            >
          </div>
          <hr />
          <div style="">
            <p class="support-text">
              NEED HELP? EMAIL
              <a href="mailto:support@fulfillify.com" style="color: #ffa500 !important"
                >SUPPORT@FULFILLIFY.COM</a
              >
            </p>
          </div>
        </div>
      </form>
    </template>
  </FfyColumnsView>
</template>

<script>
// @ is an alias to /src
import api from "./../lib/api-gateway";
import util from "./../lib/util";
import _cookie from "js-cookie";
import { mapGetters } from "vuex";
import FfyColumnsView from "../components/Common/FfyColumnsView.vue";
import ButtonWithSpinner from "../components/Common/ButtonWithSpinner.vue";
export default {
  name: "Login",
  components: { FfyColumnsView, ButtonWithSpinner },
  data: function() {
    return {
      email: "",
      password: "",
      loggingIn: false,
      errMessage: ""
    };
  },
  computed: {
    ...mapGetters(["isScreenSizeLessThanEqualTo1460"])
  },
  methods: {
    signInUser: async function() {
      const email = this.email;
      const password = this.password;

      if (!email) {
        this.errMessage = "Email is required";
        return;
      }
      if (!util.testEmail(email)) {
        this.errMessage = "Invalid email address";
        return;
      }
      if (!password) {
        this.errMessage = "Password is required";
        return;
      }

      this.password = "";
      this.errMessage = "";

      this.loggingIn = true;
      const res = await api.login(email, password);
      this.loggingIn = false;

      if (!res.error) {
        _cookie.set("fulfillify-token", res.accessToken);
        window.location.href = this.$router.resolve({ name: 'Dashboard' }).href;
      } else {
        this.errMessage = "Login failed, please try again";
      }
    },
    requestPasswordReset() {
      this.$router.push({ name: "PasswordResetRequest" });
    }
  }
};
</script>
<style scoped>
.custom-label-style {
  color: #fff !important;
}
.support-text {
  background: #fff;
  border-radius: 4px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
}
#forgot-pass-div {
  text-align: center;
  margin-top: 20px;
}
#forgot-pass-link {
  color: #fff;
  text-align: center;
}

@media screen and (min-width: 561px) and (max-width: 880px) {
  .support-text {
    border-radius: 2px;
    font-size: 16px;
    padding: 4px;
  }
  #forgot-pass-div {
    text-align: center;
    margin-top: 20px;
  }
  #forgot-pass-link {
    color: #fff;
    text-align: center;
    font-size: 16px;
  }
}
@media screen and (min-width: 270px) and (max-width: 560px) {
  .support-text {
    border-radius: 2px;
    font-size: 12px;
    padding: 4px;
  }
  #forgot-pass-div {
    text-align: center;
    margin-top: 20px;
  }
  #forgot-pass-link {
    color: #fff;
    text-align: center;
    font-size: 12px;
  }
}
</style>
