<template>
  <div>
    <CollapsiblePanelHeader :class="{ 'collapsible-panel-header-visible': visible }" @expanded="visible = $event">
      <span class="entity-heading">{{ heading || "&nbsp;" }}</span>
    </CollapsiblePanelHeader>
    <div v-show="visible">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import CollapsiblePanelHeader from './CollapsiblePanelHeader.vue';

defineProps(['heading']);

const visible = ref(true);
</script>

<style lang="less" scoped>
.collapsible-panel-header-visible {
  border-bottom: 2px solid #ddd;
  padding-bottom: 3px;
  margin-bottom: 5px;
}
</style>
