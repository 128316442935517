<template>
  <Container title="Shipments" :haveError="haveError" :errorId="errorId">
    <template #actions>
      <ShipmentHeaderActions
        @onSearch="onSearch"
        @onSearchReset="onSearchReset"
        @filterChanged="filterFieldChanged"/>
    </template>
    
    <EntityContainer
      ref="container"
      loadingString="Loading Shipments. Please Wait..."
      :headers="headers"
      :statusCards="statusCards"
      :cardStat="cardStat"
      :apiFunc="getAsns"
      :handleApiResultFunc="handleApiResult"
      :tableRowPrepareFunc="prepareTableRow"
      :enablePagination="true"
      :enableRowSelection="true"
      @changeRow="changeSelectedShipNotice"
      @selectionChanged="selectedIds = $event"
      @actionsChanged="actionsChanged" />
    
    <EntityDockActions
      :actionsData="actionsData"
      :handler="cancelAsns" />
  </Container>
</template>

<script>
const cancelActionData = {
  name: 'cancel',
  buttonClasses: 'button red-button',
  label: 'Cancel Shipments'
};
const cancelAllActionData = {
  name: 'cancelAll',
  buttonClasses: 'button red-button',
  label: 'Cancel All Shipments'
};

import api from "./../../../lib/api-gateway";
import util from './../../../lib/util';
import ui from './../../../lib/ui';
import { mapActions } from 'vuex';
import Container from './../../Common/Container';
import EntityContainer from './../../Common/EntityContainer';
import EntityDockActions from './../../Common/EntityDockActions';
import ShipmentHeaderActions from './Header/ShipmentHeaderActions.vue';

export default {
  name: 'Shipments',
  components: { Container, EntityContainer, EntityDockActions, ShipmentHeaderActions },
  data() {
    return {
      shipNoticeStats: {},
      headers: [
        "CODE",
        "ID",
        "DATE",
        "PO NUMBER",
        "CARRIER",
        "VENDOR",
        "STATUS"
        ],
      currentPageAsns: {},
      currentTab: '',
      selectedIds: [],
      actionsData: [],
      dateFrom: '',
      dateTo: '',
      warehouse: '',
      searchTerm: "",
      haveError: false,
      errorId: null,
      statusCards:[
        {
          name: "All",
          value: "AllAsn",
          iconType: "fas fa-boxes",
        },
        {
          name: "Created",
          value: "Created",
          iconType: "fas fa-truck-loading",
        },
        {
          name: "In Transit",
          value: "In Transit",
          iconType: "fas fa-truck",
        },
        {
          name: "Delivered",
          value: "Delivered",
          iconType: "fas fa-people-carry",
        },
        {
          name: "Received",
          value: "Received",
          iconType: "fas fa-receipt",
        },
        {
          name: "Cancelled",
          value: "Cancelled",
          iconType: "fas fa-times",
        },
        {
          name: "In Stock",
          value: "In Stock",
          iconType: "fas fa-check",
        }
      ]
    };
  },
  inject: ['confirm'],
  computed: {
    cardStat(){
       return {
        AllAsn: this.shipNoticeStats.totalAsnCount,
        Created: this.shipNoticeStats.createdCount,
        'In Transit': this.shipNoticeStats.inTransitCount,
        Delivered: this.shipNoticeStats.deliveredCount,
        Received: this.shipNoticeStats.receivedCount,
        'In Stock': this.shipNoticeStats.inStockCount,
        Cancelled: this.shipNoticeStats.cancelledCount
      }
    }
  },
  methods: {
    ...mapActions('asn', ['onChangeSelectedShipNotice']),
    changeSelectedShipNotice(val){
      this.onChangeSelectedShipNotice(this.currentPageAsns[val]);
      this.$router.push({ name: 'ShipNoticeDetail' });
    },
    async getAsns(page, pageSize, currentTab) {
      this.currentTab = currentTab;
      return await api.getAsnData(
        page,
        currentTab,
        this.searchTerm,
        this.dateFrom,
        this.dateTo,
        this.warehouse,
        pageSize
      );
    },
    async refreshResults() {
      return await this.$refs.container.refreshResults();
    },
    handleApiResult(res, asns) {
      if(!res.error) {
        const { inTransitCount, totalAsnCount, createdCount, deliveredCount,
          receivedCount, cancelledCount, inStockCount } = res.result;

        this.currentPageAsns = asns;
        this.shipNoticeStats = {
            inTransitCount,
            totalAsnCount, 
            createdCount,
            deliveredCount,
            receivedCount,
            cancelledCount,
            inStockCount
          };
      } else {
        this.haveError = true;
        this.errorId = res.errorId;
      }
    },
    prepareTableRow(asn) {
      return {
        actions: this.getActionsForAsn(asn),
        row: [
          { value: asn._id },
          { value: asn.Id },
          { value: util.formatAndGetDateOnly(asn.ExpectedDate) },
          { value: asn.PONumber },
          { value: asn.Carrier },
          { value: asn.Vendor },
          { value: asn.Status,
            bulletColor: ui.getAsnStatusColor(asn.Status) },
          { warehouseCode: asn.WarehouseCode }
        ]
      };
    },
    getActionsForAsn(asn) {
      return asn.Status == 'Created' || asn.Status == 'In Transit' ? ['cancel'] : [];
    },
    async cancelAsns() {
      if(!(await this.confirm('Are you sure you want to cancel these notice(s)?')))
        return;
      
      const ids = this.selectedIds.length ? this.selectedIds : Object.keys(this.currentPageAsns);
      
      this.$refs.container.startApiCall('Cancelling notice(s)...');
      const res = await api.cancelAsns(ids);
      this.$refs.container.showBulkApiResult(res, 'Notice(s) cancelled successfully.');
    },
    async onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      await this.refreshResults();
    },
    async onSearchReset() {
      this.searchTerm = '';
      await this.refreshResults();
    },
    async filterFieldChanged(event) {
      if(event.field == 'warehouse')
        this.warehouse = event.value ? event.value.Code : '';
      else
        this[event.field] = event.value;
      await this.refreshResults();
    },
    actionsChanged(actions) {
      const isCancel = actions.indexOf('cancel') >= 0;
      const isCancelAll = !isCancel
        &&!this.selectedIds.length
        && Object.keys(this.currentPageAsns).length
        && (this.currentTab == 'Created' || this.currentTab == 'In Transit');
      
      this.actionsData = isCancel ? [cancelActionData]
        : isCancelAll ? [cancelAllActionData]
        : [];
    }
  }
}
</script>
