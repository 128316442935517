<template>
  <div style="display: inline-block" @mouseleave="hideOnMouseLeave" @mouseenter="showOnMouseEnter">
    <ComponentHeaderButton
      ref="button"
      :btnText="btnText"
      :iconName="iconName"
      @handler="toggleVisibility" />
    <div ref="actionsDiv" v-show="isVisible" class="header-action-child-div">
      <slot name="options"></slot>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import ComponentHeaderButton from './ComponentHeaderButton.vue';
  export default {
    name: 'ComponentHeaderWithMoreActions',
    props: ['btnText', 'iconName'],
    emits: ['expanded'],
    components: { ComponentHeaderButton },
    data() {
      return {
        isVisible: false
      }
    },
    computed: {
      ...mapGetters([ 'isMenuHoverEnabled' ]),
    },
    methods: {
      ...mapActions(['onSetMenuHoverEnabled']),
      toggleVisibility() {
        if(this.isVisible) {
          this.hide();
          this.onSetMenuHoverEnabled(false);
        } else {
          this.show();
          this.onSetMenuHoverEnabled(true);
        }
      },
      show() {
        const div = this.$refs.actionsDiv;
        const button = this.$refs.button.$el;

        this.isVisible = true;
        
        this.$nextTick(() => {
          div.style.right = div.getBoundingClientRect().right > document.documentElement.clientWidth ? '0px' : '';
          div.style.top = button.getBoundingClientRect().bottom;
          div.style.visibility = 'visible';
          this.$emit('expanded');
        });
      },
      hide() {
        const div = this.$refs.actionsDiv;

        div.style.right = '';
        div.style.top = '';
        div.style.visibility = 'hidden';
        
        this.isVisible = false;
      },
      hideOnMouseLeave() {
        if(this.isVisible)
          this.hide();
      },
      showOnMouseEnter() {
        if(this.isMenuHoverEnabled && !this.isVisible)
          this.show();
      }
    }
  }
</script>

<style lang="less" scoped>
.header-action-child-div {
  position: absolute;
  background: white;
  padding: 12px;
  visibility: hidden;
  z-index: 10;
}
</style>