<template>
  <div class="filters-container">
    <ClearableTextBox placeholder="Search ID, Name, and Items"
      class="search-box filter-control"
      style="margin-right: auto"
      @enter="onSearch"
      @clear="onSearch"
      :haveValue="haveSearch" />
    <SelectList :instructionLabel="allStatusLabel"
      class="filter-control"
      :allowUnselect="!!status"
      :options="statuses"
      :selectedValue="status"
      :useIndexAsValue="true"
      @change="statusChanged" />
    <DropDownPanel ref="dateDropDown" :label="dateSearchLabel || 'Date'" class="filter-control">
      <div class="dropdown-item">
        <div class="field">
          <div class="control flex-control" style="margin-bottom: 10px">
            <div style="width: 45px">From:</div>
            <input ref="dateFrom"
              type="date"
              class="input"
              style="width: 165px"
              :min="minDate"
              :max="maxDate" 
              @change="dateChanged('dateFrom', $event.target.value)" />
            <i v-show="dateFrom" class="fa fa-times reset-button" @click="resetDate('dateFrom')" />
          </div>
          <div class="control flex-control">
            <div style="width: 45px">To:</div>
            <input ref="dateTo"
              type="date"
              class="input"
              style="width: 165px"
              :min="minDate"
              :max="maxDate"
              @change="dateChanged('dateTo', $event.target.value)" />
            <i v-show="dateTo" class="fa fa-times reset-button" @click="resetDate('dateTo')" />
          </div>
        </div>
      </div>
    </DropDownPanel>
    <SelectList :instructionLabel="warehouse ? 'All' : 'Warehouse'"
      class="filter-control"
      :allowUnselect="!!warehouse"
      :options="warehouses"
      valueField="Code"
      labelField="Name"
      @change="warehouseChanged" />
    <DropDownPanel ref="recipientDropDown"
      class="filter-control is-right"
      :label="recipientSearchLabel || 'Recipient'"
      @expanded="recipientSearchExpanded">
      <div class="dropdown-item" style="width: 350px">
        <span style="font-size: .9em">
          <i>Press 'Enter' to search or select a recipient from the results.</i>
        </span>
        <ClearableTextBox ref="recipientSearchBox"
          placeholder="Search First/Last Name or Company"
          style="width: 100%"
          v-model:value="recipientText"
          :disabled="isRecipientSelected"
          :haveValue="haveRecipientSearch"
          @enter="onSearchRecipient"
          @clear="onClearRecipient" />
        <RecipientSearch :search="recipientAutoSearch" @recipientSelected="onSelectRecipient" />
      </div>
    </DropDownPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import util from '../../lib/util';
import ClearableTextBox from '../Common/ClearableTextBox';
import SelectList from '../Common/SelectList';
import DropDownPanel from '../Common/DropDownPanel';
import RecipientSearch from '../Common/RecipientSearch'
export default {
  name: 'OrderFilters',
  props: ['categoryStats', 'initialStatus', 'minDate', 'maxDate'],
  emits: ['filterChanged'],
  components: { ClearableTextBox, SelectList, DropDownPanel, RecipientSearch },
  data() {
    return {
      haveSearch: false,
      status: '',
      warehouse: '',
      recipientText: '',
      isRecipientSelected: false,
      haveRecipientSearch: false,
      recipientSearchLabel: '',
      dateFrom: '',
      dateTo: '',
      dateSearchLabel: ''
    }
  },
  created() {
    this.status = this.initialStatus;
  },
  computed: {
    ...mapGetters(['warehouses', 'isUserInternal']),
    recipientAutoSearch() {
      return this.isRecipientSelected ? null : this.recipientText;
    },
    allStatusLabel() {
      return `${this.status ? 'All' : 'Status'} (${this.categoryStats.all})`;
    },
    statuses() {
      return {
          submitted: `Submitted/Waiting for Stock (${this.categoryStats.submitted})`,
          confirmed: `Confirmed/Approved (${this.categoryStats.confirmed})`,
          released: `In Process (${this.categoryStats.released})`,
          onHold: `On Hold (${this.categoryStats.onHold})`,
          cancelled: `Cancelled (${this.categoryStats.cancelled})`,
          partialOrders: `Partial Orders (${this.categoryStats.partialOrders})`,
          backorders: `Backorders (${this.categoryStats.backorders})`,
          shipped: `Shipped/Closed (${this.categoryStats.shipped})`,
          ...this.isUserInternal ? { deduct: `Deduct Orders (${this.categoryStats.deduct})` } : null
        };
    }
  },
  methods: {
    onSearch(event) {
      this.haveSearch = !!event;
      this.filterChanged(['search'], [event]);
    },
    statusChanged(event) {
      this.status = event.target.value;
      this.filterChanged(['status'], [event.target.value]);
    },
    warehouseChanged(event) {
      this.warehouse = event.target.value;
      this.filterChanged(['warehouse'], [event.target.value]);
    },
    dateChanged(field, value) {
      if(!value || parseInt(value.split('-')[0]) >= 1000) {
        this[field] = value;
        this.filterChanged([field], [value]);
        this.updateDateSearchLabel();
      }
    },
    resetDate(field) {
      this.$refs[field].value = '';
      if(this[field]) {
        this[field] = '';
        this.filterChanged([field], ['']);
        this.updateDateSearchLabel();
        if(!this.dateFrom && !this.dateTo)
          this.$refs.dateDropDown.hidePanel();
      }
    },
    updateDateSearchLabel() {
      const dateFromStr = this.dateFrom ? util.formatAndGetDateOnly(this.dateFrom) : null;
      const dateToStr = this.dateTo ? util.formatAndGetDateOnly(this.dateTo) : null;

      this.dateSearchLabel = dateFromStr && dateToStr ? `${dateFromStr}-${dateToStr}`
        : dateFromStr ? `From ${dateFromStr}`
        : dateToStr ? `To ${dateToStr}`
        : null;
    },
    onSelectRecipient(recp) {
      this.isRecipientSelected = true;
      this.recipientText = [ [ recp.FirstName, recp.LastName].filter(Boolean).join(' '), recp.Company ].filter(Boolean).join(', ');
      this.recipientSearchChanged(recp);
    },
    onSearchRecipient() {
      this.isRecipientSelected = false;
      this.recipientSearchChanged();
    },
    onClearRecipient() {
      this.isRecipientSelected = false;
      this.$refs.recipientDropDown.hidePanel();
      this.recipientSearchChanged();
    },
    recipientSearchChanged(recp = {}) {
      this.recipientSearchLabel = this.recipientText;
      this.haveRecipientSearch = !!this.recipientText;
      this.filterChanged(['recipientSearch', 'firstName', 'lastName', 'company'],
        [this.recipientAutoSearch, recp.FirstName, recp.LastName, recp.Company]);
    },
    filterChanged(fields, values) {
      const changes = fields.map((x, i) => ({ field: x, value: values[i] }));
      this.$emit('filterChanged', changes);
    },
    recipientSearchExpanded(expanded) {
      if(expanded)
        this.$refs.recipientSearchBox.focus();
    }
  }
}
</script>

<style lang="less">
.filter-control {
  input, select, button {
    border-radius: 7px !important;
    font-size: inherit;
    height: 3.2em !important;
  }
}
</style>
<style lang="less" scoped>
.filters-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
}
.search-box {
  width: 23vw;
}
.other-filters-container {
  display: flex;
  gap: 10px;
  margin-left: auto;
  flex-wrap: wrap;
}
.flex-control {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.reset-button {
  font-size: 1.25rem;
  padding: .2rem;
  cursor: pointer;
}
</style>
