import axios from "axios";
import _cookie from 'js-cookie';
import router from "./../router";

// Set this to send QSvc requests directly and bypass the API, like for development when there's no gateway
const devQSvcBaseUrl = null;

const _appClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: { Authorization: `Bearer-Jwt ${_cookie.get("fulfillify-token")}` }
});

_appClient.interceptors.response.use(undefined, authInterceptor);

function authInterceptor(err) {
  if (err.response &&
    (err.response.config.method != 'get' || err.response.config.url.indexOf('identities/verify-token') == -1) &&
    (err.response.status === 401 ||
      err.response.data.message === "401 Unauthorized" ||
      err.response.status === 403 ||
      err.response.data.message === "Forbidden")
  ) {
    _cookie.remove("fulfillify-token");
    _cookie.remove("fulfillify-client-id");
    window.location.href = router.resolve({ name: "Login" }).href;
  }
  return Promise.reject(err);
}

async function callEndpoint(method, url, data, localErrMsg, config) {
  // Needs to match gateway
  const isQueryRequest =
    (method == 'get' && !url.startsWith('orders/get')) ||
    (url.startsWith('ui/') && (method == 'post' || method == 'put'));

  const request = { method, url, data, ...config };
  if(isQueryRequest && devQSvcBaseUrl)
    request.baseURL = devQSvcBaseUrl;

  var error = false;
  var httpResp;
    
  try {
    httpResp = (await _appClient.request(request)).data;
  } catch (err) {
    console.log(err);
    httpResp = err.response ? err.response.data : null;
    error = true;
  }

  if(error) {
    const errResp = isQueryRequest
      ? httpResp && httpResp.message ? httpResp : { message: localErrMsg }
      : { message: getAppErrorMessage(httpResp) || localErrMsg, data: httpResp };
    console.log(errResp.message);
    return { error, ...errResp };
  } else
    return httpResp;
}

function getAppErrorMessage(respData) {
  let messages = [];
  if(respData) {
    if(respData.Message)
      messages.push(respData.Message);
    if(respData.Errors && respData.Errors.length)
      messages.push(...respData.Errors.map(e => e.Message));
    if(respData.ModelState)
      messages.push(
          ...Object.keys(respData.ModelState).reduce(
            (arr, x) => {
              arr.push(...respData.ModelState[x]);
              return arr;
            }, [])
        );
  }
  return messages.join('; ');
}

// Don't send empty data, it sends application/x-www-form-urlencoded instead of application/json.
export default {
  setClientId: id => _appClient.defaults.headers.common['X-Client-Id'] = id,
  apiGet: async(endpoint, localErrMsg, config) =>
    await callEndpoint('get', endpoint, null, localErrMsg, config),
  apiPost: async(endpoint, data, localErrMsg, config) =>
    await callEndpoint('post', endpoint, data == null ? {} : data, localErrMsg, config),
  apiPut: async(endpoint, data, localErrMsg, config) =>
    await callEndpoint('put', endpoint, data == null ? {} : data, localErrMsg, config),
  apiDelete: async(endpoint, data, localErrMsg, config) =>
    await callEndpoint('delete', endpoint, data == null ? {} : data, localErrMsg, config)
}
