<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Webhooks' })" />
      <EntityActions :showCreateWebhook="true" :isWebhookValid="isWebhookValid" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import EntityActions from '../../Common/EntityActions.vue';
export default {
  name: 'WebhookCreateActions',
  components: { ComponentHeaderActions, ComponentHeaderButton, EntityActions },
  props: [ 'isWebhookValid' ]
}
</script>
