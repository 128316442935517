<template>
  <div class="column is-5">
    <div v-if="!isCreateProduct" class="field">
      <label class="label">Is Active</label>
      <OnOffSwitch @toggled="onProductToggleChanged($event,'IsActive')" :on="newProduct.IsActive" />
    </div>
    
     <div  v-if="isCreateProduct"  class="field">
          <label class="label">Item Type</label>
          <div class="control">
            <div class="select is-normal">
              <select @change="onProductChanged($event, 'ItemType')">
                <option disabled>
                  -- Select Item Type --
                </option>
                <option value="Standard" :selected="isThisSelected('Standard')">Standard Item</option>
                <option value="Bundled" :selected="isThisSelected('Bundled')">Bundled Item</option>
              </select>
            </div>
          </div>
    </div>
    
    <div v-if="isCreateProduct" class="field">
      <label class="label">Stock Number</label>
      <input @input="onProductChanged($event,'StockNumber')" class="input" type="text" :value="newProduct.StockNumber" />
    </div>

    <div class="field">
      <label class="label">UPC/Barcode</label>
      <input @input="onProductChanged($event,'Barcode')" class="input" type="text" :value="newProduct.Barcode" />
    </div>
    <div class="field">
      <label class="label">Description</label>
      <input @input="onProductChanged($event,'Description')" class="input" type="text" :value="newProduct.Description" />
    </div>
    <div class="field" v-if="currentClient.SupportsItemGroups">
      <label class="label">Item Group</label>
      <SelectList instructionLabel="-- Select Item Group --"
        :options="itemGroups"
        valueField="_id"
        labelField="GroupName"
        :selectedValue="newProduct.ItemGroupId"
        :allowUnselect="true"
        :showClearBtn="true"
        @change="onProductChanged($event, 'ItemGroupId')" />
    </div>
    <div class="field">
      <label class="label">Serial Number Required</label>
      <OnOffSwitch @toggled="onProductToggleChanged($event,'RequiresSerialNumber')" :on="newProduct.RequiresSerialNumber" />
    </div>
    <div class="field">
      <label class="label">Lot Number Required</label>
      <OnOffSwitch @toggled="onProductToggleChanged($event,'LotNumberRequired')" :on="newProduct.LotNumberRequired" />
    </div>
     <div class="field">
    </div>
    <div class="field checkbox-with-field-container">
      <div>
        <label class="label">Expiration Date Required</label>
        <OnOffSwitch @toggled="onProductToggleChanged($event,'ExpirationDateRequired')" :on="newProduct.ExpirationDateRequired" />
      </div>
      <div v-if="newProduct.ExpirationDateRequired">
        <label class="label">Cycle Time</label>
        <input @input="onProductChanged($event, 'CycleTime')" class="input" type="number" :value="newProduct.CycleTime" />
      </div>
    </div>
    <div class="field">
      <label class="label">Harmonization Code</label>
      <input @input="onProductChanged($event,'HarmonizationCode')" class="input" type="text" :value="newProduct.HarmonizationCode" />
    </div>
     <div class="field">
      <label class="label">Price ($)</label>
      <input @input="onProductChanged($event,'Price')" class="input" type="number" :value="newProduct.Price" />
    </div>
    <div class="field">
      <label class="label">Cost ($)</label>
      <input @input="onProductChanged($event,'Cost')" class="input" type="number" :value="newProduct.Cost" />
    </div>
    <div class="field checkbox-with-field-container">
      <div>
        <label class="label">Item is Caseable</label>
        <OnOffSwitch @toggled="onProductToggleChanged($event,'IsCaseable')" :on="newProduct.IsCaseable" />
      </div>
      <div v-if="newProduct.IsCaseable">
        <label class="label">Pack Unit</label>
        <input @input="onProductChanged($event,'PackUnit')" class="input" type="number" :value="newProduct.PackUnit" />
      </div>
    </div>
    <template v-if="showReorderUi">
      <div class="field">
        <label class="label">Reorder Point</label>
        <input @input="onProductChanged($event, 'ReorderPoint')" class="input" type="number" :value="newProduct.ReorderPoint" />
      </div>
      <div class="field">
        <label class="label">Reorder Quantity</label>
        <input @input="onProductChanged($event, 'ReorderQty')" class="input" type="number" :value="newProduct.ReorderQty" />
      </div>
      <div class="field">
        <label class="label">Original Stock Number</label>
        <input @input="onProductChanged($event, 'OriginalStockNumber')" class="input" :value="newProduct.OriginalStockNumber" />
      </div>
    </template>
    <div class="field">
      <label class="label">Item Image</label>
      <FileUploadWithPreview
        title="Item Image"
        fileType="image"
        maxFileSizeMb="5"
        :fileName="newProduct.ImageFile"
        :fileData="itemImage"
        @fileChanged="onFileChanged($event, 'ImageData')" />
    </div>
    <div class="field">
      <label class="label">Item PDF</label>
      <FileUploadWithPreview
        title="Item PDF"
        fileType="pdf"
        maxFileSizeMb="5"
        :fileName="newProduct.PdfFile"
        :fileData="itemPdf"
        :fileLoadFunc="getLoadPdfFunc()"
        @fileChanged="onFileChanged($event, 'PdfData')" />
    </div>
    <div v-if="selectedProduct && selectedProduct.Warehouses && selectedProduct.Warehouses.length > 0" class="field">
      <label class="label">Inventory</label>
      <div class="card">
        <table class="table is-narrow is-fullwidth">
          <thead>
            <tr>
              <th>Warehouse</th>
              <th>BOH</th>
              <th>O</th>
              <th>U</th>
              <th>ATO</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="wh in selectedProduct.Warehouses" :key="wh.Code" style="color: #000">
              <td>{{ wh.Code }}</td>
              <td>{{ wh.BalanceOnHand }}</td>
              <td>{{ wh.Allocated }}</td>
              <td>{{ wh.Unshippable }}</td>
              <td>{{ wh.Available }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import OnOffSwitch from '../../Common/OnOffSwitch.vue';
  import FileUploadWithPreview from '../../Common/FileUploadWithPreview.vue';
  import SelectList from '../../Common/SelectList.vue';
  import { loadItemFile } from '../../../composables/item';
  export default {
    name: 'ProductBasic',
    props: ['isCreateProduct'],
    components: { OnOffSwitch, FileUploadWithPreview, SelectList },
    data() {
      return {
        showReorderUi: false
      }
    },
    created() {
      const reorderUiClients = process.env.VUE_APP_TAGS_REORDER_UI_CLIENTS.split(',');
      this.showReorderUi = reorderUiClients.indexOf(this.currentClient._id.toString()) >= 0;
    },
    mounted() {
      if(this.newProduct.ImageFile)
        loadItemFile('itemImage', this.newProduct.ImageFile, true);
    },
    computed:{
      ...mapGetters(['currentClient', 'isUserInternal']),
      ...mapGetters('product', ['itemGroups', 'newProduct', 'selectedProduct', 'itemImage', 'itemPdf'])
    },
    methods:{
      ...mapActions(['onMakingAPICalls', 'onSetResponseContent', 'onSetSelectedFile']),
      ...mapActions('product', ['onProductDataChange', 'onSetItemFile']),
      ...mapActions(['onItemsAssignment']),
      onProductChanged(event, field){
        this.onProductDataChange({field, value: event.target.value});
        if(field == 'ItemType' && event.target.value == 'Standard')
          this.onItemsAssignment(new Map());
      },
      onProductToggleChanged(value, field) {
        this.onProductDataChange({field, value});
        if(field == 'IsCaseable' && !value)
          this.onProductDataChange({field: 'PackUnit', value: null})
        if(field == 'ExpirationDateRequired')
          this.onProductDataChange({field: 'CycleTime', value: value ? 90 : null});
      },
      onFileChanged(event, prop) {
        this.onSetSelectedFile({ type: prop, file: event });
      },
      isThisSelected(val){
        return this.newProduct.ItemType == val
      },
      getLoadPdfFunc() {
        return () => loadItemFile('itemPdf', this.newProduct.PdfFile);
      }
    }
  }
</script>

<style lang="less" scoped>
.checkbox-with-field-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 10px;

  * {
    flex: 1 100px;
  }
}
</style>