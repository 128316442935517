<template>
  <div style="position: relative">   
    <div class="notification is-danger is-light" style="text-align: center">
      <h4 class="error-message">
        We are experiencing difficulties. Please try refreshing the page. 
        If the problem persists, either try again later or create a support ticket.
      </h4>
      <div v-if="errorId" class="error-id">
        Error ID: {{ errorId }}
      </div>
      <div class="buttons-container">
        <button class="button is-medium is-outlined" onclick="window.location.reload()">
          <span class="icon">
            <i class="fas fa-redo-alt"></i>
          </span>
          <span>Reload</span>
        </button>
        <button @click="$router.push({name: 'Support'})" class="button is-medium  is-outlined">
          <span class="icon">
            <i class="fas fa-headset"></i>
          </span>
          <span>Create Support Ticket</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FailedLoading',
    props: [ 'errorId' ]
  }
</script>

<style lang="less" scoped>
.error-message {
  margin-bottom: 8px;
  font-size: 24px;
}
.error-id {
  padding-top: 30px;
  text-align: center;
  font-size: 24px;
}
.buttons-container {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  padding-top: 30px;
}
</style>