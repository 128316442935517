<template>
  <Container title="Create Client">
    <template #actions>
      <ClientCreateActions :isClientValid="isClientValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <ClientHeaderInfo :isNewClient="true" @isClientValid="isClientValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <div class="form-section-heading">
            CLIENT INFORMATION
          </div>
          <ClientBasic :isNewClient="true" :isHorizontal="true" class="end-form-section" />
          <div class="form-section-heading">
            ACCOUNT INFORMATION
          </div>
          <UserAccount
            :isNewUser="true"
            :enableEditingEmailAndPassword="true"
            class="end-form-section" />
          <div class="form-section-heading">
            USER INFORMATION
          </div>
          <UserBasic :isNewUser="true" />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script>
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import ClientHeaderInfo from './ClientHeaderInfo.vue';
import ClientCreateActions from './Header/ClientCreateActions.vue';
import ClientBasic from './ClientBasic.vue';
import UserAccount from './UserAccount.vue';
import UserBasic from './UserBasic.vue';
export default {
  name: 'ClientCreate',
  components: { Container, EntityData, ClientHeaderInfo, ClientCreateActions, ClientBasic, UserAccount, UserBasic },
  data() {
    return {
      isClientValid: false,
    }
  }
}
</script>

<style lang="less" scoped>
.form-section-heading {
  font-size: 16px;
  font-weight: 350;
  letter-spacing: .5;
  margin-bottom: 20px;

  :not(:first-child) {
    margin-top: 20px;
  }
}
.end-form-section {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
</style>