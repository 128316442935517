<template>
  <EntityContainer
    :loadingString="loadingString"
    :headers="headers"
    :statusCards="statusCards"
    :cardStat="cardStat"
    :apiFunc="getImportDetails"
    :handleApiResultFunc="handleApiResultFunc"
    :tableRowPrepareFunc="prepareTableRow"
    :enablePagination="true" />
</template>

<script>
import api from './../../lib/api-gateway';
import { mapGetters, mapActions } from 'vuex';
import EntityContainer from './../Common/EntityContainer.vue';

export default {
  name: 'ImportDetail',
  props: ['loadingString', 'getHeadersFunc', 'handleApiResultFunc'],
  components: { EntityContainer },
  data() {
    return {
      currentTab: '',
      statusCards: [
        {
          name: 'Successes',
          value: 'Successes'
        },
        {
          name: 'Errors',
          value: 'Errors'
        }
      ],
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  unmounted() {
    this.onSetSelectedImport({});
  },
  computed: {
    ...mapGetters('resource', ['selectedImport']),
    cardStat() {
      return {
        Successes: this.selectedImport.NumSuccesses,
        Errors: this.selectedImport.NumErrors
      };
    },
    headers() {
      return this.getHeadersFunc(this.currentTab);
    },
    showValueColumn() {
      return this.headers.length > 1;
    }
  },
  methods: {
    ...mapActions('resource', ['onSetSelectedImport']),
    async getImportDetails(page, pageSize, tab) {
      this.currentTab = tab;
      return await api.getImportDetails(this.selectedImport._id, tab, page, pageSize);
    },
    prepareTableRow(orderImport) {
      return {
        row: this.showValueColumn
          ? [{ value: orderImport.Item1 }, { value: orderImport.Item2 }]
          : [{ value: orderImport.Item1 }]
      };
    }
  }
}
</script>
