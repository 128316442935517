<template>
    <div class="column is-12">
      <div @click="onToggleMRSection" class="mr-header">
        <h3 class="mr-header-heading" >Receipts</h3>
        <i :class="[showMaterialReceipt ? 'fas fa-caret-up' : 'fas fa-caret-down']"
        ></i>
      </div>
     <div v-show="showMaterialReceipt" class="mr-section">
        <div style="width: 100%" v-for="r in receipts" :key="r.ID" class="message blue-message">
          <div class="message-header">
            <p>{{r.WarehouseCode}}_{{r.ID}}</p>
          </div>
          <div  class="message-body">
              <div>
                <span class="mr-text-label">Received On</span>
                <p class="mr-text">{{ getDateAndTime(r.DateReceived) }}</p>
              </div>
              <div>
                <span class="mr-text-label">Product Received</span>
                <p class="mr-text">{{ r.StockNumber }}</p>
              </div>
              <div>
                <p class="mr-text-label">Total Quantity Received</p>
                <p class="mr-text">{{ r.TotalPieces }}</p>
              </div>
              <div v-if="r.LotBatchNo">
                <p class="mr-text-label">Lot Number</p>
                <p class="mr-text">{{ r.LotBatchNo }}</p>
              </div>
              <div v-if="r.ExpirationDate">
                <p class="mr-text-label">Expiration Date</p>
                <p class="mr-text">{{ getDate(r.ExpirationDate) }}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import util from './../../../lib/util.js'
  export default {
    name: 'MaterialReceipt',
    data(){
      return {
        showMaterialReceipt: false,
      }
    },
    props: ['receipts'],

     methods:{
      getDateAndTime(date){
        return util.formatAndGetDate(date)
      },
      getDate(date){
        return util.formatAndGetDateOnly(date)
      },
      onToggleMRSection(){
        this.showMaterialReceipt = !this.showMaterialReceipt;
      }
    }
  }
</script>

<style lang="less" scoped>
.mr-header {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid #ddd;
}
.mr-header-heading{
  font-size: 20px; 
  font-weight: bold
}
.mr-section{
  margin-top: 16px;
  width:100%;
}
.mr-text-label{
  color: #777;
  font-size: 14px;
}
.mr-text{
  color: #000;
  font-size: 18px;
}
</style>