<template>
  <div>
    <CollapsiblePanelHeader class="suborder-panel" @expanded="visible = $event">
      <div class="suborder-heading">
        <span class="entity-heading">{{ order._id }}</span>
        <div>
          <slot name="headingActions" />
        </div>
      </div>
    </CollapsiblePanelHeader>

    <EntityData v-show="visible" gapClass="is-5" style="margin-bottom: 2em">
      <template #EntityBasic>
        <div class="column is-8 entity-column">
          <SubOrderBasic :order="order" />
          <OrderItems :items="items" />
        </div>
      </template>
      <template #EntityExtra>
        <div class="column is-4 entity-column">
          <OrderHistory :order="order" />
          <OrderTracking v-if="order.Fulfillment" :order="order" />
        </div>
      </template>
    </EntityData>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import CollapsiblePanelHeader from '../Common/CollapsiblePanelHeader.vue';
import EntityData from '../Common/EntityData.vue';
import SubOrderBasic from './SubOrderBasic.vue';
import OrderItems from './OrderItems.vue';
import OrderHistory from './OrderHistory.vue';
import OrderTracking from './OrderTracking.vue';

const { order } = defineProps(['order']);

const visible = ref(true);
const items = new Map(
  order.Items.map(x => [x.StockNumber, { StockNumber: x.StockNumber, QuantityRequested: x.Quantity, ...x }]));
</script>

<style lang="less" scoped>
.suborder-panel {
  background: white;
  padding: .75rem;
  margin-bottom: 2rem;
  border-radius: 2px;
}
.suborder-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.entity-column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
</style>
