<template>
  <select v-bind="listAttrs" ref="list" :disabled="disabled" style="display: none">
    <option v-for="({ value, selected, i }) in entries" :key="i"
      :value="value"
      :selected="selected" />
  </select>
  <!-- v-bind after style so that users of this component can override the style set here. -->
  <DropDownPanel
    :style="{ width: `${listWidth}px` }"
    v-bind="panelAttrs"
    ref="panel"
    :label="currentLabel || instructionLabel"
    class="select-list-panel"
    :class="{ disabled }"
    @expanded="panelExpanded">
    <div v-if="enableSearch" class="dropdown-item" style="padding: .5rem">
      <input ref="searchBox"
        type="text"
        class="input"
        style="width: 100%"
        v-model.trim="search"
        placeholder="Search" />
    </div>
    <slot />
    <hr v-if="enableSearch || $slots.default" class="dropdown-divider" />
    <template v-if="filteredEntries.length">
      <a v-for="({ label, i }) in filteredEntries" :key="i"
        @click="valueSelected(i)"
        class="dropdown-item">
        {{ label }}
      </a>
    </template>
    <div v-else class="dropdown-item" style="font-style: italic">
      No options...
    </div>
  </DropDownPanel>
  <i v-if="showClearBtn && selectedIndex >= 0"
    class="fas fa-times clear-btn"
    @click="valueSelected(-1)"></i>
</template>

<script setup>
/* This component will produce HTML and Vue events if the entries in the list are changed in such
   a way that the selectedIndex changes, even if the selected value of the list stays the same. */
import { ref, computed, onMounted, watch, nextTick, useAttrs } from 'vue';
import DropDownPanel from './DropDownPanel.vue';
import { setupSelectListEntries, estimateSelectListWidth } from '../../composables/form';
import util from '../../lib/util';

defineOptions({ inheritAttrs: false });
const { listAttrs, panelAttrs } = setupAttrs();

const props = defineProps(['instructionLabel',
  'enableSearch',
  'options',
  'labelField',
  'valueField',
  'useIndexAsValue',
  'modelValue',
  'showClearBtn',
  'disabled',
  'size',
  'minWidth']);
const emit = defineEmits(['update:modelValue']);

const list = ref(null);
const panel = ref(null);
const searchBox = ref(null);

const selectedIndex = ref(0);
const search = ref(null);
const entries = computed(() => setupSelectListEntries(props));
const listWidth = computed(() => Math.max(props.minWidth || 0, estimateSelectListWidth(entries.value)));
const filteredEntries = computed(() => util.searchList(entries.value, search.value, x => x.label));
const currentLabel = computed(() => (entries.value[selectedIndex.value] || {}).label);

/* We need to sync our selectedIndex with the one from the select list, to produce proper events
   and display our list correctly. Example: the default browser's list will always preselect the
   first value unless told otherwise, but we don't want to do that if we are using an instruction label.
   Using a custom component also lets us use an instruction label without adding any options to the
   HTML select list, which is impossible with the browser's default control. */
onMounted(() => {
    if(!props.modelValue && props.instructionLabel)
      list.value.selectedIndex = -1;
    selectedIndex.value = list.value.selectedIndex;
  });
watch(entries, () => nextTick(selectedIndexChanged));

function valueSelected(i) {
  list.value.selectedIndex = i;
  panel.value.hidePanel();
  selectedIndexChanged();
}
async function selectedIndexChanged() {
  if(selectedIndex.value != list.value.selectedIndex) {
    selectedIndex.value = list.value.selectedIndex;
    await nextTick();
    list.value.dispatchEvent(new Event('change', { bubbles: true }));
    emit('update:modelValue', list.value.value);
  }
}
function panelExpanded(expanded) {
  if(props.enableSearch && expanded)
    searchBox.value.focus();
}
function setupAttrs() {
  const panelAttrs = { ...useAttrs() };
  const listAttrs = { onChange: panelAttrs.onChange };
  delete panelAttrs.onChange;
  return { listAttrs, panelAttrs };
}
</script>

<style lang="less">
.select-list-panel {
  * {
    color: var(--theme-control-text) !important;
  }
  .dropdown-menu {
    min-width: 100%;
  }
  .dropdown-item {
    font-size: 1em;
  }
  a.dropdown-item:hover {
    color: var(--theme-blue) !important;
    background-color: var(--theme-light-blue);
  }
  .expand-button, .dropdown-item {
    padding-left: calc(.75em - 1px); // Match other inputs
  }
  &.disabled {
    cursor: not-allowed;
    .expand-button {
      pointer-events: none;
      background-color: whitesmoke;
    }
  }
}
</style>
<style lang="less" scoped>
.clear-btn {
  font-size: 1.25em;
  vertical-align: baseline;
  position: relative;
  top: 1px;
  padding: .3em;
  cursor: pointer;
  margin-left: .4em;
}
</style>
