<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Settings' })" />
      <ComponentHeaderButton
        btnText="Add Webhook"
        iconName="fa fa-plus"
        @handler="$router.push({ name: 'WebhookCreate' })" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
export default {
  name: 'WebhookHeaderActions',
  components: { ComponentHeaderActions, ComponentHeaderButton }
}
</script>
