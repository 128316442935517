<template>
  <FfyColumnsView>
    <template #form>
      <div style="width: 450px; max-width: 100%">
        <div v-if="success">
          <p class="custom-label-style" style="margin-bottom: 10px">
            If we have this email on file, an email with a password reset link was sent to it. Please check your email.
          </p>
          <ButtonWithSpinner label="Back to Login" class="is-fullwidth submit-btn is-large" @click="backToLogin" />
        </div>
        <form v-else v-on:submit.prevent="submitRequest">
          <div>
            <p class="custom-label-style" style="margin-bottom: 10px">
              Enter your email address, and you will receive an email with a link to reset your password:
            </p>
            <div class="control has-icons-left has-icons-right">
              <input v-model.trim="email" class="input is-large" placeholder="Email" />
              <span class="icon is-left is-large">
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <span class="icon is-right is-large">
                <i class="fa fa-check"></i>
              </span>
            </div>
          </div>
          <div style="margin-top: 20px">
            <ButtonWithSpinner
              label="Submit Request"
              class="is-fullwidth submit-btn is-large"
              :isSubmit="true"
              :isInProcess="submitting" />
            <div 
              v-if="haveError || errMessage" 
              style="color:orange; font-weight: 600; text-align: center"
            >
              <template v-if="errMessage">
                {{ errMessage }}
              </template>
              <template v-else>
                Our system can't fulfill this request at this time. Please try again later. If the error persists, contact support.
              </template>
            </div>
            <hr />
            <div style="">
              <p class="support-text">
                NEED HELP? EMAIL
                <a href="mailto:support@fulfillify.com" style="color: #ffa500 !important">SUPPORT@FULFILLIFY.COM</a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </template>
  </FfyColumnsView>
</template>

<script>
// @ is an alias to /src
import api from './../lib/api-gateway';
import util from './../lib/util';
import FfyColumnsView from "../components/Common/FfyColumnsView.vue";
import ButtonWithSpinner from "../components/Common/ButtonWithSpinner.vue";
export default {
  name: "PasswordResetRequest",
  components: { FfyColumnsView, ButtonWithSpinner },
  data: function() {
    return {
      email: '',
      submitting: false,
      success: false,
      haveError: false,
      errMessage: ''
    }
  },
  methods: {
    async submitRequest() {
      this.haveError = false;
      this.errMessage = '';

      if(!this.email) {
        this.errMessage = 'Email is required';
        return;
      }
      if(!util.testEmail(this.email)) {
        this.errMessage = 'Invalid email address';
        return;
      }

      this.submitting = true;
      const res = await api.requestPasswordReset(this.email);
      this.submitting = false;

      if(!res.error)
        this.success = true;
      else
        this.haveError = true;
    },
    backToLogin() {
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>

<style lang="less" scoped>
.custom-label-style { 
  color:#fff !important;
}
.support-text{
  background: #fff; 
  border-radius: 4px;
  font-weight: bold;
  padding: 8px; 
  text-align: center; 
}
@media screen and (min-width: 561px)
  and (max-width: 880px){
    .support-text{
      border-radius: 2px;
      font-size: 16px;
      padding: 4px;
    }
  }
@media screen and (min-width: 270px)
  and (max-width: 560px){
    .support-text{
      border-radius: 2px;
      font-size: 12px;
      padding: 4px;
    }
  }
</style>
