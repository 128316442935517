import store from '../store';
import api from '../lib/api-gateway';
import { makeApiCall, makeApiCallGetResponse } from './api'

export async function loadOrderFile(field, fileName) {
  const res = await makeApiCallGetResponse(
    'Loading file...',
    () => api.getOrderFile(`${store.getters[`order/selectedOrder`]._id}/${fileName}`));
  
  if(res)
    store.dispatch('order/onSetLoadedFile', { file: field, data: res.File });
}

export async function checkOrderWarehouseStatus(orderNumber) {
  return await makeApiCallGetResponse(
    'Checking order warehouse status...',
    () => api.checkOrderWarehouseStatus(orderNumber));
}

export async function fixAndCloseOrder(orderNumber) {
  return await makeApiCall(
    'Fixing and closing order...',
    (res) => res,
    () => api.fixAndCloseOrder(orderNumber));
}

export async function forceCloseOrder(orderNumber, shipDate, carrier, serviceLevel, trackingNumbers) {
  const filteredTrackingNumbers = trackingNumbers.filter(Boolean);

  return await makeApiCall(
    'Force closing order...',
    (res) => res,
    () => api.forceCloseOrder(
      orderNumber.toString(),
      shipDate,
      carrier || null,
      serviceLevel || null,
      filteredTrackingNumbers.length ? filteredTrackingNumbers : null));
}

export function getOrderItems(order) {
  return new Map(order.Items.map(x => [x.Product.Name, { StockNumber: x.Product.Name, ...x }]));
}

export function setupOrderItemsForEdit(order) {
  const items = new Map(order.Items.map(item =>
    [
      item.Product.Name,
      {
        ItemType: item.ItemType,
        StockNumber: item.Product.Name,
        Quantity: item.QuantityRequested,
        LotNo: item.LotNo,
        Tags: item.Tags }
    ]));

  store.dispatch('onItemsAssignment', items);
}
