<template>
  <!-- Remove wrapper once EntityList supports mobile widths. Set to both
       dimensions because absolutely positioned elements can cause overflow. -->
  <div style="overflow: hidden">
    <table class="table is-fullwidth text-size" style="border-radius: 10px">
      <thead>
        <EntityHeaderRow
          :headers="headers"
          :enableCheckboxSelection="anyRowsHaveActions"
          :checkboxChecked="allEnabledRowsSelected"
          :checkboxDisabled="!selectedIds.length && !commonActionsForAllRowsWithActions.length"
          @checkboxToggled="toggleSelectAll" />
      </thead>
      <tbody>
        <template v-for="tableData in tableDataArray" :key="tableData._id">
          <EntityRow ref="rows"
            :tableData="tableData"
            :enableRowSelection="enableRowSelection"
            :enableCheckboxSelection="anyRowsHaveActions"
            :checkboxDisabled="!rowIsEnabled(tableData._id)"
            @selectThis="rowSelected"
            @checkboxToggled="rowToggled(tableData._id, $event)"
            v-slot="props">
            <slot name="cellSlot" v-bind="props"></slot>
          </EntityRow>
          <slot name="afterRowSlot" v-bind="tableData"></slot>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import EntityHeaderRow from './EntityHeaderRow.vue';
  import EntityRow from './EntityRow';
  export default {
    name: 'EntityList',
    props: ['headers', 'tableDataArray', 'enableRowSelection'],
    emits: ['selectionChanged', 'actionsChanged', 'changeRow'],
    components: { EntityHeaderRow, EntityRow },
    data() {
      return {
        selectedIds: []
      }
    },
    watch: {
      selectedIds: {
        handler(value) {
          this.$emit('selectionChanged', [...value]);
        },
        deep: true
      },
      commonActionsForSelectedRows(value) {
        // selectedIds event needs to be emitted first
        this.$nextTick(() => this.$emit('actionsChanged', [...value]));
      }
    },
    computed: {
      actionsForEntities() {
        return this.tableDataArray.reduce(
          (o, row) => {
            const rowActions = row.actions;
            if(rowActions && rowActions.length)
              o[row._id] = rowActions;
            return o;
          }, {});
      },
      commonActionsForSelectedRows() {
        return this.getCommonActionsForRows(this.selectedIds);
      },
      commonActionsForAllRowsWithActions() {
        return this.getCommonActionsForRows(Object.keys(this.actionsForEntities));
      },
      anyRowsHaveActions() {
        return !!Object.keys(this.actionsForEntities).length;
      },
      allEnabledRowsSelected() {
        return Object.keys(this.actionsForEntities).every(id =>
          !this.rowIsEnabled(id) || this.selectedIds.indexOf(id) >= 0)
      }
    },
    methods:{
      rowIsEnabled(id) {
        const rowActions = this.actionsForEntities[id];

        if(!rowActions)
          return false;

        for(const action of this.commonActionsForSelectedRows)
          if(rowActions.indexOf(action) == -1)
            return false;
        
        return true;
      },
      getCommonActionsForRows(ids) {
        if(!ids.length)
          return [];

        const actions = new Set(this.actionsForEntities[ids[0]]);
        
        for(var action of [...actions])
          for(const id of ids)
            if(this.actionsForEntities[id].indexOf(action) == -1) {
              actions.delete(action);
              break;
            }

        return [...actions];
      },
      rowSelected(val) {
        this.$emit('changeRow', val);
      },
      rowToggled(id, checked) {
        checked
          ? this.selectedIds.push(id)
          : this.selectedIds.splice(this.selectedIds.indexOf(id), 1);
      },
      toggleSelectAll() {
        this.selectAll(!this.allEnabledRowsSelected);
      },
      selectAll(select) {
        if(!this.$refs.rows)
          return;

        const selectedIds = [];
        
        for(const row of this.$refs.rows) {
          const id = row.tableData._id;
          const selectRow = select && this.rowIsEnabled(id);

          if(selectRow)
            selectedIds.push(id);
          row.selectCheckbox(selectRow);
        }

        this.selectedIds = selectedIds;
      }
    }
  }
</script>
