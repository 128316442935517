<template>
  <Modal v-if="loadedInv.length"
    title="Select Tags"
    width="1000px"
    :buttons="[clearButtonData]"
    :defaultOkCancelBtnData="[{ label: 'Update', disabled: formHasErrors }, 'Cancel']"
    @close="doAction">
    <table class="text-size" style="width: 100%">
      <thead>
        <tr class="header-row theme-gray-bg">
          <th></th>
          <th>Tag</th>
          <th>Location</th>
          <th>Lot Number</th>
          <th>Expiration Date</th>
          <th>Available</th>
          <th>Pick</th>
          <th>Leave</th>
        </tr>
      </thead>
      <tbody>
        <DeductOrderItemTagLine ref="lines"
          v-for="inv in loadedInv"
          :key="inv._id"
          :inv="inv"
          :existingTag="tags.find(x => x.TagNumber == inv.TagNumber)"
          @selectedOrErrChanged="updateFormHasErrors" />
      </tbody>
    </table>
  </Modal>
</template>

<script>
const clearButtonData = {
  label: 'Clear',
  classes: 'blue-button',
  style: 'width: 100px',
  value: 2
};
</script>
<script setup>
import { ref, computed, onMounted, inject, nextTick } from 'vue';
import { useStore } from 'vuex';
import Modal from '../Common/Modal.vue';
import DeductOrderItemTagLine from './DeductOrderItemTagLine.vue';
import { loadItemTagInventory } from '../../composables/item';

const { stockNumber, warehouse } = defineProps(['stockNumber', 'warehouse']);
const emit = defineEmits(['close']);
const alert = inject('alert');
const store = useStore();

const formHasErrors = ref(false);
const lines = ref(null);
const tags = store.getters['selectedItems'].get(stockNumber).Tags || [];
const loadedInv = computed(() => (store.getters['product/inventoryTags'][stockNumber] || {})[warehouse] || []);

onMounted(async () => {
    if(!await loadItemTagInventory(stockNumber, warehouse)) {
      alert(`No tag data found for item ${stockNumber} in warehouse ${warehouse}.`);
      emit('close');
    }
  });

function updateFormHasErrors() {
  nextTick(() => formHasErrors.value = lines.value && lines.value.some(x => x.selected && x.error));
}
function doAction(value) {
  if(value == 1) {
    const tags = lines.value.filter(x => x.selected && x.tag.Quantity).map(x => x.tag);
    const item = tags.length
      ? { Quantity: tags.reduce((q, x) => q + x.Quantity, 0), Tags: tags }
      : { Quantity: 1, Tags: null };
    
    store.dispatch('onUpdateSelectedItem', { stockNumber, item });
  } else if(value == 2)
    lines.value.forEach(x => x.clear());

  if(value != 2)
    emit('close');
}
</script>

<style lang="less" scoped>
.header-row {
  height: 45px;
  & th {
    padding: 0 .4em;
    vertical-align: middle;
  }
}
</style>
