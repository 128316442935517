<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Settings' })" />
      <ComponentHeaderButton
        btnText="Assign Service Levels"
        iconName="fas fa-exchange-alt"
        :useButtonStyle="true"
        @handler="onToggleShowAssignServiceLevelsModal" />
      <ComponentHeaderButton
        btnText="Add Custom Service Level"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="onToggleShowAddCustomServiceLevelModal" />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import { mapActions } from 'vuex';
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
export default {
  name: 'ServiceLevelActions',
  components: { ComponentHeaderActions, ComponentHeaderButton },
  methods: {
    ...mapActions('manage', ['onToggleShowAssignServiceLevelsModal', 'onToggleShowAddCustomServiceLevelModal'])
  }
}
</script>
