<template>
  <!-- This component registers a click handler on the element that comes before it in the DOM,
       to pop up a custom panel right under that element. -->
  <Teleport to="body" :disabled="!isSetup">
    <div ref="panelRef" class="popup-panel-div" style="display: none">
      <slot></slot>
    </div>
  </Teleport>
</template>

<script>
document.addEventListener('click', hideAllPanels);

function hideAllPanels() {
  document.querySelectorAll('.popup-panel-div').forEach(x => x.style.display = 'none');
}
</script>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const panelRef = ref(null);
const isSetup = ref(false);
let panel = null;
let clickEl = null;

onMounted(() => {
    panel = panelRef.value;
    clickEl = panel.previousElementSibling;
    clickEl.addEventListener('click', toggleVisibility);
    isSetup.value = true;
  });
onBeforeUnmount(() => clickEl.removeEventListener('click', toggleVisibility));

function show() {
  hideAllPanels();

  const clickElRect = clickEl.getBoundingClientRect();
  panel.style.top = (document.documentElement.scrollTop + clickElRect.bottom) + 'px';
  panel.style.right = (document.documentElement.clientWidth - clickElRect.right) + 'px';
  panel.style.display = null;
}

function hide() {
  panel.style.display = 'none';
}

function toggleVisibility(evt) {
  evt.stopImmediatePropagation();
  panel.style.display == 'none' ? show() : hide();
}
</script>

<style lang="less" scoped>
.popup-panel-div {
  position: absolute;
  z-index: 10;
}
</style>
