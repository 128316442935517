<template>
  <Modal
    title="Add Custom Service Level"
    width="600px"
    :defaultOkCancelBtnData="[{ label: 'Add Service Level', disabled: !isValid }, 'Cancel']"
    @close="doAction">

    <CarrierList @change="carrier = $event.target.value" />
    
    <p class="custom-label">Select a service level:</p>
    <ServiceLevelList :carrierId="carrier" @change="serviceLevel = $event.target.value" />
    
    <p class="custom-label">Custom service level text:</p>
    <input v-model.trim="customServiceLevelText" class="input" type="text" />
  </Modal>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import Modal from '../Common/Modal.vue';
import CarrierList from '../Common/CarrierList.vue';
import ServiceLevelList from '../Common/ServiceLevelList.vue';
import api from '../../lib/api-gateway';
import { makeApiCall } from '../../composables/api';

const store = useStore();

const carrier = ref(null);
const serviceLevel = ref(null);
const customServiceLevelText = ref('');
const client = store.getters['currentClient'];

const isValid = computed(() => carrier.value && serviceLevel.value && customServiceLevelText.value);
watch(carrier, () => serviceLevel.value = null);

function doAction(value) {
  if(!value)
    store.dispatch('manage/onToggleShowAddCustomServiceLevelModal');
  else
    makeApiCall('Adding custom service level...',
      'Custom service level added successfully.',
      makeAddCustomServiceLevelCalls);
}

async function makeAddCustomServiceLevelCalls() {
  const res = await api.addCustomServiceLevel(serviceLevel.value, customServiceLevelText.value);
  if(!res.error && !client.OnboardingFlags.ServiceLevelsSetup)
    await api.updateClientOnboardingFlags(client._id, { ServiceLevelsSetup: true })
  return res;
}
</script>

<style lang="less" scoped>
.custom-label {
  padding: .25em;
  margin-top: .75em;
}
</style>