<template>
  <CollapsiblePanel class="detail-panel" heading="Order Details">
    <div class="columns" style="padding: .75rem 0">
      <div class="column is-6 detail-column">
        <div class="field">
          <label class="label">Order Name</label>
          <div class="entity-value">
            {{ order.Name }}
          </div>
        </div>
        <div class="field">
          <label class="label">Warehouse</label>
          <div class="entity-value">
            {{ warehouses.get(order.Warehouse).Name }}
          </div>
        </div>
        <div class="field">
          <label class="label">Created By</label>
          <div class="entity-value">
            {{ order.OrderBy }}
          </div>
        </div>
        <div class="field">
          <label class="label">Delivery Option</label>
          <div class="entity-value">
            {{ order.DeliveryOption }}
          </div>
        </div>
        <div class="field">
          <label class="label">Ship Date</label>
          <div v-if="order.ShipByDate" class="entity-value">
            {{ util.formatAndGetDateOnly(order.ShipByDate) }}
          </div>
          <div v-else class="entity-value-empty">
            NOT PROVIDED
          </div>
        </div>
      </div>
      <div class="column is-6 detail-column">
        <div class="field">
          <label class="label">Current Status</label>
          <div class="entity-value">
            {{ util.addSpacesBeforeCapitals(order.Status) }}
          </div>
        </div>
        <div class="field">
          <label class="label">Current Warehouse Status</label>
          <div class="entity-value">
            {{ util.addSpacesBeforeCapitals(order.WarehouseStatus) }}
          </div>
        </div>
        <div class="field">
          <label class="label">Shipping Label</label>
          <FilePreviewField
            :isInline="true"
            titleOverride="Shipping Label"
            :fileName="order.ShippingLabel"
            :fileData="files.ShippingLabel"
            :fileLoadFunc="() => loadOrderFile('ShippingLabel', order.ShippingLabel)" />
        </div>
        <div class="field">
          <label class="label">Customer Invoice</label>
          <FilePreviewField
            :isInline="true"
            titleOverride="Customer Invoice"
            :fileName="order.InvoiceFile"
            :fileData="files.InvoiceFile"
            :fileLoadFunc="() => loadOrderFile('InvoiceFile', order.InvoiceFile)" />
        </div>
        <div v-if="checkCurrentClientFeature('VUE_APP_UCC_LABELS_CLIENTS')" class="field">
          <label class="label">Box Labels Requested</label>
          <div :class="order.RequestUCCLabels ? 'entity-value' : 'entity-value-empty'">
            {{ order.RequestUCCLabels ? 'YES' : 'NO' }}
          </div>
        </div>
      </div>
    </div>
  </CollapsiblePanel>
</template>

<script setup>
import { useStore } from 'vuex';
import util from '../../lib/util';
import { loadOrderFile } from '../../composables/order';
import CollapsiblePanel from '../Common/CollapsiblePanel.vue';
import FilePreviewField from '../Common/FilePreviewField.vue';
import { checkCurrentClientFeature } from '../../composables/client_config';

defineProps(['order']);
const store = useStore();

const files = store.getters['order/loadedFiles'];
const warehouses = store.getters['warehouses'];
</script>

<style lang="less" scoped>
.detail-panel {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 2px;
}
.detail-column {
  display: flex;
  flex-direction: column;
  row-gap: .75rem;
}
.label {
  font-weight: normal;
}
</style>
