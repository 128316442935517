<template>
  <div class="switch-container" :style="{ width: width ? width : '100px' }">
    <input type="checkbox" class="switch-checkbox" :checked="on" :id="id" @change="toggled" />
    <label class="switch-label" :for="id">
      <span class="switch-inner">
        <span class="on-label">{{ onLabel ? onLabel : 'YES' }}</span>
        <span class="off-label">{{ offLabel ? offLabel : 'NO' }}</span>
      </span>
      <span class="switch-knob"></span>
    </label>
  </div>
</template>

<script>
let idx = 0;
export default {
  name: 'OnOffSwitch',
  props: [ 'width', 'on', 'onLabel', 'offLabel' ],
  emits: ['toggled'],
  data() {
    return {
      id: `onOffSwitch${idx++}`
    }
  },
  methods: {
    toggled(event) {
      this.$emit('toggled', event.target.checked);
    }
  }
}
</script>

<style lang="less" scoped>
.switch-container {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.switch-checkbox {
  display: none;
}
.switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
}
.switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.on-label, .off-label {
  display: inline-block;
  width: 50%;
  height: 2em;
  padding: 0;
  line-height: 2em;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.on-label {
  padding-right: 1.6em;
  background-color: #8dc63f;
  color: #FFFFFF;
  text-align: center;
}
.off-label {
  padding-left: 1.6em;
  background-color: #EEEEEE;
  color: #999999;
  text-align: center;
}
.switch-knob {
  display: block;
  width: 2.4em;
  height: 2.4em;
  margin: -.2em;
  background: #FFFFFF;
  position: absolute;
  top: .1em;
  bottom: 0;
  right: calc(100% - 2.1em);
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in 0s;
}
.switch-checkbox:checked + .switch-label .switch-inner {
  margin-left: 0;
}
.switch-checkbox:checked + .switch-label .switch-knob {
  right: 0;
}
</style>
