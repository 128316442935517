<template>
  <div class="backorder-product-container">
    <div class="backorder-product-section">
    <h3 class="backorder-items-header theme-blue">
      Backorder Products
    </h3>
    <div class="backorder-items-container">
      <PieChartComponent
        chart-type="PieChart"
        :ordersStat="productBackOrderData.topBackOrder"
        :height="250"
        :width="300"
      />
      <div class="chart-container">
        <table class="table is-narrow">
          <tbody>
            <tr v-for="bck in productBackOrderData.topBackOrder" :key="bck[0]">
              <td>
                <div class="table-row">
                  {{ bck[0] }}
                </div>
              </td>
              <td>
                <div class="table-row">
                  {{ bck[1] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import PieChartComponent from "./../Charts/PieChartComponent";
export default {
  components: { PieChartComponent },
  name: "BackOrderItems",
  props: ["productBackOrderData"],
};
</script>

<style lang="less" scoped>
.backorder-product-container{
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 2.5px 5px 10px #888888;
}
.backorder-items-container {
  display: flex;
  flex-basis: 200px;
  min-height: 200px;
}
.backorder-items-header {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 8px;
}
.table-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 100px;
  height: 100%;
  color: #000 !important;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 561px)
  and (max-width: 880px){
    .backorder-product-container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 28px;
    }
  }
 @media screen and (min-width: 270px)
  and (max-width: 560px){
  .backorder-product-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 28px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 12px;
    padding-bottom: 16px;
  }
  .backorder-product-section{
    width: 100%;
  }
  .chart-container{
    overflow: auto;
    width: 100%;
  }
  .backorder-items-header {
    font-size: 18px;
  }
}
@media screen and (min-width: 881px)
  and (max-width: 1400px){
  .backorder-product-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 28px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
</style>