<template>
  <div class="column is-7 product-item-container">
    <Dimensions />
    <div v-if="newProduct.ItemType == 'Bundled'" class="container items-custom">
      <ItemSearchInput :searchForActiveItems="isCreateProduct || newProduct.IsActive" :excludeKits="true" />
      <ItemListEdit :items="selectedItems" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ItemSearchInput from './../../Common/ItemSearchInput' ;
import ItemListEdit from './../../Common/ItemListEdit';
import Dimensions from './Dimensions'

defineProps(['isCreateProduct']);

const store = useStore();
const selectedItems = computed(() => store.getters['selectedItems']);
const newProduct = computed(() => store.getters['product/newProduct']);
</script>

<style lang="less" scoped>
  .product-item-container {
    padding-top: 11px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .items-custom {
    margin-top: 16px;
  }
</style>