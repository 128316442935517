<template>
  <EntityContainer
    :loadingString="loadingString"
    :headers="headers"
    :apiFunc="getOrderImports"
    :handleApiResultFunc="handleApiResult"
    :tableRowPrepareFunc="prepareTableRow"
    :enablePagination="true"
    @changeRow="importSelected" />
</template>

<script>
  import api from './../../lib/api-gateway';
  import util from './../../lib/util';
  import { mapActions } from 'vuex';
  import EntityContainer from  './../Common/EntityContainer';
  export default {
    name: 'Imports',
    props: ['importType', 'loadingString', 'handleApiResultFunc', 'importSelectedFunc'],
    emits: ['importSelected'],
    data() {
      return {
        headers: [
          'FILE NAME',
          'START DATE',
          'FINISH DATE',
          '# IMPORTED',
          '# ERRORS',
          'MESSAGE',
          'STATUS'
        ],
        currentPageImports: {},
        tableDataArray: [],
        haveError: false,
        errorId: null
      }
    },
    components: { EntityContainer },
    methods: {
      ...mapActions(['onMakingAPICalls', 'onSetResponseContent']),
      ...mapActions('resource', ['onSetSelectedImport']),
      async getOrderImports(page, pageSize) {
        return await api.getImports(this.importType, page, pageSize);
      },
      handleApiResult(res, entities) {
        if(!res.error)
          this.currentPageImports = entities;
        
        this.handleApiResultFunc(res, entities);
      },
      prepareTableRow(importReq) {
        return {
          _enableRowSelection: ['Finished', 'FinishedWithErrors'].indexOf(importReq.ImportStatus) >= 0,
          row: [
            { value: importReq.FileName },
            { value: util.formatAndGetDate(importReq.createdAt) },
            { value: importReq.FinishedAt ? util.formatAndGetDate(importReq.FinishedAt) : '' },
            { value: importReq.NumSuccesses },
            { value: importReq.NumErrors },
            { value: importReq.Message },
            { value: this.getImportStatusString(importReq.ImportStatus) }
          ]
        };
      },
      getImportStatusString(status) {
        return status.slice(0, 1) + util.addSpacesBeforeCapitals(status.slice(1)).toLowerCase();
      },
      importSelected(id) {
        this.onSetSelectedImport(this.currentPageImports[id]);
        this.$emit('importSelected');
      }
    }
  }
</script>
