<template>
  <tr class="tag-row" @click="toggleTag">
    <td class="checkbox-cell">
      <input type="checkbox" :checked="selected" :disabled="!available" />
    </td>
    <td>{{ inv.TagNumber }}</td>
    <td>{{ inv.Location }}</td>
    <td>{{ inv.LotNumber }}</td>
    <td>{{ inv.ExpirationDate ? util.formatAndGetDateOnly(inv.ExpirationDate) : '' }}</td>
    <td>{{ inv.AvailableToOrder }}</td>
    <td @click.stop>
      <input
        type="number"
        class="input qty-input input-no-arrows"
        :value="tag.Pick"
        @input="quantityEntered('Pick', $event.target.value)"
        :disabled="!available" />
    </td>
    <td @click.stop>
      <input
        type="number"
        class="input qty-input input-no-arrows"
        :value="tag.Leave"
        @input="quantityEntered('Leave', $event.target.value)"
        :disabled="!available" />
    </td>
  </tr>
  <tr v-show="selected && error">
    <td colspan="8" class="theme-error" style="text-align: right">
      {{ error }}
    </td>
  </tr>
</template>

<script>
const pickErrStr = 'Pick quantity must be between 1 and Available.';
const leaveErrStr = 'Leave quantity must be between 1 and (Available - 1).';
</script>
<script setup>
import { ref, reactive, computed, watch } from 'vue';
import util from '../../lib/util';

const { inv, existingTag } = defineProps(['inv', 'existingTag']);
const emit = defineEmits(['selectedOrErrChanged']);

const available = !!inv.AvailableToOrder;
const tag = reactive({
    ...existingTag,
    TagNumber: inv.TagNumber, 
    LotNo: inv.LotNumber,
    ExpDate: inv.ExpirationDate
  });
const selected = ref(available && existingTag && existingTag.Quantity);
const error = computed(() =>
  getQtyError(tag.Pick, inv.AvailableToOrder, pickErrStr) || getQtyError(tag.Leave, inv.AvailableToOrder - 1, leaveErrStr));

watch([selected, error], () => emit('selectedOrErrChanged'), { immediate: true });

function getQtyError(qty, max, errorStr) {
  return qty && (qty < 1 || qty > max) ? errorStr : null;
}
function toggleTag(i) {
  selected.value = available && !selected.value;
};
function quantityEntered(type, val) {
  const otherType = type == 'Pick' ? 'Leave' : 'Pick';
  const qty = parseInt(val);

  tag[type] = qty;
  tag[otherType] = null;
  tag.Quantity = type == 'Pick' ? qty : inv.AvailableToOrder - qty;

  selected.value = !!qty;
}
function clear() {
  tag.Pick = null;
  tag.Leave = null;
  tag.Quantity = null;

  selected.value = false;
}

defineExpose({ tag, selected, error, clear });
</script>

<style lang="less" scoped>
.tag-row:hover {
  background: var(--theme-gray-bg);
}
.tag-row td {
  padding: .2em .2em;
  vertical-align: middle;
}
.checkbox-cell {
  padding-left: .75em !important;
  cursor: pointer;
}
.qty-input {
  width: 80px;
}
</style>
