<template>
  <SelectList instructionLabel="-- Select a Role --" :options="roles" />
</template>

<script>
import { mapGetters } from 'vuex';
import SelectList from '../Common/SelectList.vue';
export default {
  name: 'RoleList',
  props: ['isNewUser', 'isSearch'],
  components: { SelectList },
  computed: {
    ...mapGetters(['isUserAdmin', 'isUserInternalOrOwner']),
    isAdminSection() {
      return this.$route.params.isAdminSection;
    },
    roles() {
      return this.isAdminSection
        ? ['Admin', 'Manager'] : (this.isUserAdmin && this.isNewUser) || (this.isUserInternalOrOwner && this.isSearch)
        ? ['Owner', 'User', 'API']
        : ['Owner', 'User'];
    }
  }
}
</script>
