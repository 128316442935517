<template>
  <div class="column is-3">
    <div class="columns">
      <div  class="column is-12">
        <div class="field">
          <label class="label  custom-label-style">Order Status</label>
          {{ getVal(order.Status ) }}
          <p v-if="onHoldReason" class="is-medium has-background-warning-light on-hold-reason">
            {{ onHoldReason }}
          </p>
        </div>
        <div class="field">
          <label class="label custom-label-style">WH Status</label>
          {{ getVal(order.WarehouseStatus ) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import util from './../../lib/util.js';

const order = useStore().getters['order/selectedOrder'];
const onHoldReason = order.Status.slice(0, 6) == 'OnHold' ? order.OnHoldReason : null;

function getVal(val) {
  return util.getUpperCaseVal(util.addSpacesBeforeCapitals(val));
}
</script>

<style lang="less" scoped>
  .custom-label-style{
    margin: 0px !important;
    padding-bottom: 5px;
  }
  .on-hold-reason {
    margin-top: 10px;
    padding: 5px 10px;
  }
</style>