<template>
  <nav role="navigation" aria-label="pagination">
    <div class="page-nav-container">
      <div class="control">
        <button
          class="button nav-prev-next"
          @click="$emit('onPreviousPage')"
          :disabled="currentPage == 1"
          title="Go to previous page">
          &lt;
        </button>
      </div>
      <div class="control page-num-input-container text-size">
        Page
        <input
          ref="pageBox"
          type="number"
          class="input page-num-input input-no-arrows"
          :value="currentPage"
          @focus="$event.target.select()"
          @blur="resetPageIfInvalid"
          @input="validatePageNumber"
          @keydown.enter="goToPage" />
        of <span style="margin-left: 2px">{{totalPages}}</span>
      </div>
      <div class="control">
        <button
          class="button nav-prev-next"
          :disabled="!totalPages || currentPage == totalPages" 
          @click="$emit('onNextPage')"
          title="Go to next page">
          &gt;
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'PageNavigation',
    props: [ 'currentPage', 'totalPages' ],
    emits: ['onPreviousPage', 'onNextPage', 'onGoToPage'],
    methods: {
      validatePageNumber() {
        const field = this.$refs.pageBox;
        if(field.value)
          field.value = field.value.replace(/[^0-9]/, '');
      },
      goToPage() {
        let goToPage = this.$refs.pageBox.value
        if(goToPage >= 1 && goToPage <= this.totalPages)
          this.$emit('onGoToPage', parseInt(goToPage))
      },
      resetPageIfInvalid() {
        if(!this.$refs.pageBox.value)
          this.$refs.pageBox.value = this.currentPage;
        else {
          const num = parseInt(this.$refs.pageBox.value);
          if(isNaN(num) || num > this.totalPages)
            this.$refs.pageBox.value = this.currentPage;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.page-nav-container {
  display: flex;
  justify-content: center;
  gap: 0 .75rem;
}
.nav-prev-next {
  padding-left: 15px;
  padding-right: 15px;
}
.page-num-input-container {
  margin-left: 8px;
  margin-right: 8px;
  white-space: nowrap;
}
.page-num-input {
  width: 40px;
  text-align: center;
  vertical-align: baseline;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px 5px;
}
</style>
