export const getters = {
  newClient: state => state.newClient,
  newClientConfig: state => state.newClientConfig,
  newUser: state => state.newUser,
  newRecipient: state => state.newRecipient,
  newWebhook: state => state.newWebhook,
  selectedClient: state => state.selectedClient,
  selectedUser: state => state.selectedUser,
  selectedRecipient: state => state.selectedRecipient,
  selectedWebhook: state => state.selectedWebhook,
  showAssignServiceLevelsModal: state => state.showAssignServiceLevelsModal,
  showAddCustomServiceLevelModal: state => state.showAddCustomServiceLevelModal
};
