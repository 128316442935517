<template>
  <Container :title="selectedImport.FileName"
    :haveError="haveError"
    :errorId="errorId">
    <template #actions>
      <OrderImportDetailActions />
    </template>
    
    <ImportDetail
      loadingString="Loading Import Data. Please Wait..."
      :getHeadersFunc="getHeaders"
      :handleApiResultFunc="handleApiResult" />
  </Container>
</template>

<script>
import { mapGetters } from 'vuex';
import Container from './../Common/Container.vue';
import ImportDetail from './../Common/ImportDetail.vue';
import OrderImportDetailActions from './Header/OrderImportDetailActions.vue';

export default {
  name: 'OrderImportDetail',
  components: { Container, ImportDetail, OrderImportDetailActions },
  data() {
    return {
      haveError: false,
      errorId: null
    }
  },
  computed: {
    ...mapGetters('resource', ['selectedImport'])
  },
  methods: {
    getHeaders(currentTab) {
      return [
        'ORDER NAME',
        currentTab == 'Successes' ? 'ORDER NO.' : 'ERROR'
      ];
    },
    handleApiResult(res) {
      if(res.error) {
        this.haveError = true;
        this.errorId = res.errorId;
      }
    }
  }
}
</script>
