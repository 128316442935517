<template>
  <button
    class="button components-header-button"
    :class="useButtonStyle ? getButtonColorStyle() : 'components-header-text-button'"
    :disabled="disabled"
    @click="onClick">
    <i v-if="isSubmenuBtn" :class="iconName" class="components-header-button-icon" style="margin-right: 14px"></i>
    <template v-else>
      <i :class="iconName" class="components-header-button-icon"></i>
      <br style="display: none" class="show-on-small-screens" />
    </template>
    <span>{{btnText}}</span>
  </button>
</template>

<script>
  export default {
    name: 'ComponentHeaderButton',
    props: ['btnText', 'iconName', 'isSubmenuBtn', 'disabled', 'useButtonStyle', 'isOutlinedButton', 'isDanger'],
    emits: ['handler'],
    methods: {
      getButtonColorStyle() {
        return `${ this.isDanger ? 'red' : 'blue' }-button${ this.isOutlinedButton ? '-outlined' : '' }`;
      },
      onClick() {
        if(!this.disabled)
          this.$emit('handler');
      }
    }
  }
</script>

<style lang="less" scoped>
  .components-header-button {
    display: inline-block; // Undo Bulma
    height: unset; // Undo Bulma
    min-height: 3.1em;
    border-radius: 7px;
    padding: 1px 1.8vw;
    white-space: nowrap;
    font-size: 14px;
  }
  .components-header-text-button {
    outline: none;
    border: none;
    background: transparent;
    font-weight: bold;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed !important;
      color: #999 !important;
    }
    &:focus:not(:active) {
      box-shadow: none; // Undo Bulma
    }
  }
  .components-header-button-icon {
    width: .8em; // FAS icon widths vary
    font-size: .9em;
  }

  @media screen and (max-width: 880px) {
    .show-on-small-screens {
      display: initial !important;
    }
  }

  @media screen and (min-width: 881px) {
    .components-header-button-icon {
      margin-right: 14px;
    }
  }
</style>