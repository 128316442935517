<template>
  <div class="ffy-container">
    <div class="brand-section">
      <div class="brand-box">
        <div class="product-image-div">
          <img
            alt="Fulfillify Logo"
            src="../../assets/fulfillify_logo_2.0.png"
            class="product-image"
          />
        </div>
        <div class="brand-div">
          <p class="brand-text">By</p>
          <img
            alt="Complemar logo"
            src="../../assets/comp_logo.png"
            class="brand-image"
          />
      </div>
      </div>
    </div>
    <div class="ffy-section theme-blue-bg">
      <div class="brand-form theme-blue-bg">
        <slot name="form" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.ffy-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: space-evenly;
}
.ffy-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
}
.brand-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.brand-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.brand-div {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  height:40%
}
.brand-text {
  font-weight: bold;
  font-size: 24px;
}
.brand-image {
  width: 300px;
}
.product-image-div {
  height: 60%;
  display:flex;
  justify-content: center;
  align-items: flex-end;
}
.product-image {
  width: 80%
}
.brand-form {
  padding: 24px;
  min-width: 0px; // Flexbox sets min-width: auto
}
:deep(.brand-form) .is-large {
  font-size: 1.5em !important;
}
:deep(.brand-form) .submit-btn {
  border: 1px solid #fff;
  background: #ffa500;
}
:deep(.brand-form) .submit-btn * {
  color: #fff !important;
}
:deep(.brand-form) .submit-btn:hover {
  border: 1px solid #ffa500;
  background: #fff;
}
:deep(.brand-form) .submit-btn:hover * {
  color: #ffa500 !important;
}

@media screen and (min-width: 561px)
  and (max-width: 880px) {
  .ffy-container {
    display: block;
  }
  .ffy-section {
    width: 100%;
    align-items: flex-start;
    background-color: #fff;
    padding-top: 10%;
    color: #000;
  }
  .brand-section {
    display: block;
    width: 100%;
    padding-top: 5%;
  }
  .brand-box {
    flex-direction: row;
    padding: 24px;
  }
  .product-image-div {
      width: 70%;
  }
  .product-image {
      width: 70%;
  }
  .brand-div {
    justify-content: flex-start;
    align-items: center;
    width: 30%;
  }
  .brand-image {
    width: 200px;
  }
  .brand-text {
    font-size: 16px;
  }
  .brand-form{
    padding: 36px;
    padding-top: 28px;
    border-radius: 5px;
  }
  :deep(.brand-form) .is-large {
    font-size: 1em !important;
  }
}

@media screen and (min-width: 270px)
  and (max-width: 560px){
  .ffy-container {
    display: block;
  }
  .ffy-section {
    width: 100%;
    align-items: flex-start;
    background-color: #fff;
    padding-top: 16%;
    color: #000;
  }
  .brand-section {
    display: block;
    width: 100%;
    padding-top: 5%;
  }
  .brand-box {
    flex-direction: row;
    padding: 24px;
  }
  .product-image-div {
      width: 60%;
  }
  .product-image {
      width: 100%;
  }
  .brand-div {
    justify-content: flex-start;
    align-items: center;
    width: 40%;
  }
  .brand-image {
    width: 100px;
  }
  .brand-text {
    font-size: 12px;
  }
  .brand-form{
    padding-top: 18px;
    border-radius: 5px;
  }
  :deep(.brand-form) .is-large {
    font-size: 1em !important;
  }
}
</style>
