import { newClient, newClientConfig, newUser, newRecipient, newWebhook } from './index';

export const actions = {
  onSetNewClient: ({commit}, c) => commit('setNewClient', c),
  onSetNewClientData: ({commit}, {field, value}) => commit('setNewClientData',{field, value}),
  onSetNewClientConfig: ({commit}, c) => commit('setNewClientConfig', c),
  onSetNewClientConfigData: ({commit}, {field, value}) => commit('setNewClientConfigData',{field, value}),
  onSetNewClientNotificationSettingsData: ({commit}, {field, value}) => commit('setNewClientNotificationSettingsData', {field, value}),
  onSetNewUser: ({commit}, user) => commit('setNewUser', user),
  onSetNewUserData: ({commit}, {field, value}) => commit('setNewUserData', {field, value}),
  onSetNewRecipient: ({commit}, recipient) => commit('setNewRecipient', recipient),
  onSetNewRecipientData: ({commit}, {field, value}) => commit('setNewRecipientData', {field, value}),
  onSetNewWebhook: ({commit}, webhook) => commit('setNewWebhook', webhook),
  onSetNewWebhookData: ({commit}, {field, value}) => commit('setNewWebhookData', {field, value}),
  onSetSelectedClient: ({commit}, client) => commit('setSelectedClient', client),
  onSetSelectedUser: ({commit}, user) => commit('setSelectedUser', user),
  onSetEditSelectedUser: ({commit, state}) => {
    commit('setNewUser', {
      FirstName: state.selectedUser.FirstName,
      LastName: state.selectedUser.LastName,
      Role: state.selectedUser.Role,
      ClientId: state.selectedUser.ClientId,
      Active: state.selectedUser.Active,
      Address1: state.selectedUser.Address.Address1,
      Address2: state.selectedUser.Address.Address2,
      City: state.selectedUser.Address.City,
      State: state.selectedUser.Address.State,
      Zip: state.selectedUser.Address.Zip,
      Country: state.selectedUser.Address.Country,
      Phone: (state.selectedUser.Address.Phone || '').replace(/[^\d]+/g, '')
    });
  },
  onSetSelectedRecipient: ({commit}, recipient) => commit('setSelectedRecipient', recipient),
  onSetSelectedWebhook: ({commit}, webhook) => commit('setSelectedWebhook', webhook),
  onToggleShowAssignServiceLevelsModal: ({commit}) => commit('toggleShowAssignServiceLevelsModal'),
  onToggleShowAddCustomServiceLevelModal: ({commit}) => commit('toggleShowAddCustomServiceLevelModal'),
  onResetNewClient: ({commit}) => {
    commit('setNewClient', newClient());
    commit('setNewClientConfig', newClientConfig());
  },
  onResetNewUser: ({commit}) => commit('setNewUser', newUser()),
  onResetNewRecipient: ({commit}) => commit('setNewRecipient', newRecipient()),
  onResetNewWebhook: ({commit}) => commit('setNewWebhook', newWebhook())
}
