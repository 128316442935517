<template>
  <ComponentHeaderActions>
    <template #actions>
      <ComponentHeaderButton
        btnText="Back"
        iconName="fas fa-caret-left"
        @handler="$router.push({ name: 'Manage' })" />
      <ComponentHeaderWithMoreActions
        btnText="Search"
        iconName="fas fa-search"
        @expanded="$refs.searchBox.select()">
        <template #options>
          <SearchBox
            ref="searchBox"
            @onSearch="$emit('onSearch', $event)"
            @onSearchReset="$emit('onSearchReset')" />
        </template>
      </ComponentHeaderWithMoreActions>
      <ComponentHeaderButton
        btnText="Add Client"
        iconName="fa fa-plus"
        :useButtonStyle="true"
        @handler="$router.push({ name: 'ClientCreateManage' })" />
      <NavigationMenuComponent />
    </template>
  </ComponentHeaderActions>
</template>

<script>
import ComponentHeaderActions from '../../Common/ComponentHeaderActions.vue';
import ComponentHeaderButton from '../../Common/ComponentHeaderButton.vue';
import ComponentHeaderWithMoreActions from '../../Common/ComponentHeaderWithMoreActions.vue';
import SearchBox from '../../Common/Search/SearchBox.vue';
import NavigationMenuComponent from './NavigationMenuComponent.vue';
export default {
  name: 'ClientHeaderActions',
  components: {
    ComponentHeaderActions,
    ComponentHeaderButton,
    ComponentHeaderWithMoreActions,
    SearchBox,
    NavigationMenuComponent
  },
  emits: ['onSearch', 'onSearchReset']
}
</script>
