<template>
  <Container :title="`Webhook - ${webhookEventTypes[this.selectedWebhook.WebhookEventType]}`">
    <template #actions>
      <WebhookEditActions :isWebhookValid="isWebhookValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <WebhookHeaderInfo @isWebhookValid="isWebhookValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <WebhookBasic />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import data from '../../lib/data';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import WebhookEditActions from './Header/WebhookEditActions.vue';
import WebhookHeaderInfo from './Header/WebhookHeaderInfo.vue';
import WebhookBasic from './WebhookBasic.vue';
export default {
  name: 'WebhookDetail',
  components: { Container, EntityData, WebhookEditActions, WebhookHeaderInfo, WebhookBasic },
  data() {
    return {
      isWebhookValid: false,
      webhookEventTypes: data.webhookEventTypes
    }
  },
  created() {
    const sW = this.selectedWebhook;

    const parsedHeaders = JSON.parse(sW.HttpHeaders);
    const formHeaders = Object.keys(parsedHeaders).sort().reduce((headers, name) => {
        headers.push({ Name: name, Value: parsedHeaders[name] });
        return headers;
      }, []);

    this.onSetNewWebhook({
      Id: sW._id,
      IsActive: sW.IsActive,
      HttpMethod: sW.HttpMethod,
      HttpUrl: sW.HttpUrl,
      HttpHeaders: formHeaders,
      NotificationEmail: sW.NotificationEmail
    });
  },
  computed: {
    ...mapGetters('manage', ['selectedWebhook'])
  },
  methods: {
    ...mapActions('manage', ['onSetNewWebhook'])
  }
}
</script>
