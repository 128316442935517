<template>
  <Container title="Create Order">
    <template #actions>
      <OrderCreateActions :isOrderValid="isOrderValid" />
    </template>
    
    <EntityData class="card content-container" gapClass="is-4">
      <template #EntityHeaderInfo>
        <OrderHeaderInfo :isNewOrder="true" @isOrderValid="isOrderValid = $event" />
      </template>
      <template #EntityBasic>
        <OrderBasicEdit isNewOrder='true' />
      </template>
      
      <template #EntityItems>
        <OrderEditItems :isNewOrder="true" />
      </template>
    
    </EntityData>
  </Container>
</template>

<script>
import { mapActions } from 'vuex';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import OrderCreateActions from './Header/OrderCreateActions.vue'
import OrderBasicEdit from './OrderBasicEdit.vue'
import OrderHeaderInfo from './OrderHeaderInfo.vue'
import OrderEditItems from './OrderEditItems.vue'
  export default {
    name: 'OrderCreate',
    components: { Container, EntityData, OrderCreateActions, OrderBasicEdit, OrderHeaderInfo, OrderEditItems },
    data() {
      return {
        isOrderValid: false
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    unmounted() {
      this.onResetNewOrder();
      this.onResetRecipient();
      this.onEmptySelectedItems();
    },
    methods:{
      ...mapActions(['onEmptySelectedItems']),
      ...mapActions('order', ['onResetNewOrder','onResetRecipient'])
    },
  }
</script>
