<template>
  <Container title="Create Recipient">
    <template #actions>
      <RecipientCreateActions :isRecipientValid="isRecipientValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <RecipientHeaderInfo @isRecipientValid="isRecipientValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <RecipientBasic />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script>
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import RecipientHeaderInfo from './Header/RecipientHeaderInfo.vue';
import RecipientCreateActions from './Header/RecipientCreateActions.vue';
import RecipientBasic from './RecipientBasic.vue';
export default {
  name: 'UserCreate',
  components: { Container, EntityData, RecipientHeaderInfo, RecipientCreateActions, RecipientBasic },
  data() {
    return {
      isRecipientValid: false,
    }
  }
}
</script>
