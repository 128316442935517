<template>
  <ComponentHeaderButton
    v-if="returnPath"
    :btnText="returnPathLabel"
    iconName="fas fa-caret-left"
    @handler="$router.push({ name: returnPath })" />
</template>

<script>
import ComponentHeaderButton from './ComponentHeaderButton.vue';
export default {
  name: 'ComponentHeaderBackButton',
  components: { ComponentHeaderButton },
  props: ['label', 'path'],
  computed: {
    returnPath() {
      return this.$route.params.returnPath // Route parameter, via our implementation using the store
        || this.path
        || this.$route.meta.returnPath; // From router config for the current path
    },
    returnPathLabel() {
      return this.label
        || this.$route.meta.returnPathLabel
        || 'Back';
    }
  }
}
</script>
