<template>
  <Container title="Create User">
    <template #actions>
      <UserCreateActions :isUserValid="isUserValid" />
    </template>
    
    <EntityData class="card content-container">
      <template #EntityHeaderInfo>
        <UserHeaderInfo
          :isNewUser="true"
          :validateRole="true"
          :validateClient="true"
          @isUserValid="isUserValid = $event" />
      </template>
      <template #EntityBasic>
        <div class="column is-12">
          <div class="form-section-heading">
            ACCOUNT INFORMATION
          </div>
          <UserAccount
            :isNewUser="true"
            :enableEditingEmailAndPassword="true"
            :enableEditingRole="true"
            :enableEditingClient="true"
            class="end-form-section" />
          <div class="form-section-heading">
            USER INFORMATION
          </div>
          <UserBasic :isNewUser="true" />
        </div>
      </template>
    </EntityData>
  </Container>
</template>

<script setup>
import { ref } from 'vue';
import Container from './../Common/Container.vue';
import EntityData from './../Common/EntityData.vue';
import UserHeaderInfo from './UserHeaderInfo.vue';
import UserCreateActions from './Header/UserCreateActions.vue';
import UserBasic from './UserBasic.vue';
import UserAccount from './UserAccount.vue';

const isUserValid = ref(false);
</script>

<style lang="less" scoped>
.form-section-heading {
  font-size: 16px;
  font-weight: 350;
  letter-spacing: .5;
  margin-bottom: 20px;
  
  :not(:first-child) {
    margin-top: 20px;
  }
}
.end-form-section {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
</style>