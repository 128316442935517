<template>
  <Container title="Create Advance Shipment Notice">
    <template #actions>
      <ShipmentCreateActions :isShipNoticeValid="isShipNoticeValid" />
    </template>
    
    <EntityData class="card content-container" gapClass="is-4">
      <template #EntityHeaderInfo>
        <ShipNoticeHeaderInfo
          :isNewShipNotice="true"
          :isEditable="true"
          @isShipNoticeValid="isShipNoticeValid = $event"/>
      </template>
      <template #EntityBasic>
        <ShipNoticeBasic :isNewNotice="true" containerClass="column is-5" />
      </template>
      
      <template #EntityItems>
        <ShipNoticeItems :isNewNotice="true" />
      </template>
    </EntityData>
  
  </Container>
</template>

<script>
import { mapActions } from 'vuex';
import Container from './../../Common/Container.vue';
import EntityData from './../../Common/EntityData.vue';
import ShipmentCreateActions from './Header/ShipmentCreateActions.vue';
import ShipNoticeBasic from './ShipNoticeBasic'
import ShipNoticeHeaderInfo from './ShipNoticeHeaderInfo'
import ShipNoticeItems from './ShipNoticeItems'
  export default {
    name: 'ShipNoticeCreate',
    components: {
      Container,
      EntityData,
      ShipmentCreateActions,
      ShipNoticeBasic,
      ShipNoticeHeaderInfo,
      ShipNoticeItems },
    data() {
      return {
        isShipNoticeValid: false
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    unmounted() {
      this.onEmptySelectedItems();
      this.onResetNewShipNotice();
    },
    methods:{
      ...mapActions(['onEmptySelectedItems']),
      ...mapActions('asn', ['onResetNewShipNotice'])
    },
  }
</script>
