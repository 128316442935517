<template>
  <Container :title="`Item - ${selectedProduct.StockNumber}`">
    <template #actions>
      <InventoryEditActions :isProductValid="isProductValid" />
    </template>
    
    <div class="content-container" style="padding: 0">
      <Tabs v-if="showTagsUi" style="margin-bottom: 1em" :tabs="tabs" @onTabChange="tabSelected($event)" />
      <div class="card content-container" style="margin: 0">
        <EntityData v-show="selectedTab == 'ItemDetails'">
          <template #EntityHeaderInfo>
            <ProductHeaderInfo 
              :showProductInfo="true" 
              :isEditable="true"
              @isProductValid="isProductValid = $event" />
          </template>
          <template #EntityBasic>
            <ProductBasic />
          </template>
          
          <template #EntityItems>
            <ProductDimensionAndComponents />
          </template>

          <template #EntityExtra>
          </template>
        
        </EntityData>
        <ItemTags v-if="loadTagsTab" v-show="selectedTab == 'Tags'" />
      </div>
    </div>
  </Container>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import Container from './../../Common/Container.vue';
  import Tabs from './../../Common/Tabs.vue';
  import EntityData from './../../Common/EntityData.vue';
  import InventoryEditActions from './Header/InventoryEditActions.vue';
  import ProductHeaderInfo from './ProductHeaderInfo.vue';
  import ProductBasic from './ProductBasic.vue';
  import ProductDimensionAndComponents from './ProductDimensionAndComponents';
  import ItemTags from './ItemTags.vue';
  export default {
    name: 'ProductDetail',
    components: {
      Container,
      Tabs,
      EntityData,
      InventoryEditActions,
      ProductHeaderInfo,
      ProductBasic,
      ProductDimensionAndComponents,
      ItemTags },
    props: ['isEditable'],
    data() {
      return {
        isProductValid: false,
        selectedTab: 'ItemDetails',
        loadTagsTab: false,
        showTagsUi: false
      }
    },
    computed:{
      ...mapGetters(['currentClient']),
      ...mapGetters('product', ['selectedProduct']),
      tabs() {
        const tabs = [{ name: 'ItemDetails', value: 'ItemDetails' }];

        if(this.selectedProduct.ItemType != 'Bundled')
          tabs.push({ name: 'Tags', value: 'Tags' });
        
        return tabs;
      }
    },
    methods:{
       ...mapActions(['onItemsAssignment']),
       ...mapActions('product', [ 'onProductSelect', 'onResetNewProduct', 'onDimensionSelect']),
      tabSelected(tab) {
        this.selectedTab = tab;
        if(tab == 'Tags')
          this.loadTagsTab = true;
      }
    },
    unmounted() {
      this.onResetNewProduct();
      this.onItemsAssignment(new Map());
    },
    created(){
      const tagsUiClients = process.env.VUE_APP_TAGS_REORDER_UI_CLIENTS.split(',');
      this.showTagsUi = tagsUiClients.indexOf(this.currentClient._id.toString()) >= 0;

      const nP = this.selectedProduct;

      const p = {
        IsActive: nP.IsActive,
        StockNumber: nP.StockNumber,
        Barcode: nP.Barcode,
        Description: nP.Description,
        ItemGroupId: nP.ItemGroupId,
        RequiresSerialNumber: nP.RequiresSerialNumber,
        LotNumberRequired: nP.LotNumberRequired,
        HarmonizationCode: nP.HarmonizationCode,
        Price: nP.Price || '',
        Cost: nP.Cost || '',
        IsCaseable: nP.IsCaseable,
        PackUnit: nP.IsCaseable ? nP.PackUnit : '',
        ExpirationDateRequired: nP.ExpirationDateRequired,
        CycleTime: nP.CycleTime,
        ItemType: nP.ItemType,
        ReorderPoint: nP.ReorderPoint,
        ReorderQty: nP.ReorderQty,
        OriginalStockNumber: nP.OriginalStockNumber,
        ImageFile: nP.ImageFile,
        PdfFile: nP.PdfFile
      }

      if(nP.Components && nP.Components.length > 0)
      {
        const comps = new Map();

        for(let c of nP.Components)
          comps.set(c.Link.Name, { StockNumber: c.Link.Name, Quantity: c.Quantity })
        
        this.onItemsAssignment(comps);
      }
      this.onDimensionSelect( nP.Dimensions )
      this.onProductSelect(p);
    },
    mounted() {
      window.scrollTo(0, 0);
    }
  }
</script>
