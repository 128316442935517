<template>
  <div>
    <slot name="EntityActions"></slot>
    <slot name="EntityHeaderInfo"></slot>
    <div class="columns" :class="gapClass">
      <slot name="EntityBasic"></slot>
      <slot name="EntityItems"></slot>
      <slot name="EntityExtra"></slot>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(['gapClass']);
const gapClass = props['gapClass'] ? `is-variable ${props['gapClass']}` : '';
</script>
