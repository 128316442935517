<template>
  <div class="component theme-gray-bg">
    <ComponentHeader>
      <template #title>
        <slot name="title">
          {{ title || $route.name }}
        </slot>
      </template>
      <template #subtitle>
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </template>
      <template #actions v-if="!computedHaveError">
        <slot name="actions">
          <ComponentHeaderActions />
        </slot>
      </template>
    </ComponentHeader>

    <div v-if="!computedHaveError" id="mainContent">
      <slot />
    </div>

    <FailedLoading v-if="computedHaveError" :computedErrorId="computedErrorId" />

    <Dock class="dock" :haveError="computedHaveError" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FailedLoading from './../Common/FailedLoading';
import ComponentHeader from './../Common/ComponentHeader.vue';
import ComponentHeaderActions from './../Common/ComponentHeaderActions.vue';
import Dock from './../Common/Dock.vue';
export default {
  name: 'Container',
  props: [ 'title', 'subtitle', 'haveError', 'errorId' ],
  components: { FailedLoading, ComponentHeader, ComponentHeaderActions, Dock },
  computed: {
    ...mapGetters(['pageError']),
    computedHaveError() {
      // TODO: this should eventually only come from the state
      return this.pageError.haveError || this.haveError;
    },
    computedErrorId() {
      // TODO: this should eventually only come from the state
      return this.pageError.errorId || this.errorId;
    }
  }
}
</script>

<style lang="less" scoped>
  .component{
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .dock {
    margin-top: auto;
    position: sticky;
    position: -webkit-sticky; // Required for Safari before 2019
    bottom: 0;
  }
</style>